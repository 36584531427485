.containerOfReturn {
    top: 190px;
    left: 205px;
    position: absolute;
    border-color: coral;
    border: solid 0.3em;
    height: 870px;
    width: 1670px;
    background: rgba(255, 255, 255, 1);
    z-index: 1000;
}

.CloseButtonReturn {
    /*left: 95.4%;*/
    left: 97.65%;
    top: -0.1%;
    /*left: 1210px;*/
    position: relative;
    width: 40px;
    height: 30px;
}

.NewReturnMessage {
    color: rgba(10, 180, 10, 1.0);
    font-size: 20pt;
    left:2%;
    position: relative;
}

.WrapperAllTables {
    width: 1180px;
    height: 600px;
    top: 20%;
    /*border-style: dashed;*/
    /*border: 5px;*/
    /*border-color: black;*/
    position: absolute;

}

.ChooseChangesTableTBody {
    width: 300px;
    /*height: 300px;*/
    height: 243px;
    position: absolute;
    border: 1px;
    /*border: 3px solid;*/
    /*border-color: coral;*/
    overflow-y: scroll;
}




.ChooseReturnsTable {
    position: absolute;
    text-align: center;
    border-collapse: collapse;
    width: 26.7%;
    display: block;
    left: 2.8%;
    table-layout: fixed;
    /*border: 3px;*/
    /*border: 1px solid #ddd;*/
    overflow-y: auto;
    overflow-x: hidden;
    /*height: 270px;*/
    /*left: 375px;*/
    height: 488px;
    max-height: 488px;
    border: 3px;
    /*border: 1px solid red;*/
}

.ChooseReturnsTable td{
    border: 0.2px solid #ddd;
    /*width: 80px;*/
    font-size: 100%;
    /*width: 100px;*/
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    text-overflow: ellipsis; overflow: hidden;
    white-space: nowrap;
    /*border: 1px solid;*/
    /*word-wrap: break-word;*/
    /*max-width: 50px;*/
}

.ChooseReturnsTable th {
    font-weight: bold;
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;;
    padding-bottom: 2px;;
    text-align: center;
    background-color: rgba(10, 180, 10, 1.0);
    color: white;
    position: sticky;
    height: 35px;

    /*word-wrap: break-word;*/
    max-width: 300px;
}

.ChooseReturnsTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;
}

.inputChooseChangesTable {
    width: 100%;
    /*height: 110%;*/
    height: 20px;
    font-size: 100%;
    /*max-width: 80px;*/
}

.ChooseReturnsTable tr:nth-child(even){background-color: #f2f2f2;}

.ChooseReturnsTable th:nth-child(1) {width:  37px; text-align: center; padding-right: 3px;}
.ChooseReturnsTable th:nth-child(2) {width: 47px; text-align: center; padding-right: 3px;}
.ChooseReturnsTable th:nth-child(3) {width: 217px; text-align: center;}

.ChooseReturnsTable td:nth-child(1) {width:  37px; text-align: right; padding-right: 3px;}
.ChooseReturnsTable td:nth-child(2) {width: 47px; max-width: 47px; text-align: right; padding-right: 3px;}
.ChooseReturnsTable td:nth-child(3) {width: 217px; max-width: 205px; text-align: left;}

.ChooseReturnsTable tr:hover {background-color: #ddd;}


.ReturnTable {
    position: absolute;
    text-align: center;
    font-size: small;
    border-collapse: collapse;
    width: 77.3%;

    display: block;
    /*height: 270px;*/
    left: 375px;
    height: 488px;
    max-height: 488px;
    table-layout: fixed;
    border-color: coral;
    border: 3px;
    /*border: 1px solid red;*/
    overflow: auto;
}


.ChangeTableHeader {
    font-size: 12.0pt;
}


.ChangeTableBody{
    width: 100%;
    font-size: 12.0pt;
    max-height: 243px;
}

.ReturnTable td{
    border: 0.2px solid #ddd;
    /*width: 80px;*/
    font-size: 100%;
    width: 100px;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;

    max-width: 50px;
}

.ReturnTable th {
    font-weight: bold;
    /*font-weight: normal;*/
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: rgba(10, 180, 10, 1.0);
    color: white;
    position: sticky;
    height: 35px;

    max-width: 300px;
}

.ReturnTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
}

.inputChangeTable {
    width: 100%;
    /*height: 110%;*/
    height: 20px;
    font-size: 100%;
    /*max-width: 80px;*/
}

.ReturnTable tr:nth-child(even){background-color: #f2f2f2;}

/*.ChangeTable td:nth-child(1) {width: 30px; text-align: center}*/
/*.ChangeTable td:nth-child(2) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(3) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(4) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(5) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(6) {width: 50px; text-align: right}*/
/*.ChangeTable td:nth-child(7) {width: 40px; text-align: right}*/
/*.ChangeTable td:nth-child(8) {width: 60px; text-align: right}*/
/*.ChangeTable td:nth-child(9) {width: 50px; text-align: right}*/
/*.ChangeTable td:nth-child(10){width: 20px; text-align: right}*/
/*.ChangeTable td:nth-child(11){width: 50px; text-align: left}*/
/*.ChangeTable td:nth-child(12){width: 50px; text-align: right}*/

.ReturnTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.ReturnTable th:nth-child(2) {width: 127px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(3) {width: 97px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(4) {width: 97px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(5) {width: 97px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(6) {width: 99px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(7) {width: 147px; text-align: center; padding-right: 3px;}
.ReturnTable th:nth-child(8) {width: 147px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.ReturnTable th:nth-child(9) {width:  44px; max-width: 44px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.ReturnTable th:nth-child(10){width:  47px; text-align: center; padding-right: 3px;}
.ReturnTable th:nth-child(11){width:  87px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(12){width:  67px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}

.ReturnTable td:nth-child(1) {width:  57px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(2) {width: 127px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(3) {width: 97px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(4) {width: 97px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(5) {width: 97px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(6) {width: 99px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(7) {width: 147px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(8) {width: 147px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(9) {width:  44px; max-width: 44px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(10){width:  47px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(11){width:  87px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(12){width:  67px; text-align: right; padding-right: 3px;}

.ReturnTable tr:hover {background-color: #ddd;}






.NewReturnFieldShipping {
    top: 255.5px;
    width: 100px;
    left: 87.4%;
    /*border: 1px solid;*/
    position: absolute;
}

.NewReturnFieldShippingName {
    width: 70px;
}

.NewReturnFieldShippingValue {
    left: 70%;
    width: 50px;
    position: absolute;
}


.NewReturnDate{
    position: absolute;
    border-color: black;
    border: 4px;
    top: -2.9%;
    left: 87%;
}













.NewReturnTicket {
    left: 1330px;
    top: 20%;
    width: 330px;
    height: 450px;
    /*border: 2px solid #ddd;*/
    position: absolute;
}


/*.NewChangeTicketSummary{*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 240px;*/
/*    border: 2px solid #ddd;*/
/*}*/

.NewReturnTicketSummaryHeader{
    position: absolute;
    width: 100%;
}

.NewReturnTicketSummaryHeader th{
    position: absolute;
    text-align: center;
    width: 100%;
    /*height: 20px;*/
    color: white;
    border: none;
    background-color: #4CAF50;
}


.ReturnSummaryAbove {
    /*border-bottom: 1px dashed #ddd;*/
    position: absolute;
    /*top: 50px;*/
    text-align: center;
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    font-size: 11.0pt;
    border-collapse: collapse;
    /*border: 1px solid red;*/
    border-top: none;
    display: block;
    width: 100%;
    height: 323px;
    height: 405px;
    overflow-y: auto;
    white-space: nowrap;
    /*top: 30px;*/
}


.ReturnSummaryAbove td:nth-child(1) {width: 140px; max-width: 130px; text-overflow: ellipsis; overflow: hidden; padding-left: 10px;}
.ReturnSummaryAbove td:nth-child(2) {width: 50px; max-width: 50px; text-overflow: ellipsis; overflow: hidden; text-align: center;}
.ReturnSummaryAbove td:nth-child(3) {width: 50px; max-width: 50px; text-overflow: ellipsis; overflow: hidden; text-align: center; }
.ReturnSummaryAbove td:nth-child(4) {width: 70px; max-width: 70px; text-overflow: ellipsis; overflow: hidden; text-align: right; }

.ReturnSummaryAbove th:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px;}
.ReturnSummaryAbove th:nth-child(2) {width: 50px; max-width: 50px; text-align: center;}
.ReturnSummaryAbove th:nth-child(3) {width: 50px; max-width: 50px; text-align: center;}
.ReturnSummaryAbove th:nth-child(4) {width: 70px; max-width: 70px; text-align: right; }


.ReturnSummaryAbove th {
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
}

.ReturnSummaryAbove th:nth-child(1) {
    color: red;
}

.ReturnSummaryAbove th td {
    border-bottom: 0.5px solid;
}

.ReturnSummaryAbove td {
    /*border: 0.5px solid #ddd;*/
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
}

.ReturnSummaryAboveBody {
    width: 100%;
    /*height: 100%;*/
    position: absolute;
    /*border: 1px solid #ddd;*/
    /*border: 2px solid #ddd;*/
    /*border-bottom: none;*/
    /*border: 1px;*/
}

/*.ReturnSummaryBelowTBody {*/
/*    width: 100%;*/
/*    height: 135px;*/
/*    top: 155px;*/
/*    overflow-y: scroll;*/
/*    position: absolute;*/
/*    !*border: 1px;*!*/
/*}*/

/*.ReturnSummaryBelow {*/
/*    text-align: center;*/
/*    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
/*    font-size: small;*/
/*    border-collapse: collapse;*/
/*    border: 1px solid #ddd;*/
/*    border-top: none;*/
/*    width: 100%;*/
/*}*/


/*.ReturnSummaryBelow th {*/
/*    line-height: 20px;*/
/*    text-align: left;*/
/*    background-color: rgb(255, 255, 255);*/
/*}*/

/*.ReturnSummaryBelow th:nth-child(1) {*/
/*    color: green;*/
/*}*/

/*.ReturnSummaryBelow th td {*/
/*    border-bottom: 0.5px solid;*/
/*}*/

/*.ReturnSummaryBelow td {*/
/*    !*border: 0.5px solid #ddd;*!*/
/*    line-height: 20px;*/
/*    text-align: left;*/
/*    background-color: rgb(255, 255, 255);*/
/*}*/

.NewReturnSummaryTotals{
    width: 100%;
    /*top: 200px;*/
    top: 384px;
    top: 470px;
    height: 120px;
    position: absolute;
    font-size: 11.0pt;
}


.NewReturnSummaryTotalsTable{
    width: 100%;
    position: absolute;
}

.NewReturnSummaryTotalsTable td:nth-child(1){
    width: 75%;
}
.NewReturnSummaryTotalsTable td:nth-child(2){
    width: 25%;
}









.NewReturnTicketClient{
    top: 326px;
    top: 500.0px;
    /*top: 465px;*/
    width: 100%;
    height: 80px;
    position: absolute;
    border: 2px solid #ddd;
}

.NewReturnClientDataHeader{
    left: 38%;
    position: absolute;
    text-align: center;
    top: 9px;
    font-size: 12pt;
    font-weight: bold;
}

.NewReturnTicketClientHeader {
    background-color: rgba(10, 180, 10, 1);
    color: white;
    width: 100%;
    height: 35px;
}

.NewReturnTicketSummary{
    position: absolute;
    width: 100%;
    /*height: 300px;*/
    height: 485px;
    height: 580px;
    border: 2px solid #ddd;
}

.horizontalDivisionInTableForTotalsReturn {
    border-bottom: 1.5px dashed #ddd;
    /*width: 92.5%;*/
    width: 100%;
    /*left: 2.5%;*/
    /*top: 63%;*/
    top: 76.7%;
    top: 78.8%;
    position: absolute;
    z-index: 500;
}

.NewReturnClientDataHeader{
    left: 40%;
    position: absolute;
}

.NewReturnClientDataEmail{
    top: 60%;
    width: 40%;
    height: 80%;
    position: absolute;
    /*border: 2px solid #ddd;*/
}

.NewReturnClientDataMessageEmail{
    position: absolute;
    height: 25px;
    left: 8%;
    font-size: 11pt;
    /*border: 1px solid;*/
}

.NewReturnClientDataFormEmail{
    position: absolute;
    left: 100%;
}






.buttonScanAndPayReturn{
    /*top: 435px;*/
    top: 605px;
    top: 615px;
    width: 100%;
    height: 38.5px;
    position: absolute;
}

.buttonScanAndPayButtonReturn {
    position: absolute;
    width: 55%;
    left: 22.5%;
    height: 100%;
    border-radius: 5%;
    /*background-color: ;*/
}

