.My_area_general_container {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	/*transform: translate(-50%, -50%);*/
	flex-direction: column; 
	align-items: center; 
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*border-radius: 5px;*/
	width: 100%;
	/*width: 1920px;*/
	height: 100%;
	/*height: 1057px;*/
	/*height: 1088px;*/
	background-color: white;
}


.TIDI_Title {
	margin-left: 2.5%;
	margin-top: 0.5%;
	font-size: 200%;
	color: #FFFFFF;
	position: absolute;
}

#title {
  text-align: center;
  /*font-family: Calibri, sans-serif;*/
}

.welcomeName {
	/*margin-left: 73.0%;*/
	/*margin-top: 19px;*/
	/*margin-right: 20%;*/
	margin-top: 19px;
	color: white;
	height: 100%;
	width: 40%;
	/*width: 100%;*/
	position: absolute;
	text-align: right;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*background-color: red;*/
	/*align-items: center;*/
	/*font-size: 18px;*/
	font-size: 14pt;
	right: 120px;
}

.welcomeNameSpan {
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*margin-top: 140%;*/
	height: 100%;
	text-decoration: underline;
	/*vertical-align: middle;*/
	/*display: inline-block;*/
}

.HistoricalOperationsDiv {
	/*border: 1px solid red;*/
	top:190px;
	/*width: 68.4%; */
	width: 100%;
	/* height: 50%; */
	/*height:80%;*/
	/*height: 800px;*/
	/*height: 840px;*/
	z-index: 10;
	position: absolute;
	display: flex;
	font-size: 12pt;
}

.filterHistoricalData {
	font-size: 85%;
	border: 1px solid #ddd;
	/*width: 9%;*/
	/*width: 185px;*/
	width: 197px;
	height: 63%;
	height: 96%;
	height: 480px;
	margin-left: 100px;
	position: absolute;
}
.filterHistoricalDataPerson {
	border: 1px solid #ddd;
	/*width: 9%;*/
	/*width: 185px;*/
	width: 197px;
	height: 49.5%;
	margin-left: 100px;
	position: absolute;
}


.buttonFilterOperations {
	width: 100%;
	height: 100%;
	background-color: red;
	font-size: 100%;
}

.renderFiltersHeader {
	text-align: center;
	/*font-size: 12pt;*/
	border-collapse: collapse;
	border: 1px solid #ddd;
	width: 100%;
	/*height: 7%;*/
	height: 35px;
}

.renderFiltersHeader th {
	border: 0.5px solid #ddd;
	padding: 1px;
	height: 20px;
	/*padding-top: 10px;*/
	position: sticky;
	top: 0;
	/*padding-bottom: 10px;*/
	text-align: center;
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	color: white;
}

.filterHistoricalDataForm {
	width: 100%;
	height: 80%;
}

.filterFieldName {
	margin-left: 14%;
	margin-right: 11%;
	margin-top: 12.0px;
	width: 75%;
	/*height: 20px;*/
	/*height: 200%;*/
	/*border: solid 1pt;*/
	/*font-size: 100%;*/
	/*font-size: 12.5pt;*/
	font-size: 110%;
}

.filterFieldField {
	margin-left: 11%;
	margin-right: 11%;
	/*margin-top: 0.6%;*/
	width: 73%;
	/*height: 5.0%;*/
	/*height: 27px;*/
	/*height: 18px;*/
	/*height: */
	/*min-height: 7%;*/
	/*margin-bottom: 4px;*/
	font-size: 100%;
}

.filterFieldFieldDate {
	margin-left: 11%;
	margin-right: 11%;
	/*margin-top: 0.6%;*/
	width: 74.5%;
	/*height: 5.0%;*/
	height: 27px;
	height: 20px;
	/*min-height: 7%;*/
	/*margin-bottom: 4px;*/
	font-size: 100%;
}

.filterFieldFieldButton {
	margin-left: 11%;
	margin-right: 11%;
	/*margin-top: 55%;*/
	width: 77%;
	/*height: 6.0%;*/
	/*height: 34px;*/
	/*height: 25px;*/
	/*min-height: 7%;*/
	/*margin-bottom: 4px;*/
	/*font-size: 105%;*/
	text-align: center;
	border: 1px solid;
	background-color: rgba(230, 230, 230, 1);
}
.filterFieldFieldButton:hover {
	background-color: rgba(190, 190, 190, 1);
}
.renderFilterOperationType {
	text-align: left;
	/*font-size: 12pt;*/
	position: absolute;
}
.renderFilterEmployee {
	text-align: left;
	/*font-size: 12pt;*/
	width: 130px;
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	/*margin-left: -4%;*/
}


.dropDownOperations{
	height: 20%;
	width: 100%;
}

.dropdownOperationChosen {
	left: 11.0%;
	/*width: 100px;*/
	width: 76.0%;
	height: 80px;
	position: absolute;
	background-color: white;
	border: 1px solid;
	border-color: rgba(150, 150, 150, 1);
	/*font-size: 12.5pt;*/
}

.dropdownOperationChosen a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}

.Operations_button{
	margin-left: 3%;
	margin-right: 3%;
	width: 94%;
}

.dropDownOperations{
	height: 20%;
	width: 100%;
}


.vertical_dropdown {
	margin-left: 1%;
	margin-right: 5%;
	width: 94%;
}

.vertical_dropdown.menu{
}

.dropdown-menu {
	position:absolute !important;
	height: 100px;
	width: 300px;
	overflow-y: scroll;
	border-color: rgba(46,139,87, 0.1);
	border: solid 0.3em;
	background-color: rgb(255,255,255);
	z-index: 100;
}


.ButtonsFilter {
	margin-left: 11%;
	margin-top: 16%;
	width: 75%;
	height: 23px;
	/*font-size: 12pt;*/
	/*background-color: red;*/
}

.Clear_button {
	/*margin-left: 11%;*/
	/*margin-top: 60%;*/
	width: 36%;
	position: absolute;
	height: 23px;
	/*font-size: 11pt;*/
	border: 1px solid;
	/*background-color: rgba(200, 200, 200, 1);*/
	background-color: rgba(230, 230, 230, 1);
}
.Clear_button:hover {
	background-color: rgba(190, 190, 190, 1);
}

.UnderstandMissingInformation {
	margin-left: 35%;
	width: 30%;
	position: absolute;
	height: 25px;
	font-size: 11pt;
	border: 1px solid;
	/*background-color: rgba(200, 200, 200, 1);*/
	color: white;
	border-color: black;
	background-color: rgba(10, 130, 10, 0.85);
}
.UnderstandMissingInformation:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.Filter_button {
	margin-left: 41%;
	/*margin-top: 15%;*/
	/*margin-top: 60%;*/
	width: 36%;
	position: absolute;
	height: 23px;
	/*font-size: 11pt;*/
	border: 1px solid;
	background-color: rgba(230, 230, 230, 1);
}
.Filter_button:hover {
	background-color: rgba(190, 190, 190, 1);
}


.HistoricalOperationsTable {
	font-size: 100%;
	font-size: 85%;
	width: 71.4%;
	width: 68.5%;
	height: 98.5%;
	/*height: 540px;*/
	/*height: 110px;*/
	/*height: 25%;*/
	margin-left: 350px;
	display: block;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	table-layout: fixed;
	empty-cells: show;
	overflow-y: auto;
	overflow-x: hidden;
	border: 1px solid red;
}


.HistoricalOperationsTable tr:nth-child(even){background-color: #f2f2f2;}

.HistoricalOperationsTable th {
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	/*font-size: 12pt;*/
	font-weight: bold;
	color: white;
	border-right: 0.5px solid #ddd;
	/*width: 1000px;*/
	/*height: 35px;*/
	/*height: 100%;*/
	/*padding: 2px;*/
}

.HistoricalOperationsTableHeader {
	width: 100%;
}

.HistoricalOperationsTableHeader tr{
	width: 100%;
	height: 4.0vh;
	/*font-size: 13pt;*/
	/*font-weight: normal;*/
}


.HistoricalOperationsTableTBody  {
	overflow: hidden;
	white-space: nowrap;
	height: 94.7%;
	/*height: 100%;*/
	width: 100%;

	/*width: 700px;*/
}

.HistoricalOperationsTableTBody tr {
	width: 100%;
	max-width: 100%;
	height: 3.1vh;
	max-height: 3.1vh;
	/*max-height: 30px;*/
}

.HistoricalOperationsTableTBody tr:hover {background-color: #ddd;}


.HistoricalOperationsTable th:nth-child(1) {
	width: 40px; min-width: 40px; max-width: 40px;
	/*width: 10%; min-width: 10%; max-width: 10%;*/
	text-align: center; padding-right: 4px;}
.HistoricalOperationsTable th:nth-child(2) {
	width: 43px; min-width: 43px; max-width: 43px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}
.HistoricalOperationsTable th:nth-child(3) {
	width: 43px; min-width: 43px; max-width: 43px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}
.HistoricalOperationsTable th:nth-child(4) {
	width: 43px; min-width: 43px; max-width: 43px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}
.HistoricalOperationsTable th:nth-child(5) {
	width: 220px;min-width: 219px; max-width: 219px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTable th:nth-child(6) {
	width: 110px;min-width: 110px; max-width: 110px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTable th:nth-child(7) {width: 130px;min-width: 129px; max-width: 129px;
	width: 95px; min-width: 95px; max-width: 95px;
	text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTable th:nth-child(8) {width: 130px;min-width: 129px; max-width: 129px;
	width: 95px; min-width: 95px; max-width: 95px;
	text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTable th:nth-child(9) {width: 100px;min-width: 100px; max-width: 100px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center; padding-right: 3px;  padding-left: 3px;}
.HistoricalOperationsTable th:nth-child(10){width: 180px;min-width: 180px; max-width: 180px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable th:nth-child(11){width: 50px;min-width: 50px; max-width: 50px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable th:nth-child(12){
	width: 45px;min-width: 45px; max-width: 45px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}

.HistoricalOperationsTable td:nth-child(1) {width: 50px;  min-width: 50px;  max-width: 50px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: right; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(2) {width: 67px;  min-width: 67px;  max-width: 67px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(3) {width: 67px;  min-width: 67px;  max-width: 67px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(4) {width: 67px;  min-width: 67px;  max-width: 67px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(5) {
	width: 220px; min-width: 220px; max-width: 220px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: left;padding-left: 3px; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(6) {
	width: 110px;min-width: 110px; max-width: 110px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: left;padding-left: 3px; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(7) {width: 130px;min-width: 130px; max-width: 130px;
	width: 95px; min-width: 95px; max-width: 95px;
	text-align: left;padding-left: 5px; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(8) {width: 130px;min-width: 130px; max-width: 130px;
	width: 95px; min-width: 95px; max-width: 95px;
	text-align: left;padding-left: 5px; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(9) {width: 100px;min-width: 100px; max-width: 100px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: right; padding-right: 3px; padding-left: 3px;}
.HistoricalOperationsTable td:nth-child(10){width: 160px;min-width: 160px; max-width: 160px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(11){width: 50px;min-width: 50px; max-width: 50px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(12){
	width: 45px;min-width: 45px; max-width: 45px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}

.HistoricalOperationsTable td {
	width: 1200px;
	border: 0.5px solid #ddd;
	/*padding: 1px;*/
}


.HistoricalOperationsTableTBody td {
	/*display: block;*/
	/*white-space: nowrap;*/
	/*word-wrap: break-word;*/
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: 3.5px;
}

.MoreInfoButton {
	width: 100%;
	height: 100%;
	/*font-size: 12pt;*/
	font-size: 90%;
	border: none;
	background-color: transparent;
	text-decoration: underline;
}

.HistoricalOperationsTablePSI {
	font-size: 100%;
	width: 71.4%;
	width: 75.5%;
	height: 98.5%;
	/*height: 540px;*/
	/*height: 110px;*/
	/*height: 25%;*/
	margin-left: 350px;
	display: block;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	table-layout: fixed;
	empty-cells: show;
	overflow-y: auto;
	overflow-x: hidden;
}

.HistoricalOperationsTablePSI thead, .HistoricalOperationsTablePSI tbody {

}

.HistoricalOperationsTablePSI tr:nth-child(even){background-color: #f2f2f2;}

.HistoricalOperationsTablePSI th {
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	font-size: 12pt;
	font-weight: bold;
	color: white;
	border-right: 0.5px solid #ddd;
	/*height: 35px;*/
	/*height: 100%;*/
	/*padding: 2px;*/
}

.HistoricalOperationsTableHeaderPSI {
	width: 100%;
}

.HistoricalOperationsTableHeaderPSI tr{
	width: 100%;
	height: 3.1vh;
	/*font-size: 13pt;*/
	/*font-weight: normal;*/
}


.HistoricalOperationsTableTBodyPSI  {
	overflow: hidden;
	white-space: nowrap;
	height: 94.7%;
	/*height: 100%;*/
	width: 100%;

	/*width: 700px;*/
}

.HistoricalOperationsTableTBodyPSI tr {
	width: 100%;
	max-width: 100%;
	height: 3.1vh;
	max-height: 3.1vh;
	/*max-height: 30px;*/
}

.HistoricalOperationsTableTBodyPSI tr:hover {background-color: #ddd;}


.HistoricalOperationsTablePSI th:nth-child(1) {width: 50px; min-width: 50px; max-width: 50px; text-align: center; padding-right: 4px;}
.HistoricalOperationsTablePSI th:nth-child(2) {width: 67px; min-width: 67px; max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(3) {width: 67px; min-width: 67px; max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(4) {width: 67px; min-width: 67px; max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(5) {width: 67px;min-width: 67px; max-width: 67px; text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTablePSI th:nth-child(6) {width: 220px;min-width: 220px; max-width: 220px; text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTablePSI th:nth-child(7) {width: 220px;min-width: 220px; max-width: 220px; text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTablePSI th:nth-child(8) {width: 130px;min-width: 129px; max-width: 129px; text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTablePSI th:nth-child(9) {width: 130px;min-width: 130px; max-width: 130px; text-align: center; padding-right: 3px;  padding-left: 3px;}
.HistoricalOperationsTablePSI th:nth-child(10){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(11){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(12){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}

.HistoricalOperationsTablePSI td:nth-child(1) {width: 50px;  min-width: 50px;  max-width: 50px; text-align: right; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(2) {width: 67px;  min-width: 67px;  max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(3) {width: 67px;  min-width: 67px;  max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(4) {width: 67px;  min-width: 67px;  max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(5) {width: 67px; min-width: 67px; max-width: 67px; text-align: left;padding-left: 3px; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(6) {width: 220px;min-width: 220px; max-width: 220px; text-align: left;padding-left: 3px; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(7) {width: 220px;min-width: 220px; max-width: 220px; text-align: left;padding-left: 5px; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(8) {width: 130px;min-width: 130px; max-width: 130px; text-align: left;padding-left: 5px; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(9) {width: 130px;min-width: 130px; max-width: 130px; text-align: right; padding-right: 3px; padding-left: 3px;}
.HistoricalOperationsTablePSI td:nth-child(10){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(11){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(12){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}

.HistoricalOperationsTablePSI td {
	border: 0.5px solid #ddd;
	/*padding: 1px;*/
}


.HistoricalOperationsTableTBodyPSI td {
	/*display: block;*/
	/*white-space: nowrap;*/
	/*word-wrap: break-word;*/
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: 3.5px;
}










.Flag_sale {
	background-color: rgb(0,128,0);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}

.Flag_change {
	background-color: rgb(255,165,0);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}

.Flag_return {
	background-color: 	rgb(220,20,60);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}




.ButtonsEnv {
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	top: 60px;
	height: 145px;
	width: 100%;
	position: absolute;
	border: none;
	/*display: flex;*/
}

.NewSaleButton {
	font-size: 11pt;
	top: 40%;
	margin-left: 350px;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.NewSaleButton:hover {
	background-color: rgba(190, 190, 190, 1);
}

.modifyCompany {
	font-size: 11pt;
	top: 40%;
	margin-left: 544px;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.modifyCompany:hover {
	background-color: rgba(190, 190, 190, 1);
}

.removeCompany {
	font-size: 11pt;
	top: 40%;
	margin-left: 699px;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.removeCompany:hover {
	background-color: rgba(190, 190, 190, 1);
}

.sendEmails2CompaniesButton {
	font-size: 11pt;
	top: 40%;
	margin-left: 1500px;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.sendEmails2CompaniesButton:hover {
	background-color: rgba(190, 190, 190, 1);
}


.NewChangeButton {
	font-size: 11pt;
	top: 40%;
	/*margin-left: 1400px;*/
	/*margin-left: 68.5%;*/
	/*margin-left: 71.2%;*/
	margin-left: 70.3%;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.NewChangeButton:hover {
	background-color: rgba(190, 190, 190, 1);
}

.NewReturnButton {
	font-size: 11pt;
	top: 40%;
	/*margin-left: 1507px;*/
	margin-left: 78.6%;
	/*height: 35%;*/

	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.NewReturnButton:hover {
	background-color: rgba(190, 190, 190, 1);
}

.UpdateButton {
	font-size: 11pt;
	top: 40%;
	/*left: 88%;*/
	/*margin-left: 1673.2px;*/
	/*margin-left: 89.0%;*/
	margin-left: 89.8%;
	/*height: 35%;*/
	height: 38px;
	width: 4%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.UpdateButton:hover {
	background-color: rgba(190, 190, 190, 1);
}

.UpdateButtonPSI {
	font-size: 11pt;
	top: 40%;
	/*left: 88%;*/
	/*margin-left: 1673.2px;*/
	/*margin-left: 89.0%;*/
	margin-left: 89.8%;
	/*height: 35%;*/
	height: 38px;
	width: 4%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.UpdateButtonPSI:hover {
	background-color: rgba(190, 190, 190, 1);
}


.HeaderLandingPageMyArea {
	border-color: rgba(0,128,0, 0.9);
	border: solid 0;
	height: 60px;
	width: 100%;
	/*background-color: rgba(0,128,0, 0.9);*/
	/*background-color: rgba(28, 210, 28, 1.0);*/
	/*background-color: rgba(10, 195, 10, 1.0);*/
	background-color: rgba(10, 180, 10, 1.0);
	position: absolute;
	/*border: none;*/
	margin: 0 !important;
	padding : 0 !important;
}

.LogoLandingPageMyArea{
	margin-left: 1% !important;
	margin-top: 0.45%;
	color: white;
	border: none;
	/*border: solid 0.1em;*/
	/*font-size: 300%;*/
	font-size: 38px;

	position: absolute;
}

.LogoLandingPageMyAreaText {
	margin-left: 10% !important;
	width: 100%;
	/*border: solid 0.1em;*/
	position: absolute;
}

.navigationButtons {
	/*margin-left: 15.9%;*/
	margin-left: 18.4%;
	/*margin-left: 305px;*/
	height: 100%;
	width: 1320px;
	width: 66.8%;
	background-color: Transparent;
	position: absolute;
	font-size: 14.0pt;
	/*border-color: red;*/
	/*border: solid 0.2em;*/
	border: none;
}

.navigationButtonsRow {
	margin: 0 !important;
	margin-left: 0.4% !important;
	padding: 0 !important;
	height: 100%;
	width: 100%;
}

.basicColumn {
	margin: 0 !important;
	padding: 0 !important;
}

.HistoricalLandingPageMyArea{
	height: 100%;
	width: 20%;
	color: white;
	background-color: Transparent;
	/*background-color: rgba(255, 255, 255, 0.25);*/
	font-size: 14pt;
	position: absolute;
	border: none;
	/*border: 2px solid rgba(255, 255, 255, 0.45);*/
	/*text-decoration: underline;*/
	font-weight: bold;
	/*border: 1px solid;*/
	cursor: pointer;
}
.HistoricalLandingPageMyArea:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

.MyProfileLandingPageMyArea {
	margin-left: 20%;
	height: 100%;
	/*width: 25%;*/
	width: 20%;
	color: white;
	background-color: Transparent;
	font-size: 14pt;
	position: absolute;
	/*border: none;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	border: none;
	cursor: pointer;
}
.MyProfileLandingPageMyArea:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}


.LogOutButtonMyArea {
	/*margin-left: 94.5%;*/
	height: 100%;
	width: 90px;
	/*top: 2px;*/
	background-color: Transparent;
	background-repeat:no-repeat;
	font-size: 14pt;
	position: absolute;
	color: white;
	border: none;
	cursor: pointer;
	right: 0px;
	bold: false;
}

.LogOutButtonMyArea:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

/*.containerOfSale {*/
/*	top: 190px;*/
/*	left: 285px;*/
/*	position: absolute;*/
/*	border-color: coral;*/
/*	border: solid 0.3em;*/
/*	height: 630px;*/
/*	height: 870px;*/
/*	width: 1270px;*/

/*	background: rgba(255, 255, 255, 1);*/
/*	z-index: 1000;*/
/*}*/

.containerOfSale {
	top: 117px;
	left: 308px;
	position: absolute;
	border-color: coral;
	border: 3px solid;
	/*height: 800px;*/
	/*width: 1310px;*/
	/*height: 630px;*/
	height: 870px;
	height: 830px;
	width: 1350px;

	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}



.CloseButton {
	left: 94.1%;
	left: 97.0%;
	top: -0.1%;
	/*left: 1210px;*/
	position: relative;
	width: 40px;
	height: 30px;
}

.CloseButtonButton {
	width: 100%;
	height: 100%;
	font-size: 12.5pt;
}

.CloseButtonButton:hover {
	background-color: RGBA(150, 150, 150, 0.5);
}

.CloseButtonButtonText {
	margin-left: 12%;
}


.NewSaleMessage {
	/*color: rgba(0,128,0, 0.9);*/
	color: rgba(10, 180, 10, 1.0);
	font-size: 150%;
	font-size: 20pt;
	left: 2.5%;
	left: 45px;
	top: 8px;
	position: relative;
}


.NewSaleID{
	top: 3.5%;
	left:2%;
	height: 25px;
	position: relative;
	/*border: 1px solid red;*/
}

.NewSaleIDID {
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	margin-top: 4.5px;
	width: 80px;
	/*border-right: 1pt solid #ddd;*/
}

.NewSaleIDIDOld {
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	margin-top: 4.5px;
	margin-left: -2%;
	width: 80px;
	/*border-right: 1pt solid #ddd;*/
}

.NewSaleIDNo{
	/*left: 6.5%;*/
	margin-left: 32.4%;
	margin-top: 4.5px;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	/*width: 30px;*/
	width: 67%;
	height: 19px;
	/*width: 89px;*/
}

.NewSaleIDNo2{
	/*left: 6.5%;*/
	margin-left: 30.4%;
	margin-top: 4.5px;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	/*width: 30px;*/
	width: 67%;
	height: 19px;
	/*width: 89px;*/
}

.NewSaleIDNoID {
	margin-top: 0px;
}

.verticalSeparatorFirstBoxes {
	border-right: 1px solid #ddd;
	left: -2%;
	width: 89px;
	height: 25px;
	/*top: 0%;*/
	position: absolute;
}
.horizontalSeparatorFirstBoxes {
	border-bottom: 1px solid #ddd;
	margin-top: 25px;
	left: -2%;
	width: 100%;
	position: absolute;
}

.horizontalSeparatorSecondBoxes {
	border-bottom: 1px solid #ddd;
	margin-top: 0px;
	left: -2%;
	width: 100%;
	position: absolute;
}


.NewSaleEmployee{
	top: 3.5%;
	left:2%;
	height: 25px;
	position: relative;
	/*border: 1px solid red;*/
}

.NewOperationUpperDataContainerNoBorder {
	top: 3.5%;
	left:2%;
	left: 2.5%;
	left: 45px;
	width: 22.3%;
	width: 283px;
	position: relative;
}

.NewOperationUpperDataContainer{
	top: 3.5%;
	left:2%;
	left: 2.5%;
	left: 45px;
	width: 22.3%;
	width: 283px;
	position: relative;
	border: 1px solid #ddd;
}

.NewSaleEmployeeEmployee{
	/*border-color: coral;*/
	/*border: solid 1px;*/
	margin-top: 4.5px;
	font-size: 12pt;
	position: absolute;
	width: 80px;
}

.NewSaleEmployeeEmployeeOld {
	/*border-color: coral;*/
	/*border: solid 1px;*/
	margin-top: 4.5px;
	margin-left: -2%;
	font-size: 12pt;
	position: absolute;
	width: 80px;
}

.NewSaleEmployeeEmployeeNo {
	/*border-color: coral;*/
	/*border: solid red 1px;*/
	margin-top: 4.5px;
	margin-left: 30%;
	font-size: 12pt;
	position: absolute;
	/*width: 600px;*/
	width: 250%;
	/*width: 195px;*/
}

.NewSaleEmployeeButton {
	/*width: 68%;*/
	/*margin-left: 30%;*/
	/*height: 100%;*/
	/*left: 6.5%;*/
	left: 29.7%;
	width: 68.3%;
	height: 100%;
	margin-top: 1.0px;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: relative;
	/*width: 30px;*/
	/*height: 15px;*/
	/*width: 89px;*/
}

.containerDropDownShops {
	border-color: coral;
	border: solid 1px;
	margin-left: 11%;
	min-width: 186px;
	max-height: 150px;
	min-height: 30px;
	position: absolute;
	z-index: 50;
	background-color: white;
	overflow: auto;
	padding-left: 3px;
	padding-right: 3px;
	white-space: nowrap;
}

.containerDropDownShops a{
	text-decoration: none;
	color: black;
}






.containerDropDownShopsFilterTAble {
	border-color: coral;
	border: solid 1px;
	margin-left: 84px;
	min-width: 186px;
	max-height: 150px;
	min-height: 30px;
	position: absolute;
	z-index: 50;
	background-color: white;
	overflow: auto;
	padding-left: 3px;
	padding-right: 3px;
	white-space: nowrap;
}
.containerDropDownShopsFilterTAble a{
	text-decoration: none;
	color: black;
}


.EmployeeAndShopDropDown{
	/*left: 6.5%;*/
	left: 25%;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: relative;
	width: 230px;
}


.NewSaleShop{
	top: 3.5%;
	left:2%;
	position: relative;
	height: 25px;
	/*border: 1px solid red;*/
}

.NewSaleShopShop{
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	width: 75px;
	margin-top: 4.5px;
}

.NewSaleShopShopOld {
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	width: 75px;
	margin-top: 4.5px;
	margin-left: -2%;
}

.NewSaleShopShopNo {
	/*border-color: coral;*/
	/*border: solid red 1px;*/
	position: absolute;
	/*width: 295px;*/
	margin-top: 4.5px;
	margin-left: 30%;
}

.NewSaleShopButton {
	margin-left: 29.7%;
	width: 68.3%;
	height: 100%;
}

.NewSaleShopDropdown{
	/*left: 6.5%;*/
	left: 25%;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: relative;
	width: 230px;
}


.dropDownIndividualShop {

}


.NewSaleDate{
	top:3.7%;
	left: 57.45%;
	left: 778px;
	width: 155px;
	position: relative;
	/*border: 1px solid;*/
	border: 1px solid #ddd;
	text-align: right;
}

.NewSaleDatePrevious {
	top:3.7%;
	left: 58.45%;
	left: 764.5px;
	left: 785px;
	width: 150px;
	position: relative;
	/*border: 1px solid;*/
	text-align: right;

}

.NewSaleDateDate{

}




.SaleTablePlusShippingPlusTicket{
	left: 2%;
	left: 45px;
	width: 96%;
	height: 80%;
	/*border: 1px solid;*/
	position: absolute;
}
.NewCompaniesTablePlusDisclaimer {
	left: 2%;
	left: 45px;
	width: 93.2%;
	height: 80%;
	/*border: 1px solid red;*/
	position: absolute;
	margin-top: 2%;
}

.SaleTableContainer{
	width: 890px;
	height: 220px;
	top: 5%;
	position: absolute;
	/*border: red 5px solid;*/
}

.NewCompaniesTableContainer{
	width: 890px;
	height: 220px;
	top: 5%;
	position: absolute;
	/*border: red 5px solid;*/
}

.SaleTable {
	position: absolute;
	text-align: center;
	font-size: small;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	width: 100%;
	display: block;
	/*height: 270px;*/
	height: 225px;
	height: 423.5px;
	max-height: 454px;
	table-layout: fixed;
	border-color: coral;
	border: 3px;
	overflow: auto;
}

.SaleTableHeader {
}

.SaleTableBody{
	width: 100%;
	/*height: 400px;*/
	height: 100%;
	max-height: 243px;
	/*position: absolute;*/
}
.SaleTableBody td{
	text-overflow: ellipsis;
}


.SaleTable td{
	border: 0.2px solid #ddd;
	/*width: 80px;*/
	font-size: 100%;
	width: 100px;
	/*white-space: nowrap;*/
	/*text-overflow: ellipsis;*/
	overflow: hidden;
	/*border: 1px solid;*/
	/*word-wrap: break-word;*/
	max-width: 50px;
}

.SaleTable th {
	border: 0.5px solid #ddd;
	/*padding: 8px;*/
	line-height: 13px;
	padding-top: 2px;
	padding-bottom: 2px;
	text-align: center;
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	color: white;
	position: sticky;
	height: 35px;
	height: 35px;
	font-size: 12pt;
	font-weight: bold;

	/*word-wrap: break-word;*/
	max-width: 300px;
}

.SaleTable tr{
	/*line-height: 30px;*/
	/*position: relative;*/
	height: 30px;
	max-height: 30px;
	font-size: 12pt;
	padding: 0;
	margin: 0;
}

.inputSaleTable {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
}
.inputSaleTable2 {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}
.inputSaleTableRed{

}

.SaleTable tr:nth-child(even){background-color: #f2f2f2;}



.SaleTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.SaleTable th:nth-child(2) {width: 127px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(3) {width: 97px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(4) {width: 87px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(5) {width: 87px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(6) {width: 89px; text-align: center; padding-right: 3px;}
.SaleTable th:nth-child(7) {width: 157px; text-align: center; padding-right: 3px;}
.SaleTable th:nth-child(8) {width: 157px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.SaleTable th:nth-child(9) {width:  44px; max-width: 44px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.SaleTable th:nth-child(10){width:  47px; text-align: center; padding-right: 3px;}
.SaleTable th:nth-child(11){width:  87px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(12){width:  67px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}

.SaleTable td:nth-child(1) {width:  57px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(2) {width: 127px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(3) {width: 97px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(4) {width: 87px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(5) {width: 87px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(6) {width: 89px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(7) {width: 157px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(8) {width: 157px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(9) {width:  44px; max-width: 44px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(10){width:  47px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(11){width:  87px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(12){width:  67px; text-align: right; padding-right: 3px;}

.SaleTable tr:hover {background-color: #ddd;}


.NewCompaniesTable {
	position: absolute;
	text-align: center;
	font-size: small;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	width: 100%;
	display: block;
	height: 543.5px;
	/*max-height: 454px;*/
	table-layout: fixed;
	/*border: coral 3px solid;*/
	overflow: auto;
}

.NewCompaniesTableHeader {
}

.NewCompaniesTableBody{
	width: 100%;
	/*height: 400px;*/
	height: 100%;
	max-height: 243px;
	/*position: absolute;*/
}
.NewCompaniesTableBody td{
	text-overflow: ellipsis;
}


.NewCompaniesTable td{
	border: 0.2px solid #ddd;
	/*width: 80px;*/
	font-size: 100%;
	width: 100px;
	/*white-space: nowrap;*/
	/*text-overflow: ellipsis;*/
	overflow: hidden;
	/*border: 1px solid;*/
	/*word-wrap: break-word;*/
	max-width: 50px;
}

.NewCompaniesTable th {
	border: 0.5px solid #ddd;
	/*padding: 8px;*/
	line-height: 13px;
	padding-top: 2px;
	padding-bottom: 2px;
	text-align: center;
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	color: white;
	position: sticky;
	height: 35px;
	height: 35px;
	font-size: 12pt;
	font-weight: bold;

	/*word-wrap: break-word;*/
	max-width: 300px;
}

.NewCompaniesTable tr{
	/*line-height: 30px;*/
	/*position: relative;*/
	height: 30px;
	max-height: 30px;
	font-size: 12pt;
	padding: 0;
	margin: 0;
}

.inputSaleTable {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
}
.inputSaleTable2 {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}
.inputSaleTableRed{

}

.NewCompaniesTable tr:nth-child(even){background-color: #f2f2f2;}



.NewCompaniesTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.NewCompaniesTable th:nth-child(2) {width: 220px; text-align: center; padding-left: 3px;}
.NewCompaniesTable th:nth-child(3) {width: 220px; text-align: center; padding-left: 3px;}
.NewCompaniesTable th:nth-child(4) {width: 120px; text-align: center; padding-left: 3px;}
.NewCompaniesTable th:nth-child(5) {width: 220px; text-align: center; padding-left: 3px;}
.NewCompaniesTable th:nth-child(6) {width: 220px; text-align: center; padding-right: 3px;}

.NewCompaniesTable td:nth-child(1) {width:  57px; text-align: right; padding-right: 3px;}
.NewCompaniesTable td:nth-child(2) {width: 220px; text-align: left; padding-left: 3px;}
.NewCompaniesTable td:nth-child(3) {width: 220px; text-align: left; padding-left: 3px;}
.NewCompaniesTable td:nth-child(4) {width: 120px; text-align: left; padding-left: 3px;}
.NewCompaniesTable td:nth-child(5) {width: 220px; text-align: left; padding-left: 3px;}
.NewCompaniesTable td:nth-child(6) {width: 220px; text-align: right; padding-right: 3px;}

.NewCompaniesTable tr:hover {background-color: #ddd;}



.NewSaleShipping{
	/*top: 278.5px;*/
	/*width: 128px;*/
	/*width: 228px;*/
	/*width: 168px;*/
	height: 21px;
	/*left: 59.5%;*/
	/*left: 57.4%;*/
	left: 720px;
	border: 1px solid #ddd;
	position: absolute;
	font-size: 12pt;

	/*top: 555.5px;*/
	/*top: 527px;*/
	top: 468px;
	/*left: 1122px;*/
	/*left: 1116px;*/
	/*width: 150px;*/
	position: absolute;
	width: 148px;
	width: 168px;
	height: 21px;
	border: 1px solid #ddd;
}

.NewSaleShippingPrevious {
	/*top: 278.5px;*/
	/*width: 228px;*/
	/*width: 168px;*/
	height: 21px;
	/*left: 59.5%;*/
	left: 57.3%;
	/*border: 1px solid #ddd;*/
	position: absolute;
	text-align: right;
	font-size: 12pt;

	left: 57.4%;
	left: 730px;

	position: absolute;
	font-size: 12pt;

	/*top: 555.5px;*/
	/*top: 527px;*/
	top: 468px;
	position: absolute;
	/*width: 148px;*/
	width: 168px;
	height: 21px;
}
.NewSaleShippingPreviousSpace {
	width: 2px;
	position: absolute;
}

.NewSaleShippingName {
	width: 70px;
	width: 120px;
	top: 2px;
	position: absolute;
}

.NewSaleShippingNamePrevious {
	width: 120px;
	position: absolute;
}

.NewSaleShippingValue {
	/*left: 70px;*/
	left: 121px;
	width: 39px;
	height: 15px;
	position: absolute;
	text-align: right;
	font-size: 11pt;
	/*border: 1px solid #ddd;*/
}
.NewSaleShippingValue2 {
	/*left: 70px;*/
	left: 121px;
	width: 39px;
	height: 15px;
	position: absolute;
	text-align: right;
	font-size: 11pt;
	/*border: 1px solid #ddd;*/
	/*max-width: 80px;*/
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;

}

.NewSaleShippingValuePrevious {
	left: 110px;
	width: 50px;
	position: absolute;
	text-align: right;
	/*border: 1px solid #ddd;*/
}







.NewSaleTicket {
	left: 71.5%;
	top: 5%;
	width: 330px;
	height: 450px;
	/*border: 1px solid;*/
	position: absolute;

}

.NewSaleTicketSummary {
	position: absolute;
	width: 100%;
	height: 270px;
	height: 420px;
	height: 510px;
	border: 2px solid #ddd;
}

.NewSaleSummaryHeader {
	position: relative;
	text-align: center;
	font-size: 12pt;
	border-collapse: collapse;
	/*border-bottom: 1px solid #ddd;*/
	border-bottom: none;
	width: 100%;
	/*height: 46px;*/
	height: 38px;
}

.NewSaleSummaryHeader th {
	/*border: 0.5px solid #ddd;*/
	/*padding: 8px;*/
	line-height: 30px;
	text-align: left;
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	color: white;
	max-width: 300px;
}
.NewSaleSummaryHeader th:nth-child(1) {
	text-align: left;
	padding-left: 10px;
}
.NewSaleSummaryHeader th:nth-child(2) {
	text-align: right;
	padding-right: 13px;
}



.NewSaleSummaryTable{

}


.SummarySaleTableBody {
	width: 100%;
	height: 135px;
	position: absolute;
	/*border-bottom: 1px solid #ddd;*/
	/*border: 1px;*/
}
.SummarySaleTableBody td{
	text-overflow: ellipsis;
	overflow: hidden;
}

.SummarySaleTable {
	position: absolute;
	text-align: center;
	font-size: 11pt;
	border-collapse: collapse;
	/*border-top: none;*/
	width: 100%;
	display: block;
	/*height: 132px;*/
	height: 285px;
	height: 247px;
	height: 325px;
	overflow-y: auto;
	/*border-bottom: 1px solid #ddd;*/
}

.SummarySaleTableHeader{
	/*border-bottom: 1px solid #ddd;*/
}


.SummarySaleTable th {
	line-height: 20px;
	text-align: left;
	background-color: rgb(255, 255, 255);
}

.SummarySaleTable th:nth-child(1) {
	color: rgba(10, 130, 10, 1.0);
}

.SummarySaleTable th td {
	border-bottom: 0.5px solid;
}

.SummarySaleTable td {
	/*border: 0.5px solid #ddd;*/
	line-height: 20px;
	text-align: left;
	background-color: rgb(255, 255, 255);
	white-space: nowrap;
	word-wrap: break-word;
}


.SummarySaleTable td:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px; }
.SummarySaleTable td:nth-child(2) {width: 50px; max-width: 50px; text-align: right;}
.SummarySaleTable td:nth-child(3) {width: 50px; max-width: 50px; text-align: right;}
.SummarySaleTable td:nth-child(4) {width: 80px; max-width: 70px; text-align: right;}

.SummarySaleTable th:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px;text-align: left;}
.SummarySaleTable th:nth-child(2) {width: 50px; max-width: 50px; text-align: center;}
.SummarySaleTable th:nth-child(3) {width: 50px; max-width: 50px; text-align: center;}
.SummarySaleTable th:nth-child(4) {width: 70px; max-width: 70px; text-align: right;}


.SummarySaleTableHeaderEndLine {
	border-bottom: 1px solid #ddd;
	/*border-bottom: 1px solid red;*/
	margin-top: 20px;
	position: absolute;
	width: 92%;
	margin-left: 3%;
	height: 1px;
	/*z-index: 1500;*/
}

.SummarySaleTableBodyEndLine {
	border-bottom: 1px dashed #ddd;
	/*border-bottom: 1px solid red;*/
	margin-top: 260px;
	margin-top: 345px;
	/*margin-top: 12px;*/
	position: absolute;
	width: 100%;
	/*margin-left: 3%;*/
	height: 1px;
	/*z-index: 1500;*/
}



.NewSaleSummaryTotals{
	width: 100%;
	top: 180px;
	height: 120px;
	top: 319px;
	top: 400px;
	height: 120px;
	position: absolute;
}

.NewSaleSummaryTotalsTable{
	width: 100%;
	position: absolute;
	font-size: 11pt;
	top: -4%;
}


.NewSaleSummaryTotalsTable td:nth-child(1){
	padding-left: 3px;
	/*border: 1px solid #ddd;*/
}
.NewSaleSummaryTotalsTable td:nth-child(2){
	/*border: 1px solid #ddd;*/
	width: 30px;
	padding-right: 8px;
	text-align: right;
}

.NewSaleSummaryTotalsTable tr:nth-child(2){
	width: 40px;
	padding-bottom: 29.5px;
}

.NewSaleSummaryTotalsTable tr:nth-child(3){
	height: 7px;
	padding-bottom: 29.5px;
}
.NewSaleSummaryTotalsTable tr:nth-child(4) {
	font-weight: bold;
}

.NewSaleSummaryTotalsTableBody {

}







.NewSaleTicketClient{
	top: 308px;
	top: 500.0px;
	width: 98%;
	height: 70px;
	position: absolute;
	border: 2px solid #ddd;

	top: 435.0px;
	width: 100%;
	height: 80px;
	position: absolute;
	border: 2px solid #ddd;
}

.NewSaleClientDataHeader{
	position: absolute;
	width: 100%;
	border-collapse: collapse;
	font-size: 12pt;
	background-color: #4CAF50;
}

.NewSaleClientDataHeader tr{
	background-color: #4CAF50;
}

.NewSaleClientDataHeader th{
	position: absolute;
	text-align: center;
	width: 100%;
	color: white;
	border: none;
	background-color: #4CAF50;
	height: 35px;
}

.NewSaleClientDataEmail{
	top: 50%;
	width: 40%;
	height: 80%;
	position: absolute;
}

.NewSaleClientDataMessageEmail{
	position: absolute;
	left: 8%;
	/*border: 1px solid;*/
	font-size: 11pt;
}

.NewSaleClientDataFormEmail {
	position: absolute;
	left: 100%;
	top: -5%;
	text-overflow: ellipsis;
	overflow: hidden;
}
.NewSaleClientDataFormEmail2 {
	position: absolute;
	left: 100%;
	top: -5%;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
	text-overflow: ellipsis;
	overflow: hidden;
}





.NewSaleTicketButton{
	top: 400px;
	top: 545px;
	width: 100%;
	height: 38.5px;
	position: absolute;
}


.buttonScanAndPayButton {
	position: absolute;
	width: 55%;
	left: 22.5%;
	height: 100%;
	border-radius: 5%;
	font-size: 13.5pt;
 }

.buttonScanAndPayButton:hover {
	background-color: RGBA(150, 150, 150, 0.5);
}

.decideAnyPopupOnArrival {
	position:absolute !important;
	margin-top: 200px;
	z-index: 1000;
	height: 15%;
	width: 25%;
	border: solid 0.1em;
	background-color: rgb(255,255,255);
}
.ArrivalPopupText {
	margin: 3%;
	text-align: justify;
}

.PopUpMissingData {
	position:absolute !important;
	margin-top: 200px;
	z-index: 1000;
	height: 31.0%;
	width: 35%;
	border: solid 0.1em;
	background-color: rgb(255,255,255);
	text-align: justify;
}
.PopUpMissingDataInside {
	margin: 3%;
	height: 240px;
}

.showQRCodeOfHistoricOperation {
	width: 100%;
	height: 100%;
	border: none;
	/*border: 0;*/
	background-color: transparent;
}

.showQRCodeOfHistoricOperationQR {
	width: 10%;
	height: 10%;
}


.ArrivalPopupCloseButton {
	margin-left: 35%;
	margin-top: 40px;
	position: relative;
	color: white;
	height: 25px;
	width: 30%;
	border-color: black;
	background-color: rgba(10, 130, 10, 0.85);
}
.ArrivalPopupCloseButton:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.Obscurer {
	position:absolute !important;
	height: 100%;
	width: 100%;
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	z-index: 200;
}

.ObscurerQRReceived {
	position:absolute !important;
	height: 100%;
	width: 100%;
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	margin-top: -0.40%;
	margin-left: -0.40%;
	z-index: 1200;
}

.showQRCodeimage {
	flex: 1;
	width: 20px;
	height: 20px;
}

.showQRCode {
	/*width: 100%;*/
	/*height: 100%;*/
	/*top: 20%;*/
	/*position: absolute;*/
}

.showQRCodeimage2 {
	/*flex: 1;*/
	/*width: 80%;*/
	/*height: 60%;*/

	width: 210px;
	left: 73px;
	/*text-align: center;*/
	top: 22%;
	position: absolute;

}

.showQRCodeimage3 {
	/*flex: 1;*/
	/*width: 80%;*/
	/*height: 60%;*/

	width: 210px;
	left: 70px;
	/*text-align: center;*/
	top: 26%;
	position: absolute;
}


.SalesQRCodeWindow {
	position:absolute !important;
	margin-top: 250px;
	margin-left: 20px;
	z-index: 2000;
	/*height: 35%;*/
	height: 560px;
	height: 55.6%;
	/*width: 25%;*/
	width: 350px;
	/*width: 17.5%;*/
	border: solid 0.1em;
	background-color: rgb(255,255,255);
}
.SalesQRCodeWindowSale {
	position:absolute !important;
	margin-top: 250px;
	margin-left: 20px;
	z-index: 2000;
	/*height: 35%;*/
	height: 490px;
	/*width: 25%;*/
	width: 350px;
	border: solid 0.1em;
	background-color: rgb(255,255,255);
}
.resendEmail {
	position: absolute;
	/*border: 1px solid red;*/
	height: 20%;
	height: 23px;
	width: 78%;
	margin-left: 10%;
	top: 395px;
	top: 350px;
}
.resendEmailEmail {
	position: absolute;
	top: 290px;
	font-size: 14pt;
	margin: 10%;
}

.resendEmailInput {
	position: absolute;
	height: 100%;
	width: 100%;
	font-size: 90%;
}
.resendEmailInput2{
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
 }


.resendEmailButtonButton {
	/*position: absolute;*/
	/*border: 1px solid red;*/
	height: 100%;
	width: 100%;
	font-size: 14pt;
}

.SalesQRCodeWindowText {
	width: 80%;
	/*left: 7%;*/
	/*top: 5%;*/
	margin: 10%;
	text-align: justify;
	position: absolute;
	font-size: 14pt;
}
.SalesQRCodeWindowText2 {
	width: 80%;
	/*left: 7%;*/
	top: 57%;
	margin: 10%;
	text-align: justify;
	position: absolute;
	font-size: 14pt;
}
.EmailAddresInputAfterOperationCompletion {
	width: 100%;
	height: 17px;
	margin-top: 5%;
}
.errorResendQR {
	color: red;
	width: 80%;
	left: 0.5%;
	/*top: 83.5%;*/
	margin: 10%;
	margin-top: 108.7%;
	text-align: justify;
	position: absolute;
	font-size: 10pt;
}
.errorSendingOperationQR {
	color: red;
	width: 80%;
	left: 6.5%;
	top: 71.5%;
	margin: 10%;
	text-align: justify;
	position: absolute;
	font-size: 10pt;
}

.QRImage {
	width: 220px;
	height: 220px;
	left: 65px;
	/*text-align: center;*/
	top: 25.5%;
	top: 17%;
	position: absolute;
}

.resendEmailAndCloseButtonsProvider {
	top: 500px;
	position: absolute;
	display: flex;
	height: 38px;
	height: 28px;
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
	font-size: 14pt;
}
.resendEmailButton {
	width: 50%;
	height: 100%;
	/*position: absolute;*/
	/*border: 1px solid red;*/
	/*height: 20px;*/
	/*width: 60%;*/
	/*left: 85px;*/
	/*top: 345px;*/
}

.buttonCloseHistoricalQRCode{
	/*position: absolute;*/
	height: 100%;
	/*left: 42%;*/
	width: 50%;
	/*top: 83%;*/
	/*width: 50%;*/
	/*height: 8%;*/
	border-radius: 5%;
	/*border: 1px solid red;*/
}

.buttonCloseQRCode {
	position: absolute;
	left: 25%;
	top: 83%;
	width: 50%;
	height: 8%;
	border-radius: 5%;
	/*border: 1px solid red;*/
}
.buttonCloseQRCodeSale {
	position: absolute;
	left: 10%;
	top: 83%;
	width: 80%;
	height: 10%;
	border-radius: 5%;
}

.buttonCloseQRCodeButton {
	width: 100%;
	height: 100%;
	font-size: 14pt;
}


.displayErrorMessageIDInFilters {
	position: absolute;
	margin-left: 55%;
	margin-top: 7.0%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.ErrorMessagesInSaleWindow {
	position: absolute;
	/*margin-left: 35%;*/
	/*margin-top: -2.0%;*/
	/*background-color: green;*/
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: column;
	text-align: justify;
	color: red;
	max-height: 60px;
	font-size: 10pt;
	/*border: 1px solid red;*/
	z-index: 1500;
	/*border: 1px solid red;*/
}
.ErrorMessagesInSaleWindowText {
	margin: 0;
}
.ErrorMessagesInChangeWindowContainer {
	position: absolute;
	/*margin-left: 25%;*/
	margin-left: 30.5%;
	/*margin-top: -2.5%;*/
	margin-top: 2.5%;
	/*width: 50.5%;*/
	/*min-width: 800px;*/
	/*border: 1px solid red;*/
}
.ErrorMessagesInChangeWindowTextSales {
	margin: 0;
	width: 278px;
}

.messageFiltersCannotBeApplied {
	position: absolute;
	margin-left: 15%;
	margin-top: -20.0%;
	/*background-color: green;*/
	width: 71.5%;
	height: 15px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	/*border: 1px solid red;*/
}
.messageWrongEmailWhenResendingQRCode {
	position: absolute;
	margin-left: 53%;
	margin-top: 94.3%;
	/*background-color: green;*/
	width: 71.5%;
	height: 15px;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageWrongEmailWhenSendingQRCodeAtEndOfOperation {
	position: absolute;
	margin-left: 58%;
	margin-top: 96.3%;
	/*background-color: green;*/
	width: 71.5%;
	height: 15px;
	text-align: justify;
	color: red;
	font-size: 10pt;
}

.legalTextContainers {
	position: absolute;
	/*margin-top: 52.3%;*/
	bottom: 0;
	width: 100%;
	/*height: 50px;*/
	height: 40px;
	background-color: #f2f2f2;
	font-size: 85%;
}
.legalTextContainersColumn {
	width: 25%;
	/*border: 1px solid red;*/
	height: 100%;
	display: inline;
	float: left;
}

/* On screens that are 1600px wide or less, the background color is blue */
@media screen and (max-width: 1600px) {
	.legalTextContainersColumn {
		width: 25%;
	}
	.TIDINameLogIn {
		width: 14%;
	}
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1200px) {
	.legalTextContainersColumn {
		width: 50%;
		background-color: #f2f2f2;
	}
	.licensesButtonLogIn {
		width: 15%;
	}
	.TIDINameLogIn {
		width: 20%;
	}
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 992px) {
	.legalTextContainersColumn {
		width: 50%;
	}

}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
	.legalTextContainersColumn {
		/*background-color: olive;*/
		width: 100%;
	}
	.legalNoticeButtonLogInLegal {
		top: 5px;
		width: 90%;
	}
	.licensesButtonLogIn {
		width: 90%;
	}
	.TIDINameLogIn {
		width: 90%;
	}
}
.legalTextContainers1{
	position: absolute;
	margin-left: 5.3%;
	text-align: center;
	/*margin-top: -30%;*/
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers1:hover {
	text-decoration: underline;
 }
.legalTextContainers2{
	position: absolute;
	margin-left: 24.35%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers2:hover {
	 text-decoration: underline;
 }
.legalTextContainers3{
	position: absolute;
	margin-left: 48%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers3:hover {
	 text-decoration: underline;
 }
.legalTextContainers4{
	position: absolute;
	margin-left: 65.5%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers4:hover {
	text-decoration: underline;
}
.legalTextContainers5{
	position: absolute;
	margin-left: 87.2%;
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers5:hover {
	text-decoration: underline;
}

.showLegalTextBig {
	margin-top: 10%;
	/*margin-left: 25%;*/
	position: absolute;
	width: 60%;
	height: 55.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextSmall {
	margin-top: 10%;
	/*margin-left: 25%;*/
	position: absolute;
	width: 60%;
	height: 40.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextDeciderTitleDivSupportLandingPage {
	margin-top: 10%;
	/*margin-left: 25%;*/
	position: absolute;
	width: 30%;
	height: 120px;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.buttonCloseLegalText {
	left: 97.5%;
	top: -0.1%;
	/*left: 1210px;*/
	position: absolute;
	width: 30px;
	height: 23px;
}
.buttonCloseLegalTextSupport {
	left: 95.0%;
	top: -0.5%;
	/*left: 1210px;*/
	position: absolute;
	width: 30px;
	height: 23px;
}
/*.buttonCloseLegalTextButton {*/
/*	width: 100%;*/
/*	height: 100%;*/
/*	font-size: 11.5pt;*/
/*}*/
.showLegalTextDeciderTitleDivSupportTitle {
	margin-top: 2%;
	/*font-size: 15pt;*/
	/*height: 490px;*/
	width: 80%;
	overflow: hidden;
	text-align: justify;
	/*border: 1px solid red;*/
}
.showLegalTextDeciderTextDivSupport {
	margin-right: 1%;
	/*width: 99%;*/
	width: 96%;
	/*max-width: 250px;*/
	/*border: 1px solid red;*/
	overflow: hidden;
	text-align: justify;
	line-height: 1.5em;
	margin-top: 2%;
	font-size: 10pt;
	height: 490px;
	overflow-y: auto;
}
.showLegalTextDeciderTextDivSupportInterior {

}
.showLegalTextDeciderTextMyArea {
	margin-top: 2%;
	font-size: 10pt;
	height: 490px;
	height: 100%;
	/*overflow-y: auto;*/
	text-align: justify;
}
.showLegalTextDeciderTextMyAreaText{
	margin-right: 1%;
	/*width: 99%;*/
	width: 1030px;
	width: 99%;
	/*border: 1px solid red;*/
	text-align: justify;
}
.showLegalTextDeciderText{
	margin-top: 2%;
	font-size: 10pt;
}
.allLicenseTextsButtons {
	display:block;
}
.allLicenseTextsButtonsIntro{
	height: 17px;
}
.allLicenseTextsButtonsButtons{
	margin-top: 37px;
	/*margin-top: 4%;*/
}
/* On screens that are 1600px wide or less, the background color is blue */
@media screen and (max-width: 1600px) {
	.allLicenseTextsButtonsButtons {
		margin-top: 27px;
	}
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1200px) {
	.allLicenseTextsButtonsButtons {
		margin-top: 45px;
	}
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 992px) {
	.allLicenseTextsButtonsButtons {
		margin-top: 65px;
	}
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
	.allLicenseTextsButtonsButtons {
		margin-top: 90px;
	}
}
.allLicenseTextsButtonsText{
	margin-top: 17px;
}
.buttonLicenseTextActivator {
	display:block;
	border: none;
	cursor: pointer;
	color: blue;
	background-color: transparent;
	text-decoration: underline;
	height: 17px;
	padding: 0;
}
.showCookiesDetailsCloseButton{
	position: absolute;
	margin-left: 97.5%;
	margin-top: -43.3%;
	text-align: center;
	width: 30px;
	height: 23px;
	position: absolute;
	/*margin-left: 98.2%;*/
	/*margin-top: 0.8%;*/
}
.CookiesMenuChangePreferencesMyArea{
	position: absolute;
	width: 100%;
	height: 100%;
}
.buttonAcceptCookiesDivMyArea{

}
.cookiesMenuChangePreferencesMenuLandingPage {
	position: absolute;
	margin-top: 2%;
}
.cookiesMenuChangePreferencesMenuAreaLandingPage {
	/*position: absolute;*/
	margin-top: 2%;
	width: 90%;
}
.cookiesMenuChangePreferencesMenuSpanMyArea {
	/*margin-left: 2%;*/
	/*width: 500px;*/
	/*text-align: justify;*/

	margin-left: 3%;
	width: 950px;
	text-align: justify;
}

.toggleCookiesTechnicalMyArea {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	border: 0;
	outline: 0;
	cursor: pointer;
	/*left: 700px;*/
	/*text-align: right;*/
	float: right;
	margin-left: 1005px;
	margin-top: -1.6%;
	/*margin: 10px;*/
}

/* To create surface of toggle button */
.toggleCookiesTechnicalMyArea:before {
	content: '';
	width: 60px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;

	/*	This is for a smooth transition*/
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
}

/* Contents before checkbox to create toggle handle */
.toggleCookiesTechnicalMyArea:after {
	content: '';
	width: 18px;
	height: 18px;
	display: block;
	position: absolute;
	left: 0px;
	top: -1px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.toggleCookiesTechnicalMyArea:checked:after {
	left: 43px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
/* Background color when toggle button will be active */
.toggleCookiesTechnicalMyArea:checked:before {
	background-color: rgba(155, 155, 155, 1);
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
	/*background: red;*/
}

.toggleCookiesMyArea {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	border: 0;
	outline: 0;
	cursor: pointer;
	/*left: 700px;*/
	/*text-align: right;*/
	float: right;
	margin-left: 1005px;
	margin-top: -5.6%;
	margin-top: -1.6%;
	/*margin: 10px;*/
}

/* To create surface of toggle button */
.toggleCookiesMyArea:before {
	content: '';
	width: 60px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;

	/*	This is for a smooth transition*/
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
}

/* Contents before checkbox to create toggle handle */
.toggleCookiesMyArea:after {
	content: '';
	width: 18px;
	height: 18px;
	display: block;
	position: absolute;
	left: 0px;
	top: -1px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.toggleCookiesMyArea:checked:after {
	left: 43px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
/* Background color when toggle button will be active */
.toggleCookiesMyArea:checked:before {
	background: rgba(10, 130, 10, 0.85);;
	/*background: red;*/
}

.cookiesMenuChangePreferencesMenuItemMyArea {
	list-style-type: ' ';
	margin-left: 0.0%;
	margin-top: 0%;
}
.cookiesMenuChangePreferencesTitleMyArea {
	position: absolute;
}

.buttonAcceptCookiesMyArea {
	position: absolute;
	margin-top: 350px;
	margin-left: 82.2%;
}
.dropDownInMyAreaFilterTable {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 125px;
	/*top: -15px;*/
	transform: rotate(180deg);
}
.dropDownInMyProfileNewSale {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 172px;
	/*top: -15px;*/
	transform: rotate(180deg);
}

.BackgroundMyArea{
	background-color: white;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1000;
}

.buttonCookiesDetailMyArea {
	position: absolute;
	margin-top: 350px;
	margin-left: 0.3%;
	width: 15%;
	height: 30px;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.buttonCookiesDetailMyArea:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.ContainerCookiesPolicyButtonRejectAllCMPMyArea {
	position: absolute;
	margin-top: 350px;
	margin-left: 62.5%;
	width: 15%;
	height: 30px;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.ContainerCookiesPolicyButtonRejectAllCMPMyArea:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.buttonAcceptCookiesMyArea {
	position: absolute;
	margin-top: 350px;
	margin-left: 77.4%;
	width: 15%;
	height: 30px;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.buttonAcceptCookiesMyArea:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.CountAndEmails {
	/*position: relative;*/
	margin-top: 47%;
	margin-left: 70%;
	/*border: 3px solid red;*/
	height: 15%;
}
.disclaimerNewCompaniesInPSIContainer {
	margin-bottom: 2%;
}

.countOfNewCompanies {
	float: right;
}

/*.buttonConfirmCompaniesDiv {*/
/*	*/
/*}*/

.buttonConfirmCompanies {
	/*position: absolute;*/
	margin-right: 0;
	float: right;
	width: 80%;
	border-radius: 5%;
	font-size: 13.5pt;
	height: 38.5px;
	/*height: 100px;*/
}

.buttonConfirmAndEmailsPSI{
	position: relative;
	top: 5%;
	width: 100%;
	margin-right: 0;
}

.chooseUpdateClientEmail {
	position: absolute;
	width: 80%;
	height: 13.3%;
	/*border: 1px solid red;*/
	/*left: 7%;*/
	/*top: 5%;*/
	margin-left: 10%;
	margin-right: 10%;
	top: 395px;
	font-size: 13.5pt;

}
.chooseUpdateClientEmailButtonYes {
	font-size: 14pt;
	width: 50%;
	/*border: 1.75px solid black;*/
}
.chooseUpdateClientEmailButtonNo {
	font-size: 14pt;
	width: 50%;
	/*border: 1.75px solid black;*/
}
.chooseUpdateClientEmailButtonUnchosen {
	font-size: 14pt;
	width: 50%;
	/*height: 110%;*/
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
	/*border: 1.75px solid black;*/
}
.chooseUpdateClientEmailButtonChosen {
	font-size: 14pt;
	width: 50%;
	/*height: 160%;*/
	border: 1.95px solid black;
	border-radius: 3px;
	/*border: none;*/
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.errorMessageChosenButton {
	color: red;
	/*width: 80%;*/
	/*left: 6.5%;*/
	/*margin: 10%;*/
	/*text-align: justify;*/
	/*position: absolute;*/
	font-size: 10pt;
}

.body {
	background-color: rgba(46,139,87, 0.1);
	width: 100%;
	height: 100%;
	/*font-family: Calibri, sans-serif;*/
}