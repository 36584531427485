.indentationPrivacyPolicy {
    margin-left: 2%;
}

.buttonToRedirectInTexts {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    margin: 0px;
    cursor: pointer;
    /*color: blue;*/
}

.itemizeToRight {
    margin-left: 2%;
}
.itemizeToRight2{
    /*display: inline;*/
    /*margin-left: 2%;*/
}
.li1{
    border: 1px solid red;
}

.ulItem{
    margin-left: -0.5%;
}
.ulItem2{
    margin-left: -0.5%;
}
.AlignToRight {
    font-weight: 700;
    text-align: right;
}
