.Sign_up_general_container {
	display: flex;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	width: 400px;
	height: 550px;
	height: 665px;
	/*width: 20%;*/
	/*height: 50%;*/
	background-color: #FFFFFF;
}

.Sign_up_company_container {
	display: flex;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	/*width: 790px;*/
	width: 701px;
	/*height: 675px;*/
	height: 640px;
	background-color: #FFFFFF;
}
.Sign_up_company_container_Client {
	display: flex;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	width: 400px;
	height: 550px;
	/*height: 615px;*/
	/*width: 20%;*/
	/*height: 50%;*/
	background-color: #FFFFFF;
}

.Sign_up_form {
	margin-top: 4%;
	width: 73%;
	height: 35%;
}

.legalTextContainersSignUp {
	position: absolute;
	/*margin-top: 52.3%;*/
	bottom: 0;
	width: 100%;
	height: 50px;
	background-color: #f2f2f2;
}

.Sign_up_input_field {
	width: 98%;
	margin-bottom: 3%;
	/*height: 13%;*/
	height: 30px;
	border: solid 1px black;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	/*font-size: 13.5pt;*/
	font-size: 13pt;
}

.Sign_up_field_name {
	left: 90px;
	height: 10%;
	/*width: 5px;*/
	margin-bottom: 1%;
	font-size: 110%;
	font-size: 14pt;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
}
.messageFormatPassword{
	position: absolute;
	font-size: 10pt;
	text-align: justify;
	width: 73%;
}

.Sign_up_input_field_company_profile {
	width: 98.8%;
	margin-bottom: 3%;
	height: 15%;
	border: solid 0.05em;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	/*font-size: 13.5pt;*/
	font-size: 13pt;
}
.Sign_up_input_field_company_profile::placeholder {
	color: black;
}

.Sign_up_log_in_text {
	text-align: left;
	font-size: 85%;
	position: absolute;
	margin-top: 139.0%;
}

.Sign_up_button {
	width: 73%;
	/*height: 6%;*/
	height: 33px;
	position: absolute;
	margin-top: 125.5%;
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	font-size: 14pt;
	color: white;
	border-color: black;
	background-color: rgba(10, 130, 10, 0.85);
}
.Sign_up_button:hover {
	background-color: rgba(10, 130, 10, 0.65);
}
.Sign_up_button_log_in {
	width: 73%;
	/*height: 6%;*/
	height: 33px;
	position: absolute;
	/*margin-top: 115.5%;*/
	margin-top: 59.2%;
	margin-left: -36.5%;
	/*margin-top: 1%;*/
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	font-size: 14pt;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.Sign_up_button_log_in:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.signUpCheckboxes{
	position: absolute;
	margin-top: 75%;
	margin-top: 87%;
	/*border: 1px solid blue;*/
	width: 75%;
	/*margin-left: 12.5%;*/
	/*margin-left: -7.5%;*/
	/*margin-top: 79%;*/
	/*margin-left: -7%;*/
}
@media screen and (max-width: 600px) {
	.signUpCheckboxes {
		margin-left: -3%;
	}
}
@media screen and (max-width: 450px) {
	.signUpCheckboxes {
		margin-left: -3%;
		margin-top: 88%;
	}
}

.Horizontal_lines_container_SignUp {
	width: 177px;
	height: 10px;
	position: absolute;
	margin-top: 105%;
	margin-top: 138.5%;
	/*border: 1px solid red;*/
}

.checkboxCompany {
	width: 90%;
	width: 100%;
	margin-top: 1.2%;
	font-size: 8pt;
	/*margin-left: 15%;*/
	/*border: 1px solid red;*/
}

.Sign_up_form_verification {
	margin-top: 2%;
	width: 73%;
	height: 35%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
}

.checkboxCompanyText {
	font-size: 110%;
	font-size: 12pt;
}
.checkboxCompanyTextGTC {
	font-size: 110%;
	font-size: 12pt;
	/*width: 20px;*/

	/*margin-left: 20%;*/
	/*position: absolute;*/
}
.checkboxTextSignUp{
	width: 113%;
	width: 90%;
	margin-left: 7.8%;
	margin-top: -6%;
	/*border: 1px solid red;*/
	text-align: justify;
}
.checkboxCompanyTextBlue{
	font-size: 12pt;
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}

.containerDualButtons {
	width: 73%;
	height: 8%;
}

.validateCodeButton {
	width: 73%;
	height: 6%;
	margin-top: 115.3%;
	margin-top: 128.3%;
	margin-top: 140%;
	font-size: 110%;
	border-radius: 3px;
	position: absolute;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.validateCodeButton:hover {
	background-color: rgba(10, 130, 10, 0.65);
}
/*.validateCodeButton:disabled {*/
/*	!*background-color: rgba(10, 180, 10, 1);*!*/
/*	text-shadow: rgba(150, 150, 150, 1);*/
/*}*/

.sendAgainButtonSignUp {
	position: absolute;
	margin-top: 55%;
	margin-left: 56%;
	border: none;
	background-color: rgba(255, 255, 255, 1);
	font-size: 85%;
	font-size: 10.5pt;
	text-decoration: underline;
	cursor: pointer;
}
@media screen and (max-width: 600px) {
	.sendAgainButtonSignUp {
		margin-left: 27%;
	}
	.legalTextContainersSignUp {
		bottom: -35px;
	}
}
@media screen and (max-width: 450px) {
	.sendAgainButtonSignUp {
		margin-left: 31%;
	}
	.legalTextContainersSignUp {
		bottom: -35px;
	}
}

.sendAgainButtonSignUpLogIn {
	position: absolute;
	margin-top: 60%;
	margin-left: 58%;
	border: none;
	background-color: rgba(255, 255, 255, 1);
	font-size: 85%;
	text-decoration: underline;
	cursor: pointer;
}

.Sign_up_button_link{
	width: 100%;
	height: 100%;
	font-size: 100%;
	background-color: rgba(46,139,87, 0.25);
	flex-direction: column;
	align-items: center;
	border: solid 0;
}



.SignUpCompanyTable{
	border-collapse: collapse;
	/*border: 1px solid;*/
	width: 100%;
	/*height: 20%;*/
}

.SignUpCompanyCol1 {
	width: 50%;
}

.SignUpCompanyCol2 {
	width: 50%;
}

.Sign_up_company_2_columns_field_name_left_column {
	width: 99%;
	/*height: 37%;*/
	font-size: 110%;
	text-align: left;
}

.Sign_up_company_2_columns_field_name_right_column {
	/*height: 37%;*/
	width: 92%;
	margin-left: 2%;
	font-size: 110%;
	text-align: left;
}

.Sign_up_company_2_columns_input_field_left_column {
	width: 96%;
	margin-bottom: 5%;
	margin-right: 4%;
	height: 15%;
	border: solid 0.05em;
	border-radius: 3px;
	/*font-size: 110%;*/
	font-size: 13pt;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.Sign_up_company_2_columns_input_field_left_column::placeholder {
	color: black;
}

.Sign_up_company_2_columns_input_field_right_column {
	width: 96%;
	margin-bottom: 5%;
	margin-left: 2%;
	height: 15%;
	border: solid 0.05em;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	font-size: 13pt;
}
.Sign_up_company_2_columns_input_field_right_column::placeholder {
	color: black;
}

.Sign_up_company_2_columns_input_field_button {
	width: 99.9%;
	height: 100%;
	height: 25px;
	border: solid 0.05em;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	text-align: left;
	font-size: 13pt;
}

.Sign_up_company_2_columns_input_field_left_column_no_border_left {
	/*width: 93.5%;*/
	width: 98%;
	margin-bottom: 5%;
	margin-right: 2%;
	height: 15%;
	border-radius: 3px;
	font-size: 110%;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}


.Sign_up_company_2_columns_input_field_left_column_no_border_right {
	/*width: 93.5%;*/
	width: 98%;
	margin-bottom: 5%;
	/*margin-left: 5%;*/
	margin-left: 2%;
	height: 15%;
	border-radius: 3px;
	font-size: 110%;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}



.companySignUpLeftPartitioned {
	display: flex;
	flex-direction: row;
}

.Sign_up_input_field_partitioned_phone_1 {
	width: 25%;
	height: 6%;
	margin-right: 3.9%;
	margin-bottom: 5%;
	max-width: 20%;
	max-height: 6%;
	font-size: 110%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
}

.Sign_up_input_field_partitioned_phone_2 {
	width: 72.0%;
	height: 6%;
	/*margin-left: 5%;*/
	margin-bottom: 5%;
	font-size: 110%;
	border: solid 0.05em;
	border-radius: 3px;
}
.Sign_up_input_field_partitioned_phone_2::placeholder {
	color: black;
}
.Sign_up_input_field_partitioned_phone_2Red {
	width: 72.0%;
	height: 6%;
	/*margin-left: 5%;*/
	margin-bottom: 5%;
	font-size: 110%;

	border-radius: 3px;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}
.Sign_up_input_field_partitioned_phone_2Red::placeholder {
	color: black;
}


.dropdownMenu {
	width: 43.4%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
}
.dropdownMenu a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
}
.dropdownMenuTreatment {
	width: 12.7%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	text-align: left;
}
.dropdownMenuTreatment a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
}

.dropdownMenuCountry {
	width: 30.9%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	text-align: left;
}
.dropdownMenuCountry a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
	text-align: left;
}

.dropdownMenuRegion {
	width: 30.9%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1200;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	text-align: left;
	margin-left: -10.5%;
}
.dropdownMenuRegion a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
}

.dropdownMenuProvince {
	width: 30.9%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	text-align: left;
	margin-left: -21.1%;
}
.dropdownMenuProvince a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
}

.dropdownMenuPhonePrefix {
	width: 8.7%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
}
.dropdownMenuPhonePrefix a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
	font-size: 12.0pt;
}
.dropdownMenuSettingsCompanySignUp{
	width: 43.4%;
	max-height: 30.5%;
	/*max-height: 200px;*/
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	/*margin-top:24px;*/
	font-size: 12pt;
}
.dropdownMenuSettingsCompanySignUp a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}


.MandatoryFieldCompany{
	font-size: 75%;
	vertical-align: super;
	margin-top: 0px;
	position: absolute;
	/*top: 85%;*/
}
.MandatoryFieldCompanySecondAsterisk{
	font-size: 75%;
	vertical-align: super;
	margin-top: 16px;
	position: absolute;
	/*top: 85%;*/
}
.MandatoryFieldCompany2 {
	font-size: 75%;
	vertical-align: super;
	margin-top: 16px;
	margin-left: 12px;
	position: absolute;
	/*top: 85%;*/
}

.RenderCompanyProfileCompany{
	width: 90%;
	height: 67%;
	/*border: solid 0.05em;*/
}

.RenderCompanyProfileCompanyForm{
	width: 90%;
	height: 67%;
	/*border: solid 0.05em;*/
}


.stepShopAndEmployeesDiv {
	margin-bottom: 1.5%;
	margin-top: 2%;
	/*position: absolute;*/
}

.stepShopAndEmployees{
	height: 5%;
	/*font-size: 100%;*/
	font-size: 13pt;
}

.VerificationCodeSentMessage {
	width: 74%;
	margin-top: 5%;
	font-size: 85%;
	text-align: justify;
}

.HR_line_Sign_up {
	height: 0.5px;
	margin-top: +15px;
	/*left: 60px;
	top:160px;*/
	border: 0;
	width: 100px;
	color: #C0C0C0;
	background-color: #C0C0C0;
}

.LogInFromSignUp {
	border-color: coral;
	border: solid 0.3em;
}

.Sign_up_back_title_container{
	width: 100%;
	height: 4.6%;
	margin-top: 5px;
	font-size: 85%;
	/*border: solid 0.1em red;*/
	/*border-radius: 5px;*/
}


.RenderCompanyProfileButtons {
	height: 5%;
	width: 90%;
	display: flex;
	flex-direction: row;
	margin-top: 3%;
	/*border-color: black;*/
	/*border: solid 0.05em;*/
}

.RenderCompanyProfileButtons_Left {
	width: 49%;
	height: 100%;
	margin-right: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	border: none;
	font-size: 100%;
	/*background-color: rgba(46,139,87, 0.35);*/
}

.RenderCompanyProfileButtons_ButtonSaveAndContinue {
	width: 100%;
	height: 100%;
	border-radius: 3px;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
	font-size: 13pt;
}
.RenderCompanyProfileButtons_ButtonSaveAndContinue:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.RenderCompanyProfileButtons_Right {
	width: 49%;
	height: 100%;
	margin-left: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	/*font-size: 110%;*/
	/*background-color: rgba(46,139,87, 0.35);*/
}

.RenderCompanyProfileButtons_ButtonContinue {
	width: 100%;
	height: 100%;
	/*background-color: rgba(46,139,87, 0.6);*/
	border-radius: 3px;
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 13pt;
	background-color: rgba(10, 130, 10, 0.85);
	/*background-color: rgba(190, 190, 190, 1);*/
	/*background-color: rgba(10, 180, 10, 1);*/
}
.RenderCompanyProfileButtons_ButtonContinue:hover {
	background-color: rgba(10, 130, 10, 0.65);
}
.messageBeforeContinueButtonWorks {
	position: absolute;
	margin-top: -17px;
	margin-left: 50%;
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}


.RenderCompanyProfileButtons_Center {
	height: 5%;
	width: 80%;
	display: flex;
	flex-direction: row;
	border-color: black;
	border: solid 0.05em;
}


.Back_button_sign_up {
	width: 55px;
	height: 100%;
	margin-bottom: 10px;
	/*margin-left: 10px;*/
	margin-left: 0.5%;
	border: none;
	font-size: 125%;
	background-color: rgba(255, 255, 255, 1);
}

.Sign_up_title_text_in_button{
	font-size: 300%;
	margin-right: 10%;
}

.Sign_up_title_container {

}

.Employees {
	text-align: center;
	width: 100%;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	border: none;
	/*border: 3px solid #ddd;*/
	height: 82%;
	table-layout:fixed;
	display: block;
	font-size: 80%;
}

.Employees th {
	/*border: 0.5px solid #ddd;*/
	/*padding: 3px;*/
	/*height: 10%;*/
}


.RenderCompanyProfileEmployeesTable {
	text-align: center;
	width: 100%;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	/*border: 3px solid #ddd;*/
	/*height: 95%;*/
	height: 80%;
	table-layout:fixed;
	display: block;
	font-size: 80%;
	font-size: 12pt;
}

.RenderCompanyProfileEmployeesTableBody{
	display: block;
	height: 95%;
	/*height: 50px;*/
	overflow-y: auto;
	overflow-x: hidden;
}

.RenderCompanyProfileEmployeesTableHead{
}

.RenderCompanyProfileEmployeesTableHead th {
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}


.RenderCompanyProfileEmployeesTableHead tr {
}


.RenderCompanyProfileEmployeesTable td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 0px;
	height: 10%;
}


.RenderCompanyProfileEmployeesTableHead th:nth-child(1) { width: 27px; text-align: right; padding-right: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(1) { width: 30px; text-align: right; padding-right: 3px; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(2) { width: 85px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(2) { width: 157px; text-align: left; padding-left: 3px; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(3) { width: 110px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(3) { width: 116px;  text-align: left; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(4) { width: 140px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(4) { width: 146px; text-align: left; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(5) { width: 75px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(5) { width: 81px; text-align: left; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(6) { width: 160px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(6) { width: 150px; text-align: left; }


.RenderCompanyProfileEmployeesTable tr:nth-child(even){background-color: #f2f2f2;}

.RenderCompanyProfileEmployeesTable tr:hover {background-color: #ddd;}

.RenderCompanyProfileEmployeesTable th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	background-color: #4CAF50;
	color: white;
}

.dropdownInSettingsSignUp {
	width: 100%;
	height: 100%;
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 13pt;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	text-align: left;
}
.inputSaleTableSignUp {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
}

.inputSaleTableSignUpAddress {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
}
.inputSaleTableTreatment {
	width: 91px;
	/*height: 110%;*/
	height: 27px;
	font-size: 100%;
	/*max-width: 80px;*/
}
.inputSaleTableCountry {
	width: 70px;
	/*margin-left: 4px;*/
	/*padding-left: 2px;*/
	/*margin-top: -2px;*/
	/*height: 110%;*/
	height: 27px;
	font-size: 90%;
	/*max-width: 80px;*/
}
.inputSaleTableCountryContent {
	width: 90%;
	margin-top: 5%;
	/*width: 10%;*/
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 90%;
}
.inputSaleTableRegion {
	width: 70px;
	/*padding-left: -2px;*/
	/*margin-top: -2.2px;*/
	/*margin-left: 1px;*/
	/*height: 110%;*/
	height: 27px;
	font-size: 90%;
	/*max-width: 80px;*/
}
.inputSaleTableProvince {
	width: 70px;
	/*margin-top: -2.2px;*/
	/*padding-left: 2px;*/
	/*height: 110%;*/
	height: 27px;
	font-size: 90%;
	/*max-width: 80px;*/
}


.RenderCompanyProfileEmployeesTableLowNumberOfEmployees {
	text-align: center;
	width: 100%;
	border-collapse: collapse;
	/*border: 3px solid #ddd;*/
	/*height: 95%;*/
	height: 80%;
	table-layout:fixed;
	display: block;
	font-size: 12pt;
}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody{
	display: block;
	height: 100%;
	/*height: 50px;*/
	overflow-y: auto;
	overflow-x: hidden;
}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead{
}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th {
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}


.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead tr {
}


.RenderCompanyProfileEmployeesTableLowNumberOfEmployees td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 0px;
	height: 10%;
}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(1) { width: 27px; text-align: center; padding-right: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(1) { width: 30px; text-align: right; padding-right: 3px; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(2) { width: 85px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(2) { width: 157px; text-align: left; padding-left: 3px; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(3) { width: 110px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(3) { width: 116px;  text-align: left; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(4) { width: 140px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(4) { width: 146px; text-align: left; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(5) { width: 75px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(5) { width: 81px; text-align: left; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(6) { width: 160px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(6) { width: 165px; text-align: left; }


.RenderCompanyProfileEmployeesTableLowNumberOfEmployees tr:nth-child(even){background-color: #f2f2f2;}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployees tr:hover {background-color: #ddd;}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployees th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	/*background-color: rgba(10, 180, 10, 1);*/
	background-color: rgba(10, 130, 10, 0.85);
	/*background-color: rgba(170, 170, 170, 1);*/
	color: white;
}


.Employees td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}

.Employees tr:nth-child(even){background-color: #f2f2f2;}

.Employees tr:hover {background-color: #ddd;}

.Employees th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	background-color: #4CAF50;
	color: white;
}



.RenderCompanyProfileShopsTable {
	text-align: center;
	width: 100%;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	/*border: 3px solid #ddd;*/
	/*height: 95%;*/
	height: 82%;
	table-layout:fixed;
	display: block;
	font-size: 80%;
	font-size: 12pt;
}

.RenderCompanyProfileShopsTableBody{
	display: block;
	height: 100%;
	/*height: 50px;*/
	overflow-y: auto;
	overflow-x: hidden;
	height: 95%;
}

.RenderCompanyProfileShopsTableHead{
}

.RenderCompanyProfileShopsTableHead th {
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}


.RenderCompanyProfileShopsTableHead tr {
}


.RenderCompanyProfileShopsTable td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 0px;
	height: 10%;
}


.RenderCompanyProfileShopsTableHead th:nth-child(1) { width: 27px; text-align: right; padding-right: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(1) { width: 30px;  text-align: right; padding-right: 3px;}

.RenderCompanyProfileShopsTableHead th:nth-child(2) { width: 90px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(2) { width: 95px; text-align: left; }

.RenderCompanyProfileShopsTableHead th:nth-child(3) { width: 155px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(3) { width: 159px; }

.RenderCompanyProfileShopsTableHead th:nth-child(4) { width: 65px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(4) { width: 73px; padding-left: 3px;}

.RenderCompanyProfileShopsTableHead th:nth-child(5) { width: 65px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(5) { width: 73px; padding-left: 3px;}

.RenderCompanyProfileShopsTableHead th:nth-child(6) { width: 65px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(6) { width: 73px; padding-left: 3px;}

.RenderCompanyProfileShopsTableHead th:nth-child(7) { width: 115px; text-align: right; padding-right: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(7) { width: 102px; text-align: right; padding-right: 3px;}


.RenderCompanyProfileShopsTable tr:nth-child(even){background-color: #f2f2f2;}

.RenderCompanyProfileShopsTable tr:hover {background-color: #ddd;}

.RenderCompanyProfileShopsTable th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	background-color: #4CAF50;
	color: white;
}





.RenderCompanyProfileShopsTableLowNumberOfShops {
	text-align: center;
	width: 100%;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	/*border: 3px solid #ddd;*/
	/*height: 95%;*/
	height: 82%;
	table-layout:fixed;
	display: block;
	/*font-size: 80%;*/
	font-size: 12pt;
}

.RenderCompanyProfileShopsTableLowNumberOfShopsBody{
	display: block;
	height: 100%;
	/*height: 50px;*/
	overflow-y: auto;
	overflow-x: hidden;
}

.RenderCompanyProfileShopsTableLowNumberOfShopsHead{
}

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th {
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}


.RenderCompanyProfileShopsTableLowNumberOfShopsHead tr {
}


.RenderCompanyProfileShopsTableLowNumberOfShops td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 0px;
	height: 10%;
}

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(1) { width: 27px; text-align: center; padding-right: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(1) { width: 30px;  text-align: right; padding-right: 3px;}

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(2) { width: 90px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(2) { width: 99px; text-align: left; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(3) { width: 155px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(3) { width: 158px; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(4) { width: 65px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(4) { width: 70px; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(5) { width: 65px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(5) { width: 70px; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(6) { width: 65px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(6) { width: 70px; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(7) { width: 115px; text-align: center; padding-right: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(7) { width: 115px; text-align: right; padding-right: 3px; }


.RenderCompanyProfileShopsTableLowNumberOfShops tr:nth-child(even){background-color: #f2f2f2;}

.RenderCompanyProfileShopsTableLowNumberOfShops tr:hover {background-color: #ddd;}

.RenderCompanyProfileShopsTableLowNumberOfShops th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	background-color: rgba(10, 130, 10, 0.85);
	color: white;
}



.Sign_up_title_text {
	font-size: 300%;
	font-size: 36pt;
	/*color: rgba(10, 180, 10, 1);*/
}

.containerRowOfNumberOfCurrentState{
	width: 45%;
	height: 5%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: row;
	justify-content: space-between;
}

.numberInsideFlag {

}

.Flag_complete_step {
	background-color: rgba(46,139,87, 0.6);
	height: 20px;
	width: 20px;
	border-radius: 80px;
	border: solid 2px;
	border-color: rgba(46,139,87, 0.6);
	background-color: rgba(10, 180, 10, 1);
	background-color: rgba(10, 130, 10, 0.85);
	color: white;
}

.Flag_incomplete_step {
	background-color: rgba(255, 255, 255, 1);
	height: 20px;
	width: 20px;
	border-radius: 80px;
	border: solid 2px;
	border-color: rgba(125, 125, 125, 1);
	color: rgba(125, 125, 125, 1);
}


.Flag_current_step {
	background-color: rgba(255, 255, 255, 1);
	height: 20px;
	width: 20px;
	border-radius: 80px;
	border: solid 2px;
	border-color: rgba(46,139,87, 0.6);
	border-color: rgba(10, 130, 10, 0.85);
	color: black;
}
.numberInsideCircleContainer {
	position: absolute;
	margin-top: 2px;
	margin-left: 6px;
}

.sentenceTermsAndConditions {
	width: 74%;
	margin-top: 8%;
	font-size: 85%;
	text-align: justify;
}

.TermsAndConditionsTextContainerClose {
	/*top: -300px;*/
	/*width: 100%;*/
	/*left:200px;*/
	/*height: 38.5px;*/
	/*position: absolute;*/
}

.TermsAndConditionsTextContainer {
	display: flex;
	position: fixed;
	top: 10%;
	left: 15%;
	/*transform: translate(-50%, -50%);*/
	flex-direction: column;
	align-items: center;
	/*top: 190px;*/
	/*left: 285px;*/
	/*position: absolute;*/
	border-color: coral;
	border: solid 0.3em;
	height: 70%;
	width: 70%;
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}


.RenderPersonProfile {
	margin-top: 4%;
	width: 73%;
	height: 80%;
	/*border: solid 0.05em red;*/
}

.Sign_up_input_field_person {
	left: 90px;
	/*height: 6%;*/
	height: 27px;
	width: 98%;
	margin-bottom: 5%;
	font-size: 110%;
	font-size: 13pt;
	border: solid 0.05em;
	border-radius: 3px;
	/*-webkit-border-radius: 3px;*/
	/*-moz-border-radius: 3px;*/
}

.Sign_up_input_field_person_date {
	left: 90px;
	/*height: 6%;*/
	height: 27px;
	width: 98%;
	margin-bottom: 5%;
	font-size: 110%;
	font-size: 13pt;
	border: solid 0.05em;
	border-radius: 3px;
	/*-webkit-border-radius: 3px;*/
	/*-moz-border-radius: 3px;*/
}

@media screen and (max-width: 450px) {
	.Sign_up_input_field_person_date {
		/*margin-left: 31%;*/
		width: 286px;
		height: 34px;
	}
}



.companySignUpLeftPartitionedPerson {
	display: flex;
	flex-direction: row;
	height: 6%;
	width: 100%;
	margin-bottom: 5%;
	border-radius: 3px;
	/*border: solid 0.05em;*/
}

.Sign_up_input_field_partitioned_person_phone_1 {
	width: 18%;
	height: 110%;
	margin-right: 6%;
	margin-bottom: 5%;
	max-width: 18%;
	font-size: 110%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
}

.Sign_up_input_field_partitioned_person_phone_2 {
	width: 80%;
	height: 100%;
	/*margin-left: 5%;*/
	margin-bottom: 5%;
	font-size: 110%;
	border: solid 0.05em;
	border-radius: 3px;
}

.checkboxGenres {
	margin-bottom: 5%;
}

.checkboxGenresTable {

}

.checkboxGenresCol1 {

	width: 18%;
}

.checkboxGenresCol2 {
	width: 18%;
}

.checkboxGenresCol3 {
	width: 24%;
}

.checkboxCircular {
	width: 1.3em;
	height: 1.3em;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	-webkit-appearance: caret;
	outline: none;
	cursor: pointer;
}

.RenderPersonalProfileButton {
	position: absolute;
	margin-top: 115%;
	height: 6%;
	width: 73%;
	margin-left: 1%;
	display: flex;
	flex-direction: row;
	/*border-color: black;*/
	/*border: solid 0.05em;*/
}

.Sign_up_button_person {
	height: 100%;
	width: 100%;
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	/*background-color: rgba(46,139,87, 0.35);*/
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.Sign_up_button_person:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.popupContinue {
	position: absolute;
	margin-top: 20%;
	margin-left: 36.5%;
	width: 500px;
	height: 200px;
	z-index: 1000;
	background-color: rgba(255, 255, 255, 1);
	border: solid 0.05em;
}

.popupContinueInterior {
	text-align: justify;
	margin: 7%;
	font-size: 14pt;
}
.boldWithout{
	font-weight: bold;
}
.popupContinueButtons {
	top: 75%;
	width: 84%;
	position: absolute;
	height: 25px;
}
.popupContinueButtonsCancel {
	width: 49%;
	height: 100%;
	margin-right: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	font-size: 14pt;
	/*font-size: 100%;*/
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.popupContinueButtonsCancel:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.popupContinueButtonsConfirm {
	width: 49%;
	height: 100%;
	margin-left: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	font-size: 14pt;
	background-color: rgba(125, 125, 125, 0.25);
	border: 1px solid rgba(50, 50, 50, 1);
	color: rgba(10, 130, 10, 0.85);
	font-size: 13pt;
}
.popupContinueButtonsConfirm:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}


.popupConfirmSaveAndContinue {
	position: absolute;
	margin-top: 20%;
	margin-left: 35.5%;
	width: 27%;
	height: 18%;
	z-index: 1000;
	background-color: rgba(255, 255, 255, 1);
	border: solid 0.05em;
}


.ObscurerSignUp {
	position: absolute ;
	height: 100%;
	width: 100%;
	/*margin-left: -8px;*/
	/*margin-top: -8px;*/
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	z-index: 200;
}
.ObscurerLegalTexts {
	position: absolute ;
	height: 2300%;
	width: 3000%;
	/*margin-left: -390%;*/
	margin-top: -1000%;
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	z-index: 200;
}

.popupConfirmSaveAndContinueInterior {
	margin: 7%;
	text-align: justify;
	font-size: 14pt;
}

.popupConfirmSaveAndContinueButtons {
	top: 72%;
	width: 86%;
	height: 25px;
	position: absolute;
}

.popupConfirmSaveAndContinueCancel {
	width: 49%;
	height: 100%;
	margin-right: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	border: none;
	font-size: 100%;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);

}
.popupConfirmSaveAndContinueCancel:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.popupConfirmSaveAndContinueConfirm {
	width: 49%;
	height: 100%;
	margin-left: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px ;
	border: none;
	font-size: 100%;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.popupConfirmSaveAndContinueConfirm:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}


.dropDownInSignUp {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: absolute;
	margin-left: 283px;
	margin-top: 6px;
	transform: rotate(180deg);
}
.dropDownInSignUpPhonePrefix {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: absolute;
	margin-left: 38px;
	margin-top: 6px;
	transform: rotate(180deg);
}
.dropDownInSignUpTreatment {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 67px;
	top: -20px;
	transform: rotate(180deg);
}
.dropDownInSignUpTreatmentEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 67px;
	margin-top: -35px;
	transform: rotate(180deg);
}
.dropDownInSignUpShop {
	/*width: 0pt;*/
	/*height: 0pt;*/
	/*border-style: solid;*/
	/*border-width: 0 5px 10px 5px;*/
	/*border-color: transparent transparent black transparent;*/
	/*!*position: absolute;*!*/
	/*margin-left: 50px;*/
	/*margin-top: -37px;*/
	/*transform: rotate(180deg);*/

	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	border-color: red;
	position: relative;
	/*margin-left: 50px;*/
	/*top: -47px;*/
	transform: rotate(180deg);

	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 50px;
	top: -18px;
	transform: rotate(180deg);
}
.dropDownInSignUpShopEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 49px;
	margin-top: -2px;
	transform: rotate(180deg);
}

.messageEmailIsNotValid {
	position: absolute;
	margin-left: 99%;
	/*margin-left: 0%;*/
	margin-top: 39.5%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}

.messagePasswordNotProvided {
	position: absolute;
	margin-left: 0%;
	margin-top: 72.3%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: right;
	color: red;
	font-size: 10pt;
}

.messageIncorrectPasswordFormat {
	position: absolute;
	margin-left: 0%;
	margin-top: 72.3%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: right;
	color: red;
	font-size: 10pt;
}

.messagePasswordsToBeTheSame {
	position: absolute;
	margin-left: 60%;
	/*margin-left: 0%;*/
	margin-top: 72.3%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.AlreadyExistingAccount {
	position: absolute;
	margin-left: 0%;
	/*margin-left: 0%;*/
	margin-top: 72.3%;
	margin-top: 108.5%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}
.messageUntickedCheckboxes{
	position: absolute;
	margin-left: 11%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageStringInVerificationCode {
	position: absolute;
	margin-left: 1%;
	margin-top: 51.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageStringInVerificationCodeFromLogIn {
	position: absolute;
	margin-left: 1%;
	margin-top: 58.2%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageIncorrectVerificationCodeSignUp {
	position: absolute;
	margin-left: 1%;
	margin-top: 51.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageIncorrectVerificationCodeSignUpFromLogIn {
	position: absolute;
	margin-left: 1%;
	margin-top: 56.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageUnknownErrorInSignUp2SignUp {
	position: absolute;
	/*margin-left: 1%;*/
	margin-top: 57.5%;
	/*background-color: green;*/
	width: 73.0%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}

.MessageWrongContactPhone {
	position: absolute;
	margin-left: 11.0%;
	margin-top: -76px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}

.checkThereIsAnyInputValidationErrorInShops {
	position: absolute;
	margin-left: 46.0%;
	margin-top: -15px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}
.checkThereIsAnyInputValidationErrorInEmployees {
	position: absolute;
	margin-left: 46.0%;
	margin-top: -15px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}
.checkThereIsAnyInputValidationErrorInEmployeesName {
	position: absolute;
	margin-left: 18.0%;
	margin-top: -15px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}
.checkThereIsAnyInputValidationErrorInShopsName {
	position: absolute;
	margin-left: 5.0%;
	margin-top: -15px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}


.showLegalTextBigSignUp {
	margin-top: 10%;
	/*margin-left: 20%;*/
	position: absolute;
	width: 250%;
	height: 85.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextSmallSignUp {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 60%;
	height: 40.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
/*.buttonCloseLegalTextButton {*/
/*	width: 100%;*/
/*	height: 100%;*/
/*	font-size: 11.5pt;*/
/*}*/
.showLegalTextDecider {
	margin-left: 4%;
	width: 92%;
	margin-top: 2%;
	height: 92%;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
}
.showLegalTextDeciderTextSignUp{
	margin-top: 2%;
	font-size: 10pt;
	overflow-y: auto;
	height: 430px;
}

.legalNoticeButtonSignUp {
	margin-top: 200%;
	margin-left: 350%;
	margin-top: 147.5%;
	margin-left: 57%;
	width: 23%;
	/*border: solid 1pt red;*/
	position: absolute;
}
.legalNoticeButtonSignUpButton {
	border: none;
	background-color: transparent;
}
.legalNoticeButtonSignUpButton:hover {
	text-decoration: underline;
}

.disclaimerTFNSignUp {
	position: absolute;
	margin-top: 32px;
	font-size: 75%;
	width: 90%;
	text-align: justify;
}
.disclaimerTFNSignUp2 {
	position: absolute;
	margin-top: 32px;
	font-size: 75%;
	width: 88%;
	text-align: justify;
	margin-left: 12px;
}

.disclaimerConfirmationInformationSignUp {
	position: absolute;
	margin-top: 0px;
	font-size: 75%;
	width: 90%;
	text-align: justify;
}

.disclaimerConfirmationInformationEmployee{
	position: absolute;
	margin-top: 25px;
	font-size: 75%;
	width: 90%;
	text-align: justify;
}

.disclaimerConfirmationInformationShops {
	position: absolute;
	margin-top: 30px;
	font-size: 75%;
	width: 90%;
	text-align: justify;
}

.BackgroundSignUp {
	/*background-color: rgba(10, 180, 10, 0.1);*/
	/*!*background-color: rgba(255, 0, 0, 0.3);*!*/
	/*width: 100%;*/
	/*height: 100%;*/
	/*position: absolute;*/
	/*z-index: -1000;*/
}

.divDisclaimersCompanyProfile {
	margin-top: 10%;
}



body {
	/*background-color: rgba(46,139,87, 0.1);*/
	background-color: rgba(10, 180, 10, 0.1);

	/*background-color: rgba(210, 210, 210, 0.1);*/
}



/*!* On screens that are 992px wide or less, the background color is blue *!*/
/*@media screen and (max-width: 992px) {*/
/*	body {*/
/*		background-color: blue;*/
/*		color: white;*/
/*	}*/
/*}*/

/*!* On screens that are 600px wide or less, the background color is olive *!*/
/*@media screen and (max-width: 600px) {*/
/*	body {*/
/*		background-color: olive;*/
/*		color: white;*/
/*	}*/
/*}*/