.containerOfChange {
    top: 117px;
    left: 100px;
    position: absolute;
    border-color: coral;
    border: 3px solid;
    height: 870px;
    width: 1710px;
    background: rgba(255, 255, 255, 1);
    z-index: 1000;
}

.CloseButtonChanges {
    left: 95.4%;
    left: 97.65%;
    top: -0.1%;
    /*left: 1210px;*/
    position: relative;
    width: 40px;
    height: 30px;
}

.NewChangeMessage {
    color: rgba(10, 180, 10, 1.0);
    font-size: 20pt;
    left:2%;
    position: relative;
}


.NewChangeEmployee{
    height: 24px;
    top: 3.5%;
    left:2%;
    position: relative;
    /*border: 1px solid red;*/
    /*height: 24px;*/
}

.NewChangeEmployeeEmployee{
    /*border-color: coral;*/
    /*border: solid 1px;*/
    margin-top: 2px;
    width: 76px;
    top: 5%;
    height: 34px;
    position: absolute;
    /*width: 80px;*/
}

.NewChangeEmployeeNo {
    /*width: 68%;*/
    /*margin-left: 30%;*/
    /*height: 100%;*/
    /*left: 6.5%;*/
    left: 30%;
    top: 15%;
    /*top: 5%;*/
    /*border-color: coral;*/
    /*border: solid 1px;*/

    position: absolute;
    /*width: 30px;*/
    /*border: 1px solid red;*/
    width: 250%;
    /*width: 89px;*/
}
.NewChangeEmployeeButton {
    left: 27.25%;
    position: relative;
    width: 70.6%;
    height: 100%;
}
.NewChangeEmployeeName {
    left: 29.85%;
    position: absolute;
    /*border: 1px solid red;*/
    width: 250%;
    top: 10%;
}
.NewChangeShop{
    height: 24px;
    top: 3.5%;
    left:2%;
    position: relative;
}

.NewChangeShopShop{
    /*border-color: coral;*/
    /*border: solid 1px;*/
    position: absolute;
    top: 10%;
    width: 70px;
}

.NewChangeShopDropdown{
    left: 6.5%;
    border-color: coral;
    border: solid 1px;
    position: relative;
    width: 30px;
}

.containerDropDownShopsChanges {
    border-color: coral;
    border: solid 1px;
    margin-left: 85px;
    width: 216px;
    max-height: 150px;
    position: absolute;
    z-index: 50;
    background-color: white;
    overflow: auto;
    font-size: 12pt;
    padding-left: 3px;
    padding-right: 3px;
    white-space: nowrap;
}

.containerDropDownShopsChanges a{
    text-decoration: none;
    color: black;
}

.containerDropDownShopsChangesAttribute {
    /*text-decoration: none;*/
}


.horizontalSeparatorFirstBoxesChanges {
    border-bottom: 1px solid #ddd;
    margin-top: 0px;
    left: -2%;
    width: 100%;
    position: absolute;
}

.WrapperAllTables {
    width: 1180px;
    height: 600px;
    top: 20%;
    /*border-style: dashed;*/
    /*border: 5px;*/
    /*border-color: black;*/
    position: absolute;

}

.ChooseChangesTable {
    position: absolute;
    text-align: center;
    border-collapse: collapse;
    width: 26.7%;
    display: block;
    /*height: 270px;*/
    left: 2.8%;
    height: 225px;
    max-height: 250px;
    table-layout: fixed;
    border-color: coral;
    /*border: 3px;*/
    /*border: 1px solid #ddd;*/
    overflow-y: auto;
    overflow-x: hidden;
}

.ChooseChangesTableHeader {
    font-size: 12.0pt;
}

.ChooseChangesTableBody{
    width: 100%;
    /*height: 243px;*/
    height: 100%;
    max-height: 243px;
    /*position: absolute;*/
    /*border: 1px solid #ddd;*/
}

.ChooseChangesTable td{
    border: 0.2px solid #ddd;
    /*width: 80px;*/
    font-size: 100%;
    /*width: 100px;*/
    /*text-overflow: ellipsis;*/
    text-overflow: ellipsis; overflow: hidden;
    white-space: nowrap;
    /*border: 1px solid;*/
    /*word-wrap: break-word;*/
    /*max-width: 50px;*/
}

.ChooseChangesTable th {
    font-weight: bold;
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    /*background-color: rgba(10, 180, 10, 1.0);*/
    background-color: rgba(10, 180, 10, 1.0);
    color: white;
    position: sticky;
    height: 35px;

    /*word-wrap: break-word;*/
    max-width: 300px;
}

.ChooseChangesTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;
}

.inputChooseChangesTable {
    width: 100%;
    /*height: 110%;*/
    height: 20px;
    font-size: 100%;
    /*max-width: 80px;*/
}

.ChooseChangesTable tr:nth-child(even){background-color: #f2f2f2;}

.ChooseChangesTable th:nth-child(1) {width:  37px; text-align: center; padding-right: 3px;}
.ChooseChangesTable th:nth-child(2) {width: 47px; text-align: center; padding-right: 3px;}
.ChooseChangesTable th:nth-child(3) {width: 217px; min-width: 205px; text-align: center;}

.ChooseChangesTable td:nth-child(1) {width:  37px; text-align: right; padding-right: 3px;}
.ChooseChangesTable td:nth-child(2) {width: 47px; max-width: 47px; text-align: right; padding-right: 3px;}
.ChooseChangesTable td:nth-child(3) {width: 217px; max-width: 205px; text-align: left;}

.ChooseChangesTable tr:hover {background-color: #ddd;}


.inputChooseTablefunctionUnits {font-size: 12pt; width: 43px; height: 21px; text-align: right;}
.inputChooseTablefunctionReason {font-size: 12pt; width: 209px; height: 21px; text-align: left;}
.inputChooseTablefunctionUnitsRed {font-size: 12pt; width: 43px; height: 21px; text-align: right; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}
.inputChooseTablefunctionReasonRed {font-size: 12pt; width: 209px; height: 21px; text-align: left; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}












.ChangeTableTBody {
    width: 800px;
    /*left: 35%;*/
    left: 380px;
    /*border: 3px solid;*/
    /*border-color: coral;*/
    overflow-y: scroll;
    position: absolute;
    height: 243px;
}

.ChangeTable {
    position: absolute;
    text-align: center;
    font-size: small;
    border-collapse: collapse;
    border: 1px solid #ddd;
    width: 77.3%;

    display: block;
    /*height: 270px;*/
    left: 375px;
    height: 225px;
    max-height: 250px;
    table-layout: fixed;
    border-color: coral;
    border: 3px;
    overflow: auto;
}


.ChangeTableBody{
    width: 100%;
    font-size: 12.0pt;
    /*height: 243px;*/
}

.ChangeTable td{
    border: 0.2px solid #ddd;
    /*width: 80px;*/
    font-size: 100%;
    width: 100px;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;

    max-width: 50px;
}

.ChangeTable th {
    font-weight: bold;
    /*font-weight: normal;*/
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: rgba(10, 180, 10, 1.0);
    /*background-color: rgba(210, 210, 210, 1.0);*/
    color: white;
    /*color: black;*/
    position: sticky;
    height: 35px;

    max-width: 300px;
}

.ChangeTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
}

.inputChangeTable {
    width: 100%;
    /*height: 110%;*/
    height: 20px;
    font-size: 100%;
    /*max-width: 80px;*/
}

.ChangeTable tr:nth-child(even){background-color: #f2f2f2;}

/*.ChangeTable td:nth-child(1) {width: 30px; text-align: center}*/
/*.ChangeTable td:nth-child(2) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(3) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(4) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(5) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(6) {width: 50px; text-align: right}*/
/*.ChangeTable td:nth-child(7) {width: 40px; text-align: right}*/
/*.ChangeTable td:nth-child(8) {width: 60px; text-align: right}*/
/*.ChangeTable td:nth-child(9) {width: 50px; text-align: right}*/
/*.ChangeTable td:nth-child(10){width: 20px; text-align: right}*/
/*.ChangeTable td:nth-child(11){width: 50px; text-align: left}*/
/*.ChangeTable td:nth-child(12){width: 50px; text-align: right}*/

.ChangeTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.ChangeTable th:nth-child(2) {width: 127px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(3) {width: 97px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(4) {width: 97px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(5) {width: 97px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(6) {width: 99px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(7) {width: 147px; text-align: center; padding-right: 3px;}
.ChangeTable th:nth-child(8) {width: 147px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.ChangeTable th:nth-child(9) {width:  44px; max-width: 44px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.ChangeTable th:nth-child(10){width:  47px; text-align: center; padding-right: 3px;}
.ChangeTable th:nth-child(11){width:  87px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(12){width:  67px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}

.ChangeTable td:nth-child(1) {width:  57px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(2) {width: 127px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(3) {width: 97px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(4) {width: 97px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(5) {width: 97px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(6) {width: 99px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(7) {width: 147px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(8) {width: 147px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(9) {width:  44px; max-width: 44px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(10){width:  47px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(11){width:  87px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(12){width:  67px; text-align: right; padding-right: 3px;}

.ChangeTable tr:hover {background-color: #ddd;}


.NewChangeDate{
    position: absolute;
    border: 1px;
    /*top: 45.6%;*/
    /*left: 87%;*/
    top: -3.6%;
    /*left: 87.4%;*/
    left: 1130px;
    width: 155px;
    text-align: right;
    border: 1px solid #ddd;
}

.NewChangeDatePrevious {
    position: absolute;
    border: 1px;
    /*top: 45.6%;*/
    /*left: 87%;*/
    top: -3.6%;
    /*left: 87.4%;*/
    left: 1135px;
    width: 150px;
    text-align: right;
}

.NewChangeDateDate {
    padding-right: 2px;
}







.NewSaleTable {
    /*top: 49%;*/
    top: 44%;
    position: absolute;
    text-align: center;
    font-size: 12pt;
    border-collapse: collapse;
    /*border: 1px solid #ddd;*/
    width: 77.3%;
    display: block;
    /*height: 270px;*/
    height: 225px;
    max-height: 250px;
    table-layout: fixed;
    border-color: coral;
    border: 3px;
    /*border: 1px solid red;*/
    overflow: auto;
    left: 375px;
}

.NewSaleTableHeader {
    font-size: 12.0pt;
}

.NewSaleTableBody{
    width: 100%;
    /*height: 243px;*/

}

.NewSaleTable td{
    border: 0.2px solid #ddd;
    font-size: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 50px;
}

.NewSaleTable th {
    font-weight: bold;
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: rgba(10, 180, 10, 1);
    color: white;
    position: sticky;
    height: 35px;

    /*max-width: 300px;*/
}

.NewSaleTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;

    /*height: 35px;*/
}


.NewSaleTable tr:nth-child(even){background-color: #f2f2f2;}


.NewSaleTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.NewSaleTable th:nth-child(2) {width: 127px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(3) {width: 97px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(4) {width: 97px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(5) {width: 97px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(6) {width: 99px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(7) {width: 147px; text-align: center; padding-right: 3px;}
.NewSaleTable th:nth-child(8) {width: 147px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.NewSaleTable th:nth-child(9) {width:  44px; max-width: 44px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.NewSaleTable th:nth-child(10){width:  47px; text-align: center; padding-right: 3px;}
.NewSaleTable th:nth-child(11){width:  87px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(12){width:  67px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}

.NewSaleTable td:nth-child(1) {width:  57px;  height: 27px; max-width: 57px; min-width: 20px; text-align: right; padding-right: 3px;}
.NewSaleTable td:nth-child(2) {width: 120px;  height: 27px;text-align: left; padding-right: 8px;}
.NewSaleTable td:nth-child(3) {width: 90px;  height: 27px; text-align: left; padding-right: 8px; text-overflow: ellipsis; overflow: hidden; }
.NewSaleTable td:nth-child(4) {width: 90px;  height: 27px; text-align: left; padding-right: 8px;}
.NewSaleTable td:nth-child(5) {width: 90px;  height: 27px; text-align: left; padding-right: 8px;}
.NewSaleTable td:nth-child(6) {width: 92px; height: 27px; text-align: left;  padding-right: 8px;}
.NewSaleTable td:nth-child(7) {width: 140px; height: 27px; text-align: right; padding-right: 8px;}
.NewSaleTable td:nth-child(8) {width: 140px; height: 27px; text-align: right; padding-right: 8px;}
.NewSaleTable td:nth-child(9) {width:  37px; height: 27px; max-width: 44px; text-align: right; padding-right: 8px;}
.NewSaleTable td:nth-child(10){width:  40px; height: 27px; text-align: right; padding-right: 8px;}
.NewSaleTable td:nth-child(11){width:  80px; height: 27px; text-align: left; padding-right: 8px;}
.NewSaleTable td:nth-child(12){width:  60px; height: 27px; text-align: right; padding-right: 8px;}

.inputSaleTableProduct {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableBrand {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableType {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableSubtype {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableReference {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTablePrice {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTablePricewoVAT {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableVAT {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableUnits {width: 100%; height: 21px; font-size: 12.0pt;  }
.inputSaleTablePromotion {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableDiscount {width: 100%; height: 21px; font-size: 12.0pt; }

.NewSaleTable tr:hover {background-color: #ddd;}







.NewChangeShippingPrevious {
    /*top: 555.5px;*/
    top: 527px;
    top: 501px;
    /*left: 1122px;*/
    left: 1116px;
    /*width: 150px;*/
    position: absolute;
    width: 148px;
    width: 168px;
    height: 21px;
}

.NewSaleShippingInChange {
    /*top: 555.5px;*/
    /*top: 527px;*/
    top: 501px;
    /*left: 1122px;*/
    left: 1116px;
    /*width: 150px;*/
    position: absolute;
    /*width: 148px;*/
    width: 168px;
    height: 21px;
    border: 1px solid #ddd;
}

.NewSaleShippingInChangeName {
    width: 120px;
    font-weight: normal;
    top: 10%;
    position: absolute;
    /*border: 1px solid red;*/
}

.NewSaleShippingInChangeValue {
    /*left: 70px;*/
    left: 120px;
    width: 39px;
    position: absolute;
    text-align: right;
    font-size: 11pt;
    /*border: 1px solid #ddd;*/
}





















.NewChangeTicket {
    left: 1330px;
    top: 20%;
    width: 330px;
    height: 450px;
    /*border: 2px solid #ddd;*/
    position: absolute;
}


.NewChangeTicketSummaryHeader{
    /*position: relative;*/
    top: -10%;
    width: 100%;
    text-align: center;
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    /*font-size: 11.0pt;*/
    border-collapse: collapse;
    border: 1px solid #ddd;
    border-bottom: none;
    font-size: 12pt;
}

.NewChangeTicketSummaryHeader tr{
    height: 37.2px;
}

.NewChangeTicketSummaryHeader th{
    /*position: absolute;*/
    text-align: center;
    /*height: 20px;*/
    color: white;
    border: none;
    background-color: #4CAF50;
    background-color: rgba(10, 180, 10, 1);
    font-weight: bold;
}

.NewChangeTicketSummaryHeader th:nth-child(1) {
    text-align: left;
    padding-left: 10px;
}
.NewChangeTicketSummaryHeader th:nth-child(2) {
    text-align: right;
    padding-right: 13px;
}


.dashedLineOnTicket1 {
    border-bottom: 1pt dashed #ddd;
    width: 91.5%;
    left: 2.5%;
    height: 0px;
    top: 177.5px;
    position: absolute;
}

.dashedLineOnTicket2 {
    border-bottom: 1pt dashed #ddd;
    width: 91.5%;
    left: 2.5%;
    top: 325px;
    position: absolute;
}


.NewChooseChangesSummaryTable {
    position: absolute;
    border: 1px solid red;
    width: 100%;
    height: 30%;
}
.ChangeSummaryAbove {
    /*border-bottom: 1px dashed #ddd;*/
    position: absolute;
    margin-top: 2px;
    /*top: 50px;*/
    text-align: center;
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    font-size: 11.0pt;
    /*font-size: 12.0pt;*/
    border-collapse: collapse;
    /*border: 1px solid red;*/
    border-top: none;
    display: block;
    width: 100%;
    height: 150px;
    height: 187px;
    /*POLLA*/
    /*max-height: 150px;*/
    overflow-y: auto;

}

.ChangeSummaryAboveHead {
    /*border-bottom: 1px dashed #ddd;*/
}

.horizontalDivisionInTable {
    border-bottom: 1px dashed #ddd;
    width: 92.5%;
    left: 2.5%;
    position: absolute;
    z-index: 500;
}

.horizontalDivisionInTableForTotals {
    border-bottom: 1.5px dashed #ddd;
    /*width: 92.5%;*/
    width: 100%;
    /*left: 2.5%;*/
    top: 76.7%;
    top: 78.0%;
    position: absolute;
    z-index: 500;
}

.ChangeSummaryAbove td:nth-child(1) {width: 140px; max-width: 130px; text-overflow: ellipsis; overflow: hidden; padding-left: 10px;}
.ChangeSummaryAbove td:nth-child(2) {width: 50px; max-width: 50px; text-align: right;}
.ChangeSummaryAbove td:nth-child(3) {width: 50px; max-width: 50px; text-align: right; }
.ChangeSummaryAbove td:nth-child(4) {width: 80px; max-width: 70px; text-align: right; }

.ChangeSummaryAbove th:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px;}
.ChangeSummaryAbove th:nth-child(2) {width: 50px; max-width: 50px; text-align: center;}
.ChangeSummaryAbove th:nth-child(3) {width: 50px; max-width: 50px; text-align: center;}
.ChangeSummaryAbove th:nth-child(4) {width: 70px; max-width: 70px; text-align: right; }


.ChangeSummaryAbove th {
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
}

.ChangeSummaryAbove th:nth-child(1) {
    color: red;
}

.ChangeSummaryAbove th td {
    border-bottom: 0.5px solid;
}

.ChangeSummaryAbove td {
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ChangeSummaryAboveTBody {
    width: 100%;
    position: absolute;
    /*border: 1px solid #ddd;*/
    /*border: 2px solid #ddd;*/
    /*border-bottom: none;*/
    /*border: 1px;*/
}



.ChangeSummaryBelowTBody {
    width: 100%;
    /*height: 125px;*/
    top: 155px;
    /*border: 1px;*/
}

.ChangeSummaryBelow {
    text-align: center;
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    font-size: 11.0pt;
    display: block;
    border-collapse: collapse;
    /*border-bottom: dashed 1px  #ddd;*/
    /*border: 1px solid red;*/
    /*border-top: none;*/
    width: 100%;
    height: 150px;
    height: 185px;
    position: absolute;
    /*top: 187.5px;*/
    top: 209.5px;
    top: 245px;
    overflow-y: auto;
    /*POLLA*/
}

.ChangeSummaryBelow td:nth-child(1) {width: 140px; max-width: 130px; text-overflow: ellipsis; overflow: hidden; padding-left: 10px;}
.ChangeSummaryBelow td:nth-child(2) {width: 50px; max-width: 50px; text-align: right;}
.ChangeSummaryBelow td:nth-child(3) {width: 50px; max-width: 50px; text-align: right;}
.ChangeSummaryBelow td:nth-child(4) {width: 80px; max-width: 70px; text-align: right; }

.ChangeSummaryBelow th:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px;}
.ChangeSummaryBelow th:nth-child(2) {width: 50px; max-width: 50px; text-align: center;}
.ChangeSummaryBelow th:nth-child(3) {width: 50px; max-width: 50px; text-align: center;}
.ChangeSummaryBelow th:nth-child(4) {width: 70px; max-width: 70px; text-align: right; }


.ChangeSummaryBelow th {
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
}

.ChangeSummaryBelow th:nth-child(1) {
    color: rgba(10, 130, 10, 1.0);
}

.ChangeSummaryBelow th td {
    border-bottom: 0.5px solid;
}

.ChangeSummaryBelow td {
    /*border: 0.5px solid #ddd;*/
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}





.NewChangeSummaryTotals{
    width: 100%;
    /*top: 340px;*/
    top: 384px;
    top: 460px;
    height: 120px;
    position: absolute;
    font-size: 11.0pt;
}

.NewChangeSummaryTotalsTable{
    width: 100%;
    position: absolute;
    font-size: 11.0pt;
    top: -4%;
}

.NewChangeSummaryTotalsTable td:nth-child(1){
    padding-left: 3px;
    /*border: 1px solid #ddd;*/
}
.NewChangeSummaryTotalsTable td:nth-child(2){
    /*border: 1px solid #ddd;*/
    width: 30px;
    padding-right: 8px;
    text-align: right;
}

.NewChangeSummaryTotalsTableBody {

}

.NewChangeSummaryTotalsTable tr:nth-child(2){
    width: 40px;
    padding-bottom: 29.5px;
}

.NewChangeSummaryTotalsTable tr:nth-child(3){
    height: 7px;
    padding-bottom: 29.5px;
}
.NewChangeSummaryTotalsTable tr:nth-child(4) {
   font-weight: bold;
}







.NewChangeTicketClient{
    /*top: 527.0px;*/
    top: 500.0px;
    width: 100%;
    height: 80px;
    position: absolute;
    border: 2px solid #ddd;
}

.NewChangeTicketClientHeader {
    /*background-color: #4CAF50;*/
    background-color: rgba(10, 180, 10, 1.0);
    color: white;
    width: 100%;
    height: 35px;
}

.NewChangeTicketSummary{
    position: absolute;
    width: 100%;
    height: 485px;
    height: 570px;
    border: 2px solid #ddd;
}

.NewChangeClientDataHeader{
    left: 38%;
    position: absolute;
    text-align: center;
    top: 9px;
    /*font-size: 11pt;*/
    font-size: 12pt;
    font-weight: bold;
}

.NewChangeClientDataEmail{
    top: 60%;
    width: 40%;
    height: 80%;
    position: absolute;
    text-overflow: ellipsis;
    /*border: 2px solid #ddd;*/
}

.NewChangeClientDataMessageEmail{
    position: absolute;
    height: 25px;
    margin-top: 1.5px;
    font-size: 11pt;
    left: 8%;
    /*border: 1px solid;*/
}

.NewChangeClientDataFormEmail{
    position: absolute;
    left: 100%;
    font-size: 11pt;
}

.NewChangeClientDataFormEmailPrevious {
    position: absolute;
    left: 100%;
    width: 190px;
    font-size: 11pt;
    text-overflow: ellipsis;
    overflow: hidden;
    /*border: 1px solid red;*/
}

/*.NewChangeClientDataFormEmail {*/
/*    font-size: 11pt;*/
/*}*/

.NewChangeUpperDataContainerNoBorder {
    top: 3.5%;
    left:2%;
    width: 18.2%;
    width: 312px;
    position: relative;
    /*border: 1px solid #ddd;*/
}

.NewChangeUpperDataContainer {
    top: 3.5%;
    left:2%;
    width: 18.2%;
    width: 312px;
    position: relative;
    border: 1px solid #ddd;
}

.verticalSeparatorFirstBoxesChangeScreen {
    border-right: 1px solid #ddd;
    left: -2%;
    width: 90px;
    height: 22px;
    /*top: -18%;*/
    position: absolute;
}

.IdChange{
    top: 3.5%;
    left:2%;
    position: relative;
}

.IdChangeID1 {
    /*border-color: coral;*/
    /*border: solid 1px;*/
    position: absolute;
    margin-top: 1px;
    width: 76px;
    /*border-right: 1pt solid #ddd;*/
}
.IdChangeID2 {
    /*left: 6.5%;*/
    left: 27.25%;
    margin-top: 3.5px;
    /*border-color: coral;*/
    /*border: solid 1px;*/
    position: relative;
    /*width: 30px;*/
    width: 80%;
    /*width: 89px;*/
}

.IdChangeID2Previous {
    left: 3%;
    margin-top: 3.5px;
    /*border-color: coral;*/
    /*border: solid 1px;*/
    position: relative;
    /*width: 30px;*/
    width: 80px;
    /*width: 89px;*/
    text-align: left;
}

.IdChangeID3 {
    width: 18%;
    left: 20%;
    text-align: center;
    font-size: 11pt;
    /*border-color: coral;*/
    /*border: solid 1px;*/
}
.IdChangeID3Red {
    width: 18%;
    left: 20%;
    text-align: center;
    font-size: 11pt;
    /*border-color: coral;*/
    /*border: solid 1px;*/
    background-color: rgba(255, 0, 0, 0.10);
    border: solid rgba(255, 0, 0, 0.30) 2px;
}

.ID_Change_button {
    width: 156px;
    left: 64.5px;
    position: absolute;
    font-size: 11pt;
}

.ChangesQRCodeWindow {
    position:absolute !important;
    margin-top: 250px;
    margin-top: 5px;
    margin-left: 20px;
    margin-left: 690px;
    z-index: 2000;
    /*height: 35%;*/
    height: 475px;
    /*width: 25%;*/
    width: 350px;
    border: solid 0.1em;
    background-color: rgb(255,255,255);
}


.QRImageChange {
    width: 220px;
    left: 65px;
    /*text-align: center;*/
    top: 21.5%;
    top: 17.0%;
    position: absolute;
}

.ObscurerQRReceivedChange {
    position: absolute !important;
    height: 100%;
    width: 100%;
    /*border: solid 0.3em;*/
    background-color: rgba(105, 105, 105, 0.2);
    /*margin-top: -0.40%;*/
    /*margin-left: -0.40%;*/
    z-index: 1000;
}





.buttonScanAndPayChange{
    top: 605px;
    width: 100%;
    height: 38.5px;
    position: absolute;
}

.buttonScanAndPayButtonChange {
    position: absolute;
    width: 55%;
    left: 22.5%;
    height: 100%;
    border-radius: 5%;
    font-size: 13.5pt;
    /*background-color: ;*/
}
.messageWrongID {
    position: absolute;
    margin-left: 2%;
    margin-top: -48.0%;
    /*background-color: green;*/
    width: 371.5%;
    height: 20px;
    text-align: justify;
    color: red;
    font-size: 10pt;
    /*z-index: 7392486785;*/
}


.ErrorMessagesInChangeWindowContainerHuge {
    position: absolute;
    margin-left: 22%;
    margin-top: -2.5%;
    /*width: 50.5%;*/
    /*min-width: 800px;*/
    /*border: 1px solid red;*/
}
.ErrorMessagesInChangeWindowTitle{
    color: red;
    font-size: 10pt;
}
.ErrorMessagesInChangeWindow {
    /*position: absolute;*/
    /*margin-left: 40%;*/
    /*margin-top: -5.0%;*/
    /*background-color: green;*/
    /*width: 71.5%;*/
    display: flex;
    position: absolute;
    margin-top: -36px;
    margin-left: 358px;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: justify;
    color: red;
    max-height: 60px;
    font-size: 10pt;
    /*border: 1px solid red;*/
    /*z-index: 7392486785;*/
}
.ErrorMessagesInChangeWindowHuge {
    /*position: absolute;*/
    /*margin-left: 40%;*/
    /*margin-top: -5.0%;*/
    /*background-color: green;*/
    /*width: 71.5%;*/
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: justify;
    color: red;
    max-height: 60px;
    font-size: 10pt;
    /*border: 1px solid red;*/
    z-index: 1500;

}
.ErrorMessagesInChangeWindow>* {
    /*flex: 1 1 80px;*/
}
.ErrorMessagesInChangeWindowText {
    margin: 0;
    width: 476px;
}
.errorMessageOnButton{
    position: absolute;
    margin-left: 6%;
    margin-left: 0%;
    margin-top: -6.0%;
    /*margin-top: -7.0%;*/
    /*background-color: green;*/
    width: 99.5%;
    width: 100%;
    height: 130px;
    text-align: justify;
    color: red;
    font-size: 10pt;
}
.missingCrucialInformationDummyQR {
    position: absolute;
    margin-left: 10%;
    margin-top: 85.0%;
    /*margin-top: -7.0%;*/
    /*background-color: green;*/
    width: 80.5%;
    /*width: 100%;*/
    /*height: 130px;*/
    text-align: justify;
    color: red;
    font-size: 10pt;
}
.ErrorMessageInIncorrectID {
    position: absolute;
    margin-left: 20.9%;
    /*margin-left: 0%;*/
    margin-top: -2.1%;
    /*margin-top: -7.0%;*/
    /*background-color: green;*/
    /*width: 99.5%;*/
    /*width: 100%;*/
    /*height: 130px;*/
    text-align: right;
    color: red;
    font-size: 10pt;
}
.IDInformationExplanation {
    font-size: 10pt;
    margin-left: 2.0%;
    margin-top: -3.4%;
    margin-top: -3.8%;
}
.dropDownInMyProfileNewChange {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent black transparent;
    position: relative;
    margin-left: 195px;
    transform: rotate(180deg);
}

.buttonScanAndPayButtonChange:hover {
    background-color: RGBA(150, 150, 150, 0.5);
}