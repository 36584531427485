@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300&display=swap);
/*@import url('https://fonts.googleapis.com/css?family=Anton');*/

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: 'Roboto Condensed', sans-serif;
  /*font-family: 'Noto Sans Display', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-family: Calibri, sans-serif;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
}


.Log_in_general_container {
	display: flex;
	position: absolute;
	top: 50%;
	/*margin-top: 70%;*/
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.01em;
	border-radius: 5px;
	width: 400px;
	height: 550px;
	/*width: 20%;*/
	/*height: 50%;*/
	background-color: #FFFFFF;
	/*background-color: rgba(255, 0, 0, 0.3);*/
}

@media screen and (max-width: 786px) {
	.Log_in_general_container {
		/*background-color: red;*/
		/*top: 60%;*/
		/*transform: translate(-50%, -50%);*/
	}
}


.showLegalTextBigProfileInLogIn {
	margin-top: 10%;
	margin-left: 20%;
	position: fixed;
	width: 60%;
	height: 55.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1200;
}
@media screen and (max-width: 992px) {
	.showLegalTextBigProfileInLogIn {
		margin-left: 0;
		width: 99%;
	}
}

.Login_form {
	margin-top: 4%;
	width: 73%;
	height: 35%;
	/*height: 300px;*/
	/*border: 1px solid red;*/
}
.Login_form2 {
	margin-top: 4%;
	width: 73%;
	height: 35%;
	height: 300px;
}

.messageIForgotMyPassword {
	text-align: left;
	text-align: justify;
	width: 73%;
	margin-left: 1%;
	font-size: 85%;
	font-size: 10.25pt;
	margin-top: 5%;
}

.SignUpButtonInLogIn {
	/*height: 8.1%;*/
	width: 73%;
	margin-top: 5%;
	position: absolute;
	margin-top: 110%;
	/*border: solid 0.05em;*/
	/*border-radius: 3px;*/
}

.logInButtonContainer {
	height: 7.5%;
	width: 73%;
}
.showLegalTextDeciderTextLogIn{
	margin-top: 2%;
	font-size: 10pt;
	/*overflow-y: auto;*/
	max-height: 100%;
}

.blankSpaceLogInScreen {
	height: 10.5%;
}

.ForgotMyPassword {
	text-align: left;
	width: 73%;
	font-size: 85%;
	position: absolute;
	margin-top: 103%;
}

.NewHere{
	text-align: center;
	width: 100%;
	font-size: 85%;
	margin-bottom: 1.1%;
	/*position: absolute;*/
	margin-top: 0.5%;
	/*margin-left: -13%;*/
}

.Log_in_back_title_container{
	width: 100%;
	height: 4.6%;
	margin-top: 5px;
	font-size: 85%;
}

.Back_button_log_in {
	width: 55px;
	height: 100%;
	margin-bottom: 10px;
	/*margin-left: 10px;*/
	margin-left: 0.5%;
	border: none;
	font-size: 125%;
	background-color: rgba(255, 255, 255, 1);
}

.logInButton {
	/*font-family: 'Roboto Condensed', sans-serif;*/
	width: 100%;
	/*height: 73%;*/
	height: 33px;
	margin-top: 8.3%;
	margin-top: 4.9%;
	border-radius: 3px;
	font-size: 110%;
	position: relative;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.logInButton:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.logInButton_Sign_up{
	width: 100%;
	/*height: 73%;*/
	height: 33px;
	position: absolute;
	margin-top: 17.0%;
	margin-top: 0.7%;
	border-radius: 3px;
	font-size: 110%;
	position: relative;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.logInButton_Sign_up:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.Log_in_button {
	width: 100%;
	/*height: 100%;*/
	height: 33px;
	margin-top: 0.8%;
	/*margin-top: -29.8%;*/
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.Log_in_button:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.LoginIForgotMyPasswordButton {
	width: 100%;
	/*height: 100%;*/
	height: 33px;
	margin-top: -4.8%;
	/*margin-top: -29.8%;*/
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}

.ContainerLoginIForgotMyPasswordButton {
	margin-top: 83.3%;
	height: 17%;
	width: 100%;
	/*border: solid 0.05em;*/
}

.ContainerLoginResetPasswordButton {
	margin-top: 61.7%;
	height: 17%;
	width: 100%;
	/*border: solid 0.05em;*/
}

.sendAgainButtonLogIn {
	position: absolute;
	margin-top: 55%;
	margin-left: 56%;
	border: none;
	background-color: rgba(255, 255, 255, 1);
	/*font-size: 85%;*/
	font-size: 10.25pt;
	text-decoration: underline;
	cursor: pointer;
}

/*.Back_button_log_in {*/
/*	width: 50px;*/
/*	margin-top: 10px;*/
/*	margin-right: 10%;*/
/*	border: solid 0.05em;*/
/*	border-radius: 3px;*/
/*	font-size: 125%;*/
/*	background-color: rgba(46,139,87, 0.35);*/
/*}*/

.Log_in_field_name {
	left: 90px;
	height: 10%;
	/*width: 5px;*/
	margin-bottom: 1%;
	font-size: 110%;
	font-size: 14pt;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
}

.Log_in_input_field {
	width: 98%;
	margin-bottom: 3%;
	height: 15%;
	/*height: 10px;*/
	border: solid 1px black;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	font-size: 13pt;
	/*border: 1px solid red;*/
}
.Log_in_input_field2 {
	width: 98%;
	margin-bottom: 3%;
	height: 15%;
	height: 30px;
	border: solid 1px black;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	font-size: 13pt;
}

.InvalidEmailMessage {
	position: absolute;
	margin-left: 54.0%;
	margin-top: -18%;
	color: red;
	font-size: 10pt;
}
.noPasswordIsProvided {
	position: absolute;
	margin-left: 39.0%;
	margin-top: 0.8%;
	margin-top: -2%;
	color: red;
	font-size: 10pt;
}

.IncorrectEmailOrPassword {
	position: absolute;
	margin-left: 0.0%;
	margin-top: -15%;
	/*polla*/
	color: red;
	font-size: 10pt;
}
.messageEmailAddressDoesNotExist {
	position: absolute;
	margin-left: 1.0%;
	color: red;
	font-size: 10pt;
}
.messageIncorrectVerificationCode {
	position: absolute;
	margin-left: 1.0%;
	margin-top: -0.5%;
	color: red;
	font-size: 10pt;
}
.messageNewPasswordToBeTheSame {
	position: absolute;
	margin-left: 0.0%;
	margin-top: -0.5%;
	margin-top: 7%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}


.rememberMeCheckbox {
	width: 1.3em;
	height: 1.3em;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	-webkit-appearance: caret;
	outline: none;
	cursor: pointer;
	font-size: 9pt;
}
.rememberMeCheckboxText {
	/*font-size: 110%;*/
	font-size: 12pt;
	margin-top: 3.25px;
	margin-left: 5px;
	position: absolute;
}

.rememberMeCheckboxContainer {
	width: 80%;
	/*background-color: red;*/
	margin-top: 61%;
	margin-left: 5%;
	position: absolute;
	font-size: 110%;
}

.HR_line {
	height: 0.5px;
	margin-top: -6px;
	/*left: 60px;
	top:160px;*/
	border: 0;
	width: 100px;
	color: #C0C0C0;
	background-color: #C0C0C0;
}


.Horizontal_lines_container {
	width: 177px;
	height: 10px;
	position: absolute;
	margin-top: 105%;
	margin-top: 110%;
	/*border: 1px solid red;*/
}


.Log_in_forgot {
	font-size: 100%;
	font-size: 10.25pt;
	margin-top: -12%;
	margin-left: -42.5%;
	/*margin-top: 85%;*/
	/*position: absolute;*/
}


.Log_in_sign_up_text {
	font-size: 100%;
	text-align: left;
	width: 100%;
	border-color: coral;
	border: solid 0.1em;
}


.Log_in_title_container {
	/*margin-top: 15px;*/
}

.Log_in_title_text {
	font-size: 300%;
}

.ContainerCookiesPolicy {
	display: flex;
	position: fixed;
	/*top: 91%;*/
	left: 10%;
	/*transform: translate(-50%, -50%);*/
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	width: 80%;
	background-color: #FFFFFF;
	z-index: 1000;
	bottom: 0%;
}

.ContainerCookiesPolicyMessage {
	width: 97.4%;
	text-align: justify;
	/*margin-left: 2%;*/
	margin-top: 0.4%;
}

.ContainerCookiesPolicyButtonPrevious {
	width: 100%;
	height: 30px;
	/*height: 90%;*/
	margin-top: 5px;
	margin-bottom: 5px;
}


.ContainerCookiesPolicyButtonContainerLogIn {
	/*position: absolute;*/
	/*width: 15%;*/
	/*height: 30px;*/
	/*!*margin-left: 56.5%;*!*/
	/*margin-top: 3.3%;*/
	/*right: 1.3%;*/
}

.ContainerCookiesPolicyButtonRejectAll {
	/*top: -300px;*/
	width: 100%;
	height: 100%;
	left: 27px;
	/*height: 38.5px;*/
	position: absolute;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.ContainerCookiesPolicyButtonRejectAll:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}
.ContainerCookiesPolicyButtonAgreeAndClose {
	height: 100%;
	height: 30px;
	position: absolute;
	border: 1px solid black;
	border-radius: 3px;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
	width: 15%;
	right: 1.3%;
}
.ContainerCookiesPolicyButtonAgreeAndClose:hover {
	background-color: rgba(10, 130, 10, 0.65);
}


.buttonAcceptCookiesDiv {
	/*weight: 15%;*/

	margin-top: 30px;
	margin-bottom: 15px;
	height: 30px;
}
.buttonAcceptCookies {
	position: absolute;
	/*margin-top: 350px;*/
	/*margin-left: 82.3%;*/
	/*right: 4.7%;*/
	margin-left: 50%;
	width: 50%;
	height: 30px;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.buttonAcceptCookies:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.buttonAcceptCookiesContainer {
	position: absolute;
	right: 4.5%;
	height: 30px;
	width: 200px;
}

.ContainerCookiesPolicyButtonRejectAllCMP{
	/*top: -300px;*/
	width: 50%;
	height: 30px;
	/*left: 67.3%;*/
	/*right: 17.7%;*/
	position: absolute;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.ContainerCookiesPolicyButtonRejectAllCMP:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.buttonCookiesDetail {
	position: absolute;
	/*margin-top: 350px;*/
	margin-left: 3.8%;
	/*width: 15%;*/
	height: 30px;
	/*height: 100%;*/
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.buttonCookiesDetail:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}
.cookiesMenuChangePreferencesClose {
	position: absolute;
	/*margin-left: 97.9%;*/
	right: -0.1%;
	margin-top: -0.1%;
	/*width: 2.1%;*/
	/*background: #16a085;*/
	/*background-color: red;*/
}

.CookiesMenuChangePreferences {
	margin-top: 10%;
	/*margin-top: 100px;*/
	margin-left: 20%;
	position: absolute;
	width: 60%;
	/*height: 43.5%;*/
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
@media screen and (max-width: 786px) {
	.CookiesMenuChangePreferences {
		width: 99%;
		/*height: 92.5%;*/
		margin-top: 7.5%;
		/*margin-left: 7.5%;*/
		margin-left: 0%;
	}
}
.cookiesMenuChangePreferencesMenuSpan {
	margin-left: 5%;
	width: 800px;
	width: 86%;
	text-align: justify;
}
@media screen and (max-width: 786px) {
	.cookiesMenuChangePreferencesMenuSpan {
		margin-left: 5%;
		width: 800px;
		width: 86%;
		width: 65%;
		text-align: justify;
	}
}

.toggleCookiesTechnical {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	border: 0;
	outline: 0;
	cursor: pointer;
	/*left: 700px;*/
	/*text-align: right;*/
	float: right;
	margin-left: 90%;
	right: 4%;
	margin-top: -18px;
	/*margin: 10px;*/
}

/* To create surface of toggle button */
.toggleCookiesTechnical:before {
	content: '';
	width: 60px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;

	/*	This is for a smooth transition*/
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
}

/* Contents before checkbox to create toggle handle */
.toggleCookiesTechnical:after {
	content: '';
	width: 18px;
	height: 18px;
	display: block;
	position: absolute;
	left: 0px;
	top: -2px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.toggleCookiesTechnical:checked:after {
	left: 43px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
/* Background color when toggle button will be active */
.toggleCookiesTechnical:checked:before {
	background-color: rgba(155, 155, 155, 1);
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
	/*background: red;*/
}


.toggleCookies {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	border: 0;
	outline: 0;
	cursor: pointer;
	/*left: 700px;*/
	/*text-align: right;*/
	float: right;
	margin-left: 98%;
	right: 4%;
	margin-top: -15px;
	/*margin: 10px;*/
}

/* To create surface of toggle button */
.toggleCookies:before {
	content: '';
	width: 60px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;

	/*	This is for a smooth transition*/
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
}

/* Contents before checkbox to create toggle handle */
.toggleCookies:after {
	content: '';
	width: 18px;
	height: 18px;
	display: block;
	position: absolute;
	left: 0px;
	top: -1px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.toggleCookies:checked:after {
	left: 43px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
/* Background color when toggle button will be active */
.toggleCookies:checked:before {
	background: rgba(10, 130, 10, 0.85);;
	/*background: red;*/
}

.showCookiesDetailsCloseButtonLogIn{
	position: absolute;
	/*margin-left: 97.5%;*/
	/*margin-top: -43.3%;*/
	right: -0.1%;
	top: -0.1%;
	/*top: -1.0%;*/
	text-align: center;
	width: 30px;
	height: 23px;
	position: absolute;
	/*margin-left: 98.2%;*/
	/*margin-top: 0.8%;*/
}

.cookiesMenuChangePreferencesTitle {
	position: absolute;
	margin-left: 3.8%;
	margin-top: 2%;
}
.cookiesMenuChangePreferencesTitleSubtitle {
	position: absolute;
	margin-left: 6.3%;
	margin-top: 40px;
}
.cookiesMenuChangePreferencesMenu {
	width: 90%;
	/*position: absolute;*/
	margin-top: 50px;
	margin-left: 1.2%;
}
@media screen and (max-width: 786px) {
	.cookiesMenuChangePreferencesMenu {
		height: 92.5%;
		margin-top: 7.5%;
		margin-left: 7.5%;
	}
}
.cookiesMenuChangePreferencesMenuArea {
	/*position: absolute;*/
	/*background-color: blue;*/
}
.cookiesMenuChangePreferencesMenuItem {
	list-style-type: ' ';
	margin-left: 2.5%;
	margin-top: 2%;
}


/*.cookiesMenuChangePreferencesMenuSpan {*/
/*	margin-left: 5%;*/
/*	width: 800px;*/
/*	width: 86%;*/
/*	text-align: justify;*/
/*}*/

.tableAnalyticsCookies{
	top: 140px;
	/*width: 68.4%; */
	width: 80%;
	width: 100%;
	left: 5%;
	/* height: 50%; */
	/*height:80%;*/
	/*height: 800px;*/
	z-index: 10;
	/*position: sticky;*/
	/*border: 1px red solid;*/
	display: flex;
	/*font-size: 12pt;*/
}
.tableAnalyticsCookiesTable {
	font-size: 100%;
	height: 100%;
	display: block;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	table-layout: fixed;
	empty-cells: show;
	overflow: auto;
}
.tableAnalyticsCookiesTableHeader {
	width: 100%;
}
.tableAnalyticsCookiesTableHeader th {
	/*background-color: #4CAF50;*/
	/*background-color: rgba(10, 180, 10, 1.0);*/
	/*font-size: 12pt;*/
	font-weight: bold;
	/*color: grey;*/
	border: 0.5px solid #ddd;
	/*height: 35px;*/
	/*height: 100%;*/
	/*padding: 2px;*/
}
.tableAnalyticsCookiesTableHeader tr {
	width: 100%;
	height: 3.1vh;
}
.tableAnalyticsCookiesTableTBody {
	overflow: hidden;
	height: 94.7%;
	/*height: 100%;*/
	width: 100%;
}
.tableAnalyticsCookiesTableTBody tr {
	width: 100%;
	max-width: 100%;
	height: 2.1vh;
	max-height: 2.1vh;
	border: 0.5px solid #ddd;
	/*max-height: 30px;*/
}
.tableAnalyticsCookiesTableTBody tr:hover {background-color: #ddd;}

.tableAnalyticsCookiesTableTBody td {
	/*text-overflow: ellipsis;*/
	overflow: hidden;
	margin-left: 3.5px;
	border-right: 0.5px solid #ddd;
}

.tableAnalyticsCookiesTableTBody td:nth-child(1) {width: 150px; min-width: 150px; max-width: 150px; text-align: left;}
.tableAnalyticsCookiesTableTBody td:nth-child(2) {width: 80px; min-width: 80px; max-width: 80px; text-align: left;}
.tableAnalyticsCookiesTableTBody td:nth-child(3) {width: 420px; min-width: 420px; max-width: 420px; text-align: left;}
.tableAnalyticsCookiesTableTBody td:nth-child(4) {width: 100px; min-width: 100px; max-width: 100px; text-align: center;}




.CookiesPolicyTextContainerCloseButton {
	position: absolute;
	margin-left: 98.2%;
	/*background: #16a085;*/
}

/*.cookiesMenuChangePreferencesClose {*/
/*	position: absolute;*/
/*	margin-left: 97.4%;*/
/*	margin-top: -0.1%;*/
/*	!*background: #16a085;*!*/
/*}*/

.CookiesPolicyTextContainerLogIn {
	display: flex;
	position: fixed;
	top: 10%;
	left: 15%;
	/*transform: translate(-50%, -50%);*/
	flex-direction: column;
	align-items: center;
	/*top: 190px;*/
	/*left: 285px;*/
	/*position: absolute;*/
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	height: 70%;
	width: 70%;
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}
.CookiesPolicyTextContainerInternalTexts {
	width: 94%;
	margin-top: 2%;
	position: absolute;
}
.CookiesPolicyTitle {
	position: absolute;
}
.CookiesPolicyText {
	margin-top: 2%;
	font-size: 10pt;
	height: 650px;
	overflow-y: scroll;
	position: absolute;
	text-align: justify;

}


.ObscurerLogIn {
	position: fixed ;
	min-height: 100%;
	max-height: 1200%;
	width: 100%;
	/*margin-top: -0.4%;*/
	/*margin-left: -0.4%;*/
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	z-index: 200;
}



.showLegalTextBigLogIn {
	margin-top: 14%;
	top: -1.0%;
	margin-left: 24.5%;
	position: absolute;
	position: fixed;
	width: 51%;
	height: 48.5%;
	border: solid 0.05em;
	border-radius: 1px;
	background-color: #FFFFFF;
	z-index: 1000;
}

.showLegalTextDeciderTitleDivSupportLogIn {
	/*margin-left: 25%;*/
	position: fixed;
	width: 30%;
	height: 120px;
	margin-top: 18%;
	margin-left: 34.53%;
	border: solid 0.05em;
	border-radius: 1px;
	background-color: #FFFFFF;
	z-index: 1000;
}
@media screen and (max-width: 786px) {
	.showLegalTextBigLogIn {
		width: 85%;
		height: 92.5%;
		margin-top: 7.5%;
		margin-left: 7.5%;
	}
	.showLegalTextDeciderTitleDivSupportLogIn {
		width: 85%;
		/*height: 92.5%;*/
		margin-top: 50.5%;
		margin-left: 7.5%;
	}
}

/* On screens that are 1600px wide or less, the background color is blue */
@media screen and (max-width: 786px) {
	.ContainerCookiesPolicy {
		width: 99.2%;
		/*height: 12.7%;*/
		/*left: 0.25%;*/
		left: 0;
	}
}


.showLegalTextSmallLogIn {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 60%;
	height: 40.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.buttonCloseLegalTextLogIn {
	left: 97.0%;
	top: -0.1%;
	/*left: 1210px;*/
	position: absolute;
	width: 30px;
	height: 23px;
}
.buttonCloseLegalTextButton {
	/*width: 100%;*/
	/*height: 100%;*/
	/*padding-right: 2px;*/
	/*padding-left: 2px;*/
	/*background-color: green;*/
	right: 0;
	/*top: -0.2%;*/
	top: 0;
	position: absolute;
	border: solid 0.05em;
	/*background-color: red;*/
	/*border: 1px solid red;*/
	/*font-size: 11.5pt;*/
}

.buttonCloseLegalTextButtonText {
	font-size: 9px;
}


.showLegalTextDeciderLogIn {
	margin-left: 3%;
	width: 94%;
	margin-top: 2%;
	/*height: 81%;*/
	max-height: 92%;
	overflow-y: auto;
	/*HOLI*/
}
@media screen and (max-width: 786px) {
	.showLegalTextDeciderLogIn {
		/*background-color: red;*/
		max-height: 95%;
	}
}

.showLegalTextDeciderTitle{
	/*font-size: 10pt;*/
}



.legalNoticeButtonLogIn {
	margin-top: 52.75%;
	margin-left: 83.625%;
	margin-top: 40.15%;
	margin-left: 53.0%;
	/*width: 3%;*/
	/*border: solid 1pt red;*/
	position: absolute;
}
.legalNoticeButtonLogInButton {
	border: none;
	background-color: transparent;
}
.legalNoticeButtonLogInButton:hover {
	text-decoration: underline;
}
/* Start by setting display:none to make this hidden.
   Then we position it in relation to the viewport window
   with position:fixed. Width, height, top and left speak
   for themselves. Background we set to 80% white with
   our animation centered, and no-repeating */
.WaitingForRequest {
	display:    none;
	position:   fixed;
	z-index:    1000;
	top:        0;
	left:       0;
	height:     100%;
	width:      100%;
	background: rgba( 255, 255, 255, .8 )
	url('http://i.stack.imgur.com/FhHRx.gif')
	50% 50%
	no-repeat;
}

.loading {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.7;
	background-color: #fff;
	z-index: 5000;
}

.loading-image {
	z-index: 100;
}

.errorWhenLoadingLogIn {
	margin-left: 41%;
	margin-top: 27.0%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 80px;
	/*height: 700px;*/
	width: 18%;
	position: absolute;
	z-index: 2000;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid black;
}
.errorWhenLoadingLogInText {
	margin-left: 10%;
	margin-top: 5%;
}

.legalNoticeButtonLogInLegal {
	position: absolute;
	margin-left: 72.5%;
	margin-left: 60.0%;
	margin-left: 5.0%;
	/*margin-left: 68%;*/
	/*margin-top: -45%;*/
	text-align: left;
	width: 10%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
}
.licensesButtonLogIn {
	position: absolute;
	margin-left: 72.5%;
	margin-left: 60.0%;
	margin-left: 5.0%;
	/*margin-left: 68%;*/
	/*margin-top: -45%;*/
	text-align: left;
	width: 10%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
}
.licensesButtonLogIn:hover {
	text-decoration: underline;
}
.ContactSupportLogIn {
	position: absolute;
	margin-left: 5.0%;
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.ContactSupportLogIn:hover {
	text-decoration: underline;
}
.TIDINameLogIn{
	position: absolute;
	margin-left: 87.8%;
	margin-left: 85.8%;
	margin-left: 5.0%;
	/*margin-left: 72.5%;*/
	/*font-size: 10.0pt;*/
	text-align: left;
	width: 11%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
}

.showLegalTextBigProfileLogIn {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 40%;
	height: 55.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextDeciderTextMyAreaTextLogIn{
	margin-right: 1%;
	/*width: 99%;*/
	width: 99%;
	height: 100%;
	/*border: 1px solid red;*/
	text-align: justify;
}
.PasswordContainerWithEyeIcon {

}
.Log_in_input_field3 {
	height: 100%;
	width: 100%;
	/*border: 1px solid red;*/
}
.eyePassword {
	position: absolute;
	width: 30px;
	height: 25px;
	/*width: 100px;*/
	/*height: 100px;*/
	/*margin-left: -8.9%;*/
	right: 60px;
	margin-top: -38px;
	/*border: 1px solid red;*/
	background-color: transparent;
}



.messageNewPasswordFormat {
	position: absolute;
	font-size: 10pt;
	text-align: justify;
	width: 73%;
	margin-top: 70%;
}

.BackgroundLogIn {
}

.body {
	/*background-color: rgba(46,139,87, 0.1);*/
	background-color: rgba(255, 0, 0, 0.3);
	/*font-family: 'Roboto Condensed', sans-serif;*/
	/*background-color: rgba(85, 215, 85, 0);*/
}

.indentationPrivacyPolicy {
    margin-left: 2%;
}

.buttonToRedirectInTexts {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    margin: 0px;
    cursor: pointer;
    /*color: blue;*/
}

.itemizeToRight {
    margin-left: 2%;
}
.itemizeToRight2{
    /*display: inline;*/
    /*margin-left: 2%;*/
}
.li1{
    border: 1px solid red;
}

.ulItem{
    margin-left: -0.5%;
}
.ulItem2{
    margin-left: -0.5%;
}
.AlignToRight {
    font-weight: 700;
    text-align: right;
}

.My_area_general_container {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	/*transform: translate(-50%, -50%);*/
	flex-direction: column; 
	align-items: center; 
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*border-radius: 5px;*/
	width: 100%;
	/*width: 1920px;*/
	height: 100%;
	/*height: 1057px;*/
	/*height: 1088px;*/
	background-color: white;
}


.TIDI_Title {
	margin-left: 2.5%;
	margin-top: 0.5%;
	font-size: 200%;
	color: #FFFFFF;
	position: absolute;
}

#title {
  text-align: center;
  /*font-family: Calibri, sans-serif;*/
}

.welcomeName {
	/*margin-left: 73.0%;*/
	/*margin-top: 19px;*/
	/*margin-right: 20%;*/
	margin-top: 19px;
	color: white;
	height: 100%;
	width: 40%;
	/*width: 100%;*/
	position: absolute;
	text-align: right;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*background-color: red;*/
	/*align-items: center;*/
	/*font-size: 18px;*/
	font-size: 14pt;
	right: 120px;
}

.welcomeNameSpan {
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*margin-top: 140%;*/
	height: 100%;
	text-decoration: underline;
	/*vertical-align: middle;*/
	/*display: inline-block;*/
}

.HistoricalOperationsDiv {
	/*border: 1px solid red;*/
	top:190px;
	/*width: 68.4%; */
	width: 100%;
	/* height: 50%; */
	/*height:80%;*/
	/*height: 800px;*/
	/*height: 840px;*/
	z-index: 10;
	position: absolute;
	display: flex;
	font-size: 12pt;
}

.filterHistoricalData {
	font-size: 85%;
	border: 1px solid #ddd;
	/*width: 9%;*/
	/*width: 185px;*/
	width: 197px;
	height: 63%;
	height: 96%;
	height: 480px;
	margin-left: 100px;
	position: absolute;
}
.filterHistoricalDataPerson {
	border: 1px solid #ddd;
	/*width: 9%;*/
	/*width: 185px;*/
	width: 197px;
	height: 49.5%;
	margin-left: 100px;
	position: absolute;
}


.buttonFilterOperations {
	width: 100%;
	height: 100%;
	background-color: red;
	font-size: 100%;
}

.renderFiltersHeader {
	text-align: center;
	/*font-size: 12pt;*/
	border-collapse: collapse;
	border: 1px solid #ddd;
	width: 100%;
	/*height: 7%;*/
	height: 35px;
}

.renderFiltersHeader th {
	border: 0.5px solid #ddd;
	padding: 1px;
	height: 20px;
	/*padding-top: 10px;*/
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	/*padding-bottom: 10px;*/
	text-align: center;
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	color: white;
}

.filterHistoricalDataForm {
	width: 100%;
	height: 80%;
}

.filterFieldName {
	margin-left: 14%;
	margin-right: 11%;
	margin-top: 12.0px;
	width: 75%;
	/*height: 20px;*/
	/*height: 200%;*/
	/*border: solid 1pt;*/
	/*font-size: 100%;*/
	/*font-size: 12.5pt;*/
	font-size: 110%;
}

.filterFieldField {
	margin-left: 11%;
	margin-right: 11%;
	/*margin-top: 0.6%;*/
	width: 73%;
	/*height: 5.0%;*/
	/*height: 27px;*/
	/*height: 18px;*/
	/*height: */
	/*min-height: 7%;*/
	/*margin-bottom: 4px;*/
	font-size: 100%;
}

.filterFieldFieldDate {
	margin-left: 11%;
	margin-right: 11%;
	/*margin-top: 0.6%;*/
	width: 74.5%;
	/*height: 5.0%;*/
	height: 27px;
	height: 20px;
	/*min-height: 7%;*/
	/*margin-bottom: 4px;*/
	font-size: 100%;
}

.filterFieldFieldButton {
	margin-left: 11%;
	margin-right: 11%;
	/*margin-top: 55%;*/
	width: 77%;
	/*height: 6.0%;*/
	/*height: 34px;*/
	/*height: 25px;*/
	/*min-height: 7%;*/
	/*margin-bottom: 4px;*/
	/*font-size: 105%;*/
	text-align: center;
	border: 1px solid;
	background-color: rgba(230, 230, 230, 1);
}
.filterFieldFieldButton:hover {
	background-color: rgba(190, 190, 190, 1);
}
.renderFilterOperationType {
	text-align: left;
	/*font-size: 12pt;*/
	position: absolute;
}
.renderFilterEmployee {
	text-align: left;
	/*font-size: 12pt;*/
	width: 130px;
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	/*margin-left: -4%;*/
}


.dropDownOperations{
	height: 20%;
	width: 100%;
}

.dropdownOperationChosen {
	left: 11.0%;
	/*width: 100px;*/
	width: 76.0%;
	height: 80px;
	position: absolute;
	background-color: white;
	border: 1px solid;
	border-color: rgba(150, 150, 150, 1);
	/*font-size: 12.5pt;*/
}

.dropdownOperationChosen a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}

.Operations_button{
	margin-left: 3%;
	margin-right: 3%;
	width: 94%;
}

.dropDownOperations{
	height: 20%;
	width: 100%;
}


.vertical_dropdown {
	margin-left: 1%;
	margin-right: 5%;
	width: 94%;
}

.vertical_dropdown.menu{
}

.dropdown-menu {
	position:absolute !important;
	height: 100px;
	width: 300px;
	overflow-y: scroll;
	border-color: rgba(46,139,87, 0.1);
	border: solid 0.3em;
	background-color: rgb(255,255,255);
	z-index: 100;
}


.ButtonsFilter {
	margin-left: 11%;
	margin-top: 16%;
	width: 75%;
	height: 23px;
	/*font-size: 12pt;*/
	/*background-color: red;*/
}

.Clear_button {
	/*margin-left: 11%;*/
	/*margin-top: 60%;*/
	width: 36%;
	position: absolute;
	height: 23px;
	/*font-size: 11pt;*/
	border: 1px solid;
	/*background-color: rgba(200, 200, 200, 1);*/
	background-color: rgba(230, 230, 230, 1);
}
.Clear_button:hover {
	background-color: rgba(190, 190, 190, 1);
}

.UnderstandMissingInformation {
	margin-left: 35%;
	width: 30%;
	position: absolute;
	height: 25px;
	font-size: 11pt;
	border: 1px solid;
	/*background-color: rgba(200, 200, 200, 1);*/
	color: white;
	border-color: black;
	background-color: rgba(10, 130, 10, 0.85);
}
.UnderstandMissingInformation:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.Filter_button {
	margin-left: 41%;
	/*margin-top: 15%;*/
	/*margin-top: 60%;*/
	width: 36%;
	position: absolute;
	height: 23px;
	/*font-size: 11pt;*/
	border: 1px solid;
	background-color: rgba(230, 230, 230, 1);
}
.Filter_button:hover {
	background-color: rgba(190, 190, 190, 1);
}


.HistoricalOperationsTable {
	font-size: 100%;
	font-size: 85%;
	width: 71.4%;
	width: 68.5%;
	height: 98.5%;
	/*height: 540px;*/
	/*height: 110px;*/
	/*height: 25%;*/
	margin-left: 350px;
	display: block;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	table-layout: fixed;
	empty-cells: show;
	overflow-y: auto;
	overflow-x: hidden;
	border: 1px solid red;
}


.HistoricalOperationsTable tr:nth-child(even){background-color: #f2f2f2;}

.HistoricalOperationsTable th {
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	/*font-size: 12pt;*/
	font-weight: bold;
	color: white;
	border-right: 0.5px solid #ddd;
	/*width: 1000px;*/
	/*height: 35px;*/
	/*height: 100%;*/
	/*padding: 2px;*/
}

.HistoricalOperationsTableHeader {
	width: 100%;
}

.HistoricalOperationsTableHeader tr{
	width: 100%;
	height: 4.0vh;
	/*font-size: 13pt;*/
	/*font-weight: normal;*/
}


.HistoricalOperationsTableTBody  {
	overflow: hidden;
	white-space: nowrap;
	height: 94.7%;
	/*height: 100%;*/
	width: 100%;

	/*width: 700px;*/
}

.HistoricalOperationsTableTBody tr {
	width: 100%;
	max-width: 100%;
	height: 3.1vh;
	max-height: 3.1vh;
	/*max-height: 30px;*/
}

.HistoricalOperationsTableTBody tr:hover {background-color: #ddd;}


.HistoricalOperationsTable th:nth-child(1) {
	width: 40px; min-width: 40px; max-width: 40px;
	/*width: 10%; min-width: 10%; max-width: 10%;*/
	text-align: center; padding-right: 4px;}
.HistoricalOperationsTable th:nth-child(2) {
	width: 43px; min-width: 43px; max-width: 43px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}
.HistoricalOperationsTable th:nth-child(3) {
	width: 43px; min-width: 43px; max-width: 43px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}
.HistoricalOperationsTable th:nth-child(4) {
	width: 43px; min-width: 43px; max-width: 43px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}
.HistoricalOperationsTable th:nth-child(5) {
	width: 220px;min-width: 219px; max-width: 219px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTable th:nth-child(6) {
	width: 110px;min-width: 110px; max-width: 110px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTable th:nth-child(7) {width: 130px;min-width: 129px; max-width: 129px;
	width: 95px; min-width: 95px; max-width: 95px;
	text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTable th:nth-child(8) {width: 130px;min-width: 129px; max-width: 129px;
	width: 95px; min-width: 95px; max-width: 95px;
	text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTable th:nth-child(9) {width: 100px;min-width: 100px; max-width: 100px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center; padding-right: 3px;  padding-left: 3px;}
.HistoricalOperationsTable th:nth-child(10){width: 180px;min-width: 180px; max-width: 180px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable th:nth-child(11){width: 50px;min-width: 50px; max-width: 50px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable th:nth-child(12){
	width: 45px;min-width: 45px; max-width: 45px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}

.HistoricalOperationsTable td:nth-child(1) {width: 50px;  min-width: 50px;  max-width: 50px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: right; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(2) {width: 67px;  min-width: 67px;  max-width: 67px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(3) {width: 67px;  min-width: 67px;  max-width: 67px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(4) {width: 67px;  min-width: 67px;  max-width: 67px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(5) {
	width: 220px; min-width: 220px; max-width: 220px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: left;padding-left: 3px; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(6) {
	width: 110px;min-width: 110px; max-width: 110px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: left;padding-left: 3px; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(7) {width: 130px;min-width: 130px; max-width: 130px;
	width: 95px; min-width: 95px; max-width: 95px;
	text-align: left;padding-left: 5px; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(8) {width: 130px;min-width: 130px; max-width: 130px;
	width: 95px; min-width: 95px; max-width: 95px;
	text-align: left;padding-left: 5px; padding-right: 3px;}
.HistoricalOperationsTable td:nth-child(9) {width: 100px;min-width: 100px; max-width: 100px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: right; padding-right: 3px; padding-left: 3px;}
.HistoricalOperationsTable td:nth-child(10){width: 160px;min-width: 160px; max-width: 160px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(11){width: 50px;min-width: 50px; max-width: 50px;
	width: 5%; min-width: 5%; max-width: 5%;
	text-align: center;}
.HistoricalOperationsTable td:nth-child(12){
	width: 45px;min-width: 45px; max-width: 45px;
	/*width: 5%; min-width: 5%; max-width: 5%;*/
	text-align: center;}

.HistoricalOperationsTable td {
	width: 1200px;
	border: 0.5px solid #ddd;
	/*padding: 1px;*/
}


.HistoricalOperationsTableTBody td {
	/*display: block;*/
	/*white-space: nowrap;*/
	/*word-wrap: break-word;*/
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: 3.5px;
}

.MoreInfoButton {
	width: 100%;
	height: 100%;
	/*font-size: 12pt;*/
	font-size: 90%;
	border: none;
	background-color: transparent;
	text-decoration: underline;
}

.HistoricalOperationsTablePSI {
	font-size: 100%;
	width: 71.4%;
	width: 75.5%;
	height: 98.5%;
	/*height: 540px;*/
	/*height: 110px;*/
	/*height: 25%;*/
	margin-left: 350px;
	display: block;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	table-layout: fixed;
	empty-cells: show;
	overflow-y: auto;
	overflow-x: hidden;
}

.HistoricalOperationsTablePSI thead, .HistoricalOperationsTablePSI tbody {

}

.HistoricalOperationsTablePSI tr:nth-child(even){background-color: #f2f2f2;}

.HistoricalOperationsTablePSI th {
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	font-size: 12pt;
	font-weight: bold;
	color: white;
	border-right: 0.5px solid #ddd;
	/*height: 35px;*/
	/*height: 100%;*/
	/*padding: 2px;*/
}

.HistoricalOperationsTableHeaderPSI {
	width: 100%;
}

.HistoricalOperationsTableHeaderPSI tr{
	width: 100%;
	height: 3.1vh;
	/*font-size: 13pt;*/
	/*font-weight: normal;*/
}


.HistoricalOperationsTableTBodyPSI  {
	overflow: hidden;
	white-space: nowrap;
	height: 94.7%;
	/*height: 100%;*/
	width: 100%;

	/*width: 700px;*/
}

.HistoricalOperationsTableTBodyPSI tr {
	width: 100%;
	max-width: 100%;
	height: 3.1vh;
	max-height: 3.1vh;
	/*max-height: 30px;*/
}

.HistoricalOperationsTableTBodyPSI tr:hover {background-color: #ddd;}


.HistoricalOperationsTablePSI th:nth-child(1) {width: 50px; min-width: 50px; max-width: 50px; text-align: center; padding-right: 4px;}
.HistoricalOperationsTablePSI th:nth-child(2) {width: 67px; min-width: 67px; max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(3) {width: 67px; min-width: 67px; max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(4) {width: 67px; min-width: 67px; max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(5) {width: 67px;min-width: 67px; max-width: 67px; text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTablePSI th:nth-child(6) {width: 220px;min-width: 220px; max-width: 220px; text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTablePSI th:nth-child(7) {width: 220px;min-width: 220px; max-width: 220px; text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTablePSI th:nth-child(8) {width: 130px;min-width: 129px; max-width: 129px; text-align: center; padding-left: 4px; padding-right: 3px;}
.HistoricalOperationsTablePSI th:nth-child(9) {width: 130px;min-width: 130px; max-width: 130px; text-align: center; padding-right: 3px;  padding-left: 3px;}
.HistoricalOperationsTablePSI th:nth-child(10){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(11){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}
.HistoricalOperationsTablePSI th:nth-child(12){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}

.HistoricalOperationsTablePSI td:nth-child(1) {width: 50px;  min-width: 50px;  max-width: 50px; text-align: right; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(2) {width: 67px;  min-width: 67px;  max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(3) {width: 67px;  min-width: 67px;  max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(4) {width: 67px;  min-width: 67px;  max-width: 67px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(5) {width: 67px; min-width: 67px; max-width: 67px; text-align: left;padding-left: 3px; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(6) {width: 220px;min-width: 220px; max-width: 220px; text-align: left;padding-left: 3px; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(7) {width: 220px;min-width: 220px; max-width: 220px; text-align: left;padding-left: 5px; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(8) {width: 130px;min-width: 130px; max-width: 130px; text-align: left;padding-left: 5px; padding-right: 3px;}
.HistoricalOperationsTablePSI td:nth-child(9) {width: 130px;min-width: 130px; max-width: 130px; text-align: right; padding-right: 3px; padding-left: 3px;}
.HistoricalOperationsTablePSI td:nth-child(10){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(11){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}
.HistoricalOperationsTablePSI td:nth-child(12){width: 130px;min-width: 130px; max-width: 130px; text-align: center;}

.HistoricalOperationsTablePSI td {
	border: 0.5px solid #ddd;
	/*padding: 1px;*/
}


.HistoricalOperationsTableTBodyPSI td {
	/*display: block;*/
	/*white-space: nowrap;*/
	/*word-wrap: break-word;*/
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: 3.5px;
}










.Flag_sale {
	background-color: rgb(0,128,0);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}

.Flag_change {
	background-color: rgb(255,165,0);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}

.Flag_return {
	background-color: 	rgb(220,20,60);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}




.ButtonsEnv {
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	top: 60px;
	height: 145px;
	width: 100%;
	position: absolute;
	border: none;
	/*display: flex;*/
}

.NewSaleButton {
	font-size: 11pt;
	top: 40%;
	margin-left: 350px;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.NewSaleButton:hover {
	background-color: rgba(190, 190, 190, 1);
}

.modifyCompany {
	font-size: 11pt;
	top: 40%;
	margin-left: 544px;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.modifyCompany:hover {
	background-color: rgba(190, 190, 190, 1);
}

.removeCompany {
	font-size: 11pt;
	top: 40%;
	margin-left: 699px;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.removeCompany:hover {
	background-color: rgba(190, 190, 190, 1);
}

.sendEmails2CompaniesButton {
	font-size: 11pt;
	top: 40%;
	margin-left: 1500px;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.sendEmails2CompaniesButton:hover {
	background-color: rgba(190, 190, 190, 1);
}


.NewChangeButton {
	font-size: 11pt;
	top: 40%;
	/*margin-left: 1400px;*/
	/*margin-left: 68.5%;*/
	/*margin-left: 71.2%;*/
	margin-left: 70.3%;
	/*height: 35%;*/
	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.NewChangeButton:hover {
	background-color: rgba(190, 190, 190, 1);
}

.NewReturnButton {
	font-size: 11pt;
	top: 40%;
	/*margin-left: 1507px;*/
	margin-left: 78.6%;
	/*height: 35%;*/

	height: 38px;
	width: 8%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.NewReturnButton:hover {
	background-color: rgba(190, 190, 190, 1);
}

.UpdateButton {
	font-size: 11pt;
	top: 40%;
	/*left: 88%;*/
	/*margin-left: 1673.2px;*/
	/*margin-left: 89.0%;*/
	margin-left: 89.8%;
	/*height: 35%;*/
	height: 38px;
	width: 4%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.UpdateButton:hover {
	background-color: rgba(190, 190, 190, 1);
}

.UpdateButtonPSI {
	font-size: 11pt;
	top: 40%;
	/*left: 88%;*/
	/*margin-left: 1673.2px;*/
	/*margin-left: 89.0%;*/
	margin-left: 89.8%;
	/*height: 35%;*/
	height: 38px;
	width: 4%;
	border-radius: 5%;
	border: 0.5px solid;
	position: absolute;
	background-color: rgba(230, 230, 230, 1);
}
.UpdateButtonPSI:hover {
	background-color: rgba(190, 190, 190, 1);
}


.HeaderLandingPageMyArea {
	border-color: rgba(0,128,0, 0.9);
	border: solid 0;
	height: 60px;
	width: 100%;
	/*background-color: rgba(0,128,0, 0.9);*/
	/*background-color: rgba(28, 210, 28, 1.0);*/
	/*background-color: rgba(10, 195, 10, 1.0);*/
	background-color: rgba(10, 180, 10, 1.0);
	position: absolute;
	/*border: none;*/
	margin: 0 !important;
	padding : 0 !important;
}

.LogoLandingPageMyArea{
	margin-left: 1% !important;
	margin-top: 0.45%;
	color: white;
	border: none;
	/*border: solid 0.1em;*/
	/*font-size: 300%;*/
	font-size: 38px;

	position: absolute;
}

.LogoLandingPageMyAreaText {
	margin-left: 10% !important;
	width: 100%;
	/*border: solid 0.1em;*/
	position: absolute;
}

.navigationButtons {
	/*margin-left: 15.9%;*/
	margin-left: 18.4%;
	/*margin-left: 305px;*/
	height: 100%;
	width: 1320px;
	width: 66.8%;
	background-color: Transparent;
	position: absolute;
	font-size: 14.0pt;
	/*border-color: red;*/
	/*border: solid 0.2em;*/
	border: none;
}

.navigationButtonsRow {
	margin: 0 !important;
	margin-left: 0.4% !important;
	padding: 0 !important;
	height: 100%;
	width: 100%;
}

.basicColumn {
	margin: 0 !important;
	padding: 0 !important;
}

.HistoricalLandingPageMyArea{
	height: 100%;
	width: 20%;
	color: white;
	background-color: Transparent;
	/*background-color: rgba(255, 255, 255, 0.25);*/
	font-size: 14pt;
	position: absolute;
	border: none;
	/*border: 2px solid rgba(255, 255, 255, 0.45);*/
	/*text-decoration: underline;*/
	font-weight: bold;
	/*border: 1px solid;*/
	cursor: pointer;
}
.HistoricalLandingPageMyArea:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

.MyProfileLandingPageMyArea {
	margin-left: 20%;
	height: 100%;
	/*width: 25%;*/
	width: 20%;
	color: white;
	background-color: Transparent;
	font-size: 14pt;
	position: absolute;
	/*border: none;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	border: none;
	cursor: pointer;
}
.MyProfileLandingPageMyArea:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}


.LogOutButtonMyArea {
	/*margin-left: 94.5%;*/
	height: 100%;
	width: 90px;
	/*top: 2px;*/
	background-color: Transparent;
	background-repeat:no-repeat;
	font-size: 14pt;
	position: absolute;
	color: white;
	border: none;
	cursor: pointer;
	right: 0px;
	bold: false;
}

.LogOutButtonMyArea:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

/*.containerOfSale {*/
/*	top: 190px;*/
/*	left: 285px;*/
/*	position: absolute;*/
/*	border-color: coral;*/
/*	border: solid 0.3em;*/
/*	height: 630px;*/
/*	height: 870px;*/
/*	width: 1270px;*/

/*	background: rgba(255, 255, 255, 1);*/
/*	z-index: 1000;*/
/*}*/

.containerOfSale {
	top: 117px;
	left: 308px;
	position: absolute;
	border-color: coral;
	border: 3px solid;
	/*height: 800px;*/
	/*width: 1310px;*/
	/*height: 630px;*/
	height: 870px;
	height: 830px;
	width: 1350px;

	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}



.CloseButton {
	left: 94.1%;
	left: 97.0%;
	top: -0.1%;
	/*left: 1210px;*/
	position: relative;
	width: 40px;
	height: 30px;
}

.CloseButtonButton {
	width: 100%;
	height: 100%;
	font-size: 12.5pt;
}

.CloseButtonButton:hover {
	background-color: RGBA(150, 150, 150, 0.5);
}

.CloseButtonButtonText {
	margin-left: 12%;
}


.NewSaleMessage {
	/*color: rgba(0,128,0, 0.9);*/
	color: rgba(10, 180, 10, 1.0);
	font-size: 150%;
	font-size: 20pt;
	left: 2.5%;
	left: 45px;
	top: 8px;
	position: relative;
}


.NewSaleID{
	top: 3.5%;
	left:2%;
	height: 25px;
	position: relative;
	/*border: 1px solid red;*/
}

.NewSaleIDID {
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	margin-top: 4.5px;
	width: 80px;
	/*border-right: 1pt solid #ddd;*/
}

.NewSaleIDIDOld {
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	margin-top: 4.5px;
	margin-left: -2%;
	width: 80px;
	/*border-right: 1pt solid #ddd;*/
}

.NewSaleIDNo{
	/*left: 6.5%;*/
	margin-left: 32.4%;
	margin-top: 4.5px;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	/*width: 30px;*/
	width: 67%;
	height: 19px;
	/*width: 89px;*/
}

.NewSaleIDNo2{
	/*left: 6.5%;*/
	margin-left: 30.4%;
	margin-top: 4.5px;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	/*width: 30px;*/
	width: 67%;
	height: 19px;
	/*width: 89px;*/
}

.NewSaleIDNoID {
	margin-top: 0px;
}

.verticalSeparatorFirstBoxes {
	border-right: 1px solid #ddd;
	left: -2%;
	width: 89px;
	height: 25px;
	/*top: 0%;*/
	position: absolute;
}
.horizontalSeparatorFirstBoxes {
	border-bottom: 1px solid #ddd;
	margin-top: 25px;
	left: -2%;
	width: 100%;
	position: absolute;
}

.horizontalSeparatorSecondBoxes {
	border-bottom: 1px solid #ddd;
	margin-top: 0px;
	left: -2%;
	width: 100%;
	position: absolute;
}


.NewSaleEmployee{
	top: 3.5%;
	left:2%;
	height: 25px;
	position: relative;
	/*border: 1px solid red;*/
}

.NewOperationUpperDataContainerNoBorder {
	top: 3.5%;
	left:2%;
	left: 2.5%;
	left: 45px;
	width: 22.3%;
	width: 283px;
	position: relative;
}

.NewOperationUpperDataContainer{
	top: 3.5%;
	left:2%;
	left: 2.5%;
	left: 45px;
	width: 22.3%;
	width: 283px;
	position: relative;
	border: 1px solid #ddd;
}

.NewSaleEmployeeEmployee{
	/*border-color: coral;*/
	/*border: solid 1px;*/
	margin-top: 4.5px;
	font-size: 12pt;
	position: absolute;
	width: 80px;
}

.NewSaleEmployeeEmployeeOld {
	/*border-color: coral;*/
	/*border: solid 1px;*/
	margin-top: 4.5px;
	margin-left: -2%;
	font-size: 12pt;
	position: absolute;
	width: 80px;
}

.NewSaleEmployeeEmployeeNo {
	/*border-color: coral;*/
	/*border: solid red 1px;*/
	margin-top: 4.5px;
	margin-left: 30%;
	font-size: 12pt;
	position: absolute;
	/*width: 600px;*/
	width: 250%;
	/*width: 195px;*/
}

.NewSaleEmployeeButton {
	/*width: 68%;*/
	/*margin-left: 30%;*/
	/*height: 100%;*/
	/*left: 6.5%;*/
	left: 29.7%;
	width: 68.3%;
	height: 100%;
	margin-top: 1.0px;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: relative;
	/*width: 30px;*/
	/*height: 15px;*/
	/*width: 89px;*/
}

.containerDropDownShops {
	border-color: coral;
	border: solid 1px;
	margin-left: 11%;
	min-width: 186px;
	max-height: 150px;
	min-height: 30px;
	position: absolute;
	z-index: 50;
	background-color: white;
	overflow: auto;
	padding-left: 3px;
	padding-right: 3px;
	white-space: nowrap;
}

.containerDropDownShops a{
	text-decoration: none;
	color: black;
}






.containerDropDownShopsFilterTAble {
	border-color: coral;
	border: solid 1px;
	margin-left: 84px;
	min-width: 186px;
	max-height: 150px;
	min-height: 30px;
	position: absolute;
	z-index: 50;
	background-color: white;
	overflow: auto;
	padding-left: 3px;
	padding-right: 3px;
	white-space: nowrap;
}
.containerDropDownShopsFilterTAble a{
	text-decoration: none;
	color: black;
}


.EmployeeAndShopDropDown{
	/*left: 6.5%;*/
	left: 25%;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: relative;
	width: 230px;
}


.NewSaleShop{
	top: 3.5%;
	left:2%;
	position: relative;
	height: 25px;
	/*border: 1px solid red;*/
}

.NewSaleShopShop{
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	width: 75px;
	margin-top: 4.5px;
}

.NewSaleShopShopOld {
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: absolute;
	width: 75px;
	margin-top: 4.5px;
	margin-left: -2%;
}

.NewSaleShopShopNo {
	/*border-color: coral;*/
	/*border: solid red 1px;*/
	position: absolute;
	/*width: 295px;*/
	margin-top: 4.5px;
	margin-left: 30%;
}

.NewSaleShopButton {
	margin-left: 29.7%;
	width: 68.3%;
	height: 100%;
}

.NewSaleShopDropdown{
	/*left: 6.5%;*/
	left: 25%;
	/*border-color: coral;*/
	/*border: solid 1px;*/
	position: relative;
	width: 230px;
}


.dropDownIndividualShop {

}


.NewSaleDate{
	top:3.7%;
	left: 57.45%;
	left: 778px;
	width: 155px;
	position: relative;
	/*border: 1px solid;*/
	border: 1px solid #ddd;
	text-align: right;
}

.NewSaleDatePrevious {
	top:3.7%;
	left: 58.45%;
	left: 764.5px;
	left: 785px;
	width: 150px;
	position: relative;
	/*border: 1px solid;*/
	text-align: right;

}

.NewSaleDateDate{

}




.SaleTablePlusShippingPlusTicket{
	left: 2%;
	left: 45px;
	width: 96%;
	height: 80%;
	/*border: 1px solid;*/
	position: absolute;
}
.NewCompaniesTablePlusDisclaimer {
	left: 2%;
	left: 45px;
	width: 93.2%;
	height: 80%;
	/*border: 1px solid red;*/
	position: absolute;
	margin-top: 2%;
}

.SaleTableContainer{
	width: 890px;
	height: 220px;
	top: 5%;
	position: absolute;
	/*border: red 5px solid;*/
}

.NewCompaniesTableContainer{
	width: 890px;
	height: 220px;
	top: 5%;
	position: absolute;
	/*border: red 5px solid;*/
}

.SaleTable {
	position: absolute;
	text-align: center;
	font-size: small;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	width: 100%;
	display: block;
	/*height: 270px;*/
	height: 225px;
	height: 423.5px;
	max-height: 454px;
	table-layout: fixed;
	border-color: coral;
	border: 3px;
	overflow: auto;
}

.SaleTableHeader {
}

.SaleTableBody{
	width: 100%;
	/*height: 400px;*/
	height: 100%;
	max-height: 243px;
	/*position: absolute;*/
}
.SaleTableBody td{
	text-overflow: ellipsis;
}


.SaleTable td{
	border: 0.2px solid #ddd;
	/*width: 80px;*/
	font-size: 100%;
	width: 100px;
	/*white-space: nowrap;*/
	/*text-overflow: ellipsis;*/
	overflow: hidden;
	/*border: 1px solid;*/
	/*word-wrap: break-word;*/
	max-width: 50px;
}

.SaleTable th {
	border: 0.5px solid #ddd;
	/*padding: 8px;*/
	line-height: 13px;
	padding-top: 2px;
	padding-bottom: 2px;
	text-align: center;
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	color: white;
	position: -webkit-sticky;
	position: sticky;
	height: 35px;
	height: 35px;
	font-size: 12pt;
	font-weight: bold;

	/*word-wrap: break-word;*/
	max-width: 300px;
}

.SaleTable tr{
	/*line-height: 30px;*/
	/*position: relative;*/
	height: 30px;
	max-height: 30px;
	font-size: 12pt;
	padding: 0;
	margin: 0;
}

.inputSaleTable {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
}
.inputSaleTable2 {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}
.inputSaleTableRed{

}

.SaleTable tr:nth-child(even){background-color: #f2f2f2;}



.SaleTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.SaleTable th:nth-child(2) {width: 127px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(3) {width: 97px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(4) {width: 87px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(5) {width: 87px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(6) {width: 89px; text-align: center; padding-right: 3px;}
.SaleTable th:nth-child(7) {width: 157px; text-align: center; padding-right: 3px;}
.SaleTable th:nth-child(8) {width: 157px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.SaleTable th:nth-child(9) {width:  44px; max-width: 44px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.SaleTable th:nth-child(10){width:  47px; text-align: center; padding-right: 3px;}
.SaleTable th:nth-child(11){width:  87px; text-align: center; padding-left: 3px;}
.SaleTable th:nth-child(12){width:  67px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}

.SaleTable td:nth-child(1) {width:  57px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(2) {width: 127px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(3) {width: 97px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(4) {width: 87px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(5) {width: 87px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(6) {width: 89px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(7) {width: 157px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(8) {width: 157px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(9) {width:  44px; max-width: 44px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(10){width:  47px; text-align: right; padding-right: 3px;}
.SaleTable td:nth-child(11){width:  87px; text-align: left; padding-left: 3px;}
.SaleTable td:nth-child(12){width:  67px; text-align: right; padding-right: 3px;}

.SaleTable tr:hover {background-color: #ddd;}


.NewCompaniesTable {
	position: absolute;
	text-align: center;
	font-size: small;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	width: 100%;
	display: block;
	height: 543.5px;
	/*max-height: 454px;*/
	table-layout: fixed;
	/*border: coral 3px solid;*/
	overflow: auto;
}

.NewCompaniesTableHeader {
}

.NewCompaniesTableBody{
	width: 100%;
	/*height: 400px;*/
	height: 100%;
	max-height: 243px;
	/*position: absolute;*/
}
.NewCompaniesTableBody td{
	text-overflow: ellipsis;
}


.NewCompaniesTable td{
	border: 0.2px solid #ddd;
	/*width: 80px;*/
	font-size: 100%;
	width: 100px;
	/*white-space: nowrap;*/
	/*text-overflow: ellipsis;*/
	overflow: hidden;
	/*border: 1px solid;*/
	/*word-wrap: break-word;*/
	max-width: 50px;
}

.NewCompaniesTable th {
	border: 0.5px solid #ddd;
	/*padding: 8px;*/
	line-height: 13px;
	padding-top: 2px;
	padding-bottom: 2px;
	text-align: center;
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	color: white;
	position: -webkit-sticky;
	position: sticky;
	height: 35px;
	height: 35px;
	font-size: 12pt;
	font-weight: bold;

	/*word-wrap: break-word;*/
	max-width: 300px;
}

.NewCompaniesTable tr{
	/*line-height: 30px;*/
	/*position: relative;*/
	height: 30px;
	max-height: 30px;
	font-size: 12pt;
	padding: 0;
	margin: 0;
}

.inputSaleTable {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
}
.inputSaleTable2 {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}
.inputSaleTableRed{

}

.NewCompaniesTable tr:nth-child(even){background-color: #f2f2f2;}



.NewCompaniesTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.NewCompaniesTable th:nth-child(2) {width: 220px; text-align: center; padding-left: 3px;}
.NewCompaniesTable th:nth-child(3) {width: 220px; text-align: center; padding-left: 3px;}
.NewCompaniesTable th:nth-child(4) {width: 120px; text-align: center; padding-left: 3px;}
.NewCompaniesTable th:nth-child(5) {width: 220px; text-align: center; padding-left: 3px;}
.NewCompaniesTable th:nth-child(6) {width: 220px; text-align: center; padding-right: 3px;}

.NewCompaniesTable td:nth-child(1) {width:  57px; text-align: right; padding-right: 3px;}
.NewCompaniesTable td:nth-child(2) {width: 220px; text-align: left; padding-left: 3px;}
.NewCompaniesTable td:nth-child(3) {width: 220px; text-align: left; padding-left: 3px;}
.NewCompaniesTable td:nth-child(4) {width: 120px; text-align: left; padding-left: 3px;}
.NewCompaniesTable td:nth-child(5) {width: 220px; text-align: left; padding-left: 3px;}
.NewCompaniesTable td:nth-child(6) {width: 220px; text-align: right; padding-right: 3px;}

.NewCompaniesTable tr:hover {background-color: #ddd;}



.NewSaleShipping{
	/*top: 278.5px;*/
	/*width: 128px;*/
	/*width: 228px;*/
	/*width: 168px;*/
	height: 21px;
	/*left: 59.5%;*/
	/*left: 57.4%;*/
	left: 720px;
	border: 1px solid #ddd;
	position: absolute;
	font-size: 12pt;

	/*top: 555.5px;*/
	/*top: 527px;*/
	top: 468px;
	/*left: 1122px;*/
	/*left: 1116px;*/
	/*width: 150px;*/
	position: absolute;
	width: 148px;
	width: 168px;
	height: 21px;
	border: 1px solid #ddd;
}

.NewSaleShippingPrevious {
	/*top: 278.5px;*/
	/*width: 228px;*/
	/*width: 168px;*/
	height: 21px;
	/*left: 59.5%;*/
	left: 57.3%;
	/*border: 1px solid #ddd;*/
	position: absolute;
	text-align: right;
	font-size: 12pt;

	left: 57.4%;
	left: 730px;

	position: absolute;
	font-size: 12pt;

	/*top: 555.5px;*/
	/*top: 527px;*/
	top: 468px;
	position: absolute;
	/*width: 148px;*/
	width: 168px;
	height: 21px;
}
.NewSaleShippingPreviousSpace {
	width: 2px;
	position: absolute;
}

.NewSaleShippingName {
	width: 70px;
	width: 120px;
	top: 2px;
	position: absolute;
}

.NewSaleShippingNamePrevious {
	width: 120px;
	position: absolute;
}

.NewSaleShippingValue {
	/*left: 70px;*/
	left: 121px;
	width: 39px;
	height: 15px;
	position: absolute;
	text-align: right;
	font-size: 11pt;
	/*border: 1px solid #ddd;*/
}
.NewSaleShippingValue2 {
	/*left: 70px;*/
	left: 121px;
	width: 39px;
	height: 15px;
	position: absolute;
	text-align: right;
	font-size: 11pt;
	/*border: 1px solid #ddd;*/
	/*max-width: 80px;*/
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;

}

.NewSaleShippingValuePrevious {
	left: 110px;
	width: 50px;
	position: absolute;
	text-align: right;
	/*border: 1px solid #ddd;*/
}







.NewSaleTicket {
	left: 71.5%;
	top: 5%;
	width: 330px;
	height: 450px;
	/*border: 1px solid;*/
	position: absolute;

}

.NewSaleTicketSummary {
	position: absolute;
	width: 100%;
	height: 270px;
	height: 420px;
	height: 510px;
	border: 2px solid #ddd;
}

.NewSaleSummaryHeader {
	position: relative;
	text-align: center;
	font-size: 12pt;
	border-collapse: collapse;
	/*border-bottom: 1px solid #ddd;*/
	border-bottom: none;
	width: 100%;
	/*height: 46px;*/
	height: 38px;
}

.NewSaleSummaryHeader th {
	/*border: 0.5px solid #ddd;*/
	/*padding: 8px;*/
	line-height: 30px;
	text-align: left;
	/*background-color: #4CAF50;*/
	background-color: rgba(10, 180, 10, 1.0);
	color: white;
	max-width: 300px;
}
.NewSaleSummaryHeader th:nth-child(1) {
	text-align: left;
	padding-left: 10px;
}
.NewSaleSummaryHeader th:nth-child(2) {
	text-align: right;
	padding-right: 13px;
}



.NewSaleSummaryTable{

}


.SummarySaleTableBody {
	width: 100%;
	height: 135px;
	position: absolute;
	/*border-bottom: 1px solid #ddd;*/
	/*border: 1px;*/
}
.SummarySaleTableBody td{
	text-overflow: ellipsis;
	overflow: hidden;
}

.SummarySaleTable {
	position: absolute;
	text-align: center;
	font-size: 11pt;
	border-collapse: collapse;
	/*border-top: none;*/
	width: 100%;
	display: block;
	/*height: 132px;*/
	height: 285px;
	height: 247px;
	height: 325px;
	overflow-y: auto;
	/*border-bottom: 1px solid #ddd;*/
}

.SummarySaleTableHeader{
	/*border-bottom: 1px solid #ddd;*/
}


.SummarySaleTable th {
	line-height: 20px;
	text-align: left;
	background-color: rgb(255, 255, 255);
}

.SummarySaleTable th:nth-child(1) {
	color: rgba(10, 130, 10, 1.0);
}

.SummarySaleTable th td {
	border-bottom: 0.5px solid;
}

.SummarySaleTable td {
	/*border: 0.5px solid #ddd;*/
	line-height: 20px;
	text-align: left;
	background-color: rgb(255, 255, 255);
	white-space: nowrap;
	word-wrap: break-word;
}


.SummarySaleTable td:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px; }
.SummarySaleTable td:nth-child(2) {width: 50px; max-width: 50px; text-align: right;}
.SummarySaleTable td:nth-child(3) {width: 50px; max-width: 50px; text-align: right;}
.SummarySaleTable td:nth-child(4) {width: 80px; max-width: 70px; text-align: right;}

.SummarySaleTable th:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px;text-align: left;}
.SummarySaleTable th:nth-child(2) {width: 50px; max-width: 50px; text-align: center;}
.SummarySaleTable th:nth-child(3) {width: 50px; max-width: 50px; text-align: center;}
.SummarySaleTable th:nth-child(4) {width: 70px; max-width: 70px; text-align: right;}


.SummarySaleTableHeaderEndLine {
	border-bottom: 1px solid #ddd;
	/*border-bottom: 1px solid red;*/
	margin-top: 20px;
	position: absolute;
	width: 92%;
	margin-left: 3%;
	height: 1px;
	/*z-index: 1500;*/
}

.SummarySaleTableBodyEndLine {
	border-bottom: 1px dashed #ddd;
	/*border-bottom: 1px solid red;*/
	margin-top: 260px;
	margin-top: 345px;
	/*margin-top: 12px;*/
	position: absolute;
	width: 100%;
	/*margin-left: 3%;*/
	height: 1px;
	/*z-index: 1500;*/
}



.NewSaleSummaryTotals{
	width: 100%;
	top: 180px;
	height: 120px;
	top: 319px;
	top: 400px;
	height: 120px;
	position: absolute;
}

.NewSaleSummaryTotalsTable{
	width: 100%;
	position: absolute;
	font-size: 11pt;
	top: -4%;
}


.NewSaleSummaryTotalsTable td:nth-child(1){
	padding-left: 3px;
	/*border: 1px solid #ddd;*/
}
.NewSaleSummaryTotalsTable td:nth-child(2){
	/*border: 1px solid #ddd;*/
	width: 30px;
	padding-right: 8px;
	text-align: right;
}

.NewSaleSummaryTotalsTable tr:nth-child(2){
	width: 40px;
	padding-bottom: 29.5px;
}

.NewSaleSummaryTotalsTable tr:nth-child(3){
	height: 7px;
	padding-bottom: 29.5px;
}
.NewSaleSummaryTotalsTable tr:nth-child(4) {
	font-weight: bold;
}

.NewSaleSummaryTotalsTableBody {

}







.NewSaleTicketClient{
	top: 308px;
	top: 500.0px;
	width: 98%;
	height: 70px;
	position: absolute;
	border: 2px solid #ddd;

	top: 435.0px;
	width: 100%;
	height: 80px;
	position: absolute;
	border: 2px solid #ddd;
}

.NewSaleClientDataHeader{
	position: absolute;
	width: 100%;
	border-collapse: collapse;
	font-size: 12pt;
	background-color: #4CAF50;
}

.NewSaleClientDataHeader tr{
	background-color: #4CAF50;
}

.NewSaleClientDataHeader th{
	position: absolute;
	text-align: center;
	width: 100%;
	color: white;
	border: none;
	background-color: #4CAF50;
	height: 35px;
}

.NewSaleClientDataEmail{
	top: 50%;
	width: 40%;
	height: 80%;
	position: absolute;
}

.NewSaleClientDataMessageEmail{
	position: absolute;
	left: 8%;
	/*border: 1px solid;*/
	font-size: 11pt;
}

.NewSaleClientDataFormEmail {
	position: absolute;
	left: 100%;
	top: -5%;
	text-overflow: ellipsis;
	overflow: hidden;
}
.NewSaleClientDataFormEmail2 {
	position: absolute;
	left: 100%;
	top: -5%;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
	text-overflow: ellipsis;
	overflow: hidden;
}





.NewSaleTicketButton{
	top: 400px;
	top: 545px;
	width: 100%;
	height: 38.5px;
	position: absolute;
}


.buttonScanAndPayButton {
	position: absolute;
	width: 55%;
	left: 22.5%;
	height: 100%;
	border-radius: 5%;
	font-size: 13.5pt;
 }

.buttonScanAndPayButton:hover {
	background-color: RGBA(150, 150, 150, 0.5);
}

.decideAnyPopupOnArrival {
	position:absolute !important;
	margin-top: 200px;
	z-index: 1000;
	height: 15%;
	width: 25%;
	border: solid 0.1em;
	background-color: rgb(255,255,255);
}
.ArrivalPopupText {
	margin: 3%;
	text-align: justify;
}

.PopUpMissingData {
	position:absolute !important;
	margin-top: 200px;
	z-index: 1000;
	height: 31.0%;
	width: 35%;
	border: solid 0.1em;
	background-color: rgb(255,255,255);
	text-align: justify;
}
.PopUpMissingDataInside {
	margin: 3%;
	height: 240px;
}

.showQRCodeOfHistoricOperation {
	width: 100%;
	height: 100%;
	border: none;
	/*border: 0;*/
	background-color: transparent;
}

.showQRCodeOfHistoricOperationQR {
	width: 10%;
	height: 10%;
}


.ArrivalPopupCloseButton {
	margin-left: 35%;
	margin-top: 40px;
	position: relative;
	color: white;
	height: 25px;
	width: 30%;
	border-color: black;
	background-color: rgba(10, 130, 10, 0.85);
}
.ArrivalPopupCloseButton:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.Obscurer {
	position:absolute !important;
	height: 100%;
	width: 100%;
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	z-index: 200;
}

.ObscurerQRReceived {
	position:absolute !important;
	height: 100%;
	width: 100%;
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	margin-top: -0.40%;
	margin-left: -0.40%;
	z-index: 1200;
}

.showQRCodeimage {
	flex: 1 1;
	width: 20px;
	height: 20px;
}

.showQRCode {
	/*width: 100%;*/
	/*height: 100%;*/
	/*top: 20%;*/
	/*position: absolute;*/
}

.showQRCodeimage2 {
	/*flex: 1;*/
	/*width: 80%;*/
	/*height: 60%;*/

	width: 210px;
	left: 73px;
	/*text-align: center;*/
	top: 22%;
	position: absolute;

}

.showQRCodeimage3 {
	/*flex: 1;*/
	/*width: 80%;*/
	/*height: 60%;*/

	width: 210px;
	left: 70px;
	/*text-align: center;*/
	top: 26%;
	position: absolute;
}


.SalesQRCodeWindow {
	position:absolute !important;
	margin-top: 250px;
	margin-left: 20px;
	z-index: 2000;
	/*height: 35%;*/
	height: 560px;
	height: 55.6%;
	/*width: 25%;*/
	width: 350px;
	/*width: 17.5%;*/
	border: solid 0.1em;
	background-color: rgb(255,255,255);
}
.SalesQRCodeWindowSale {
	position:absolute !important;
	margin-top: 250px;
	margin-left: 20px;
	z-index: 2000;
	/*height: 35%;*/
	height: 490px;
	/*width: 25%;*/
	width: 350px;
	border: solid 0.1em;
	background-color: rgb(255,255,255);
}
.resendEmail {
	position: absolute;
	/*border: 1px solid red;*/
	height: 20%;
	height: 23px;
	width: 78%;
	margin-left: 10%;
	top: 395px;
	top: 350px;
}
.resendEmailEmail {
	position: absolute;
	top: 290px;
	font-size: 14pt;
	margin: 10%;
}

.resendEmailInput {
	position: absolute;
	height: 100%;
	width: 100%;
	font-size: 90%;
}
.resendEmailInput2{
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
 }


.resendEmailButtonButton {
	/*position: absolute;*/
	/*border: 1px solid red;*/
	height: 100%;
	width: 100%;
	font-size: 14pt;
}

.SalesQRCodeWindowText {
	width: 80%;
	/*left: 7%;*/
	/*top: 5%;*/
	margin: 10%;
	text-align: justify;
	position: absolute;
	font-size: 14pt;
}
.SalesQRCodeWindowText2 {
	width: 80%;
	/*left: 7%;*/
	top: 57%;
	margin: 10%;
	text-align: justify;
	position: absolute;
	font-size: 14pt;
}
.EmailAddresInputAfterOperationCompletion {
	width: 100%;
	height: 17px;
	margin-top: 5%;
}
.errorResendQR {
	color: red;
	width: 80%;
	left: 0.5%;
	/*top: 83.5%;*/
	margin: 10%;
	margin-top: 108.7%;
	text-align: justify;
	position: absolute;
	font-size: 10pt;
}
.errorSendingOperationQR {
	color: red;
	width: 80%;
	left: 6.5%;
	top: 71.5%;
	margin: 10%;
	text-align: justify;
	position: absolute;
	font-size: 10pt;
}

.QRImage {
	width: 220px;
	height: 220px;
	left: 65px;
	/*text-align: center;*/
	top: 25.5%;
	top: 17%;
	position: absolute;
}

.resendEmailAndCloseButtonsProvider {
	top: 500px;
	position: absolute;
	display: flex;
	height: 38px;
	height: 28px;
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
	font-size: 14pt;
}
.resendEmailButton {
	width: 50%;
	height: 100%;
	/*position: absolute;*/
	/*border: 1px solid red;*/
	/*height: 20px;*/
	/*width: 60%;*/
	/*left: 85px;*/
	/*top: 345px;*/
}

.buttonCloseHistoricalQRCode{
	/*position: absolute;*/
	height: 100%;
	/*left: 42%;*/
	width: 50%;
	/*top: 83%;*/
	/*width: 50%;*/
	/*height: 8%;*/
	border-radius: 5%;
	/*border: 1px solid red;*/
}

.buttonCloseQRCode {
	position: absolute;
	left: 25%;
	top: 83%;
	width: 50%;
	height: 8%;
	border-radius: 5%;
	/*border: 1px solid red;*/
}
.buttonCloseQRCodeSale {
	position: absolute;
	left: 10%;
	top: 83%;
	width: 80%;
	height: 10%;
	border-radius: 5%;
}

.buttonCloseQRCodeButton {
	width: 100%;
	height: 100%;
	font-size: 14pt;
}


.displayErrorMessageIDInFilters {
	position: absolute;
	margin-left: 55%;
	margin-top: 7.0%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.ErrorMessagesInSaleWindow {
	position: absolute;
	/*margin-left: 35%;*/
	/*margin-top: -2.0%;*/
	/*background-color: green;*/
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: column;
	text-align: justify;
	color: red;
	max-height: 60px;
	font-size: 10pt;
	/*border: 1px solid red;*/
	z-index: 1500;
	/*border: 1px solid red;*/
}
.ErrorMessagesInSaleWindowText {
	margin: 0;
}
.ErrorMessagesInChangeWindowContainer {
	position: absolute;
	/*margin-left: 25%;*/
	margin-left: 30.5%;
	/*margin-top: -2.5%;*/
	margin-top: 2.5%;
	/*width: 50.5%;*/
	/*min-width: 800px;*/
	/*border: 1px solid red;*/
}
.ErrorMessagesInChangeWindowTextSales {
	margin: 0;
	width: 278px;
}

.messageFiltersCannotBeApplied {
	position: absolute;
	margin-left: 15%;
	margin-top: -20.0%;
	/*background-color: green;*/
	width: 71.5%;
	height: 15px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	/*border: 1px solid red;*/
}
.messageWrongEmailWhenResendingQRCode {
	position: absolute;
	margin-left: 53%;
	margin-top: 94.3%;
	/*background-color: green;*/
	width: 71.5%;
	height: 15px;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageWrongEmailWhenSendingQRCodeAtEndOfOperation {
	position: absolute;
	margin-left: 58%;
	margin-top: 96.3%;
	/*background-color: green;*/
	width: 71.5%;
	height: 15px;
	text-align: justify;
	color: red;
	font-size: 10pt;
}

.legalTextContainers {
	position: absolute;
	/*margin-top: 52.3%;*/
	bottom: 0;
	width: 100%;
	/*height: 50px;*/
	height: 40px;
	background-color: #f2f2f2;
	font-size: 85%;
}
.legalTextContainersColumn {
	width: 25%;
	/*border: 1px solid red;*/
	height: 100%;
	display: inline;
	float: left;
}

/* On screens that are 1600px wide or less, the background color is blue */
@media screen and (max-width: 1600px) {
	.legalTextContainersColumn {
		width: 25%;
	}
	.TIDINameLogIn {
		width: 14%;
	}
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1200px) {
	.legalTextContainersColumn {
		width: 50%;
		background-color: #f2f2f2;
	}
	.licensesButtonLogIn {
		width: 15%;
	}
	.TIDINameLogIn {
		width: 20%;
	}
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 992px) {
	.legalTextContainersColumn {
		width: 50%;
	}

}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
	.legalTextContainersColumn {
		/*background-color: olive;*/
		width: 100%;
	}
	.legalNoticeButtonLogInLegal {
		top: 5px;
		width: 90%;
	}
	.licensesButtonLogIn {
		width: 90%;
	}
	.TIDINameLogIn {
		width: 90%;
	}
}
.legalTextContainers1{
	position: absolute;
	margin-left: 5.3%;
	text-align: center;
	/*margin-top: -30%;*/
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers1:hover {
	text-decoration: underline;
 }
.legalTextContainers2{
	position: absolute;
	margin-left: 24.35%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers2:hover {
	 text-decoration: underline;
 }
.legalTextContainers3{
	position: absolute;
	margin-left: 48%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers3:hover {
	 text-decoration: underline;
 }
.legalTextContainers4{
	position: absolute;
	margin-left: 65.5%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers4:hover {
	text-decoration: underline;
}
.legalTextContainers5{
	position: absolute;
	margin-left: 87.2%;
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers5:hover {
	text-decoration: underline;
}

.showLegalTextBig {
	margin-top: 10%;
	/*margin-left: 25%;*/
	position: absolute;
	width: 60%;
	height: 55.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextSmall {
	margin-top: 10%;
	/*margin-left: 25%;*/
	position: absolute;
	width: 60%;
	height: 40.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextDeciderTitleDivSupportLandingPage {
	margin-top: 10%;
	/*margin-left: 25%;*/
	position: absolute;
	width: 30%;
	height: 120px;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.buttonCloseLegalText {
	left: 97.5%;
	top: -0.1%;
	/*left: 1210px;*/
	position: absolute;
	width: 30px;
	height: 23px;
}
.buttonCloseLegalTextSupport {
	left: 95.0%;
	top: -0.5%;
	/*left: 1210px;*/
	position: absolute;
	width: 30px;
	height: 23px;
}
/*.buttonCloseLegalTextButton {*/
/*	width: 100%;*/
/*	height: 100%;*/
/*	font-size: 11.5pt;*/
/*}*/
.showLegalTextDeciderTitleDivSupportTitle {
	margin-top: 2%;
	/*font-size: 15pt;*/
	/*height: 490px;*/
	width: 80%;
	overflow: hidden;
	text-align: justify;
	/*border: 1px solid red;*/
}
.showLegalTextDeciderTextDivSupport {
	margin-right: 1%;
	/*width: 99%;*/
	width: 96%;
	/*max-width: 250px;*/
	/*border: 1px solid red;*/
	overflow: hidden;
	text-align: justify;
	line-height: 1.5em;
	margin-top: 2%;
	font-size: 10pt;
	height: 490px;
	overflow-y: auto;
}
.showLegalTextDeciderTextDivSupportInterior {

}
.showLegalTextDeciderTextMyArea {
	margin-top: 2%;
	font-size: 10pt;
	height: 490px;
	height: 100%;
	/*overflow-y: auto;*/
	text-align: justify;
}
.showLegalTextDeciderTextMyAreaText{
	margin-right: 1%;
	/*width: 99%;*/
	width: 1030px;
	width: 99%;
	/*border: 1px solid red;*/
	text-align: justify;
}
.showLegalTextDeciderText{
	margin-top: 2%;
	font-size: 10pt;
}
.allLicenseTextsButtons {
	display:block;
}
.allLicenseTextsButtonsIntro{
	height: 17px;
}
.allLicenseTextsButtonsButtons{
	margin-top: 37px;
	/*margin-top: 4%;*/
}
/* On screens that are 1600px wide or less, the background color is blue */
@media screen and (max-width: 1600px) {
	.allLicenseTextsButtonsButtons {
		margin-top: 27px;
	}
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1200px) {
	.allLicenseTextsButtonsButtons {
		margin-top: 45px;
	}
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 992px) {
	.allLicenseTextsButtonsButtons {
		margin-top: 65px;
	}
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
	.allLicenseTextsButtonsButtons {
		margin-top: 90px;
	}
}
.allLicenseTextsButtonsText{
	margin-top: 17px;
}
.buttonLicenseTextActivator {
	display:block;
	border: none;
	cursor: pointer;
	color: blue;
	background-color: transparent;
	text-decoration: underline;
	height: 17px;
	padding: 0;
}
.showCookiesDetailsCloseButton{
	position: absolute;
	margin-left: 97.5%;
	margin-top: -43.3%;
	text-align: center;
	width: 30px;
	height: 23px;
	position: absolute;
	/*margin-left: 98.2%;*/
	/*margin-top: 0.8%;*/
}
.CookiesMenuChangePreferencesMyArea{
	position: absolute;
	width: 100%;
	height: 100%;
}
.buttonAcceptCookiesDivMyArea{

}
.cookiesMenuChangePreferencesMenuLandingPage {
	position: absolute;
	margin-top: 2%;
}
.cookiesMenuChangePreferencesMenuAreaLandingPage {
	/*position: absolute;*/
	margin-top: 2%;
	width: 90%;
}
.cookiesMenuChangePreferencesMenuSpanMyArea {
	/*margin-left: 2%;*/
	/*width: 500px;*/
	/*text-align: justify;*/

	margin-left: 3%;
	width: 950px;
	text-align: justify;
}

.toggleCookiesTechnicalMyArea {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	border: 0;
	outline: 0;
	cursor: pointer;
	/*left: 700px;*/
	/*text-align: right;*/
	float: right;
	margin-left: 1005px;
	margin-top: -1.6%;
	/*margin: 10px;*/
}

/* To create surface of toggle button */
.toggleCookiesTechnicalMyArea:before {
	content: '';
	width: 60px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;

	/*	This is for a smooth transition*/
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
}

/* Contents before checkbox to create toggle handle */
.toggleCookiesTechnicalMyArea:after {
	content: '';
	width: 18px;
	height: 18px;
	display: block;
	position: absolute;
	left: 0px;
	top: -1px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.toggleCookiesTechnicalMyArea:checked:after {
	left: 43px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
/* Background color when toggle button will be active */
.toggleCookiesTechnicalMyArea:checked:before {
	background-color: rgba(155, 155, 155, 1);
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
	/*background: red;*/
}

.toggleCookiesMyArea {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	border: 0;
	outline: 0;
	cursor: pointer;
	/*left: 700px;*/
	/*text-align: right;*/
	float: right;
	margin-left: 1005px;
	margin-top: -5.6%;
	margin-top: -1.6%;
	/*margin: 10px;*/
}

/* To create surface of toggle button */
.toggleCookiesMyArea:before {
	content: '';
	width: 60px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;

	/*	This is for a smooth transition*/
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
}

/* Contents before checkbox to create toggle handle */
.toggleCookiesMyArea:after {
	content: '';
	width: 18px;
	height: 18px;
	display: block;
	position: absolute;
	left: 0px;
	top: -1px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.toggleCookiesMyArea:checked:after {
	left: 43px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
/* Background color when toggle button will be active */
.toggleCookiesMyArea:checked:before {
	background: rgba(10, 130, 10, 0.85);;
	/*background: red;*/
}

.cookiesMenuChangePreferencesMenuItemMyArea {
	list-style-type: ' ';
	margin-left: 0.0%;
	margin-top: 0%;
}
.cookiesMenuChangePreferencesTitleMyArea {
	position: absolute;
}

.buttonAcceptCookiesMyArea {
	position: absolute;
	margin-top: 350px;
	margin-left: 82.2%;
}
.dropDownInMyAreaFilterTable {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 125px;
	/*top: -15px;*/
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInMyProfileNewSale {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 172px;
	/*top: -15px;*/
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.BackgroundMyArea{
	background-color: white;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1000;
}

.buttonCookiesDetailMyArea {
	position: absolute;
	margin-top: 350px;
	margin-left: 0.3%;
	width: 15%;
	height: 30px;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.buttonCookiesDetailMyArea:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.ContainerCookiesPolicyButtonRejectAllCMPMyArea {
	position: absolute;
	margin-top: 350px;
	margin-left: 62.5%;
	width: 15%;
	height: 30px;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.ContainerCookiesPolicyButtonRejectAllCMPMyArea:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.buttonAcceptCookiesMyArea {
	position: absolute;
	margin-top: 350px;
	margin-left: 77.4%;
	width: 15%;
	height: 30px;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.buttonAcceptCookiesMyArea:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.CountAndEmails {
	/*position: relative;*/
	margin-top: 47%;
	margin-left: 70%;
	/*border: 3px solid red;*/
	height: 15%;
}
.disclaimerNewCompaniesInPSIContainer {
	margin-bottom: 2%;
}

.countOfNewCompanies {
	float: right;
}

/*.buttonConfirmCompaniesDiv {*/
/*	*/
/*}*/

.buttonConfirmCompanies {
	/*position: absolute;*/
	margin-right: 0;
	float: right;
	width: 80%;
	border-radius: 5%;
	font-size: 13.5pt;
	height: 38.5px;
	/*height: 100px;*/
}

.buttonConfirmAndEmailsPSI{
	position: relative;
	top: 5%;
	width: 100%;
	margin-right: 0;
}

.chooseUpdateClientEmail {
	position: absolute;
	width: 80%;
	height: 13.3%;
	/*border: 1px solid red;*/
	/*left: 7%;*/
	/*top: 5%;*/
	margin-left: 10%;
	margin-right: 10%;
	top: 395px;
	font-size: 13.5pt;

}
.chooseUpdateClientEmailButtonYes {
	font-size: 14pt;
	width: 50%;
	/*border: 1.75px solid black;*/
}
.chooseUpdateClientEmailButtonNo {
	font-size: 14pt;
	width: 50%;
	/*border: 1.75px solid black;*/
}
.chooseUpdateClientEmailButtonUnchosen {
	font-size: 14pt;
	width: 50%;
	/*height: 110%;*/
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
	/*border: 1.75px solid black;*/
}
.chooseUpdateClientEmailButtonChosen {
	font-size: 14pt;
	width: 50%;
	/*height: 160%;*/
	border: 1.95px solid black;
	border-radius: 3px;
	/*border: none;*/
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.errorMessageChosenButton {
	color: red;
	/*width: 80%;*/
	/*left: 6.5%;*/
	/*margin: 10%;*/
	/*text-align: justify;*/
	/*position: absolute;*/
	font-size: 10pt;
}

.body {
	background-color: rgba(46,139,87, 0.1);
	width: 100%;
	height: 100%;
	/*font-family: Calibri, sans-serif;*/
}
.containerOfChange {
    top: 117px;
    left: 100px;
    position: absolute;
    border-color: coral;
    border: 3px solid;
    height: 870px;
    width: 1710px;
    background: rgba(255, 255, 255, 1);
    z-index: 1000;
}

.CloseButtonChanges {
    left: 95.4%;
    left: 97.65%;
    top: -0.1%;
    /*left: 1210px;*/
    position: relative;
    width: 40px;
    height: 30px;
}

.NewChangeMessage {
    color: rgba(10, 180, 10, 1.0);
    font-size: 20pt;
    left:2%;
    position: relative;
}


.NewChangeEmployee{
    height: 24px;
    top: 3.5%;
    left:2%;
    position: relative;
    /*border: 1px solid red;*/
    /*height: 24px;*/
}

.NewChangeEmployeeEmployee{
    /*border-color: coral;*/
    /*border: solid 1px;*/
    margin-top: 2px;
    width: 76px;
    top: 5%;
    height: 34px;
    position: absolute;
    /*width: 80px;*/
}

.NewChangeEmployeeNo {
    /*width: 68%;*/
    /*margin-left: 30%;*/
    /*height: 100%;*/
    /*left: 6.5%;*/
    left: 30%;
    top: 15%;
    /*top: 5%;*/
    /*border-color: coral;*/
    /*border: solid 1px;*/

    position: absolute;
    /*width: 30px;*/
    /*border: 1px solid red;*/
    width: 250%;
    /*width: 89px;*/
}
.NewChangeEmployeeButton {
    left: 27.25%;
    position: relative;
    width: 70.6%;
    height: 100%;
}
.NewChangeEmployeeName {
    left: 29.85%;
    position: absolute;
    /*border: 1px solid red;*/
    width: 250%;
    top: 10%;
}
.NewChangeShop{
    height: 24px;
    top: 3.5%;
    left:2%;
    position: relative;
}

.NewChangeShopShop{
    /*border-color: coral;*/
    /*border: solid 1px;*/
    position: absolute;
    top: 10%;
    width: 70px;
}

.NewChangeShopDropdown{
    left: 6.5%;
    border-color: coral;
    border: solid 1px;
    position: relative;
    width: 30px;
}

.containerDropDownShopsChanges {
    border-color: coral;
    border: solid 1px;
    margin-left: 85px;
    width: 216px;
    max-height: 150px;
    position: absolute;
    z-index: 50;
    background-color: white;
    overflow: auto;
    font-size: 12pt;
    padding-left: 3px;
    padding-right: 3px;
    white-space: nowrap;
}

.containerDropDownShopsChanges a{
    text-decoration: none;
    color: black;
}

.containerDropDownShopsChangesAttribute {
    /*text-decoration: none;*/
}


.horizontalSeparatorFirstBoxesChanges {
    border-bottom: 1px solid #ddd;
    margin-top: 0px;
    left: -2%;
    width: 100%;
    position: absolute;
}

.WrapperAllTables {
    width: 1180px;
    height: 600px;
    top: 20%;
    /*border-style: dashed;*/
    /*border: 5px;*/
    /*border-color: black;*/
    position: absolute;

}

.ChooseChangesTable {
    position: absolute;
    text-align: center;
    border-collapse: collapse;
    width: 26.7%;
    display: block;
    /*height: 270px;*/
    left: 2.8%;
    height: 225px;
    max-height: 250px;
    table-layout: fixed;
    border-color: coral;
    /*border: 3px;*/
    /*border: 1px solid #ddd;*/
    overflow-y: auto;
    overflow-x: hidden;
}

.ChooseChangesTableHeader {
    font-size: 12.0pt;
}

.ChooseChangesTableBody{
    width: 100%;
    /*height: 243px;*/
    height: 100%;
    max-height: 243px;
    /*position: absolute;*/
    /*border: 1px solid #ddd;*/
}

.ChooseChangesTable td{
    border: 0.2px solid #ddd;
    /*width: 80px;*/
    font-size: 100%;
    /*width: 100px;*/
    /*text-overflow: ellipsis;*/
    text-overflow: ellipsis; overflow: hidden;
    white-space: nowrap;
    /*border: 1px solid;*/
    /*word-wrap: break-word;*/
    /*max-width: 50px;*/
}

.ChooseChangesTable th {
    font-weight: bold;
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    /*background-color: rgba(10, 180, 10, 1.0);*/
    background-color: rgba(10, 180, 10, 1.0);
    color: white;
    position: -webkit-sticky;
    position: sticky;
    height: 35px;

    /*word-wrap: break-word;*/
    max-width: 300px;
}

.ChooseChangesTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;
}

.inputChooseChangesTable {
    width: 100%;
    /*height: 110%;*/
    height: 20px;
    font-size: 100%;
    /*max-width: 80px;*/
}

.ChooseChangesTable tr:nth-child(even){background-color: #f2f2f2;}

.ChooseChangesTable th:nth-child(1) {width:  37px; text-align: center; padding-right: 3px;}
.ChooseChangesTable th:nth-child(2) {width: 47px; text-align: center; padding-right: 3px;}
.ChooseChangesTable th:nth-child(3) {width: 217px; min-width: 205px; text-align: center;}

.ChooseChangesTable td:nth-child(1) {width:  37px; text-align: right; padding-right: 3px;}
.ChooseChangesTable td:nth-child(2) {width: 47px; max-width: 47px; text-align: right; padding-right: 3px;}
.ChooseChangesTable td:nth-child(3) {width: 217px; max-width: 205px; text-align: left;}

.ChooseChangesTable tr:hover {background-color: #ddd;}


.inputChooseTablefunctionUnits {font-size: 12pt; width: 43px; height: 21px; text-align: right;}
.inputChooseTablefunctionReason {font-size: 12pt; width: 209px; height: 21px; text-align: left;}
.inputChooseTablefunctionUnitsRed {font-size: 12pt; width: 43px; height: 21px; text-align: right; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}
.inputChooseTablefunctionReasonRed {font-size: 12pt; width: 209px; height: 21px; text-align: left; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}












.ChangeTableTBody {
    width: 800px;
    /*left: 35%;*/
    left: 380px;
    /*border: 3px solid;*/
    /*border-color: coral;*/
    overflow-y: scroll;
    position: absolute;
    height: 243px;
}

.ChangeTable {
    position: absolute;
    text-align: center;
    font-size: small;
    border-collapse: collapse;
    border: 1px solid #ddd;
    width: 77.3%;

    display: block;
    /*height: 270px;*/
    left: 375px;
    height: 225px;
    max-height: 250px;
    table-layout: fixed;
    border-color: coral;
    border: 3px;
    overflow: auto;
}


.ChangeTableBody{
    width: 100%;
    font-size: 12.0pt;
    /*height: 243px;*/
}

.ChangeTable td{
    border: 0.2px solid #ddd;
    /*width: 80px;*/
    font-size: 100%;
    width: 100px;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;

    max-width: 50px;
}

.ChangeTable th {
    font-weight: bold;
    /*font-weight: normal;*/
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: rgba(10, 180, 10, 1.0);
    /*background-color: rgba(210, 210, 210, 1.0);*/
    color: white;
    /*color: black;*/
    position: -webkit-sticky;
    position: sticky;
    height: 35px;

    max-width: 300px;
}

.ChangeTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
}

.inputChangeTable {
    width: 100%;
    /*height: 110%;*/
    height: 20px;
    font-size: 100%;
    /*max-width: 80px;*/
}

.ChangeTable tr:nth-child(even){background-color: #f2f2f2;}

/*.ChangeTable td:nth-child(1) {width: 30px; text-align: center}*/
/*.ChangeTable td:nth-child(2) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(3) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(4) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(5) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(6) {width: 50px; text-align: right}*/
/*.ChangeTable td:nth-child(7) {width: 40px; text-align: right}*/
/*.ChangeTable td:nth-child(8) {width: 60px; text-align: right}*/
/*.ChangeTable td:nth-child(9) {width: 50px; text-align: right}*/
/*.ChangeTable td:nth-child(10){width: 20px; text-align: right}*/
/*.ChangeTable td:nth-child(11){width: 50px; text-align: left}*/
/*.ChangeTable td:nth-child(12){width: 50px; text-align: right}*/

.ChangeTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.ChangeTable th:nth-child(2) {width: 127px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(3) {width: 97px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(4) {width: 97px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(5) {width: 97px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(6) {width: 99px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(7) {width: 147px; text-align: center; padding-right: 3px;}
.ChangeTable th:nth-child(8) {width: 147px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.ChangeTable th:nth-child(9) {width:  44px; max-width: 44px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.ChangeTable th:nth-child(10){width:  47px; text-align: center; padding-right: 3px;}
.ChangeTable th:nth-child(11){width:  87px; text-align: center; padding-left: 3px;}
.ChangeTable th:nth-child(12){width:  67px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}

.ChangeTable td:nth-child(1) {width:  57px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(2) {width: 127px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(3) {width: 97px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(4) {width: 97px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(5) {width: 97px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(6) {width: 99px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(7) {width: 147px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(8) {width: 147px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(9) {width:  44px; max-width: 44px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(10){width:  47px; text-align: right; padding-right: 3px;}
.ChangeTable td:nth-child(11){width:  87px; text-align: left; padding-left: 3px;}
.ChangeTable td:nth-child(12){width:  67px; text-align: right; padding-right: 3px;}

.ChangeTable tr:hover {background-color: #ddd;}


.NewChangeDate{
    position: absolute;
    border: 1px;
    /*top: 45.6%;*/
    /*left: 87%;*/
    top: -3.6%;
    /*left: 87.4%;*/
    left: 1130px;
    width: 155px;
    text-align: right;
    border: 1px solid #ddd;
}

.NewChangeDatePrevious {
    position: absolute;
    border: 1px;
    /*top: 45.6%;*/
    /*left: 87%;*/
    top: -3.6%;
    /*left: 87.4%;*/
    left: 1135px;
    width: 150px;
    text-align: right;
}

.NewChangeDateDate {
    padding-right: 2px;
}







.NewSaleTable {
    /*top: 49%;*/
    top: 44%;
    position: absolute;
    text-align: center;
    font-size: 12pt;
    border-collapse: collapse;
    /*border: 1px solid #ddd;*/
    width: 77.3%;
    display: block;
    /*height: 270px;*/
    height: 225px;
    max-height: 250px;
    table-layout: fixed;
    border-color: coral;
    border: 3px;
    /*border: 1px solid red;*/
    overflow: auto;
    left: 375px;
}

.NewSaleTableHeader {
    font-size: 12.0pt;
}

.NewSaleTableBody{
    width: 100%;
    /*height: 243px;*/

}

.NewSaleTable td{
    border: 0.2px solid #ddd;
    font-size: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 50px;
}

.NewSaleTable th {
    font-weight: bold;
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: rgba(10, 180, 10, 1);
    color: white;
    position: -webkit-sticky;
    position: sticky;
    height: 35px;

    /*max-width: 300px;*/
}

.NewSaleTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;

    /*height: 35px;*/
}


.NewSaleTable tr:nth-child(even){background-color: #f2f2f2;}


.NewSaleTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.NewSaleTable th:nth-child(2) {width: 127px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(3) {width: 97px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(4) {width: 97px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(5) {width: 97px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(6) {width: 99px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(7) {width: 147px; text-align: center; padding-right: 3px;}
.NewSaleTable th:nth-child(8) {width: 147px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.NewSaleTable th:nth-child(9) {width:  44px; max-width: 44px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.NewSaleTable th:nth-child(10){width:  47px; text-align: center; padding-right: 3px;}
.NewSaleTable th:nth-child(11){width:  87px; text-align: center; padding-left: 3px;}
.NewSaleTable th:nth-child(12){width:  67px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}

.NewSaleTable td:nth-child(1) {width:  57px;  height: 27px; max-width: 57px; min-width: 20px; text-align: right; padding-right: 3px;}
.NewSaleTable td:nth-child(2) {width: 120px;  height: 27px;text-align: left; padding-right: 8px;}
.NewSaleTable td:nth-child(3) {width: 90px;  height: 27px; text-align: left; padding-right: 8px; text-overflow: ellipsis; overflow: hidden; }
.NewSaleTable td:nth-child(4) {width: 90px;  height: 27px; text-align: left; padding-right: 8px;}
.NewSaleTable td:nth-child(5) {width: 90px;  height: 27px; text-align: left; padding-right: 8px;}
.NewSaleTable td:nth-child(6) {width: 92px; height: 27px; text-align: left;  padding-right: 8px;}
.NewSaleTable td:nth-child(7) {width: 140px; height: 27px; text-align: right; padding-right: 8px;}
.NewSaleTable td:nth-child(8) {width: 140px; height: 27px; text-align: right; padding-right: 8px;}
.NewSaleTable td:nth-child(9) {width:  37px; height: 27px; max-width: 44px; text-align: right; padding-right: 8px;}
.NewSaleTable td:nth-child(10){width:  40px; height: 27px; text-align: right; padding-right: 8px;}
.NewSaleTable td:nth-child(11){width:  80px; height: 27px; text-align: left; padding-right: 8px;}
.NewSaleTable td:nth-child(12){width:  60px; height: 27px; text-align: right; padding-right: 8px;}

.inputSaleTableProduct {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableBrand {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableType {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableSubtype {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableReference {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTablePrice {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTablePricewoVAT {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableVAT {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableUnits {width: 100%; height: 21px; font-size: 12.0pt;  }
.inputSaleTablePromotion {width: 100%; height: 21px; font-size: 12.0pt; }
.inputSaleTableDiscount {width: 100%; height: 21px; font-size: 12.0pt; }

.NewSaleTable tr:hover {background-color: #ddd;}







.NewChangeShippingPrevious {
    /*top: 555.5px;*/
    top: 527px;
    top: 501px;
    /*left: 1122px;*/
    left: 1116px;
    /*width: 150px;*/
    position: absolute;
    width: 148px;
    width: 168px;
    height: 21px;
}

.NewSaleShippingInChange {
    /*top: 555.5px;*/
    /*top: 527px;*/
    top: 501px;
    /*left: 1122px;*/
    left: 1116px;
    /*width: 150px;*/
    position: absolute;
    /*width: 148px;*/
    width: 168px;
    height: 21px;
    border: 1px solid #ddd;
}

.NewSaleShippingInChangeName {
    width: 120px;
    font-weight: normal;
    top: 10%;
    position: absolute;
    /*border: 1px solid red;*/
}

.NewSaleShippingInChangeValue {
    /*left: 70px;*/
    left: 120px;
    width: 39px;
    position: absolute;
    text-align: right;
    font-size: 11pt;
    /*border: 1px solid #ddd;*/
}





















.NewChangeTicket {
    left: 1330px;
    top: 20%;
    width: 330px;
    height: 450px;
    /*border: 2px solid #ddd;*/
    position: absolute;
}


.NewChangeTicketSummaryHeader{
    /*position: relative;*/
    top: -10%;
    width: 100%;
    text-align: center;
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    /*font-size: 11.0pt;*/
    border-collapse: collapse;
    border: 1px solid #ddd;
    border-bottom: none;
    font-size: 12pt;
}

.NewChangeTicketSummaryHeader tr{
    height: 37.2px;
}

.NewChangeTicketSummaryHeader th{
    /*position: absolute;*/
    text-align: center;
    /*height: 20px;*/
    color: white;
    border: none;
    background-color: #4CAF50;
    background-color: rgba(10, 180, 10, 1);
    font-weight: bold;
}

.NewChangeTicketSummaryHeader th:nth-child(1) {
    text-align: left;
    padding-left: 10px;
}
.NewChangeTicketSummaryHeader th:nth-child(2) {
    text-align: right;
    padding-right: 13px;
}


.dashedLineOnTicket1 {
    border-bottom: 1pt dashed #ddd;
    width: 91.5%;
    left: 2.5%;
    height: 0px;
    top: 177.5px;
    position: absolute;
}

.dashedLineOnTicket2 {
    border-bottom: 1pt dashed #ddd;
    width: 91.5%;
    left: 2.5%;
    top: 325px;
    position: absolute;
}


.NewChooseChangesSummaryTable {
    position: absolute;
    border: 1px solid red;
    width: 100%;
    height: 30%;
}
.ChangeSummaryAbove {
    /*border-bottom: 1px dashed #ddd;*/
    position: absolute;
    margin-top: 2px;
    /*top: 50px;*/
    text-align: center;
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    font-size: 11.0pt;
    /*font-size: 12.0pt;*/
    border-collapse: collapse;
    /*border: 1px solid red;*/
    border-top: none;
    display: block;
    width: 100%;
    height: 150px;
    height: 187px;
    /*POLLA*/
    /*max-height: 150px;*/
    overflow-y: auto;

}

.ChangeSummaryAboveHead {
    /*border-bottom: 1px dashed #ddd;*/
}

.horizontalDivisionInTable {
    border-bottom: 1px dashed #ddd;
    width: 92.5%;
    left: 2.5%;
    position: absolute;
    z-index: 500;
}

.horizontalDivisionInTableForTotals {
    border-bottom: 1.5px dashed #ddd;
    /*width: 92.5%;*/
    width: 100%;
    /*left: 2.5%;*/
    top: 76.7%;
    top: 78.0%;
    position: absolute;
    z-index: 500;
}

.ChangeSummaryAbove td:nth-child(1) {width: 140px; max-width: 130px; text-overflow: ellipsis; overflow: hidden; padding-left: 10px;}
.ChangeSummaryAbove td:nth-child(2) {width: 50px; max-width: 50px; text-align: right;}
.ChangeSummaryAbove td:nth-child(3) {width: 50px; max-width: 50px; text-align: right; }
.ChangeSummaryAbove td:nth-child(4) {width: 80px; max-width: 70px; text-align: right; }

.ChangeSummaryAbove th:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px;}
.ChangeSummaryAbove th:nth-child(2) {width: 50px; max-width: 50px; text-align: center;}
.ChangeSummaryAbove th:nth-child(3) {width: 50px; max-width: 50px; text-align: center;}
.ChangeSummaryAbove th:nth-child(4) {width: 70px; max-width: 70px; text-align: right; }


.ChangeSummaryAbove th {
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
}

.ChangeSummaryAbove th:nth-child(1) {
    color: red;
}

.ChangeSummaryAbove th td {
    border-bottom: 0.5px solid;
}

.ChangeSummaryAbove td {
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ChangeSummaryAboveTBody {
    width: 100%;
    position: absolute;
    /*border: 1px solid #ddd;*/
    /*border: 2px solid #ddd;*/
    /*border-bottom: none;*/
    /*border: 1px;*/
}



.ChangeSummaryBelowTBody {
    width: 100%;
    /*height: 125px;*/
    top: 155px;
    /*border: 1px;*/
}

.ChangeSummaryBelow {
    text-align: center;
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    font-size: 11.0pt;
    display: block;
    border-collapse: collapse;
    /*border-bottom: dashed 1px  #ddd;*/
    /*border: 1px solid red;*/
    /*border-top: none;*/
    width: 100%;
    height: 150px;
    height: 185px;
    position: absolute;
    /*top: 187.5px;*/
    top: 209.5px;
    top: 245px;
    overflow-y: auto;
    /*POLLA*/
}

.ChangeSummaryBelow td:nth-child(1) {width: 140px; max-width: 130px; text-overflow: ellipsis; overflow: hidden; padding-left: 10px;}
.ChangeSummaryBelow td:nth-child(2) {width: 50px; max-width: 50px; text-align: right;}
.ChangeSummaryBelow td:nth-child(3) {width: 50px; max-width: 50px; text-align: right;}
.ChangeSummaryBelow td:nth-child(4) {width: 80px; max-width: 70px; text-align: right; }

.ChangeSummaryBelow th:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px;}
.ChangeSummaryBelow th:nth-child(2) {width: 50px; max-width: 50px; text-align: center;}
.ChangeSummaryBelow th:nth-child(3) {width: 50px; max-width: 50px; text-align: center;}
.ChangeSummaryBelow th:nth-child(4) {width: 70px; max-width: 70px; text-align: right; }


.ChangeSummaryBelow th {
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
}

.ChangeSummaryBelow th:nth-child(1) {
    color: rgba(10, 130, 10, 1.0);
}

.ChangeSummaryBelow th td {
    border-bottom: 0.5px solid;
}

.ChangeSummaryBelow td {
    /*border: 0.5px solid #ddd;*/
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}





.NewChangeSummaryTotals{
    width: 100%;
    /*top: 340px;*/
    top: 384px;
    top: 460px;
    height: 120px;
    position: absolute;
    font-size: 11.0pt;
}

.NewChangeSummaryTotalsTable{
    width: 100%;
    position: absolute;
    font-size: 11.0pt;
    top: -4%;
}

.NewChangeSummaryTotalsTable td:nth-child(1){
    padding-left: 3px;
    /*border: 1px solid #ddd;*/
}
.NewChangeSummaryTotalsTable td:nth-child(2){
    /*border: 1px solid #ddd;*/
    width: 30px;
    padding-right: 8px;
    text-align: right;
}

.NewChangeSummaryTotalsTableBody {

}

.NewChangeSummaryTotalsTable tr:nth-child(2){
    width: 40px;
    padding-bottom: 29.5px;
}

.NewChangeSummaryTotalsTable tr:nth-child(3){
    height: 7px;
    padding-bottom: 29.5px;
}
.NewChangeSummaryTotalsTable tr:nth-child(4) {
   font-weight: bold;
}







.NewChangeTicketClient{
    /*top: 527.0px;*/
    top: 500.0px;
    width: 100%;
    height: 80px;
    position: absolute;
    border: 2px solid #ddd;
}

.NewChangeTicketClientHeader {
    /*background-color: #4CAF50;*/
    background-color: rgba(10, 180, 10, 1.0);
    color: white;
    width: 100%;
    height: 35px;
}

.NewChangeTicketSummary{
    position: absolute;
    width: 100%;
    height: 485px;
    height: 570px;
    border: 2px solid #ddd;
}

.NewChangeClientDataHeader{
    left: 38%;
    position: absolute;
    text-align: center;
    top: 9px;
    /*font-size: 11pt;*/
    font-size: 12pt;
    font-weight: bold;
}

.NewChangeClientDataEmail{
    top: 60%;
    width: 40%;
    height: 80%;
    position: absolute;
    text-overflow: ellipsis;
    /*border: 2px solid #ddd;*/
}

.NewChangeClientDataMessageEmail{
    position: absolute;
    height: 25px;
    margin-top: 1.5px;
    font-size: 11pt;
    left: 8%;
    /*border: 1px solid;*/
}

.NewChangeClientDataFormEmail{
    position: absolute;
    left: 100%;
    font-size: 11pt;
}

.NewChangeClientDataFormEmailPrevious {
    position: absolute;
    left: 100%;
    width: 190px;
    font-size: 11pt;
    text-overflow: ellipsis;
    overflow: hidden;
    /*border: 1px solid red;*/
}

/*.NewChangeClientDataFormEmail {*/
/*    font-size: 11pt;*/
/*}*/

.NewChangeUpperDataContainerNoBorder {
    top: 3.5%;
    left:2%;
    width: 18.2%;
    width: 312px;
    position: relative;
    /*border: 1px solid #ddd;*/
}

.NewChangeUpperDataContainer {
    top: 3.5%;
    left:2%;
    width: 18.2%;
    width: 312px;
    position: relative;
    border: 1px solid #ddd;
}

.verticalSeparatorFirstBoxesChangeScreen {
    border-right: 1px solid #ddd;
    left: -2%;
    width: 90px;
    height: 22px;
    /*top: -18%;*/
    position: absolute;
}

.IdChange{
    top: 3.5%;
    left:2%;
    position: relative;
}

.IdChangeID1 {
    /*border-color: coral;*/
    /*border: solid 1px;*/
    position: absolute;
    margin-top: 1px;
    width: 76px;
    /*border-right: 1pt solid #ddd;*/
}
.IdChangeID2 {
    /*left: 6.5%;*/
    left: 27.25%;
    margin-top: 3.5px;
    /*border-color: coral;*/
    /*border: solid 1px;*/
    position: relative;
    /*width: 30px;*/
    width: 80%;
    /*width: 89px;*/
}

.IdChangeID2Previous {
    left: 3%;
    margin-top: 3.5px;
    /*border-color: coral;*/
    /*border: solid 1px;*/
    position: relative;
    /*width: 30px;*/
    width: 80px;
    /*width: 89px;*/
    text-align: left;
}

.IdChangeID3 {
    width: 18%;
    left: 20%;
    text-align: center;
    font-size: 11pt;
    /*border-color: coral;*/
    /*border: solid 1px;*/
}
.IdChangeID3Red {
    width: 18%;
    left: 20%;
    text-align: center;
    font-size: 11pt;
    /*border-color: coral;*/
    /*border: solid 1px;*/
    background-color: rgba(255, 0, 0, 0.10);
    border: solid rgba(255, 0, 0, 0.30) 2px;
}

.ID_Change_button {
    width: 156px;
    left: 64.5px;
    position: absolute;
    font-size: 11pt;
}

.ChangesQRCodeWindow {
    position:absolute !important;
    margin-top: 250px;
    margin-top: 5px;
    margin-left: 20px;
    margin-left: 690px;
    z-index: 2000;
    /*height: 35%;*/
    height: 475px;
    /*width: 25%;*/
    width: 350px;
    border: solid 0.1em;
    background-color: rgb(255,255,255);
}


.QRImageChange {
    width: 220px;
    left: 65px;
    /*text-align: center;*/
    top: 21.5%;
    top: 17.0%;
    position: absolute;
}

.ObscurerQRReceivedChange {
    position: absolute !important;
    height: 100%;
    width: 100%;
    /*border: solid 0.3em;*/
    background-color: rgba(105, 105, 105, 0.2);
    /*margin-top: -0.40%;*/
    /*margin-left: -0.40%;*/
    z-index: 1000;
}





.buttonScanAndPayChange{
    top: 605px;
    width: 100%;
    height: 38.5px;
    position: absolute;
}

.buttonScanAndPayButtonChange {
    position: absolute;
    width: 55%;
    left: 22.5%;
    height: 100%;
    border-radius: 5%;
    font-size: 13.5pt;
    /*background-color: ;*/
}
.messageWrongID {
    position: absolute;
    margin-left: 2%;
    margin-top: -48.0%;
    /*background-color: green;*/
    width: 371.5%;
    height: 20px;
    text-align: justify;
    color: red;
    font-size: 10pt;
    /*z-index: 7392486785;*/
}


.ErrorMessagesInChangeWindowContainerHuge {
    position: absolute;
    margin-left: 22%;
    margin-top: -2.5%;
    /*width: 50.5%;*/
    /*min-width: 800px;*/
    /*border: 1px solid red;*/
}
.ErrorMessagesInChangeWindowTitle{
    color: red;
    font-size: 10pt;
}
.ErrorMessagesInChangeWindow {
    /*position: absolute;*/
    /*margin-left: 40%;*/
    /*margin-top: -5.0%;*/
    /*background-color: green;*/
    /*width: 71.5%;*/
    display: flex;
    position: absolute;
    margin-top: -36px;
    margin-left: 358px;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: justify;
    color: red;
    max-height: 60px;
    font-size: 10pt;
    /*border: 1px solid red;*/
    /*z-index: 7392486785;*/
}
.ErrorMessagesInChangeWindowHuge {
    /*position: absolute;*/
    /*margin-left: 40%;*/
    /*margin-top: -5.0%;*/
    /*background-color: green;*/
    /*width: 71.5%;*/
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: justify;
    color: red;
    max-height: 60px;
    font-size: 10pt;
    /*border: 1px solid red;*/
    z-index: 1500;

}
.ErrorMessagesInChangeWindow>* {
    /*flex: 1 1 80px;*/
}
.ErrorMessagesInChangeWindowText {
    margin: 0;
    width: 476px;
}
.errorMessageOnButton{
    position: absolute;
    margin-left: 6%;
    margin-left: 0%;
    margin-top: -6.0%;
    /*margin-top: -7.0%;*/
    /*background-color: green;*/
    width: 99.5%;
    width: 100%;
    height: 130px;
    text-align: justify;
    color: red;
    font-size: 10pt;
}
.missingCrucialInformationDummyQR {
    position: absolute;
    margin-left: 10%;
    margin-top: 85.0%;
    /*margin-top: -7.0%;*/
    /*background-color: green;*/
    width: 80.5%;
    /*width: 100%;*/
    /*height: 130px;*/
    text-align: justify;
    color: red;
    font-size: 10pt;
}
.ErrorMessageInIncorrectID {
    position: absolute;
    margin-left: 20.9%;
    /*margin-left: 0%;*/
    margin-top: -2.1%;
    /*margin-top: -7.0%;*/
    /*background-color: green;*/
    /*width: 99.5%;*/
    /*width: 100%;*/
    /*height: 130px;*/
    text-align: right;
    color: red;
    font-size: 10pt;
}
.IDInformationExplanation {
    font-size: 10pt;
    margin-left: 2.0%;
    margin-top: -3.4%;
    margin-top: -3.8%;
}
.dropDownInMyProfileNewChange {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent black transparent;
    position: relative;
    margin-left: 195px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.buttonScanAndPayButtonChange:hover {
    background-color: RGBA(150, 150, 150, 0.5);
}
.containerOfReturn {
    top: 190px;
    left: 205px;
    position: absolute;
    border-color: coral;
    border: solid 0.3em;
    height: 870px;
    width: 1670px;
    background: rgba(255, 255, 255, 1);
    z-index: 1000;
}

.CloseButtonReturn {
    /*left: 95.4%;*/
    left: 97.65%;
    top: -0.1%;
    /*left: 1210px;*/
    position: relative;
    width: 40px;
    height: 30px;
}

.NewReturnMessage {
    color: rgba(10, 180, 10, 1.0);
    font-size: 20pt;
    left:2%;
    position: relative;
}

.WrapperAllTables {
    width: 1180px;
    height: 600px;
    top: 20%;
    /*border-style: dashed;*/
    /*border: 5px;*/
    /*border-color: black;*/
    position: absolute;

}

.ChooseChangesTableTBody {
    width: 300px;
    /*height: 300px;*/
    height: 243px;
    position: absolute;
    border: 1px;
    /*border: 3px solid;*/
    /*border-color: coral;*/
    overflow-y: scroll;
}




.ChooseReturnsTable {
    position: absolute;
    text-align: center;
    border-collapse: collapse;
    width: 26.7%;
    display: block;
    left: 2.8%;
    table-layout: fixed;
    /*border: 3px;*/
    /*border: 1px solid #ddd;*/
    overflow-y: auto;
    overflow-x: hidden;
    /*height: 270px;*/
    /*left: 375px;*/
    height: 488px;
    max-height: 488px;
    border: 3px;
    /*border: 1px solid red;*/
}

.ChooseReturnsTable td{
    border: 0.2px solid #ddd;
    /*width: 80px;*/
    font-size: 100%;
    /*width: 100px;*/
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    text-overflow: ellipsis; overflow: hidden;
    white-space: nowrap;
    /*border: 1px solid;*/
    /*word-wrap: break-word;*/
    /*max-width: 50px;*/
}

.ChooseReturnsTable th {
    font-weight: bold;
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;;
    padding-bottom: 2px;;
    text-align: center;
    background-color: rgba(10, 180, 10, 1.0);
    color: white;
    position: -webkit-sticky;
    position: sticky;
    height: 35px;

    /*word-wrap: break-word;*/
    max-width: 300px;
}

.ChooseReturnsTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;
}

.inputChooseChangesTable {
    width: 100%;
    /*height: 110%;*/
    height: 20px;
    font-size: 100%;
    /*max-width: 80px;*/
}

.ChooseReturnsTable tr:nth-child(even){background-color: #f2f2f2;}

.ChooseReturnsTable th:nth-child(1) {width:  37px; text-align: center; padding-right: 3px;}
.ChooseReturnsTable th:nth-child(2) {width: 47px; text-align: center; padding-right: 3px;}
.ChooseReturnsTable th:nth-child(3) {width: 217px; text-align: center;}

.ChooseReturnsTable td:nth-child(1) {width:  37px; text-align: right; padding-right: 3px;}
.ChooseReturnsTable td:nth-child(2) {width: 47px; max-width: 47px; text-align: right; padding-right: 3px;}
.ChooseReturnsTable td:nth-child(3) {width: 217px; max-width: 205px; text-align: left;}

.ChooseReturnsTable tr:hover {background-color: #ddd;}


.ReturnTable {
    position: absolute;
    text-align: center;
    font-size: small;
    border-collapse: collapse;
    width: 77.3%;

    display: block;
    /*height: 270px;*/
    left: 375px;
    height: 488px;
    max-height: 488px;
    table-layout: fixed;
    border-color: coral;
    border: 3px;
    /*border: 1px solid red;*/
    overflow: auto;
}


.ChangeTableHeader {
    font-size: 12.0pt;
}


.ChangeTableBody{
    width: 100%;
    font-size: 12.0pt;
    max-height: 243px;
}

.ReturnTable td{
    border: 0.2px solid #ddd;
    /*width: 80px;*/
    font-size: 100%;
    width: 100px;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;

    max-width: 50px;
}

.ReturnTable th {
    font-weight: bold;
    /*font-weight: normal;*/
    border: 0.5px solid #ddd;
    /*padding: 8px;*/
    line-height: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: rgba(10, 180, 10, 1.0);
    color: white;
    position: -webkit-sticky;
    position: sticky;
    height: 35px;

    max-width: 300px;
}

.ReturnTable tr{
    /*line-height: 30px;*/
    /*position: relative;*/
    height: 30px;
    max-height: 30px;
    padding: 0;
    margin: 0;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
}

.inputChangeTable {
    width: 100%;
    /*height: 110%;*/
    height: 20px;
    font-size: 100%;
    /*max-width: 80px;*/
}

.ReturnTable tr:nth-child(even){background-color: #f2f2f2;}

/*.ChangeTable td:nth-child(1) {width: 30px; text-align: center}*/
/*.ChangeTable td:nth-child(2) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(3) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(4) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(5) {width: 60px; text-align: left}*/
/*.ChangeTable td:nth-child(6) {width: 50px; text-align: right}*/
/*.ChangeTable td:nth-child(7) {width: 40px; text-align: right}*/
/*.ChangeTable td:nth-child(8) {width: 60px; text-align: right}*/
/*.ChangeTable td:nth-child(9) {width: 50px; text-align: right}*/
/*.ChangeTable td:nth-child(10){width: 20px; text-align: right}*/
/*.ChangeTable td:nth-child(11){width: 50px; text-align: left}*/
/*.ChangeTable td:nth-child(12){width: 50px; text-align: right}*/

.ReturnTable th:nth-child(1) {width:  57px; text-align: center; padding-right: 3px;}
.ReturnTable th:nth-child(2) {width: 127px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(3) {width: 97px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(4) {width: 97px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(5) {width: 97px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(6) {width: 99px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(7) {width: 147px; text-align: center; padding-right: 3px;}
.ReturnTable th:nth-child(8) {width: 147px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.ReturnTable th:nth-child(9) {width:  44px; max-width: 44px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}
.ReturnTable th:nth-child(10){width:  47px; text-align: center; padding-right: 3px;}
.ReturnTable th:nth-child(11){width:  87px; text-align: center; padding-left: 3px;}
.ReturnTable th:nth-child(12){width:  67px; text-align: center; padding: 0px; padding-right: 3px; line-height: 15px;}

.ReturnTable td:nth-child(1) {width:  57px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(2) {width: 127px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(3) {width: 97px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(4) {width: 97px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(5) {width: 97px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(6) {width: 99px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(7) {width: 147px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(8) {width: 147px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(9) {width:  44px; max-width: 44px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(10){width:  47px; text-align: right; padding-right: 3px;}
.ReturnTable td:nth-child(11){width:  87px; text-align: left; padding-left: 3px;}
.ReturnTable td:nth-child(12){width:  67px; text-align: right; padding-right: 3px;}

.ReturnTable tr:hover {background-color: #ddd;}






.NewReturnFieldShipping {
    top: 255.5px;
    width: 100px;
    left: 87.4%;
    /*border: 1px solid;*/
    position: absolute;
}

.NewReturnFieldShippingName {
    width: 70px;
}

.NewReturnFieldShippingValue {
    left: 70%;
    width: 50px;
    position: absolute;
}


.NewReturnDate{
    position: absolute;
    border-color: black;
    border: 4px;
    top: -2.9%;
    left: 87%;
}













.NewReturnTicket {
    left: 1330px;
    top: 20%;
    width: 330px;
    height: 450px;
    /*border: 2px solid #ddd;*/
    position: absolute;
}


/*.NewChangeTicketSummary{*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 240px;*/
/*    border: 2px solid #ddd;*/
/*}*/

.NewReturnTicketSummaryHeader{
    position: absolute;
    width: 100%;
}

.NewReturnTicketSummaryHeader th{
    position: absolute;
    text-align: center;
    width: 100%;
    /*height: 20px;*/
    color: white;
    border: none;
    background-color: #4CAF50;
}


.ReturnSummaryAbove {
    /*border-bottom: 1px dashed #ddd;*/
    position: absolute;
    /*top: 50px;*/
    text-align: center;
    /*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
    font-size: 11.0pt;
    border-collapse: collapse;
    /*border: 1px solid red;*/
    border-top: none;
    display: block;
    width: 100%;
    height: 323px;
    height: 405px;
    overflow-y: auto;
    white-space: nowrap;
    /*top: 30px;*/
}


.ReturnSummaryAbove td:nth-child(1) {width: 140px; max-width: 130px; text-overflow: ellipsis; overflow: hidden; padding-left: 10px;}
.ReturnSummaryAbove td:nth-child(2) {width: 50px; max-width: 50px; text-overflow: ellipsis; overflow: hidden; text-align: center;}
.ReturnSummaryAbove td:nth-child(3) {width: 50px; max-width: 50px; text-overflow: ellipsis; overflow: hidden; text-align: center; }
.ReturnSummaryAbove td:nth-child(4) {width: 70px; max-width: 70px; text-overflow: ellipsis; overflow: hidden; text-align: right; }

.ReturnSummaryAbove th:nth-child(1) {width: 140px; max-width: 130px; padding-left: 10px;}
.ReturnSummaryAbove th:nth-child(2) {width: 50px; max-width: 50px; text-align: center;}
.ReturnSummaryAbove th:nth-child(3) {width: 50px; max-width: 50px; text-align: center;}
.ReturnSummaryAbove th:nth-child(4) {width: 70px; max-width: 70px; text-align: right; }


.ReturnSummaryAbove th {
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
}

.ReturnSummaryAbove th:nth-child(1) {
    color: red;
}

.ReturnSummaryAbove th td {
    border-bottom: 0.5px solid;
}

.ReturnSummaryAbove td {
    /*border: 0.5px solid #ddd;*/
    line-height: 20px;
    text-align: left;
    background-color: rgb(255, 255, 255);
}

.ReturnSummaryAboveBody {
    width: 100%;
    /*height: 100%;*/
    position: absolute;
    /*border: 1px solid #ddd;*/
    /*border: 2px solid #ddd;*/
    /*border-bottom: none;*/
    /*border: 1px;*/
}

/*.ReturnSummaryBelowTBody {*/
/*    width: 100%;*/
/*    height: 135px;*/
/*    top: 155px;*/
/*    overflow-y: scroll;*/
/*    position: absolute;*/
/*    !*border: 1px;*!*/
/*}*/

/*.ReturnSummaryBelow {*/
/*    text-align: center;*/
/*    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
/*    font-size: small;*/
/*    border-collapse: collapse;*/
/*    border: 1px solid #ddd;*/
/*    border-top: none;*/
/*    width: 100%;*/
/*}*/


/*.ReturnSummaryBelow th {*/
/*    line-height: 20px;*/
/*    text-align: left;*/
/*    background-color: rgb(255, 255, 255);*/
/*}*/

/*.ReturnSummaryBelow th:nth-child(1) {*/
/*    color: green;*/
/*}*/

/*.ReturnSummaryBelow th td {*/
/*    border-bottom: 0.5px solid;*/
/*}*/

/*.ReturnSummaryBelow td {*/
/*    !*border: 0.5px solid #ddd;*!*/
/*    line-height: 20px;*/
/*    text-align: left;*/
/*    background-color: rgb(255, 255, 255);*/
/*}*/

.NewReturnSummaryTotals{
    width: 100%;
    /*top: 200px;*/
    top: 384px;
    top: 470px;
    height: 120px;
    position: absolute;
    font-size: 11.0pt;
}


.NewReturnSummaryTotalsTable{
    width: 100%;
    position: absolute;
}

.NewReturnSummaryTotalsTable td:nth-child(1){
    width: 75%;
}
.NewReturnSummaryTotalsTable td:nth-child(2){
    width: 25%;
}









.NewReturnTicketClient{
    top: 326px;
    top: 500.0px;
    /*top: 465px;*/
    width: 100%;
    height: 80px;
    position: absolute;
    border: 2px solid #ddd;
}

.NewReturnClientDataHeader{
    left: 38%;
    position: absolute;
    text-align: center;
    top: 9px;
    font-size: 12pt;
    font-weight: bold;
}

.NewReturnTicketClientHeader {
    background-color: rgba(10, 180, 10, 1);
    color: white;
    width: 100%;
    height: 35px;
}

.NewReturnTicketSummary{
    position: absolute;
    width: 100%;
    /*height: 300px;*/
    height: 485px;
    height: 580px;
    border: 2px solid #ddd;
}

.horizontalDivisionInTableForTotalsReturn {
    border-bottom: 1.5px dashed #ddd;
    /*width: 92.5%;*/
    width: 100%;
    /*left: 2.5%;*/
    /*top: 63%;*/
    top: 76.7%;
    top: 78.8%;
    position: absolute;
    z-index: 500;
}

.NewReturnClientDataHeader{
    left: 40%;
    position: absolute;
}

.NewReturnClientDataEmail{
    top: 60%;
    width: 40%;
    height: 80%;
    position: absolute;
    /*border: 2px solid #ddd;*/
}

.NewReturnClientDataMessageEmail{
    position: absolute;
    height: 25px;
    left: 8%;
    font-size: 11pt;
    /*border: 1px solid;*/
}

.NewReturnClientDataFormEmail{
    position: absolute;
    left: 100%;
}






.buttonScanAndPayReturn{
    /*top: 435px;*/
    top: 605px;
    top: 615px;
    width: 100%;
    height: 38.5px;
    position: absolute;
}

.buttonScanAndPayButtonReturn {
    position: absolute;
    width: 55%;
    left: 22.5%;
    height: 100%;
    border-radius: 5%;
    /*background-color: ;*/
}



* {
    /*font-family: 'Carlito', sans-serif;*/

}
.My_profile_general_container {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: white;
	top: 0;
	left: 0;
	width: 1920px;
	height: 1057px;
	height: 1088px;
}


#title {
  text-align: left;
  /*font-family: arial, sans-serif;*/
}

.HeaderLandingPage {
	border-color: coral;
	border: solid 0.3em;
	height: 5%;
	width: 100%;
}

.LogOutButtonMyProfile {
	/*margin-left: 94.5%;*/
	height: 100%;
	width: 90px;
	/*top: 2px;*/
	background-color: Transparent;
	background-repeat:no-repeat;
	font-size: 14pt;
	position: absolute;
	color: white;
	border: none;
	cursor: pointer;
	right: 0px;
}
.LogOutButtonMyProfile:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

.RowHeaderLandingPage {
	height: 100%;
	width: 100%;
	/*background-color: rgba(0,128,0, 0.9);*/
	margin: 0 !important;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	padding : 0 !important;
}

.RowHeaderLandingPage2 {
	height: 100%;
	width: 100%;
	/*background-color: red;*/
	/*max-width: 100%;*/
	/*max-height: 100%;*/
	/*min-width: 100%;*/
	/*min-height: 100%;*/
	margin: 0 !important;
	padding : 0 !important;
}

.HistoricalLandingPage {
	height: 100%;
	width: 20%;
	color: white;
	background-color: Transparent;
	font-size: 100%;
	/*font-size: 19px;*/
	position: absolute;
	border: none;
	/*text-decoration: underline;*/
	cursor: pointer;
}

.HistoricalLandingPage:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

.VerticalCenteredMessage {
	text-align: center;
	/*display: table;*/
	vertical-align: middle;
	position: absolute;
	width: 100%;
	height: 100%;
	line-height: 56px;
	margin-top: 2%;
}

.MyProfileLandingPageMyProfile {
	margin-left: 20%;
	height: 100%;
	width: 20%;
	color: white;
	background-color: Transparent;
	font-size: 100%;
	position: absolute;
	/*border: none;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*text-decoration: underline;*/
	font-weight: bold;
	border: none;
	cursor: pointer;
}

.MyProfileLandingPageMyProfile:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

.containerPage{
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	height: 94.3%;
	/*height: 1029px;*/
	/*height: 100%;*/
	/*height: 1140px;*/
	margin-top: 60px;
	position: absolute;
	/*width: 1900px;*/
	width: 100%;
}

.userNameOnLanding {
	/*width: 80%;*/
	/*height: 10%;*/
	height: 100px;
	width: 50%;
	/*margin-left: 15.75%;*/
	/*margin-left: 350px;*/
	margin-left: 18.25%;
	/*margin-top: 2.6%;*/
	margin-top: 42px;
	position: absolute;

	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*font-size: 300%;*/
	font-size: 48px;
}

.userNameOnLandingRow {
	border-color: coral;
	border: solid 0.1em;
	width: 100%;
	height: 20%;
	position: absolute;
}

.containerPageMyAccount {
	display: flex;

	width: 100%;
	height: 100%;
	/*border-color: red;*/
	/*border: solid 0.1em;*/
}

.containerPageMyAccountRow{
	border-color: red;
	border: solid 0.1em;
	position: absolute;
	margin-top: 100%;
	width: 100%;
	height: 20%;
}

.hola {
	border-color: red;
	border: solid 0.1em;
	width: 100%;
 }


.containerNavigation {
	/*margin-left: 300px;*/
	margin-left: 350px;
	margin-left: 18.25%;
	/*height: 70%;*/
	height: 713px;
	width: 12%;
	/*max-width: 250px;*/
	/*width: 231px;*/
	border-color: coral;
	border: solid 0.1em;
	/*margin-top: 7.5%;*/
	margin-top: 144px;
	position: absolute;
}

.containerInformationForm {
	/*margin-left: 132px;*/
	/*margin-left: 666.3px;*/
	margin-left: 34.7%;
	/*margin-top: 7.5%;*/
	margin-top: 144px;
	/*height: 70%;*/
	height: 713px;
	width: 29.65%;
	/*width: 570px;*/
	border-color: coral;
	border: solid 0.1em;
}

.containerSettingsForm {
	/*margin-left: 132px;*/
	margin-left: 34.7%;
	/*margin-top: 7.5%;*/
	margin-top: 144px;
	/*height: 70%;*/
	height: 713px;
	/*width: 35%;*/
	/*width: 630px;*/
	width: 32.8%;
	border-color: coral;
	border: solid 0.1em;
}

.containerInformationTable {
	/*margin-left: 132px;*/
	margin-left: 34.7%;
	position: absolute;
	margin-top: 144px;
	height: 713px;
	/*width: 890px;*/
	width: 46.32%;
	border-color: coral;
	border: solid 0.1em;
}

.containerPageTableCol1 {
	width: 30%;
	/*width: 1500px;*/
}

.containerPageTableCol2 {
	width: 70%;
}

.renderMyCompanyData {
	display: flex;
	margin-top: 7%;
	width: 100%;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*height: 70%;*/
	height: 497px;

}

.renderMyCompanyLeft {
	vertical-align: middle;
	width: 35%;
	/*height: 70%;*/
	height: 497px;
	margin-top: 5%;
	font-size: 14pt;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	position: absolute;
}

.renderMyCompanyRight {
	vertical-align: middle;
	/*width: 100%;*/
	width: 33%;
	/*height: 70%;*/
	height: 497px;
	margin-left: 30%;
	margin-top: 5%;
	font-size: 14pt;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	position: absolute;
}

.renderMyCompanySettingsButtons {
	vertical-align: middle;
	width: 28%;
	margin-top: 3%;
	margin-left: 65%;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
}

.renderMyCompanySettingsSingleButton {
	min-height: 3.8%;
	/*max-height: 11%;*/
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 9.0px;

	margin-bottom: -1%;
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}

.renderMyCompanySettingsSingleButton:hover {
	background-color: rgba(255, 255, 255, 0.45);
}

.renderMyCompanySettingsSingleButton2 {
	min-height: 3.8%;
	max-height: 7.6%;
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 38.8px;

	margin-bottom: -1%;
	/*margin-left: 10%;*/
	/*font-size: 14px;*/
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}
.renderMyCompanySettingsSingleButton2:hover {
	background-color: rgba(255, 255, 255, 0.45);
}

.renderMyCompanySettingsSingleButton3 {
	min-height: 4%;
	/*max-height: 11%;*/
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 73px;

	margin-bottom: -1%;
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}
.renderMyCompanySettingsSingleButton3:hover {
	background-color: rgba(255, 255, 255, 0.45);
}

.renderMyCompanySettingsSingleButton4 {
	min-height: 4%;
	/*max-height: 11%;*/
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 107px;

	margin-bottom: -1%;
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}
.renderMyCompanySettingsSingleButton4:hover {
	background-color: rgba(255, 255, 255, 0.45);
}


.renderMyCompanySettingsSingleButton5 {
	min-height: 4%;
	/*max-height: 11%;*/
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 142px;

	margin-bottom: -1%;
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}
.renderMyCompanySettingsSingleButton5:hover {
	background-color: rgba(255, 255, 255, 0.45);
}

.renderMyCompanyLeftText {
	/*height: 6.5%;*/
	height: 32px;
	width: 100%;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
}

.renderMyCompanyRightText {
	/*height: 6.48%;*/
	height: 32px;
	width: 200px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	/*margin-left: -50%;*/
	/*font-size: 110%;*/
	/*border: solid 0.1em;*/
	/*border-color: red;*/
}

.renderMyCompanyLeftTextBlankSpace {
	/*height: 12%;*/
	height: 55px;
}

.renderMyCompanyLeftTextBlankSpaceSettings {
	height: 9%;
}

.containerPageTable {
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	font-size: 100%;
	border-collapse: collapse;
  	/*border: 3px solid;*/
	/*border-color: red;*/
	width: 100%;
	height: 100%;
}

.containerPageTable td {
	border: none;
}

.containerPageTable tr {
	border: 3px solid;
	border-color: red;
}

.containerPageTableBody {
	/*display: block;*/
}

.MyCompany {
	margin-top: 13%;
	margin-left: 14%;
}

.MyEmployees {
	margin-top: 10%;
	margin-left: 14%;
}

.MyShops {
	margin-top: 10%;
	margin-left: 14%;
}

.Settings {
	margin-top: 10%;
	margin-left: 14%;
}

.MyCompanyButton {
	/*font-size: 125%;*/
	/*font-size: 14pt;*/
	border: none;
	width: 150px;
	/*border: 1px solid red;*/
	background-color: white;
	cursor: pointer;
	font-size: 14pt;
	text-align: left;
}

.MyCompanyButtonSelected {
	/*font-size: 125%;*/
	/*font-size: 14pt;*/
	border: none;
	width: 150px;
	/*border: 1px solid red;*/
	background-color: white;
	cursor: pointer;
	color: rgba(10, 180, 10, 1);
	font-weight: bold;
	font-size: 14pt;
	text-align: left;
	/*text-decoration: underline;*/
}

.MyEmployeesButton {
	border: none;
	width: 175px;
	text-align: left;
	background-color: white;
	cursor: pointer;
	font-size: 14pt;
}
.MyEmployeesButtonSelected {
	border: none;
	width: 175px;
	text-align: left;
	background-color: white;
	cursor: pointer;
	font-size: 14pt;
	/*text-decoration: underline;*/
	color: rgba(10, 180, 10, 1);
	font-weight: bold;
}

.MyShopsButton {
	font-size: 14pt;
	width: 150px;
	text-align: left;
	border: none;
	background-color: white;
	cursor: pointer;
}
.MyShopsButtonSelected {
	font-size: 14pt;
	width: 150px;
	text-align: left;
	border: none;
	background-color: white;
	cursor: pointer;
	/*text-decoration: underline;*/
	color: rgba(10, 180, 10, 1);
	font-weight: bold;
}
.MyCompanyButtonIcon {
	height: 18px;
	width: 18px;
	padding-right: 5px;
}

.SettingsButton {
	font-size: 14pt;
	width: 150px;
	text-align: left;
	border: none;
	background-color: white;
	cursor: pointer;
}
.SettingsButtonSelected {
	font-size: 14pt;
	width: 150px;
	text-align: left;
	border: none;
	background-color: white;
	cursor: pointer;
	/*text-decoration: underline;*/
	color: rgba(10, 180, 10, 1);
	font-weight: bold;
}

.renderMyCompany {
	/*margin-left:  2%;*/
	/*margin-right: 2%;*/
	/*margin-top: 1.5%;*/
	margin-right:  38px;
	margin-left:  38px;
	margin-top:  29px;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 710px;
	width: 31.3%;
	position: absolute;
}

.renderMyEmployees {
	/*margin-left:  38.1px;*/
	/*margin-left:  3.4%;*/

	/*margin-left:  38.1px;*/
	/*margin-right: 2%;*/
	/*margin-top:   2.5%;*/
	margin-right:  38px;
	margin-left:  38px;
	margin-top:  29px;
	/*width: 32.1%;*/
	/*width: 80%;*/
	width: 80.0%;
	position: absolute;
	/*height: 72%;*/
	/*height: 580px;*/
	/*border-color: red;*/
	/*border: solid 0.3em red;*/
}

.renderMyShops {
	margin-left:  2%;
	margin-right: 2%;
	margin-top: 1.5%;
	width: 32.1%;
	position: absolute;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
}

.renderSettings {
	margin-left:  2%;
	margin-right: 2%;
	margin-top: 1.5%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 710px;
	width: 31.3%;
	position: absolute;
}

.renderMyCompanyName {
	width: 85%;
	/*height: 8%;*/
	height: 40px;
	/*font-size: 225%;*/
	font-size: 28pt;
	/*font-size: 38px;*/
	position: absolute;
	/*border-color: red;*/
	/*border: solid 0.1em;*/
}

.editMyCompanyButtonContainer {
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*width: 20%;*/
	/*height: 20%;*/
	position: absolute;
	margin-left: 72%;
	/*margin-top: 6%;*/
	margin-top: 35.6px;
	background-color: white;
	/*border: none;*/
	cursor: pointer;
}

.editMyCompanyButton {
	width: 100%;
	height: 100%;
	text-decoration: underline;
	background-color: transparent;
	border: none;
	font-size: 11pt;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*position: absolute;*/
	/*margin-left: 35%;*/
	/*margin-top: 7%;*/
	/*background-color: white;*/
	/*!*border: none;*!*/
	/*cursor: pointer;*/
}

.renderMyCompanyTable {
	margin-top: 5%;
	width: 100%;
	border: solid 1px red;
	border-collapse: collapse;
}

.renderMyCompanyTable td {
	/*border: solid 1px red;*/
}

.renderMyCompanyTable tr {
	/*border: solid 1px red;*/
	height: 30px;
}

.renderMyCompanyTableCol1 {
	width: 35%;
}

.renderMyCompanyTableCol2 {
	width: 65%;
}

.renderMyEmployeesTable {
	margin-left: 20px;
	text-align: center;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	font-size: medium;
	border-collapse: collapse;
	border: 3px solid #ddd;
	width: 75%;
	height: 100%;
	overflow-y: auto;
	table-layout:fixed;
}

.renderMyEmployeesTable td, .renderMyEmployeesTable th {
  border: 0.5px solid #ddd;
  /*padding: 8px;*/
}

.renderMyEmployeesTable td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.renderMyEmployeesTable tr:nth-child(even){background-color: #f2f2f2;}

.renderMyEmployeesTable tr:hover {background-color: #ddd;}

.renderMyEmployeesTable th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
}


.whiteSpaceInBackground {
	position: absolute;
	/*height: 70%;*/
	height: 710px;
	/*width: 29.7%;*/
	/*height: 710px;*/
	/*width: 31.3%;*/
	width: 29.65%;
	background-color: rgba(255, 255, 255, 1);
	/*background-color: red;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	z-index: 500;
}


.fillingBackgroundContainer {
	margin-left:  2%;
	margin-right: 2%;
	margin-top:   1.5%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 580px;
	/*height: 700px;*/
	width: 31.3%;
	position: absolute;
	z-index: 1000;
}

.fillingBackgroundContainer2 {
	/*margin-left:  3.4%;*/
	/*margin-right: 2%;*/
	/*margin-top:   2.5%;*/

	margin-right:  38px;
	margin-left:  38px;
	margin-top:  29px;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 580px;
	/*height: 700px;*/
	width: 80.0%;
	position: absolute;
	z-index: 1000;
}

/*renderMyEmployees*/




.renderMyCompanyRightInput {
	width: 120%;
	/*font-size: 100%;*/
	font-size: 13pt;
	height: 20px;
	margin-bottom: 6.2px;
	display: flex;
	flex-direction: row;
	/*border: solid 0.05em;*/
	/*border-radius: 3px;*/
}

.renderMyCompanyRightDate{
	width: 121.5%;
	/*font-size: 100%;*/
	font-size: 16px;
	height: 23px;
	margin-bottom: 6.2px;
	display: flex;
	flex-direction: row;
	/*border: solid 0.05em;*/
	/*border-radius: 3px;*/
}

.renderMyCompanyRightInputDropdown {
	width: 124%;
	font-size: 13pt;
	margin-bottom: 6.25px;
	height: 25px;
	text-align: left;
}
.renderMyCompanyRightInputDropdownPerson {
	width: 124%;
	font-size: 13pt;
	margin-bottom: 6.25px;
	margin-top: -2%;
	height: 25px;
	text-align: left;
}
.renderMyCompanyRightInputDropdownTextEmployee {
	text-align: left;
	font-size: 12pt;
	position: absolute;
	/*margin-top: -4%;*/
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 70%;
	/*border: 1px solid red;*/
}
.renderMyCompanyRightInputDropdownTextEmployee a {
	padding-left: 3px;
	text-decoration: none;
	color: black;
}
.renderMyCompanyRightInputDropdownText {
	text-align: left;
	font-size: 12pt;
	position: absolute;
	/*margin-top: -4%;*/
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 11.5%;
	width: 83%;
}
.renderMyCompanyRightInputDropdownTextPerson {
	text-align: left;
	font-size: 12pt;
	position: absolute;
	/*margin-top: -4%;*/
}

.renderMyCompanyRightInputDropdownText a {
	padding-left: 3px;
	text-decoration: none;
	color: black;
}
.dropdownInSettings {
	width: 100%;
	height: 100%;
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 13pt;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.renderMyCompanyRightInputDropdownDividedPhonePrefix {
	width: 28%;
	font-size: 16px;
	height: 26.3px;
	/*margin-left: -100px;*/
	margin-left: 0%;
	/*margin-top: 2%;*/
	position: absolute;
}
.renderMyCompanyRightInputDropdownCurrency {
	width: 104.5%;
	font-size: 16px;
	height: 25px;
	/*margin-left: -100px;*/
	margin-top: 2%;
}
.dropdownMenuSettings{
	width: 123%;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	margin-top:24px;
	font-size: 18px;
}
.dropdownMenuSettingsCompany{
	width: 123%;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	/*margin-top:24px;*/
	font-size: 13pt;
}
.dropdownMenuSettingsCompany a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsTreatment {
	/*width: 296%;*/
	width: 29.3%;
	/*max-height: 200px;*/
	min-height: 70px;
	text-align: left;
	max-height: 200px;
	border: solid 0.1em;
	z-index: 2000;
	background-color: white;
	position: -webkit-sticky;
	position: sticky;
	/*position: absolute;*/
	overflow-y: auto;
	margin-top: -1px;
	font-size: 13.5pt;
}
.dropdownMenuSettingsTreatment a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsCountry {
	/*width: 308%;*/
	/*text-align: left;*/
	/*min-height: 70px;*/
	/*max-height: 200px;*/
	/*border: solid 0.05em;*/
	/*z-index: 2500;*/
	/*background-color: white;*/
	/*position: sticky;*/
	/*overflow-y: auto;*/
	/*margin-top: -1px;*/
	/*font-size: 13.5pt;*/

	width: 296%;
	width: 94%;
	/*max-height: 200px;*/
	min-height: 70px;
	text-align: left;
	max-height: 200px;
	border: solid 0.1em;
	z-index: 2000;
	background-color: white;
	position: -webkit-sticky;
	position: sticky;
	/*position: absolute;*/
	overflow-y: auto;
	margin-top: -1px;
	font-size: 13.5pt;
}
.dropdownMenuSettingsCountry a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsRegion {
	width: 307%;
	text-align: left;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: -webkit-sticky;
	position: sticky;
	overflow-y: auto;
	margin-top: -1px;
	margin-left: -104%;
	font-size: 13.5pt;
}
.dropdownMenuSettingsRegion a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsProvince {
	width: 308%;
	text-align: left;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: -webkit-sticky;
	position: sticky;
	overflow-y: auto;
	margin-top: -1px;
	margin-left: -209%;
	font-size: 13.5pt;
}
.dropdownMenuSettingsProvince a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsTreatmentAbsolute{
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 2000;
}
.dropdownMenuSettingsPhonePrefix {
	width: 97%;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	margin-top: -1px;
	font-size: 12pt;
}
.dropdownMenuSettingsPhonePrefix a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
	/*font-size: 13.5pt;*/
	line-height: 2.0vh;
}
.dropdownMenuSettingsCurrency {
	width: 103.5%;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	margin-top: -1px;
	font-size: 13.5pt
}
.dropdownMenuSettingsCurrency a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownInSettingsEmployees {
	/*POLLA*/
	width: 101%;
	width: 94px;
	height: 101%;
	height: 46px;
	font-size: 16px;
	position: relative;
	/*position: absolute;*/
	z-index: 0;
	/*margin-top: -20px;*/
}
.dropdownInSettingsShops{
	/*POLLA*/
	width: 101%;
	height: 101%;
	font-size: 16px;
	width: 101%;
	/*width: 90px;*/
	/*height: 101%;*/
	/*height: 46px;*/
	/*font-size: 16px;*/
	position: relative;
	/*position: absolute;*/
	z-index: 0;
}

.dropdownEditSettings {
	width: 70px;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.dropdownEditSettingsSticky {
	/*position: absolute;*/
	/*position: sticky;*/
	width: 100%;
	/*width: 50px;*/
}
.dropdownEditSettingsSticky2Container {
	position: -webkit-sticky;
	position: sticky;
	z-index: 1000;
	/*margin-top: -20px;*/
	/*position: absolute;*/
}
.dropdownEditSettingsSticky2 {
	/*POLLA*/
	z-index: 10000;
	/*height: 20px;*/
	width: 100%;
	width: 296px;
	position: absolute;
	/*float: bottom;*/
	/*margin-top: 24px;*/
}
.dropdownEditSettingsSticky3 {
	z-index: 10000;
	/*height: 20px;*/
	width: 100%;
	position: absolute;
}
.Test2 {
	/*POLLA*/
	z-index: 10000;
	/*height: 20px;*/
	width: 100%;
	position: absolute;
	width: 1000px;
	border: 1px solid red;
	/*float: bottom;*/
	/*margin-top: 24px;*/
}

.renderMyCompanyRightInputDivided {
	width: 83%;
	font-size: 100%;
	height: 20px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	margin-top: 2%;
	/*border: solid 0.05em;*/
	/*border-radius: 3px;*/
}

.editCompanyForm {
	width: 108%;
}

.renderMyCompanyRightInputDividedLeft {
	/*width: 36%;*/
	width: 43px;
	height: 100%;
	margin-right: 6%;
	margin-bottom: 5%;
	font-size: 18px;
}

.renderMyCompanyRightInputDividedRight {
	width: 175px;
	height: 100%;
	font-size: 18px;
	/*margin-left: 10%;*/
}

.renderMyCompanySettingRightInputDividedRight {
	width: 148px;
	height: 100%;
	font-size: 13.5pt;
	position: absolute;
	margin-left: 31%;
	/*margin-top: 20%;*/
}
.renderMyCompanySettingRightInputDividedRight2 {
	width: 148px;
	height: 100%;
	font-size: 13.5pt;
	position: absolute;
	margin-left: 31%;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}

.containerEditMyCompany {
	margin-left: 6%;
	margin-right: 5%;
	margin-top: 4.5%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	height: 80%;
	width: 100%;
	position: absolute;
}


.Save_button_edit {
	margin-left: 41%;
	/*margin-top: 15%;*/
	/*margin-top: 60%;*/
	width: 36%;
	position: absolute;
	height: 25px;
	font-size: 13pt;
	border: 1px solid;
	/*background-color: rgba(10, 140, 10, 0.6);*/
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 13pt;
	background-color: rgba(10, 180, 10, 1);
}
.Save_button_edit:hover {
	background-color: rgba(10, 180, 10, 0.65);
}
.Clear_button_edit {
	/*margin-left: 11%;*/
	/*margin-top: 60%;*/
	width: 36%;
	position: absolute;
	height: 25px;
	font-size: 13pt;
	border: 1px solid;
	/*top: -785%;*/
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.Clear_button_edit:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.whiteSpaceInBackgroundTable {
	position: absolute;
	/*height: 70%;*/
	height: 713px;
	width: 46.3%;
	background-color: rgba(255, 255, 255, 1);
	/*background-color: red;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	z-index: 500;
}

.whiteSpaceInBackgroundTable2 {
	position: absolute;
	/*height: 70%;*/
	height: 713px;
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	/*background-color: red;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	z-index: 500;
}




.containerEmployeesAndShopsTable {
	margin-right: 6%;
	/*margin-top: 12%;*/
	margin-top: 78px;
	margin-bottom: 9%;
	width: 100%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	height: 70%;
}

.editMyEmployeesButtonContainer {
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*width: 20%;*/
	/*height: 20%;*/
	position: absolute;
	/*margin-left: 40.7%;*/
	margin-left: 88.4%;
	/*margin-top: 3%;*/
	margin-top: 60px;
	background-color: white;
	/*border: none;*/
	cursor: pointer;
}


.editMyEmployeesButton {
	/*margin-left: 15%;*/
	margin-top: 2%;
	background-color: white;
	border: none;
	cursor: pointer;
	width: 100%;
	height: 100%;

	text-decoration: underline;
	/*background-color: transparent;*/
	/*border: none;*/
	font-size: 11pt;
}

.myEmployeesTable {
	text-align: center;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	font-size: 12pt;
	border-collapse: collapse;
	/*width: 100%;*/
	/*width: 815px;*/
	width: 114.4%;
	/*width: auto;*/
	table-layout: fixed;
	/*height: 98.3%;*/
	/*height: 513px;*/
	display: block;
	empty-cells: show;
	/*border: 1px solid red;*/
	overflow: auto;
}

.myEmployeesTable tbody, .myEmployeesTable thead
{
	display: block;
}

.myEmployeesTable thead
{
	position: -webkit-sticky;
	position: sticky;
}

.myEmployeesTableHead {
	width: 100%;
	position: -webkit-sticky;
	position: sticky;
}



.myEmployeesTableBodyWithoutScrollBar {
	width: 100%;
	max-height: 530px;
	max-width: 100%;
}

.myEmployeesTableBodyWithScrollBar {
	width: 100%;
	height: 530px;
	max-width: 100%;
}

.myEmployeesTableHead th:nth-child(1) {width: 45px; max-width: 45px; min-width: 45px;  text-align: center; padding-right: 3px;}
/*.myEmployeesTableHead th:nth-child(1) {width: 6.22%;  text-align: center;}*/
.myEmployeesTableHead th:nth-child(2) {width: 92px; max-width: 92px; min-width: 92px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(2) {width: 12.65%;  text-align: left;}*/
.myEmployeesTableHead th:nth-child(3) {width: 107px; max-width: 107px; min-width: 107px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(3) {width: 14.65%;  text-align: left;}*/
.myEmployeesTableHead th:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(4) {width: 24.95%;  text-align: left;}*/
.myEmployeesTableHead th:nth-child(5) {width: 95px; max-width: 95px; min-width: 95px;  text-align: center;  padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(5) {width: 13.1%;  text-align: left; }*/
.myEmployeesTableHead th:nth-child(6) {width: 230px; max-width: 230px; min-width: 230px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(6) {width: 28.43%;  text-align: left;}*/

.myEmployeesTableBodyWithoutScrollBar td:nth-child(1) {width: 45px; max-width: 45px; min-width: 45px;  text-align: right; padding-right: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(2) {width: 92px;  max-width: 92px; min-width: 92px;  text-align: left; padding-left: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(3) {width: 107px;  max-width: 107px; min-width: 107px;  text-align: left; padding-left: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px;  text-align: left;  padding-left: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(5) {width: 95px;  max-width: 95px; min-width: 95px;  text-align: left;  padding-left: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(6) {width: 230px;  max-width: 230px; min-width: 230px;  text-align: left;  padding-left: 3px;}

/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(1) {width: 6.22%;   max-width: 6.22%;  text-align: center;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(2) {width: 12.65%;  max-width: 12.65%; text-align: left;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(3) {width: 14.65%;  max-width: 14.65%; text-align: left;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(4) {width: 24.95%; max-width: 24.95%;  text-align: left; }*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(5) {width: 13.1%;  max-width: 13.1%;    text-align: left; }*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(6) {width: 28.43%;  max-width: 28.43%;  text-align: left; }*/


.myEmployeesTableBodyWithScrollBar td:nth-child(1) {width: 45px; max-width: 45px; min-width: 45px;  text-align: right; padding-right: 3px;}
.myEmployeesTableBodyWithScrollBar td:nth-child(2) {width: 92px;  max-width: 92px; min-width: 92px;  text-align: left; padding-left: 3px;}
.myEmployeesTableBodyWithScrollBar td:nth-child(3) {width: 107px; max-width: 107px; min-width: 107px;  text-align: left; padding-left: 3px;}
.myEmployeesTableBodyWithScrollBar td:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px; text-align: left;  padding-left: 3px;}
.myEmployeesTableBodyWithScrollBar td:nth-child(5) {width: 95px;  max-width: 95px; min-width: 95px;  text-align: left; padding-left: 3px; }
/*.myEmployeesTableBodyWithScrollBar td:nth-child(6) {width: 216px;  max-width: 216px; text-align: left; }*/
.myEmployeesTableBodyWithScrollBar td:nth-child(6) {width: 230px; max-width: 230px; min-width: 230px; text-align: left;  padding-left: 3px;}

/*.myEmployeesTableBodyWithScrollBar td:nth-child(1) {width: 6.22%;   max-width: 6.22%; text-align: center;}*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(2) {width: 12.65%;  max-width: 12.65%;  text-align: left;}*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(3) {width: 14.65%;  max-width: 14.65%;  text-align: left;}*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(4) {width: 24.95%; max-width: 24.95%;  text-align: left; }*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(5) {width: 13.1%;  max-width: 13.1%;   text-align: left; }*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(6) {width: 28.43%;  max-width: 28.43%;    text-align: left; }*/

.myEmployeesTableBodyWithoutScrollBar td {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.myEmployeesTableBodyWithScrollBar td {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-wrap: break-word;
}


.myEmployeesTable td, .myEmployeesTable th {
	border: 0.5px solid #ddd;
	/*padding: 8px;*/
	/*width: 100%;*/
}

.myEmployeesTable td {
	height: 45px;
	/*display: inline-block;*/
	/*white-space: nowrap;*/
	/*text-overflow: ellipsis;*/
}

.myEmployeesTable th {
	height: 30px;
}

.myEmployeesTable tr:nth-child(even){background-color: #f2f2f2;}

.myEmployeesTable tr:hover {background-color: #ddd;}

.myEmployeesTable th {
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	color: white;
}






  /* Tables of Employees and Shops with input fields*/

.myEmployeesTableInput {
	text-align: center;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	width: 114.4%;
	table-layout:fixed;
	height: 490px;
	display: block;
	empty-cells: show;
	overflow-y: auto;
	overflow-x: hidden;
}


.myEmployeesTableInput tbody, .myEmployeesTableInput thead
{
	display: block;
}

.myEmployeesTableInput thead
{
	position: -webkit-sticky;
	position: sticky;
}

.myEmployeesTableHeadInput {
	width: 100%;
}

/*.myEmployeesTableHeadInput th:nth-child(1) {width: 48px;  text-align: center;}*/
/*.myEmployeesTableHeadInput th:nth-child(2) {width: 100px;  text-align: left;}*/
/*.myEmployeesTableHeadInput th:nth-child(3) {width: 115px;  text-align: left;}*/
/*.myEmployeesTableHeadInput th:nth-child(4) {width: 200px;   text-align: left;}*/
/*.myEmployeesTableHeadInput th:nth-child(5) {width: 103px;  text-align: left;}*/
/*.myEmployeesTableHeadInput th:nth-child(6) {width: 230px;  text-align: left;}*/


.myEmployeesTableHeadInput th:nth-child(1) {width: 45px; max-width: 45px; min-width: 45px;  text-align: center; padding-right: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(1) {width: 6.22%;  text-align: center;}*/
.myEmployeesTableHeadInput th:nth-child(2) {width: 92px; max-width: 92px; min-width: 92px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(2) {width: 12.65%;  text-align: left;}*/
.myEmployeesTableHeadInput th:nth-child(3) {width: 107px; max-width: 107px; min-width: 107px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(3) {width: 14.65%;  text-align: left;}*/
.myEmployeesTableHeadInput th:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(4) {width: 24.95%;  text-align: left;}*/
.myEmployeesTableHeadInput th:nth-child(5) {width: 95px; max-width: 95px; min-width: 95px;  text-align: center; padding-left: 3px; }
/*.myEmployeesTableHeadInput th:nth-child(5) {width: 13.1%;  text-align: left; }*/
.myEmployeesTableHeadInput th:nth-child(6) {width: 230px; max-width: 230px; min-width: 230px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(6) {width: 28.43%;  text-align: left;}*/



.myEmployeesTableBodyWithoutScrollBarInput {
	width: 100%;
	max-height: 480px;
	max-width: 100%;
	/*border: 3px solid;*/
	/*border-color: coral;*/
}

.myEmployeesTableBodyWithScrollBarInput {
	width: 100%;
	height: 480px;
	max-width: 100%;
	/*border: 3px solid;*/
	/*border-color: coral;*/
}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(2) {
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
	/*top: 0;*/
	/*background-color: orange;*/
}


.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(1) {width: 48px; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(2) {width: 95px; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(3) {width: 110px; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(4) {width: 200px;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(5) {width: 98px; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(6) {width: 230px;}

.myEmployeesTableBodyWithScrollBarInput td:nth-child(1) {width: 48px; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(2) {width: 95px; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(3) {width: 110px; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(4) {width: 200px;}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(5) {width: 98px; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(6) {width: 230px;}


.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(1) {width: 45px;   max-width: 45px; min-width: 45px;  text-overflow: ellipsis;  text-align: right; padding-right: 3px;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(2) {width: 92px;  max-width: 92px; min-width: 92px; text-overflow: ellipsis;  text-align: left;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(3) {width: 110px;  max-width: 110px; min-width: 110px; text-overflow: ellipsis;  text-align: left;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px; text-overflow: ellipsis;  text-align: left; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(5) {width: 98px;  max-width: 98px; min-width: 98px; text-overflow: ellipsis;  text-align: left; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(6) {width: 227px;  max-width: 227px; min-width: 227px; text-overflow: ellipsis;  text-align: left; }

/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(1) {width: 6.22%;   max-width: 6.22%; text-overflow: ellipsis;  text-align: center;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(2) {width: 12.65%;  max-width: 12.65%; text-overflow: ellipsis;  text-align: left;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(3) {width: 14.65%;  max-width: 14.65%; text-overflow: ellipsis;  text-align: left;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(4) {width: 24.95%; max-width: 24.95%; text-overflow: ellipsis;  text-align: left; }*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(5) {width: 13.1%;  max-width: 13.1%; text-overflow: ellipsis;  text-align: left; }*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(6) {width: 28.43%;  max-width: 28.43%; text-overflow: ellipsis;  text-align: left; }*/


.myEmployeesTableBodyWithScrollBarInput td:nth-child(1) {width: 45px;   max-width: 45px; min-width: 45px;  text-overflow: ellipsis;  text-align: right; padding-right: 3px;}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(2) {width: 92px;  max-width: 92px; min-width: 92px;  text-overflow: ellipsis;  text-align: left;}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(3) {width: 110px;  max-width: 110px; min-width: 110px; text-overflow: ellipsis;  text-align: left;}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(4) {width: 199px; max-width: 199px; min-width: 199px;  text-overflow: ellipsis;  text-align: left; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(5) {width: 98px;  max-width: 98px; min-width: 98px; text-overflow: ellipsis;  text-align: left; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(6) {width: 225px;  max-width: 225px; min-width: 225px; text-overflow: ellipsis;  text-align: left; }

/*.inputEmployeeTable1{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable1:placeholder-shown{text-overflow: ellipsis; max-width: 95px; font-size: 12pt;}*/
.inputEmployeeTable1{width: 100%; height: 100%; max-height: 45px; font-size: 12pt; z-index: auto} .inputEmployeeTable1:-ms-input-placeholder{text-overflow: ellipsis; max-width: 95px; font-size: 12pt;} .inputEmployeeTable1:placeholder-shown{text-overflow: ellipsis; max-width: 95px; font-size: 12pt;}
.inputEmployeeTable2{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable2:-ms-input-placeholder{text-overflow: ellipsis; max-width: 110px; font-size: 12pt;} .inputEmployeeTable2:placeholder-shown{text-overflow: ellipsis; max-width: 110px; font-size: 12pt;}
.inputEmployeeTable22{width: 100%; height: 100%; max-height: 45px; font-size: 12pt; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}
.inputEmployeeTable3{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable3:-ms-input-placeholder{text-overflow: ellipsis; max-width: 195px;  font-size: 12pt;} .inputEmployeeTable3:placeholder-shown{text-overflow: ellipsis; max-width: 195px;  font-size: 12pt;}
.inputEmployeeTable4{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable4:-ms-input-placeholder{text-overflow: ellipsis; max-width: 98px;  font-size: 12pt;} .inputEmployeeTable4:placeholder-shown{text-overflow: ellipsis; max-width: 98px;  font-size: 12pt;}
.inputEmployeeTable5{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable5:-ms-input-placeholder{text-overflow: ellipsis; max-width: 225px;  font-size: 12pt;} .inputEmployeeTable5:placeholder-shown{text-overflow: ellipsis; max-width: 225px;  font-size: 12pt;}
.inputEmployeeTable52{width: 100%; height: 100%; max-height: 45px; font-size: 12pt; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}

.inputEmployeeTable1Button {
	position: -webkit-sticky;
	position: sticky;
	z-index: auto;
	height: 100%;
	width: 100%;
}

.myEmployeesTableInput td, .myEmployeesTableInput th {
	border: 0.5px solid #ddd;
}
.myEmployeesTableInput td {
	height: 45px;
}
.myEmployeesTableInput th {
	/*padding: 8px;*/
	height: 30px;
}




.myEmployeesTableInput tr:nth-child(even){background-color: #f2f2f2;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(2) {
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
	/*top: 0;*/
	/*background-color: orange;*/
}

.myEmployeesTableInput tr:hover {background-color: #ddd;}

.myEmployeesTableInput th {
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	color: white;
}











.myShopsTable {
	 /*POLLA*/
	/*text-align: center;*/
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	font-size: 12pt;
	border-collapse: collapse;
	/*border: 3px solid red;*/
	/*border-color: coral;*/
	/*width: 100%;*/
	width: 114.4%;
	table-layout:fixed;
	/*height: 88.3%;*/
	/*max-height: 510px;*/
	display: block;
	empty-cells: show;
	overflow: auto;
}

.myShopsTable thead, .myShopsTable tbody {
	display: block;
}

.myShopsTableHead {
	width: 100%;
}

.myShopsTableHead th:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: center; padding-right: 3px;}
.myShopsTableHead th:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px;  text-align: center; padding-left: 3px;}
.myShopsTableHead th:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px;  text-align: center; padding-left: 3px;}
.myShopsTableHead th:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center; padding-left: 3px;}
.myShopsTableHead th:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center;  padding-left: 3px;}
.myShopsTableHead th:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center; padding-left: 3px;}
.myShopsTableHead th:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;   text-align: center; padding-right: 3px;}

.myShopsTableBodyWithoutScrollBar {

	width: 100%;
	max-height: 530px;
	max-width: 100%;
	/*border: 3px solid;*/
	/*border-color: coral;*/
}

.myShopsTableBodyWithScrollBar {
	width: 100%;
	height: 530px;
	max-width: 100%;
	/*border: 3px solid;*/
	/*border-color: coral;*/
}

.myShopsTableBodyWithoutScrollBar td:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: right; padding-right: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(2) {width: 77px; max-width: 77px; min-width: 77px;  text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px; text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;  text-align: right; padding-right: 3px;}

.myShopsTableBodyWithScrollBar td:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: right; overflow: hidden; padding-right: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(2) {width: 77px; max-width: 77px; min-width: 77px; text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px;  text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;  text-align: right; overflow: hidden; padding-right: 3px;}

/*.renderMyShopsTableContent1 {max-width: 48px;   text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent2 {max-width: 130px;  text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent3 {max-width: 230px;  text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent4 {max-width: 90px;   text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent5 {max-width: 90px;   text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent6 {max-width: 90px;   text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent7 {max-width: 104px;  text-overflow: ellipsis; white-space: nowrap;}*/


.myShopsTable td, .myShopsTable th {
	border: 0.5px solid #ddd;
}

.myShopsTable td {
	height: 45px;
}

.myShopsTableHead th {
	height: 30px;
}

.myShopsTableBodyWithoutScrollBar td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	/*word-wrap: break-word;*/
}

.myShopsTableBodyWithScrollBar td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.myShopsTable tr:nth-child(even){background-color: #f2f2f2;}

.myShopsTable tr:hover {background-color: #ddd;}

.myShopsTable th {
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	color: white;
}


/*.myEmployeesTableInput{}*/


.myShopsTableInput {
	text-align: center;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	width: 114.4%;
	/*table-layout:fixed;*/
	height: 490px;
	display: block;
	empty-cells: show;
	overflow-y: auto;
	overflow-x: hidden;
}

.myShopsTableInput tbody, .myShopsTableInput thead {
	display: block;
}

.myShopsTableHeadInput {
	width: 100%;
}

.myShopsTableHeadInput th:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: center;  padding-right: 3px;}
.myShopsTableHeadInput th:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px;  text-align: center; padding-left: 3px;}
.myShopsTableHeadInput th:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px;  text-align: center; padding-left: 3px;}
.myShopsTableHeadInput th:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center; padding-left: 3px;}
.myShopsTableHeadInput th:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center; padding-left: 3px; }
.myShopsTableHeadInput th:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center;  padding-left: 3px;}
.myShopsTableHeadInput th:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;   text-align: center;  padding-right: 3px;}


.myShopsTableBodyWithoutScrollBarInput {
	width: 100%;
	max-height: 480px;
	max-width: 100%;
	/*overflow-x: hidden;*/
	white-space: nowrap;
	text-overflow: ellipsis;
	/*overflow: hidden;*/
}

.myShopsTableBodyWithScrollBarInput {
	width: 100%;
	max-height: 480px;
	max-width: 100%;
}
.myShopsTableBodyWithScrollBarInput td:nth-child(4) {
	/*position: -webkit-sticky;*/
	/*position: absolute;*/
	width: 30px;
}
.myShopsTableBodyWithScrollBarInput td:nth-child(5) {
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
}
.myShopsTableBodyWithScrollBarInput td:nth-child(6) {
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
}

.inputShopTable1{width: 100%; height: 100%; max-height: 45px; max-width: 80px;} .inputShopTable1:-ms-input-placeholder{text-overflow: ellipsis; max-width: 80px; font-size: 12pt;} .inputShopTable1:placeholder-shown{text-overflow: ellipsis; max-width: 80px; font-size: 12pt;}
.inputShopTable11{width: 100%; height: 100%; max-height: 45px; max-width: 80px; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}
.inputShopTable2{width: 100%; height: 100%; max-height: 45px; max-width: 238px;} .inputShopTable2:-ms-input-placeholder{text-overflow: ellipsis; max-width: 238px; min-width: 238px; font-size: 12pt;} .inputShopTable2:placeholder-shown{text-overflow: ellipsis; max-width: 238px; min-width: 238px; font-size: 12pt;}
.inputShopTable3{width: 99%; height: 100%; max-height: 45px;} .inputShopTable3:-ms-input-placeholder{text-overflow: ellipsis; max-width: 90px;  font-size: 12pt;} .inputShopTable3:placeholder-shown{text-overflow: ellipsis; max-width: 90px;  font-size: 12pt;}
.inputShopTable4{width: 99%; height: 100%; max-height: 45px; max-width: 90px;} .inputShopTable4:-ms-input-placeholder{text-overflow: ellipsis; max-width: 90px; min-width: 90px;  font-size: 12pt;} .inputShopTable4:placeholder-shown{text-overflow: ellipsis; max-width: 90px; min-width: 90px;  font-size: 12pt;}
.inputShopTable5{width: 99%; height: 100%; max-height: 45px; max-width: 90px;} .inputShopTable5:-ms-input-placeholder{text-overflow: ellipsis; max-width: 90px; min-width: 90px;  font-size: 12pt;} .inputShopTable5:placeholder-shown{text-overflow: ellipsis; max-width: 90px; min-width: 90px;  font-size: 12pt;}
.inputShopTable6{width: 100%; height: 100%; max-height: 45px; max-width: 126px; } .inputShopTable6:-ms-input-placeholder{text-overflow: ellipsis; max-width: 126px; min-width: 126px; font-size: 12pt; text-align: right;} .inputShopTable6:placeholder-shown{text-overflow: ellipsis; max-width: 126px; min-width: 126px; font-size: 12pt; text-align: right;}
.inputShopTable62 {width: 100%; height: 100%; max-height: 45px; max-width: 126px; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}


/*.myShopsTableHeadInput th:nth-child(1) {width: 48px;  max-width: 48px; min-width: 48px;  text-align: center;}*/
/*.myShopsTableHeadInput th:nth-child(2) {width: 80px;  max-width: 80px; min-width: 80px;  text-align: left;}*/
/*.myShopsTableHeadInput th:nth-child(3) {width: 244px; max-width: 244px; min-width: 244px;  text-align: left;}*/
/*.myShopsTableHeadInput th:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left;}*/
/*.myShopsTableHeadInput th:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; }*/
/*.myShopsTableHeadInput th:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left;}*/
/*.myShopsTableHeadInput th:nth-child(7) {width: 132px; max-width: 132px; min-width: 132px;   text-align: right;}*/

/*.myShopsTableHeadInput th:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: right;  padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px;  text-align: left; padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px;  text-align: left; padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; padding-left: 3px; }*/
/*.myShopsTableHeadInput th:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left;  padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;   text-align: right;  padding-left: 3px;}*/

.myShopsTableBodyWithoutScrollBarInput td:nth-child(1) {width: 44px;  max-width: 44px; min-width: 44px; text-align: right; padding-right: 3px; font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px; text-align: left;  font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(3) {width: 244px; max-width: 244px; min-width: 244px; text-align: left;  font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left;  padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px; text-align: right; font-size: 12pt;}

.myShopsTableBodyWithScrollBarInput td:nth-child(1) {width: 44px;  max-width: 44px; min-width: 44px; text-align: right; padding-right: 3px; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px;  text-align: left; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(3) {width: 244px; max-width: 244px; min-width: 244px; text-overflow: ellipsis;  text-align: left;  font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px; text-align: right; }


.myShopsTableInput td, .myShopsTableInput th {
	border: 0.5px solid #ddd;
}

.myShopsTableInput td {
	height: 45px;
}

.myShopsTableInput th {
	height: 30px;
}

.myShopsTableBodyWithoutScrollBarInput td {
	/*white-space: nowrap;*/
	text-overflow: ellipsis;
	/*overflow: hidden;*/
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
}

.myShopsTableBodyWithScrollBarInput td {
	white-space: nowrap;
	text-overflow: ellipsis;
	/*overflow: hidden;*/
}

.myShopsTableInput tr:nth-child(even){background-color: #f2f2f2;}

.myShopsTableInput tr:hover {background-color: #ddd;}

.myShopsTableInput th {
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	color: white;
}










.changeSettingLargeContainer {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	height: 255px;
	width:  25%;
	/*height: 190px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}
.changeSettingLargeContainerPassword {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	height: 295px;
	width:  25%;
	/*height: 190px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}

.changeSettingLargeContainerContent {
	position: absolute;
	margin: 3%;
	width: 94%;
	height: 90%;
}

.EditSettingsMessage {
	font-size: 27pt;
	margin-left: 5%;
}

.EditSettingsDeleteMyAccountMessage {
	font-size: 27pt;
	margin-left: 5%;
	margin-top: 3.5%;
	position: absolute;
}

.EditSettingsMessageDelete {
	margin-left: 5.5%;
	margin-top: 13%;
	margin-right: 7%;
	text-align: justify;
	position: absolute;
}
br {
	display: block;
	margin: 30px 0;
}

.changeSettingDeleteMyAccountContainer {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 2.5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	height: 305px;
	width:  27.5%;
	/*height: 190px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}


.changeSettingContainerName {
	vertical-align: middle;
	width: 40%;
	height: 100%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	position: absolute;
	margin-top: 5%;
	margin-left: 5%;
	font-size: 14pt;
}

.changeSettingDeleteMyAccountContainerName {
	vertical-align: middle;
	width: 40%;
	height: 100%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	position: absolute;
	margin-top: 35%;
	margin-left: 5%;
	font-size: 14pt;
}

.editSettingName {
	margin-top: 3%;
	width: 100%;
	height: 25px;
	/*position: absolute;*/
}

.inputFieldEditSettings {
	margin-top: 1.6%;
	height: 20px;
	font-size: 13.5pt;
	width: 100%;
}

.changeSettingContainerInput {
	vertical-align: middle;
	width: 45%;
	height: 20px;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	position: absolute;
	margin-left: 45%;
	margin-top: 5%;
}
.messageFormatPasswordMyProfile{
	position: absolute;
	font-size: 10pt;
	text-align: justify;
	width: 46%;
	margin-left: 45%;
	margin-top: 111px;
}

.changeSettingDeleteMyAccountContainerInput {
	vertical-align: middle;
	width: 45%;
	height: 20px;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	position: absolute;
	margin-left: 45%;
	margin-top: 36%;
}

.changeSettingSmallContainer {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	/*height: 22%;*/
	height: 190px;
	width:  22%;
	/*width:  418px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}

.changeSettingSmallContainerContent {
	position: absolute;
	margin: 3%;
	width: 94%;
	height: 90%;
}

.changeSettingContactPhoneContainer {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	/*height: 22%;*/
	height: 190px;
	width:  26%;
	/*width:  418px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}

.manageMyPSIContainer {
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	/*height: 22%;*/
	height: 190px;
	width:  26%;
	/*width:  418px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}

.buttonsEditMyEmail {
	position: absolute;
	margin-left: 11.6%;
	/*margin-top: 34%;*/
	margin-top: 153px;
	/*margin-top: 12.5%;*/
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}
.buttonsDeleteMyAccount {
	position: absolute;
	margin-left: 11.6%;
	/*margin-top: 34%;*/
	margin-top: 240px;
	/*margin-top: 12.5%;*/
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}
.buttonsEditMyPassword {
	position: absolute;
	margin-left: 30.6%;
	/*margin-top: 34%;*/
	margin-top: 193px;
	/*margin-top: 12.5%;*/
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}

.buttonsEditMyCurrency {
	position: absolute;
	margin-left: 12.1%;
	margin-top: 23%;
	/*margin-top: 5.8%;*/
	/*margin-top: 12.5%;*/
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}

.buttonsEditMyContactPhone {
	position: absolute;
	margin-left: 12.1%;
	/*margin-top: 22%;*/
	/*margin-top: 5.8%;*/
	margin-top: 18.4%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}


.EditSettingButtonLeft {
	position: absolute;
	width: 40%;
	height: 25px;
	margin-left: 10%;
	font-size: 13.0pt;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.EditSettingButtonLeft:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.EditSettingButtonRight {
	position: absolute;
	width: 40%;
	height: 25px;
	margin-left: 60%;
	font-size: 13.0pt;
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 13pt;
	background-color: rgba(10, 180, 10, 1);
}
.EditSettingButtonRight:hover {
	background-color: rgba(10, 180, 10, 0.65);
}

.EditSettingPassButtonLeft {
	position: absolute;
	width: 36%;
	height: 25px;
	margin-left: 0%;
	font-size: 13.0pt;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.EditSettingPassButtonLeft:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.EditSettingPassButtonRight {
	position: absolute;
	width: 36%;
	height: 25px;
	margin-left: 41%;
	font-size: 13.0pt;
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	background-color: rgba(10, 180, 10, 1);
}
.EditSettingPassButtonRight:hover {
	background-color: rgba(10, 180, 10, 0.65);
}

.buttonsEditMyShops {
	position: absolute;
	margin-left: 6.0%;
	margin-top: 73%;
	margin-top: 649px;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 85.8%;
	z-index: 1500;
}

.buttonsEditMyShopsLeft {
	position: absolute;
	width: 45%;
	margin-left: 5%;
	height: 32px;
	font-size: 18px;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.buttonsEditMyShopsLeft:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.buttonsEditMyShopsRight {
	position: absolute;
	width: 50%;
	margin-left: 55%;
	height: 32px;
	font-size: 18px;
	/*background-color: rgba(10, 165, 10, 1.0);*/
	color: white;
	/*border: 1px solid rgba(50, 50, 50, 1);*/
	/*background-color: rgba(125, 125, 125, 0.25);*/
	/*color: rgba(10, 130, 10, 0.85);*/
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 13pt;
	background-color: rgba(10, 180, 10, 1);
}
.buttonsEditMyShopsRight:hover {
	background-color: rgba(10, 180, 10, 0.65);
}


.ObscurerMyProfile {
	position:absolute !important;
	height: 100%;
	width: 100%;
	/*margin-top: -60px;*/
	/*border: solid 0.3em;*/
	border: none;
	background-color: rgba(105, 105, 105, 0.1);
	z-index: 200;
}
.ObscurerDeletion {
	position:absolute !important;
	height: 100%;
	width: 100%;
	/*margin-top: -60px;*/
	/*border: solid 0.3em;*/
	border: none;
	background-color: rgba(105, 105, 105, 0.3);
	z-index: 1100;
}

.messageWrongEmails {
	position: absolute;
	text-align: right;
	margin-left: -8%;
	margin-top: 30.0%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}
.messageWrongPasswords {
	position: absolute;
	text-align: right;
	margin-left: -8%;
	margin-top: 39.0%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}
.messageWrongPasswordsForDeleteWrongEmail {
	position: absolute;
	text-align: right;
	margin-left: -21%;
	margin-left: -8.5%;
	margin-top: 41.5%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}
.messageWrongEmailsForDelete {
	position: absolute;
	text-align: right;
	margin-left: -21%;
	margin-top: 39.0%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}

.inputFieldEditSettings2 {
	margin-top: 1.6%;
	height: 20px;
	font-size: 13.5pt;
	width: 100%;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}

.messageWrongContactPhone {
	position: absolute;
	text-align: right;
	margin-left: -8%;
	margin-top: 14.5%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}
.ErrorMessageEmployeeName {
	position: absolute;
	margin-left: 21%;
	margin-top: 10.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	z-index: 1500;
	/*border: 1px red solid;*/
}
.ErrorMessagesInMyAccount{
	position: absolute;
	margin-left: 77.55%;
	margin-top: 10.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	z-index: 1500;
	/*border: 1px red solid;*/
}
.ErrorMessageShopName {
	position: absolute;
	margin-left: 10.5%;
	margin-top: 10.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	z-index: 1500;
}
.ErrorMessagesInMyAccountText {
	margin: 0;
}
.ErrorMessagesInMyAccountShops {
	position: absolute;
	margin-left: 77.55%;
	margin-top: 10.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	z-index: 1500;
	/*border: 1px red solid;*/
}
.ErrorMessagesInMyAccountShopsText {
	margin: 0;
}


.MessageOnSaveButton {
	position: absolute;
	margin-left: 65%;
	margin-top: -2.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	width: 300px;
	text-align: right;
	color: red;
	font-size: 10pt;
	/*z-index: 7392486785;*/
	/*border: 1px red solid;*/
}

.legalTextContainersProfile {
	position: absolute;
	margin-top: 52.3%;
	width: 100%;
	height: 50px;
	background-color: #f2f2f2;;
}
.legalTextContainers1{
	position: absolute;
	margin-left: 5.3%;
	text-align: center;
	/*margin-top: -30%;*/
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.legalTextContainers1:hover {
	text-decoration: underline;
}
.legalTextContainers2{
	position: absolute;
	margin-left: 25.35%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers2:hover {
	text-decoration: underline;
}
.legalTextContainers3{
	position: absolute;
	margin-left: 47.5%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers3:hover {
	text-decoration: underline;
}
.legalTextContainers4{
	position: absolute;
	margin-left: 65.5%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers4:hover {
	text-decoration: underline;
}
.legalTextContainers5{
	position: absolute;
	margin-left: 87.2%;
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers5:hover {
	text-decoration: underline;
}

.legalTextContainers22{
	position: absolute;
	margin-left: 32.2%;
	margin-left: 19.5%;
	margin-left: 17.0%;
	/*margin-left: 36.5%;*/
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.legalTextContainers22:hover {
	text-decoration: underline;
}
.legalTextContainers32{
	position: absolute;
	margin-left: 61.2%;
	margin-left: 37.5%;
	margin-left: 32.0%;
	/*margin-left: 55%;*/
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.legalTextContainers32:hover {
	text-decoration: underline;
}
.legalTextContainers42{
	position: absolute;
	margin-left: 84.8%;
	margin-left: 53.0%;
	margin-left: 45.0%;
	/*margin-left: 19.0%;*/
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.legalTextContainers42:hover {
	text-decoration: underline;
}
.ContactSupport {
	position: absolute;
	margin-left: 84.8%;
	margin-left: 53.0%;
	margin-left: 75.0%;
	/*margin-left: 19.0%;*/
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.ContactSupport:hover {
	text-decoration: underline;
}

.TIDIName{
	position: absolute;
	margin-left: 87.8%;
	margin-left: 85.8%;
	/*margin-left: 72.5%;*/
	/*font-size: 10.0pt;*/
	text-align: left;
	width: 11%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
	font-size: 85%;
}
.licensesButton {
	position: absolute;
	margin-left: 72.5%;
	margin-left: 60.0%;
	/*margin-left: 68%;*/
	/*margin-top: -45%;*/
	text-align: left;
	width: 10%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
	font-size: 85%;
}
.licensesButton:hover {
	text-decoration: underline;
}

.showLegalTextBigProfile {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 60%;
	height: 55.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1200;
}
.showLegalTextSmallProfile {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 60%;
	height: 40.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextDeciderTitleDivSupport {
	margin-top: 18%;
	margin-left: 34.53%;
	/*margin-left: 25%;*/
	position: absolute;
	width: 30%;
	height: 120px;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.buttonCloseLegalText {
	left: 97.5%;
	top: -0.1%;
	/*left: 1210px;*/
	position: absolute;
	width: 30px;
	height: 23px;
}
/*.buttonCloseLegalTextButton {*/
/*	width: 100%;*/
/*	height: 100%;*/
/*	font-size: 11.5pt;*/
/*}*/
.showLegalTextDeciderText{
	margin-top: 2%;
	font-size: 10pt;
}
.showCookiesDetailsCloseButtonMyProfile{
	position: absolute;
	margin-left: 97.5%;
	margin-top: -6.7%;
	text-align: center;
	width: 30px;
	height: 23px;
	/*margin-top: 0.8%;*/
}

.buttonsEditMyCompany {
	/*height: 5%;*/
	height: 26px;
	width: 70%;
	position: absolute;
	/*bottom: 30%;*/
	/*top: 72%;*/
	/*top: 504px;*/
	top: 554px;
	top: 484px;
	top: 600px;
	/*top: 204px;*/
	left: 15.7%;
	/*border: 3px solid;*/
	/*border-color: red;*/
}

.disclaimerTFN {
	position: absolute;
	margin-top: 85%;
	width: 420px;
	font-size: 9pt;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationCompanyAndSettings {
	position: absolute;
	margin-top: 95%;
	width: 420px;
	font-size: 9pt;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationCompanyAndSettingsEdit {
	position: absolute;
	margin-top: 78%;
	width: 420px;
	font-size: 9pt;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationEmployees {
	position: absolute;
	margin-top: 4%;
	width: 815px;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationShops{
	position: absolute;
	margin-top: 7%;
	width: 815px;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationCurrency {
	position: absolute;
	margin-top: 14.4%;
	width: 345px;
	font-size: 8.5pt;
	margin-left: 5%;
	text-align: justify;
}
.accountDeletionSuccessful {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 8.0%;
	margin-top: 8%;
	border-color: coral;
	border: solid 0.1em;
	height: 115px;
	width:  16.5%;
	/*height: 190px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1200;
}
.accountDeletionSuccessfulText {
	margin-left: 10.5%;
	margin-top: 5%;
	margin-right: 7%;
	text-align: justify;
	position: absolute;
}
.accountDeletionSuccessfulButton {
	position: absolute;
	width: 40%;
	height: 25px;
	margin-left: 29%;
	margin-top: 65px;
	font-size: 13.0pt;
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 12pt;
	background-color: rgba(10, 180, 10, 1);
}
.accountDeletionSuccessfulButton:hover {
	background-color: rgba(10, 180, 10, 0.65);
}

.dropDownInMyProfileEmployeesTreatment {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 67px;
	top: -0px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInMyProfileEmployeesTreatmentEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 67px;
	margin-top: -45px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.dropDownInMyProfileContactNumber {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 37px;
	top: -0px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInMyProfileContactNumberEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 35px;
	margin-top: -45px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInMyProfilePersonGenre {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 215px;
	margin-top: -45px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInMyProfilePersonGenreEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 215px;
	margin-top: -45px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInMyProfileCurrency {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 155px;
	top: -0px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInMyProfileCurrencyEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 155px;
	margin-top: -45px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInMyProfileProfile {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 215px;
	top: -0px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.errorMessageWhenUpdatingProfile {
	margin-left:  42%;
	margin-top:   18.0%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 80px;
	/*height: 700px;*/
	width: 18%;
	position: absolute;
	z-index: 2000;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid black;
}
.errorMessageWhenUpdatingProfileText {
	margin-left: 10%;
	margin-top: 5%;
}

.errorWhenLoading {
	margin-left:  42%;
	margin-top:   18.0%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 80px;
	/*height: 700px;*/
	width: 18%;
	position: absolute;
	z-index: 2000;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid black;
}
.errorWhenLoadingText {
	margin-left: 10%;
	margin-top: 5%;
}

.BackgroundMyProfile{
	background-color: white;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1000;
}

.PasswordContainerWithEyeIcon4 {
	margin-bottom: 3.9%;
	height: 23px;
	width: 100%;
	/*border: 1px solid red;*/
}
.eyePasswordMyProfile {
	height: 20px;
	width: 20px;
	position: absolute;
	right: 0%;
	margin-top: -8.0%;
	margin-top: -31px;
	margin-top: -23px;

}
.PasswordContainerWithEyeIconMyProfile2 {
	width: 100%;
	/*height: 110%;*/
	height: 22px;
	font-size: 100%;
	/*border: 1px solid red;*/
}
.deleteMyAccountInputFields {
	width: 100%;
	height: 100%;
	font-size: 100%;
}
.eyePasswordMyProfile2 {
	height: 20px;
	width: 20px;
	position: absolute;
	right: 0;
	margin-top: -9.0%;
	margin-top: -23px;
	/*margin-right: 6%;*/
}
.deleteMyAccountInputFieldsToAccomodateScreen {
	position: absolute;
	margin-top: 550%;
}


.body {
	/*background-color: rgba(46,139,87, 0.1);*/
	background-color: rgba(255, 255, 255, 0.1);
	/*background-color: red;*/
}
.Sign_up_general_container {
	display: flex;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	width: 400px;
	height: 550px;
	height: 665px;
	/*width: 20%;*/
	/*height: 50%;*/
	background-color: #FFFFFF;
}

.Sign_up_company_container {
	display: flex;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	/*width: 790px;*/
	width: 701px;
	/*height: 675px;*/
	height: 640px;
	background-color: #FFFFFF;
}
.Sign_up_company_container_Client {
	display: flex;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	width: 400px;
	height: 550px;
	/*height: 615px;*/
	/*width: 20%;*/
	/*height: 50%;*/
	background-color: #FFFFFF;
}

.Sign_up_form {
	margin-top: 4%;
	width: 73%;
	height: 35%;
}

.legalTextContainersSignUp {
	position: absolute;
	/*margin-top: 52.3%;*/
	bottom: 0;
	width: 100%;
	height: 50px;
	background-color: #f2f2f2;
}

.Sign_up_input_field {
	width: 98%;
	margin-bottom: 3%;
	/*height: 13%;*/
	height: 30px;
	border: solid 1px black;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	/*font-size: 13.5pt;*/
	font-size: 13pt;
}

.Sign_up_field_name {
	left: 90px;
	height: 10%;
	/*width: 5px;*/
	margin-bottom: 1%;
	font-size: 110%;
	font-size: 14pt;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
}
.messageFormatPassword{
	position: absolute;
	font-size: 10pt;
	text-align: justify;
	width: 73%;
}

.Sign_up_input_field_company_profile {
	width: 98.8%;
	margin-bottom: 3%;
	height: 15%;
	border: solid 0.05em;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	/*font-size: 13.5pt;*/
	font-size: 13pt;
}
.Sign_up_input_field_company_profile::-webkit-input-placeholder {
	color: black;
}
.Sign_up_input_field_company_profile:-ms-input-placeholder {
	color: black;
}
.Sign_up_input_field_company_profile::placeholder {
	color: black;
}

.Sign_up_log_in_text {
	text-align: left;
	font-size: 85%;
	position: absolute;
	margin-top: 139.0%;
}

.Sign_up_button {
	width: 73%;
	/*height: 6%;*/
	height: 33px;
	position: absolute;
	margin-top: 125.5%;
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	font-size: 14pt;
	color: white;
	border-color: black;
	background-color: rgba(10, 130, 10, 0.85);
}
.Sign_up_button:hover {
	background-color: rgba(10, 130, 10, 0.65);
}
.Sign_up_button_log_in {
	width: 73%;
	/*height: 6%;*/
	height: 33px;
	position: absolute;
	/*margin-top: 115.5%;*/
	margin-top: 59.2%;
	margin-left: -36.5%;
	/*margin-top: 1%;*/
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	font-size: 14pt;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.Sign_up_button_log_in:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.signUpCheckboxes{
	position: absolute;
	margin-top: 75%;
	margin-top: 87%;
	/*border: 1px solid blue;*/
	width: 75%;
	/*margin-left: 12.5%;*/
	/*margin-left: -7.5%;*/
	/*margin-top: 79%;*/
	/*margin-left: -7%;*/
}
@media screen and (max-width: 600px) {
	.signUpCheckboxes {
		margin-left: -3%;
	}
}
@media screen and (max-width: 450px) {
	.signUpCheckboxes {
		margin-left: -3%;
		margin-top: 88%;
	}
}

.Horizontal_lines_container_SignUp {
	width: 177px;
	height: 10px;
	position: absolute;
	margin-top: 105%;
	margin-top: 138.5%;
	/*border: 1px solid red;*/
}

.checkboxCompany {
	width: 90%;
	width: 100%;
	margin-top: 1.2%;
	font-size: 8pt;
	/*margin-left: 15%;*/
	/*border: 1px solid red;*/
}

.Sign_up_form_verification {
	margin-top: 2%;
	width: 73%;
	height: 35%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
}

.checkboxCompanyText {
	font-size: 110%;
	font-size: 12pt;
}
.checkboxCompanyTextGTC {
	font-size: 110%;
	font-size: 12pt;
	/*width: 20px;*/

	/*margin-left: 20%;*/
	/*position: absolute;*/
}
.checkboxTextSignUp{
	width: 113%;
	width: 90%;
	margin-left: 7.8%;
	margin-top: -6%;
	/*border: 1px solid red;*/
	text-align: justify;
}
.checkboxCompanyTextBlue{
	font-size: 12pt;
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}

.containerDualButtons {
	width: 73%;
	height: 8%;
}

.validateCodeButton {
	width: 73%;
	height: 6%;
	margin-top: 115.3%;
	margin-top: 128.3%;
	margin-top: 140%;
	font-size: 110%;
	border-radius: 3px;
	position: absolute;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.validateCodeButton:hover {
	background-color: rgba(10, 130, 10, 0.65);
}
/*.validateCodeButton:disabled {*/
/*	!*background-color: rgba(10, 180, 10, 1);*!*/
/*	text-shadow: rgba(150, 150, 150, 1);*/
/*}*/

.sendAgainButtonSignUp {
	position: absolute;
	margin-top: 55%;
	margin-left: 56%;
	border: none;
	background-color: rgba(255, 255, 255, 1);
	font-size: 85%;
	font-size: 10.5pt;
	text-decoration: underline;
	cursor: pointer;
}
@media screen and (max-width: 600px) {
	.sendAgainButtonSignUp {
		margin-left: 27%;
	}
	.legalTextContainersSignUp {
		bottom: -35px;
	}
}
@media screen and (max-width: 450px) {
	.sendAgainButtonSignUp {
		margin-left: 31%;
	}
	.legalTextContainersSignUp {
		bottom: -35px;
	}
}

.sendAgainButtonSignUpLogIn {
	position: absolute;
	margin-top: 60%;
	margin-left: 58%;
	border: none;
	background-color: rgba(255, 255, 255, 1);
	font-size: 85%;
	text-decoration: underline;
	cursor: pointer;
}

.Sign_up_button_link{
	width: 100%;
	height: 100%;
	font-size: 100%;
	background-color: rgba(46,139,87, 0.25);
	flex-direction: column;
	align-items: center;
	border: solid 0;
}



.SignUpCompanyTable{
	border-collapse: collapse;
	/*border: 1px solid;*/
	width: 100%;
	/*height: 20%;*/
}

.SignUpCompanyCol1 {
	width: 50%;
}

.SignUpCompanyCol2 {
	width: 50%;
}

.Sign_up_company_2_columns_field_name_left_column {
	width: 99%;
	/*height: 37%;*/
	font-size: 110%;
	text-align: left;
}

.Sign_up_company_2_columns_field_name_right_column {
	/*height: 37%;*/
	width: 92%;
	margin-left: 2%;
	font-size: 110%;
	text-align: left;
}

.Sign_up_company_2_columns_input_field_left_column {
	width: 96%;
	margin-bottom: 5%;
	margin-right: 4%;
	height: 15%;
	border: solid 0.05em;
	border-radius: 3px;
	/*font-size: 110%;*/
	font-size: 13pt;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.Sign_up_company_2_columns_input_field_left_column::-webkit-input-placeholder {
	color: black;
}
.Sign_up_company_2_columns_input_field_left_column:-ms-input-placeholder {
	color: black;
}
.Sign_up_company_2_columns_input_field_left_column::placeholder {
	color: black;
}

.Sign_up_company_2_columns_input_field_right_column {
	width: 96%;
	margin-bottom: 5%;
	margin-left: 2%;
	height: 15%;
	border: solid 0.05em;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	font-size: 13pt;
}
.Sign_up_company_2_columns_input_field_right_column::-webkit-input-placeholder {
	color: black;
}
.Sign_up_company_2_columns_input_field_right_column:-ms-input-placeholder {
	color: black;
}
.Sign_up_company_2_columns_input_field_right_column::placeholder {
	color: black;
}

.Sign_up_company_2_columns_input_field_button {
	width: 99.9%;
	height: 100%;
	height: 25px;
	border: solid 0.05em;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	text-align: left;
	font-size: 13pt;
}

.Sign_up_company_2_columns_input_field_left_column_no_border_left {
	/*width: 93.5%;*/
	width: 98%;
	margin-bottom: 5%;
	margin-right: 2%;
	height: 15%;
	border-radius: 3px;
	font-size: 110%;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}


.Sign_up_company_2_columns_input_field_left_column_no_border_right {
	/*width: 93.5%;*/
	width: 98%;
	margin-bottom: 5%;
	/*margin-left: 5%;*/
	margin-left: 2%;
	height: 15%;
	border-radius: 3px;
	font-size: 110%;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}



.companySignUpLeftPartitioned {
	display: flex;
	flex-direction: row;
}

.Sign_up_input_field_partitioned_phone_1 {
	width: 25%;
	height: 6%;
	margin-right: 3.9%;
	margin-bottom: 5%;
	max-width: 20%;
	max-height: 6%;
	font-size: 110%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
}

.Sign_up_input_field_partitioned_phone_2 {
	width: 72.0%;
	height: 6%;
	/*margin-left: 5%;*/
	margin-bottom: 5%;
	font-size: 110%;
	border: solid 0.05em;
	border-radius: 3px;
}
.Sign_up_input_field_partitioned_phone_2::-webkit-input-placeholder {
	color: black;
}
.Sign_up_input_field_partitioned_phone_2:-ms-input-placeholder {
	color: black;
}
.Sign_up_input_field_partitioned_phone_2::placeholder {
	color: black;
}
.Sign_up_input_field_partitioned_phone_2Red {
	width: 72.0%;
	height: 6%;
	/*margin-left: 5%;*/
	margin-bottom: 5%;
	font-size: 110%;

	border-radius: 3px;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}
.Sign_up_input_field_partitioned_phone_2Red::-webkit-input-placeholder {
	color: black;
}
.Sign_up_input_field_partitioned_phone_2Red:-ms-input-placeholder {
	color: black;
}
.Sign_up_input_field_partitioned_phone_2Red::placeholder {
	color: black;
}


.dropdownMenu {
	width: 43.4%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
}
.dropdownMenu a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
}
.dropdownMenuTreatment {
	width: 12.7%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	text-align: left;
}
.dropdownMenuTreatment a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
}

.dropdownMenuCountry {
	width: 30.9%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	text-align: left;
}
.dropdownMenuCountry a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
	text-align: left;
}

.dropdownMenuRegion {
	width: 30.9%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1200;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	text-align: left;
	margin-left: -10.5%;
}
.dropdownMenuRegion a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
}

.dropdownMenuProvince {
	width: 30.9%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	text-align: left;
	margin-left: -21.1%;
}
.dropdownMenuProvince a {
	text-decoration: none;
	padding-left: 3px;
	color: black;
}

.dropdownMenuPhonePrefix {
	width: 8.7%;
	max-height: 30%;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
}
.dropdownMenuPhonePrefix a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
	font-size: 12.0pt;
}
.dropdownMenuSettingsCompanySignUp{
	width: 43.4%;
	max-height: 30.5%;
	/*max-height: 200px;*/
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	/*margin-top:24px;*/
	font-size: 12pt;
}
.dropdownMenuSettingsCompanySignUp a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}


.MandatoryFieldCompany{
	font-size: 75%;
	vertical-align: super;
	margin-top: 0px;
	position: absolute;
	/*top: 85%;*/
}
.MandatoryFieldCompanySecondAsterisk{
	font-size: 75%;
	vertical-align: super;
	margin-top: 16px;
	position: absolute;
	/*top: 85%;*/
}
.MandatoryFieldCompany2 {
	font-size: 75%;
	vertical-align: super;
	margin-top: 16px;
	margin-left: 12px;
	position: absolute;
	/*top: 85%;*/
}

.RenderCompanyProfileCompany{
	width: 90%;
	height: 67%;
	/*border: solid 0.05em;*/
}

.RenderCompanyProfileCompanyForm{
	width: 90%;
	height: 67%;
	/*border: solid 0.05em;*/
}


.stepShopAndEmployeesDiv {
	margin-bottom: 1.5%;
	margin-top: 2%;
	/*position: absolute;*/
}

.stepShopAndEmployees{
	height: 5%;
	/*font-size: 100%;*/
	font-size: 13pt;
}

.VerificationCodeSentMessage {
	width: 74%;
	margin-top: 5%;
	font-size: 85%;
	text-align: justify;
}

.HR_line_Sign_up {
	height: 0.5px;
	margin-top: +15px;
	/*left: 60px;
	top:160px;*/
	border: 0;
	width: 100px;
	color: #C0C0C0;
	background-color: #C0C0C0;
}

.LogInFromSignUp {
	border-color: coral;
	border: solid 0.3em;
}

.Sign_up_back_title_container{
	width: 100%;
	height: 4.6%;
	margin-top: 5px;
	font-size: 85%;
	/*border: solid 0.1em red;*/
	/*border-radius: 5px;*/
}


.RenderCompanyProfileButtons {
	height: 5%;
	width: 90%;
	display: flex;
	flex-direction: row;
	margin-top: 3%;
	/*border-color: black;*/
	/*border: solid 0.05em;*/
}

.RenderCompanyProfileButtons_Left {
	width: 49%;
	height: 100%;
	margin-right: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	border: none;
	font-size: 100%;
	/*background-color: rgba(46,139,87, 0.35);*/
}

.RenderCompanyProfileButtons_ButtonSaveAndContinue {
	width: 100%;
	height: 100%;
	border-radius: 3px;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
	font-size: 13pt;
}
.RenderCompanyProfileButtons_ButtonSaveAndContinue:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.RenderCompanyProfileButtons_Right {
	width: 49%;
	height: 100%;
	margin-left: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	/*font-size: 110%;*/
	/*background-color: rgba(46,139,87, 0.35);*/
}

.RenderCompanyProfileButtons_ButtonContinue {
	width: 100%;
	height: 100%;
	/*background-color: rgba(46,139,87, 0.6);*/
	border-radius: 3px;
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 13pt;
	background-color: rgba(10, 130, 10, 0.85);
	/*background-color: rgba(190, 190, 190, 1);*/
	/*background-color: rgba(10, 180, 10, 1);*/
}
.RenderCompanyProfileButtons_ButtonContinue:hover {
	background-color: rgba(10, 130, 10, 0.65);
}
.messageBeforeContinueButtonWorks {
	position: absolute;
	margin-top: -17px;
	margin-left: 50%;
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}


.RenderCompanyProfileButtons_Center {
	height: 5%;
	width: 80%;
	display: flex;
	flex-direction: row;
	border-color: black;
	border: solid 0.05em;
}


.Back_button_sign_up {
	width: 55px;
	height: 100%;
	margin-bottom: 10px;
	/*margin-left: 10px;*/
	margin-left: 0.5%;
	border: none;
	font-size: 125%;
	background-color: rgba(255, 255, 255, 1);
}

.Sign_up_title_text_in_button{
	font-size: 300%;
	margin-right: 10%;
}

.Sign_up_title_container {

}

.Employees {
	text-align: center;
	width: 100%;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	border: none;
	/*border: 3px solid #ddd;*/
	height: 82%;
	table-layout:fixed;
	display: block;
	font-size: 80%;
}

.Employees th {
	/*border: 0.5px solid #ddd;*/
	/*padding: 3px;*/
	/*height: 10%;*/
}


.RenderCompanyProfileEmployeesTable {
	text-align: center;
	width: 100%;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	/*border: 3px solid #ddd;*/
	/*height: 95%;*/
	height: 80%;
	table-layout:fixed;
	display: block;
	font-size: 80%;
	font-size: 12pt;
}

.RenderCompanyProfileEmployeesTableBody{
	display: block;
	height: 95%;
	/*height: 50px;*/
	overflow-y: auto;
	overflow-x: hidden;
}

.RenderCompanyProfileEmployeesTableHead{
}

.RenderCompanyProfileEmployeesTableHead th {
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}


.RenderCompanyProfileEmployeesTableHead tr {
}


.RenderCompanyProfileEmployeesTable td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 0px;
	height: 10%;
}


.RenderCompanyProfileEmployeesTableHead th:nth-child(1) { width: 27px; text-align: right; padding-right: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(1) { width: 30px; text-align: right; padding-right: 3px; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(2) { width: 85px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(2) { width: 157px; text-align: left; padding-left: 3px; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(3) { width: 110px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(3) { width: 116px;  text-align: left; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(4) { width: 140px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(4) { width: 146px; text-align: left; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(5) { width: 75px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(5) { width: 81px; text-align: left; }

.RenderCompanyProfileEmployeesTableHead th:nth-child(6) { width: 160px; text-align: left; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableBody td:nth-child(6) { width: 150px; text-align: left; }


.RenderCompanyProfileEmployeesTable tr:nth-child(even){background-color: #f2f2f2;}

.RenderCompanyProfileEmployeesTable tr:hover {background-color: #ddd;}

.RenderCompanyProfileEmployeesTable th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	background-color: #4CAF50;
	color: white;
}

.dropdownInSettingsSignUp {
	width: 100%;
	height: 100%;
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 13pt;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	text-align: left;
}
.inputSaleTableSignUp {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
}

.inputSaleTableSignUpAddress {
	width: 100%;
	/*height: 110%;*/
	height: 20px;
	font-size: 100%;
	/*max-width: 80px;*/
}
.inputSaleTableTreatment {
	width: 91px;
	/*height: 110%;*/
	height: 27px;
	font-size: 100%;
	/*max-width: 80px;*/
}
.inputSaleTableCountry {
	width: 70px;
	/*margin-left: 4px;*/
	/*padding-left: 2px;*/
	/*margin-top: -2px;*/
	/*height: 110%;*/
	height: 27px;
	font-size: 90%;
	/*max-width: 80px;*/
}
.inputSaleTableCountryContent {
	width: 90%;
	margin-top: 5%;
	/*width: 10%;*/
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 90%;
}
.inputSaleTableRegion {
	width: 70px;
	/*padding-left: -2px;*/
	/*margin-top: -2.2px;*/
	/*margin-left: 1px;*/
	/*height: 110%;*/
	height: 27px;
	font-size: 90%;
	/*max-width: 80px;*/
}
.inputSaleTableProvince {
	width: 70px;
	/*margin-top: -2.2px;*/
	/*padding-left: 2px;*/
	/*height: 110%;*/
	height: 27px;
	font-size: 90%;
	/*max-width: 80px;*/
}


.RenderCompanyProfileEmployeesTableLowNumberOfEmployees {
	text-align: center;
	width: 100%;
	border-collapse: collapse;
	/*border: 3px solid #ddd;*/
	/*height: 95%;*/
	height: 80%;
	table-layout:fixed;
	display: block;
	font-size: 12pt;
}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody{
	display: block;
	height: 100%;
	/*height: 50px;*/
	overflow-y: auto;
	overflow-x: hidden;
}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead{
}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th {
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}


.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead tr {
}


.RenderCompanyProfileEmployeesTableLowNumberOfEmployees td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 0px;
	height: 10%;
}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(1) { width: 27px; text-align: center; padding-right: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(1) { width: 30px; text-align: right; padding-right: 3px; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(2) { width: 85px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(2) { width: 157px; text-align: left; padding-left: 3px; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(3) { width: 110px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(3) { width: 116px;  text-align: left; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(4) { width: 140px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(4) { width: 146px; text-align: left; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(5) { width: 75px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(5) { width: 81px; text-align: left; }

.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead th:nth-child(6) { width: 160px; text-align: center; padding-left: 3px; }
.RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody td:nth-child(6) { width: 165px; text-align: left; }


.RenderCompanyProfileEmployeesTableLowNumberOfEmployees tr:nth-child(even){background-color: #f2f2f2;}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployees tr:hover {background-color: #ddd;}

.RenderCompanyProfileEmployeesTableLowNumberOfEmployees th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	/*background-color: rgba(10, 180, 10, 1);*/
	background-color: rgba(10, 130, 10, 0.85);
	/*background-color: rgba(170, 170, 170, 1);*/
	color: white;
}


.Employees td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}

.Employees tr:nth-child(even){background-color: #f2f2f2;}

.Employees tr:hover {background-color: #ddd;}

.Employees th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	background-color: #4CAF50;
	color: white;
}



.RenderCompanyProfileShopsTable {
	text-align: center;
	width: 100%;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	/*border: 3px solid #ddd;*/
	/*height: 95%;*/
	height: 82%;
	table-layout:fixed;
	display: block;
	font-size: 80%;
	font-size: 12pt;
}

.RenderCompanyProfileShopsTableBody{
	display: block;
	height: 100%;
	/*height: 50px;*/
	overflow-y: auto;
	overflow-x: hidden;
	height: 95%;
}

.RenderCompanyProfileShopsTableHead{
}

.RenderCompanyProfileShopsTableHead th {
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}


.RenderCompanyProfileShopsTableHead tr {
}


.RenderCompanyProfileShopsTable td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 0px;
	height: 10%;
}


.RenderCompanyProfileShopsTableHead th:nth-child(1) { width: 27px; text-align: right; padding-right: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(1) { width: 30px;  text-align: right; padding-right: 3px;}

.RenderCompanyProfileShopsTableHead th:nth-child(2) { width: 90px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(2) { width: 95px; text-align: left; }

.RenderCompanyProfileShopsTableHead th:nth-child(3) { width: 155px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(3) { width: 159px; }

.RenderCompanyProfileShopsTableHead th:nth-child(4) { width: 65px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(4) { width: 73px; padding-left: 3px;}

.RenderCompanyProfileShopsTableHead th:nth-child(5) { width: 65px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(5) { width: 73px; padding-left: 3px;}

.RenderCompanyProfileShopsTableHead th:nth-child(6) { width: 65px; text-align: left; padding-left: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(6) { width: 73px; padding-left: 3px;}

.RenderCompanyProfileShopsTableHead th:nth-child(7) { width: 115px; text-align: right; padding-right: 3px;}
.RenderCompanyProfileShopsTableBody td:nth-child(7) { width: 102px; text-align: right; padding-right: 3px;}


.RenderCompanyProfileShopsTable tr:nth-child(even){background-color: #f2f2f2;}

.RenderCompanyProfileShopsTable tr:hover {background-color: #ddd;}

.RenderCompanyProfileShopsTable th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	background-color: #4CAF50;
	color: white;
}





.RenderCompanyProfileShopsTableLowNumberOfShops {
	text-align: center;
	width: 100%;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	/*border: 3px solid #ddd;*/
	/*height: 95%;*/
	height: 82%;
	table-layout:fixed;
	display: block;
	/*font-size: 80%;*/
	font-size: 12pt;
}

.RenderCompanyProfileShopsTableLowNumberOfShopsBody{
	display: block;
	height: 100%;
	/*height: 50px;*/
	overflow-y: auto;
	overflow-x: hidden;
}

.RenderCompanyProfileShopsTableLowNumberOfShopsHead{
}

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th {
	border: 0.5px solid #ddd;
	padding: 3px;
	height: 10%;
}


.RenderCompanyProfileShopsTableLowNumberOfShopsHead tr {
}


.RenderCompanyProfileShopsTableLowNumberOfShops td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 0.5px solid #ddd;
	padding: 0px;
	height: 10%;
}

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(1) { width: 27px; text-align: center; padding-right: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(1) { width: 30px;  text-align: right; padding-right: 3px;}

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(2) { width: 90px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(2) { width: 99px; text-align: left; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(3) { width: 155px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(3) { width: 158px; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(4) { width: 65px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(4) { width: 70px; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(5) { width: 65px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(5) { width: 70px; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(6) { width: 65px; text-align: center; padding-left: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(6) { width: 70px; }

.RenderCompanyProfileShopsTableLowNumberOfShopsHead th:nth-child(7) { width: 115px; text-align: center; padding-right: 3px;}
.RenderCompanyProfileShopsTableLowNumberOfShopsBody td:nth-child(7) { width: 115px; text-align: right; padding-right: 3px; }


.RenderCompanyProfileShopsTableLowNumberOfShops tr:nth-child(even){background-color: #f2f2f2;}

.RenderCompanyProfileShopsTableLowNumberOfShops tr:hover {background-color: #ddd;}

.RenderCompanyProfileShopsTableLowNumberOfShops th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	background-color: rgba(10, 130, 10, 0.85);
	color: white;
}



.Sign_up_title_text {
	font-size: 300%;
	font-size: 36pt;
	/*color: rgba(10, 180, 10, 1);*/
}

.containerRowOfNumberOfCurrentState{
	width: 45%;
	height: 5%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: row;
	justify-content: space-between;
}

.numberInsideFlag {

}

.Flag_complete_step {
	background-color: rgba(46,139,87, 0.6);
	height: 20px;
	width: 20px;
	border-radius: 80px;
	border: solid 2px;
	border-color: rgba(46,139,87, 0.6);
	background-color: rgba(10, 180, 10, 1);
	background-color: rgba(10, 130, 10, 0.85);
	color: white;
}

.Flag_incomplete_step {
	background-color: rgba(255, 255, 255, 1);
	height: 20px;
	width: 20px;
	border-radius: 80px;
	border: solid 2px;
	border-color: rgba(125, 125, 125, 1);
	color: rgba(125, 125, 125, 1);
}


.Flag_current_step {
	background-color: rgba(255, 255, 255, 1);
	height: 20px;
	width: 20px;
	border-radius: 80px;
	border: solid 2px;
	border-color: rgba(46,139,87, 0.6);
	border-color: rgba(10, 130, 10, 0.85);
	color: black;
}
.numberInsideCircleContainer {
	position: absolute;
	margin-top: 2px;
	margin-left: 6px;
}

.sentenceTermsAndConditions {
	width: 74%;
	margin-top: 8%;
	font-size: 85%;
	text-align: justify;
}

.TermsAndConditionsTextContainerClose {
	/*top: -300px;*/
	/*width: 100%;*/
	/*left:200px;*/
	/*height: 38.5px;*/
	/*position: absolute;*/
}

.TermsAndConditionsTextContainer {
	display: flex;
	position: fixed;
	top: 10%;
	left: 15%;
	/*transform: translate(-50%, -50%);*/
	flex-direction: column;
	align-items: center;
	/*top: 190px;*/
	/*left: 285px;*/
	/*position: absolute;*/
	border-color: coral;
	border: solid 0.3em;
	height: 70%;
	width: 70%;
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}


.RenderPersonProfile {
	margin-top: 4%;
	width: 73%;
	height: 80%;
	/*border: solid 0.05em red;*/
}

.Sign_up_input_field_person {
	left: 90px;
	/*height: 6%;*/
	height: 27px;
	width: 98%;
	margin-bottom: 5%;
	font-size: 110%;
	font-size: 13pt;
	border: solid 0.05em;
	border-radius: 3px;
	/*-webkit-border-radius: 3px;*/
	/*-moz-border-radius: 3px;*/
}

.Sign_up_input_field_person_date {
	left: 90px;
	/*height: 6%;*/
	height: 27px;
	width: 98%;
	margin-bottom: 5%;
	font-size: 110%;
	font-size: 13pt;
	border: solid 0.05em;
	border-radius: 3px;
	/*-webkit-border-radius: 3px;*/
	/*-moz-border-radius: 3px;*/
}

@media screen and (max-width: 450px) {
	.Sign_up_input_field_person_date {
		/*margin-left: 31%;*/
		width: 286px;
		height: 34px;
	}
}



.companySignUpLeftPartitionedPerson {
	display: flex;
	flex-direction: row;
	height: 6%;
	width: 100%;
	margin-bottom: 5%;
	border-radius: 3px;
	/*border: solid 0.05em;*/
}

.Sign_up_input_field_partitioned_person_phone_1 {
	width: 18%;
	height: 110%;
	margin-right: 6%;
	margin-bottom: 5%;
	max-width: 18%;
	font-size: 110%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
}

.Sign_up_input_field_partitioned_person_phone_2 {
	width: 80%;
	height: 100%;
	/*margin-left: 5%;*/
	margin-bottom: 5%;
	font-size: 110%;
	border: solid 0.05em;
	border-radius: 3px;
}

.checkboxGenres {
	margin-bottom: 5%;
}

.checkboxGenresTable {

}

.checkboxGenresCol1 {

	width: 18%;
}

.checkboxGenresCol2 {
	width: 18%;
}

.checkboxGenresCol3 {
	width: 24%;
}

.checkboxCircular {
	width: 1.3em;
	height: 1.3em;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	-webkit-appearance: caret;
	outline: none;
	cursor: pointer;
}

.RenderPersonalProfileButton {
	position: absolute;
	margin-top: 115%;
	height: 6%;
	width: 73%;
	margin-left: 1%;
	display: flex;
	flex-direction: row;
	/*border-color: black;*/
	/*border: solid 0.05em;*/
}

.Sign_up_button_person {
	height: 100%;
	width: 100%;
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	/*background-color: rgba(46,139,87, 0.35);*/
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.Sign_up_button_person:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.popupContinue {
	position: absolute;
	margin-top: 20%;
	margin-left: 36.5%;
	width: 500px;
	height: 200px;
	z-index: 1000;
	background-color: rgba(255, 255, 255, 1);
	border: solid 0.05em;
}

.popupContinueInterior {
	text-align: justify;
	margin: 7%;
	font-size: 14pt;
}
.boldWithout{
	font-weight: bold;
}
.popupContinueButtons {
	top: 75%;
	width: 84%;
	position: absolute;
	height: 25px;
}
.popupContinueButtonsCancel {
	width: 49%;
	height: 100%;
	margin-right: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	font-size: 14pt;
	/*font-size: 100%;*/
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.popupContinueButtonsCancel:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.popupContinueButtonsConfirm {
	width: 49%;
	height: 100%;
	margin-left: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	font-size: 14pt;
	background-color: rgba(125, 125, 125, 0.25);
	border: 1px solid rgba(50, 50, 50, 1);
	color: rgba(10, 130, 10, 0.85);
	font-size: 13pt;
}
.popupContinueButtonsConfirm:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}


.popupConfirmSaveAndContinue {
	position: absolute;
	margin-top: 20%;
	margin-left: 35.5%;
	width: 27%;
	height: 18%;
	z-index: 1000;
	background-color: rgba(255, 255, 255, 1);
	border: solid 0.05em;
}


.ObscurerSignUp {
	position: absolute ;
	height: 100%;
	width: 100%;
	/*margin-left: -8px;*/
	/*margin-top: -8px;*/
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	z-index: 200;
}
.ObscurerLegalTexts {
	position: absolute ;
	height: 2300%;
	width: 3000%;
	/*margin-left: -390%;*/
	margin-top: -1000%;
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	z-index: 200;
}

.popupConfirmSaveAndContinueInterior {
	margin: 7%;
	text-align: justify;
	font-size: 14pt;
}

.popupConfirmSaveAndContinueButtons {
	top: 72%;
	width: 86%;
	height: 25px;
	position: absolute;
}

.popupConfirmSaveAndContinueCancel {
	width: 49%;
	height: 100%;
	margin-right: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px;
	border: none;
	font-size: 100%;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);

}
.popupConfirmSaveAndContinueCancel:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.popupConfirmSaveAndContinueConfirm {
	width: 49%;
	height: 100%;
	margin-left: 1%;
	/*border: solid 0.05em;*/
	border-radius: 3px ;
	border: none;
	font-size: 100%;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.popupConfirmSaveAndContinueConfirm:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}


.dropDownInSignUp {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: absolute;
	margin-left: 283px;
	margin-top: 6px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInSignUpPhonePrefix {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: absolute;
	margin-left: 38px;
	margin-top: 6px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInSignUpTreatment {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 67px;
	top: -20px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInSignUpTreatmentEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 67px;
	margin-top: -35px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.dropDownInSignUpShop {
	/*width: 0pt;*/
	/*height: 0pt;*/
	/*border-style: solid;*/
	/*border-width: 0 5px 10px 5px;*/
	/*border-color: transparent transparent black transparent;*/
	/*!*position: absolute;*!*/
	/*margin-left: 50px;*/
	/*margin-top: -37px;*/
	/*transform: rotate(180deg);*/

	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	border-color: red;
	position: relative;
	/*margin-left: 50px;*/
	/*top: -47px;*/
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);

	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 50px;
	top: -18px;
	transform: rotate(180deg);
}
.dropDownInSignUpShopEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 49px;
	margin-top: -2px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.messageEmailIsNotValid {
	position: absolute;
	margin-left: 99%;
	/*margin-left: 0%;*/
	margin-top: 39.5%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}

.messagePasswordNotProvided {
	position: absolute;
	margin-left: 0%;
	margin-top: 72.3%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: right;
	color: red;
	font-size: 10pt;
}

.messageIncorrectPasswordFormat {
	position: absolute;
	margin-left: 0%;
	margin-top: 72.3%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: right;
	color: red;
	font-size: 10pt;
}

.messagePasswordsToBeTheSame {
	position: absolute;
	margin-left: 60%;
	/*margin-left: 0%;*/
	margin-top: 72.3%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.AlreadyExistingAccount {
	position: absolute;
	margin-left: 0%;
	/*margin-left: 0%;*/
	margin-top: 72.3%;
	margin-top: 108.5%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}
.messageUntickedCheckboxes{
	position: absolute;
	margin-left: 11%;
	margin-top: 108.3%;
	margin-top: 117.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageStringInVerificationCode {
	position: absolute;
	margin-left: 1%;
	margin-top: 51.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageStringInVerificationCodeFromLogIn {
	position: absolute;
	margin-left: 1%;
	margin-top: 58.2%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageIncorrectVerificationCodeSignUp {
	position: absolute;
	margin-left: 1%;
	margin-top: 51.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageIncorrectVerificationCodeSignUpFromLogIn {
	position: absolute;
	margin-left: 1%;
	margin-top: 56.5%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}
.messageUnknownErrorInSignUp2SignUp {
	position: absolute;
	/*margin-left: 1%;*/
	margin-top: 57.5%;
	/*background-color: green;*/
	width: 73.0%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}

.MessageWrongContactPhone {
	position: absolute;
	margin-left: 11.0%;
	margin-top: -76px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}

.checkThereIsAnyInputValidationErrorInShops {
	position: absolute;
	margin-left: 46.0%;
	margin-top: -15px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}
.checkThereIsAnyInputValidationErrorInEmployees {
	position: absolute;
	margin-left: 46.0%;
	margin-top: -15px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}
.checkThereIsAnyInputValidationErrorInEmployeesName {
	position: absolute;
	margin-left: 18.0%;
	margin-top: -15px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}
.checkThereIsAnyInputValidationErrorInShopsName {
	position: absolute;
	margin-left: 5.0%;
	margin-top: -15px;
	/*background-color: green;*/
	/*width: 71.5%;*/
	text-align: justify;
	color: red;
	font-size: 9.5pt;
}


.showLegalTextBigSignUp {
	margin-top: 10%;
	/*margin-left: 20%;*/
	position: absolute;
	width: 250%;
	height: 85.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextSmallSignUp {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 60%;
	height: 40.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
/*.buttonCloseLegalTextButton {*/
/*	width: 100%;*/
/*	height: 100%;*/
/*	font-size: 11.5pt;*/
/*}*/
.showLegalTextDecider {
	margin-left: 4%;
	width: 92%;
	margin-top: 2%;
	height: 92%;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
}
.showLegalTextDeciderTextSignUp{
	margin-top: 2%;
	font-size: 10pt;
	overflow-y: auto;
	height: 430px;
}

.legalNoticeButtonSignUp {
	margin-top: 200%;
	margin-left: 350%;
	margin-top: 147.5%;
	margin-left: 57%;
	width: 23%;
	/*border: solid 1pt red;*/
	position: absolute;
}
.legalNoticeButtonSignUpButton {
	border: none;
	background-color: transparent;
}
.legalNoticeButtonSignUpButton:hover {
	text-decoration: underline;
}

.disclaimerTFNSignUp {
	position: absolute;
	margin-top: 32px;
	font-size: 75%;
	width: 90%;
	text-align: justify;
}
.disclaimerTFNSignUp2 {
	position: absolute;
	margin-top: 32px;
	font-size: 75%;
	width: 88%;
	text-align: justify;
	margin-left: 12px;
}

.disclaimerConfirmationInformationSignUp {
	position: absolute;
	margin-top: 0px;
	font-size: 75%;
	width: 90%;
	text-align: justify;
}

.disclaimerConfirmationInformationEmployee{
	position: absolute;
	margin-top: 25px;
	font-size: 75%;
	width: 90%;
	text-align: justify;
}

.disclaimerConfirmationInformationShops {
	position: absolute;
	margin-top: 30px;
	font-size: 75%;
	width: 90%;
	text-align: justify;
}

.BackgroundSignUp {
	/*background-color: rgba(10, 180, 10, 0.1);*/
	/*!*background-color: rgba(255, 0, 0, 0.3);*!*/
	/*width: 100%;*/
	/*height: 100%;*/
	/*position: absolute;*/
	/*z-index: -1000;*/
}

.divDisclaimersCompanyProfile {
	margin-top: 10%;
}



body {
	/*background-color: rgba(46,139,87, 0.1);*/
	background-color: rgba(10, 180, 10, 0.1);

	/*background-color: rgba(210, 210, 210, 0.1);*/
}



/*!* On screens that are 992px wide or less, the background color is blue *!*/
/*@media screen and (max-width: 992px) {*/
/*	body {*/
/*		background-color: blue;*/
/*		color: white;*/
/*	}*/
/*}*/

/*!* On screens that are 600px wide or less, the background color is olive *!*/
/*@media screen and (max-width: 600px) {*/
/*	body {*/
/*		background-color: olive;*/
/*		color: white;*/
/*	}*/
/*}*/
