.Log_in_general_container {
	display: flex;
	position: absolute;
	top: 50%;
	/*margin-top: 70%;*/
	left: 50%;
	transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.01em;
	border-radius: 5px;
	width: 400px;
	height: 550px;
	/*width: 20%;*/
	/*height: 50%;*/
	background-color: #FFFFFF;
	/*background-color: rgba(255, 0, 0, 0.3);*/
}

@media screen and (max-width: 786px) {
	.Log_in_general_container {
		/*background-color: red;*/
		/*top: 60%;*/
		/*transform: translate(-50%, -50%);*/
	}
}


.showLegalTextBigProfileInLogIn {
	margin-top: 10%;
	margin-left: 20%;
	position: fixed;
	width: 60%;
	height: 55.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1200;
}
@media screen and (max-width: 992px) {
	.showLegalTextBigProfileInLogIn {
		margin-left: 0;
		width: 99%;
	}
}

.Login_form {
	margin-top: 4%;
	width: 73%;
	height: 35%;
	/*height: 300px;*/
	/*border: 1px solid red;*/
}
.Login_form2 {
	margin-top: 4%;
	width: 73%;
	height: 35%;
	height: 300px;
}

.messageIForgotMyPassword {
	text-align: left;
	text-align: justify;
	width: 73%;
	margin-left: 1%;
	font-size: 85%;
	font-size: 10.25pt;
	margin-top: 5%;
}

.SignUpButtonInLogIn {
	/*height: 8.1%;*/
	width: 73%;
	margin-top: 5%;
	position: absolute;
	margin-top: 110%;
	/*border: solid 0.05em;*/
	/*border-radius: 3px;*/
}

.logInButtonContainer {
	height: 7.5%;
	width: 73%;
}
.showLegalTextDeciderTextLogIn{
	margin-top: 2%;
	font-size: 10pt;
	/*overflow-y: auto;*/
	max-height: 100%;
}

.blankSpaceLogInScreen {
	height: 10.5%;
}

.ForgotMyPassword {
	text-align: left;
	width: 73%;
	font-size: 85%;
	position: absolute;
	margin-top: 103%;
}

.NewHere{
	text-align: center;
	width: 100%;
	font-size: 85%;
	margin-bottom: 1.1%;
	/*position: absolute;*/
	margin-top: 0.5%;
	/*margin-left: -13%;*/
}

.Log_in_back_title_container{
	width: 100%;
	height: 4.6%;
	margin-top: 5px;
	font-size: 85%;
}

.Back_button_log_in {
	width: 55px;
	height: 100%;
	margin-bottom: 10px;
	/*margin-left: 10px;*/
	margin-left: 0.5%;
	border: none;
	font-size: 125%;
	background-color: rgba(255, 255, 255, 1);
}

.logInButton {
	/*font-family: 'Roboto Condensed', sans-serif;*/
	width: 100%;
	/*height: 73%;*/
	height: 33px;
	margin-top: 8.3%;
	margin-top: 4.9%;
	border-radius: 3px;
	font-size: 110%;
	position: relative;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.logInButton:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.logInButton_Sign_up{
	width: 100%;
	/*height: 73%;*/
	height: 33px;
	position: absolute;
	margin-top: 17.0%;
	margin-top: 0.7%;
	border-radius: 3px;
	font-size: 110%;
	position: relative;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.logInButton_Sign_up:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.Log_in_button {
	width: 100%;
	/*height: 100%;*/
	height: 33px;
	margin-top: 0.8%;
	/*margin-top: -29.8%;*/
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.Log_in_button:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.LoginIForgotMyPasswordButton {
	width: 100%;
	/*height: 100%;*/
	height: 33px;
	margin-top: -4.8%;
	/*margin-top: -29.8%;*/
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 110%;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}

.ContainerLoginIForgotMyPasswordButton {
	margin-top: 83.3%;
	height: 17%;
	width: 100%;
	/*border: solid 0.05em;*/
}

.ContainerLoginResetPasswordButton {
	margin-top: 61.7%;
	height: 17%;
	width: 100%;
	/*border: solid 0.05em;*/
}

.sendAgainButtonLogIn {
	position: absolute;
	margin-top: 55%;
	margin-left: 56%;
	border: none;
	background-color: rgba(255, 255, 255, 1);
	/*font-size: 85%;*/
	font-size: 10.25pt;
	text-decoration: underline;
	cursor: pointer;
}

/*.Back_button_log_in {*/
/*	width: 50px;*/
/*	margin-top: 10px;*/
/*	margin-right: 10%;*/
/*	border: solid 0.05em;*/
/*	border-radius: 3px;*/
/*	font-size: 125%;*/
/*	background-color: rgba(46,139,87, 0.35);*/
/*}*/

.Log_in_field_name {
	left: 90px;
	height: 10%;
	/*width: 5px;*/
	margin-bottom: 1%;
	font-size: 110%;
	font-size: 14pt;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
}

.Log_in_input_field {
	width: 98%;
	margin-bottom: 3%;
	height: 15%;
	/*height: 10px;*/
	border: solid 1px black;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	font-size: 13pt;
	/*border: 1px solid red;*/
}
.Log_in_input_field2 {
	width: 98%;
	margin-bottom: 3%;
	height: 15%;
	height: 30px;
	border: solid 1px black;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	/*font-size: 110%;*/
	font-size: 13pt;
}

.InvalidEmailMessage {
	position: absolute;
	margin-left: 54.0%;
	margin-top: -18%;
	color: red;
	font-size: 10pt;
}
.noPasswordIsProvided {
	position: absolute;
	margin-left: 39.0%;
	margin-top: 0.8%;
	margin-top: -2%;
	color: red;
	font-size: 10pt;
}

.IncorrectEmailOrPassword {
	position: absolute;
	margin-left: 0.0%;
	margin-top: -15%;
	/*polla*/
	color: red;
	font-size: 10pt;
}
.messageEmailAddressDoesNotExist {
	position: absolute;
	margin-left: 1.0%;
	color: red;
	font-size: 10pt;
}
.messageIncorrectVerificationCode {
	position: absolute;
	margin-left: 1.0%;
	margin-top: -0.5%;
	color: red;
	font-size: 10pt;
}
.messageNewPasswordToBeTheSame {
	position: absolute;
	margin-left: 0.0%;
	margin-top: -0.5%;
	margin-top: 7%;
	/*background-color: green;*/
	width: 71.5%;
	text-align: justify;
	color: red;
	font-size: 10pt;
}


.rememberMeCheckbox {
	width: 1.3em;
	height: 1.3em;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	-webkit-appearance: caret;
	outline: none;
	cursor: pointer;
	font-size: 9pt;
}
.rememberMeCheckboxText {
	/*font-size: 110%;*/
	font-size: 12pt;
	margin-top: 3.25px;
	margin-left: 5px;
	position: absolute;
}

.rememberMeCheckboxContainer {
	width: 80%;
	/*background-color: red;*/
	margin-top: 61%;
	margin-left: 5%;
	position: absolute;
	font-size: 110%;
}

.HR_line {
	height: 0.5px;
	margin-top: -6px;
	/*left: 60px;
	top:160px;*/
	border: 0;
	width: 100px;
	color: #C0C0C0;
	background-color: #C0C0C0;
}


.Horizontal_lines_container {
	width: 177px;
	height: 10px;
	position: absolute;
	margin-top: 105%;
	margin-top: 110%;
	/*border: 1px solid red;*/
}


.Log_in_forgot {
	font-size: 100%;
	font-size: 10.25pt;
	margin-top: -12%;
	margin-left: -42.5%;
	/*margin-top: 85%;*/
	/*position: absolute;*/
}


.Log_in_sign_up_text {
	font-size: 100%;
	text-align: left;
	width: 100%;
	border-color: coral;
	border: solid 0.1em;
}


.Log_in_title_container {
	/*margin-top: 15px;*/
}

.Log_in_title_text {
	font-size: 300%;
}

.ContainerCookiesPolicy {
	display: flex;
	position: fixed;
	/*top: 91%;*/
	left: 10%;
	/*transform: translate(-50%, -50%);*/
	flex-direction: column;
	align-items: center;
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	width: 80%;
	background-color: #FFFFFF;
	z-index: 1000;
	bottom: 0%;
}

.ContainerCookiesPolicyMessage {
	width: 97.4%;
	text-align: justify;
	/*margin-left: 2%;*/
	margin-top: 0.4%;
}

.ContainerCookiesPolicyButtonPrevious {
	width: 100%;
	height: 30px;
	/*height: 90%;*/
	margin-top: 5px;
	margin-bottom: 5px;
}


.ContainerCookiesPolicyButtonContainerLogIn {
	/*position: absolute;*/
	/*width: 15%;*/
	/*height: 30px;*/
	/*!*margin-left: 56.5%;*!*/
	/*margin-top: 3.3%;*/
	/*right: 1.3%;*/
}

.ContainerCookiesPolicyButtonRejectAll {
	/*top: -300px;*/
	width: 100%;
	height: 100%;
	left: 27px;
	/*height: 38.5px;*/
	position: absolute;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.ContainerCookiesPolicyButtonRejectAll:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}
.ContainerCookiesPolicyButtonAgreeAndClose {
	height: 100%;
	height: 30px;
	position: absolute;
	border: 1px solid black;
	border-radius: 3px;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
	width: 15%;
	right: 1.3%;
}
.ContainerCookiesPolicyButtonAgreeAndClose:hover {
	background-color: rgba(10, 130, 10, 0.65);
}


.buttonAcceptCookiesDiv {
	/*weight: 15%;*/

	margin-top: 30px;
	margin-bottom: 15px;
	height: 30px;
}
.buttonAcceptCookies {
	position: absolute;
	/*margin-top: 350px;*/
	/*margin-left: 82.3%;*/
	/*right: 4.7%;*/
	margin-left: 50%;
	width: 50%;
	height: 30px;
	border: 1px solid black;
	color: white;
	background-color: rgba(10, 130, 10, 0.85);
}
.buttonAcceptCookies:hover {
	background-color: rgba(10, 130, 10, 0.65);
}

.buttonAcceptCookiesContainer {
	position: absolute;
	right: 4.5%;
	height: 30px;
	width: 200px;
}

.ContainerCookiesPolicyButtonRejectAllCMP{
	/*top: -300px;*/
	width: 50%;
	height: 30px;
	/*left: 67.3%;*/
	/*right: 17.7%;*/
	position: absolute;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.ContainerCookiesPolicyButtonRejectAllCMP:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.buttonCookiesDetail {
	position: absolute;
	/*margin-top: 350px;*/
	margin-left: 3.8%;
	/*width: 15%;*/
	height: 30px;
	/*height: 100%;*/
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.buttonCookiesDetail:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}
.cookiesMenuChangePreferencesClose {
	position: absolute;
	/*margin-left: 97.9%;*/
	right: -0.1%;
	margin-top: -0.1%;
	/*width: 2.1%;*/
	/*background: #16a085;*/
	/*background-color: red;*/
}

.CookiesMenuChangePreferences {
	margin-top: 10%;
	/*margin-top: 100px;*/
	margin-left: 20%;
	position: absolute;
	width: 60%;
	/*height: 43.5%;*/
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
@media screen and (max-width: 786px) {
	.CookiesMenuChangePreferences {
		width: 99%;
		/*height: 92.5%;*/
		margin-top: 7.5%;
		/*margin-left: 7.5%;*/
		margin-left: 0%;
	}
}
.cookiesMenuChangePreferencesMenuSpan {
	margin-left: 5%;
	width: 800px;
	width: 86%;
	text-align: justify;
}
@media screen and (max-width: 786px) {
	.cookiesMenuChangePreferencesMenuSpan {
		margin-left: 5%;
		width: 800px;
		width: 86%;
		width: 65%;
		text-align: justify;
	}
}

.toggleCookiesTechnical {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	border: 0;
	outline: 0;
	cursor: pointer;
	/*left: 700px;*/
	/*text-align: right;*/
	float: right;
	margin-left: 90%;
	right: 4%;
	margin-top: -18px;
	/*margin: 10px;*/
}

/* To create surface of toggle button */
.toggleCookiesTechnical:before {
	content: '';
	width: 60px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;

	/*	This is for a smooth transition*/
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
}

/* Contents before checkbox to create toggle handle */
.toggleCookiesTechnical:after {
	content: '';
	width: 18px;
	height: 18px;
	display: block;
	position: absolute;
	left: 0px;
	top: -2px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.toggleCookiesTechnical:checked:after {
	left: 43px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
/* Background color when toggle button will be active */
.toggleCookiesTechnical:checked:before {
	background-color: rgba(155, 155, 155, 1);
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
	/*background: red;*/
}


.toggleCookies {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	border: 0;
	outline: 0;
	cursor: pointer;
	/*left: 700px;*/
	/*text-align: right;*/
	float: right;
	margin-left: 98%;
	right: 4%;
	margin-top: -15px;
	/*margin: 10px;*/
}

/* To create surface of toggle button */
.toggleCookies:before {
	content: '';
	width: 60px;
	height: 18px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;

	/*	This is for a smooth transition*/
	transition: ease .3s;
	-webkit-transition: ease .3s;
	-moz-transition: ease .3s;
	-o-transition: ease .3s;
}

/* Contents before checkbox to create toggle handle */
.toggleCookies:after {
	content: '';
	width: 18px;
	height: 18px;
	display: block;
	position: absolute;
	left: 0px;
	top: -1px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.toggleCookies:checked:after {
	left: 43px;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}
/* Background color when toggle button will be active */
.toggleCookies:checked:before {
	background: rgba(10, 130, 10, 0.85);;
	/*background: red;*/
}

.showCookiesDetailsCloseButtonLogIn{
	position: absolute;
	/*margin-left: 97.5%;*/
	/*margin-top: -43.3%;*/
	right: -0.1%;
	top: -0.1%;
	/*top: -1.0%;*/
	text-align: center;
	width: 30px;
	height: 23px;
	position: absolute;
	/*margin-left: 98.2%;*/
	/*margin-top: 0.8%;*/
}

.cookiesMenuChangePreferencesTitle {
	position: absolute;
	margin-left: 3.8%;
	margin-top: 2%;
}
.cookiesMenuChangePreferencesTitleSubtitle {
	position: absolute;
	margin-left: 6.3%;
	margin-top: 40px;
}
.cookiesMenuChangePreferencesMenu {
	width: 90%;
	/*position: absolute;*/
	margin-top: 50px;
	margin-left: 1.2%;
}
@media screen and (max-width: 786px) {
	.cookiesMenuChangePreferencesMenu {
		height: 92.5%;
		margin-top: 7.5%;
		margin-left: 7.5%;
	}
}
.cookiesMenuChangePreferencesMenuArea {
	/*position: absolute;*/
	/*background-color: blue;*/
}
.cookiesMenuChangePreferencesMenuItem {
	list-style-type: ' ';
	margin-left: 2.5%;
	margin-top: 2%;
}


/*.cookiesMenuChangePreferencesMenuSpan {*/
/*	margin-left: 5%;*/
/*	width: 800px;*/
/*	width: 86%;*/
/*	text-align: justify;*/
/*}*/

.tableAnalyticsCookies{
	top: 140px;
	/*width: 68.4%; */
	width: 80%;
	width: 100%;
	left: 5%;
	/* height: 50%; */
	/*height:80%;*/
	/*height: 800px;*/
	z-index: 10;
	/*position: sticky;*/
	/*border: 1px red solid;*/
	display: flex;
	/*font-size: 12pt;*/
}
.tableAnalyticsCookiesTable {
	font-size: 100%;
	height: 100%;
	display: block;
	border-collapse: collapse;
	/*border: 1px solid #ddd;*/
	table-layout: fixed;
	empty-cells: show;
	overflow: auto;
}
.tableAnalyticsCookiesTableHeader {
	width: 100%;
}
.tableAnalyticsCookiesTableHeader th {
	/*background-color: #4CAF50;*/
	/*background-color: rgba(10, 180, 10, 1.0);*/
	/*font-size: 12pt;*/
	font-weight: bold;
	/*color: grey;*/
	border: 0.5px solid #ddd;
	/*height: 35px;*/
	/*height: 100%;*/
	/*padding: 2px;*/
}
.tableAnalyticsCookiesTableHeader tr {
	width: 100%;
	height: 3.1vh;
}
.tableAnalyticsCookiesTableTBody {
	overflow: hidden;
	height: 94.7%;
	/*height: 100%;*/
	width: 100%;
}
.tableAnalyticsCookiesTableTBody tr {
	width: 100%;
	max-width: 100%;
	height: 2.1vh;
	max-height: 2.1vh;
	border: 0.5px solid #ddd;
	/*max-height: 30px;*/
}
.tableAnalyticsCookiesTableTBody tr:hover {background-color: #ddd;}

.tableAnalyticsCookiesTableTBody td {
	/*text-overflow: ellipsis;*/
	overflow: hidden;
	margin-left: 3.5px;
	border-right: 0.5px solid #ddd;
}

.tableAnalyticsCookiesTableTBody td:nth-child(1) {width: 150px; min-width: 150px; max-width: 150px; text-align: left;}
.tableAnalyticsCookiesTableTBody td:nth-child(2) {width: 80px; min-width: 80px; max-width: 80px; text-align: left;}
.tableAnalyticsCookiesTableTBody td:nth-child(3) {width: 420px; min-width: 420px; max-width: 420px; text-align: left;}
.tableAnalyticsCookiesTableTBody td:nth-child(4) {width: 100px; min-width: 100px; max-width: 100px; text-align: center;}




.CookiesPolicyTextContainerCloseButton {
	position: absolute;
	margin-left: 98.2%;
	/*background: #16a085;*/
}

/*.cookiesMenuChangePreferencesClose {*/
/*	position: absolute;*/
/*	margin-left: 97.4%;*/
/*	margin-top: -0.1%;*/
/*	!*background: #16a085;*!*/
/*}*/

.CookiesPolicyTextContainerLogIn {
	display: flex;
	position: fixed;
	top: 10%;
	left: 15%;
	/*transform: translate(-50%, -50%);*/
	flex-direction: column;
	align-items: center;
	/*top: 190px;*/
	/*left: 285px;*/
	/*position: absolute;*/
	border-color: coral;
	border: solid 0.1em;
	border-radius: 5px;
	height: 70%;
	width: 70%;
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}
.CookiesPolicyTextContainerInternalTexts {
	width: 94%;
	margin-top: 2%;
	position: absolute;
}
.CookiesPolicyTitle {
	position: absolute;
}
.CookiesPolicyText {
	margin-top: 2%;
	font-size: 10pt;
	height: 650px;
	overflow-y: scroll;
	position: absolute;
	text-align: justify;

}


.ObscurerLogIn {
	position: fixed ;
	min-height: 100%;
	max-height: 1200%;
	width: 100%;
	/*margin-top: -0.4%;*/
	/*margin-left: -0.4%;*/
	/*border: solid 0.3em;*/
	background-color: rgba(105, 105, 105, 0.2);
	z-index: 200;
}



.showLegalTextBigLogIn {
	margin-top: 14%;
	top: -1.0%;
	margin-left: 24.5%;
	position: absolute;
	position: fixed;
	width: 51%;
	height: 48.5%;
	border: solid 0.05em;
	border-radius: 1px;
	background-color: #FFFFFF;
	z-index: 1000;
}

.showLegalTextDeciderTitleDivSupportLogIn {
	/*margin-left: 25%;*/
	position: fixed;
	width: 30%;
	height: 120px;
	margin-top: 18%;
	margin-left: 34.53%;
	border: solid 0.05em;
	border-radius: 1px;
	background-color: #FFFFFF;
	z-index: 1000;
}
@media screen and (max-width: 786px) {
	.showLegalTextBigLogIn {
		width: 85%;
		height: 92.5%;
		margin-top: 7.5%;
		margin-left: 7.5%;
	}
	.showLegalTextDeciderTitleDivSupportLogIn {
		width: 85%;
		/*height: 92.5%;*/
		margin-top: 50.5%;
		margin-left: 7.5%;
	}
}

/* On screens that are 1600px wide or less, the background color is blue */
@media screen and (max-width: 786px) {
	.ContainerCookiesPolicy {
		width: 99.2%;
		/*height: 12.7%;*/
		/*left: 0.25%;*/
		left: 0;
	}
}


.showLegalTextSmallLogIn {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 60%;
	height: 40.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.buttonCloseLegalTextLogIn {
	left: 97.0%;
	top: -0.1%;
	/*left: 1210px;*/
	position: absolute;
	width: 30px;
	height: 23px;
}
.buttonCloseLegalTextButton {
	/*width: 100%;*/
	/*height: 100%;*/
	/*padding-right: 2px;*/
	/*padding-left: 2px;*/
	/*background-color: green;*/
	right: 0;
	/*top: -0.2%;*/
	top: 0;
	position: absolute;
	border: solid 0.05em;
	/*background-color: red;*/
	/*border: 1px solid red;*/
	/*font-size: 11.5pt;*/
}

.buttonCloseLegalTextButtonText {
	font-size: 9px;
}


.showLegalTextDeciderLogIn {
	margin-left: 3%;
	width: 94%;
	margin-top: 2%;
	/*height: 81%;*/
	max-height: 92%;
	overflow-y: auto;
	/*HOLI*/
}
@media screen and (max-width: 786px) {
	.showLegalTextDeciderLogIn {
		/*background-color: red;*/
		max-height: 95%;
	}
}

.showLegalTextDeciderTitle{
	/*font-size: 10pt;*/
}



.legalNoticeButtonLogIn {
	margin-top: 52.75%;
	margin-left: 83.625%;
	margin-top: 40.15%;
	margin-left: 53.0%;
	/*width: 3%;*/
	/*border: solid 1pt red;*/
	position: absolute;
}
.legalNoticeButtonLogInButton {
	border: none;
	background-color: transparent;
}
.legalNoticeButtonLogInButton:hover {
	text-decoration: underline;
}
/* Start by setting display:none to make this hidden.
   Then we position it in relation to the viewport window
   with position:fixed. Width, height, top and left speak
   for themselves. Background we set to 80% white with
   our animation centered, and no-repeating */
.WaitingForRequest {
	display:    none;
	position:   fixed;
	z-index:    1000;
	top:        0;
	left:       0;
	height:     100%;
	width:      100%;
	background: rgba( 255, 255, 255, .8 )
	url('http://i.stack.imgur.com/FhHRx.gif')
	50% 50%
	no-repeat;
}

.loading {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.7;
	background-color: #fff;
	z-index: 5000;
}

.loading-image {
	z-index: 100;
}

.errorWhenLoadingLogIn {
	margin-left: 41%;
	margin-top: 27.0%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 80px;
	/*height: 700px;*/
	width: 18%;
	position: absolute;
	z-index: 2000;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid black;
}
.errorWhenLoadingLogInText {
	margin-left: 10%;
	margin-top: 5%;
}

.legalNoticeButtonLogInLegal {
	position: absolute;
	margin-left: 72.5%;
	margin-left: 60.0%;
	margin-left: 5.0%;
	/*margin-left: 68%;*/
	/*margin-top: -45%;*/
	text-align: left;
	width: 10%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
}
.licensesButtonLogIn {
	position: absolute;
	margin-left: 72.5%;
	margin-left: 60.0%;
	margin-left: 5.0%;
	/*margin-left: 68%;*/
	/*margin-top: -45%;*/
	text-align: left;
	width: 10%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
}
.licensesButtonLogIn:hover {
	text-decoration: underline;
}
.ContactSupportLogIn {
	position: absolute;
	margin-left: 5.0%;
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.ContactSupportLogIn:hover {
	text-decoration: underline;
}
.TIDINameLogIn{
	position: absolute;
	margin-left: 87.8%;
	margin-left: 85.8%;
	margin-left: 5.0%;
	/*margin-left: 72.5%;*/
	/*font-size: 10.0pt;*/
	text-align: left;
	width: 11%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
}

.showLegalTextBigProfileLogIn {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 40%;
	height: 55.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextDeciderTextMyAreaTextLogIn{
	margin-right: 1%;
	/*width: 99%;*/
	width: 99%;
	height: 100%;
	/*border: 1px solid red;*/
	text-align: justify;
}
.PasswordContainerWithEyeIcon {

}
.Log_in_input_field3 {
	height: 100%;
	width: 100%;
	/*border: 1px solid red;*/
}
.eyePassword {
	position: absolute;
	width: 30px;
	height: 25px;
	/*width: 100px;*/
	/*height: 100px;*/
	/*margin-left: -8.9%;*/
	right: 60px;
	margin-top: -38px;
	/*border: 1px solid red;*/
	background-color: transparent;
}



.messageNewPasswordFormat {
	position: absolute;
	font-size: 10pt;
	text-align: justify;
	width: 73%;
	margin-top: 70%;
}

.BackgroundLogIn {
}

.body {
	/*background-color: rgba(46,139,87, 0.1);*/
	background-color: rgba(255, 0, 0, 0.3);
	/*font-family: 'Roboto Condensed', sans-serif;*/
	/*background-color: rgba(85, 215, 85, 0);*/
}
