.My_profile_general_container {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: white;
	top: 0;
	left: 0;
	width: 1920px;
	height: 1057px;
	height: 1088px;
}


#title {
  text-align: left;
  /*font-family: arial, sans-serif;*/
}

.HeaderLandingPage {
	border-color: coral;
	border: solid 0.3em;
	height: 5%;
	width: 100%;
}

.LogOutButtonMyProfile {
	/*margin-left: 94.5%;*/
	height: 100%;
	width: 90px;
	/*top: 2px;*/
	background-color: Transparent;
	background-repeat:no-repeat;
	font-size: 14pt;
	position: absolute;
	color: white;
	border: none;
	cursor: pointer;
	right: 0px;
}
.LogOutButtonMyProfile:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

.RowHeaderLandingPage {
	height: 100%;
	width: 100%;
	/*background-color: rgba(0,128,0, 0.9);*/
	margin: 0 !important;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	padding : 0 !important;
}

.RowHeaderLandingPage2 {
	height: 100%;
	width: 100%;
	/*background-color: red;*/
	/*max-width: 100%;*/
	/*max-height: 100%;*/
	/*min-width: 100%;*/
	/*min-height: 100%;*/
	margin: 0 !important;
	padding : 0 !important;
}

.HistoricalLandingPage {
	height: 100%;
	width: 20%;
	color: white;
	background-color: Transparent;
	font-size: 100%;
	/*font-size: 19px;*/
	position: absolute;
	border: none;
	/*text-decoration: underline;*/
	cursor: pointer;
}

.HistoricalLandingPage:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

.VerticalCenteredMessage {
	text-align: center;
	/*display: table;*/
	vertical-align: middle;
	position: absolute;
	width: 100%;
	height: 100%;
	line-height: 56px;
	margin-top: 2%;
}

.MyProfileLandingPageMyProfile {
	margin-left: 20%;
	height: 100%;
	width: 20%;
	color: white;
	background-color: Transparent;
	font-size: 100%;
	position: absolute;
	/*border: none;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*text-decoration: underline;*/
	font-weight: bold;
	border: none;
	cursor: pointer;
}

.MyProfileLandingPageMyProfile:hover {
	/*PruebaLandingPage*/
	background-color: rgba(255, 255, 255, 0.55);
	background-color: rgba(10, 100, 10, 0.55);
}

.containerPage{
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	height: 94.3%;
	/*height: 1029px;*/
	/*height: 100%;*/
	/*height: 1140px;*/
	margin-top: 60px;
	position: absolute;
	/*width: 1900px;*/
	width: 100%;
}

.userNameOnLanding {
	/*width: 80%;*/
	/*height: 10%;*/
	height: 100px;
	width: 50%;
	/*margin-left: 15.75%;*/
	/*margin-left: 350px;*/
	margin-left: 18.25%;
	/*margin-top: 2.6%;*/
	margin-top: 42px;
	position: absolute;

	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*font-size: 300%;*/
	font-size: 48px;
}

.userNameOnLandingRow {
	border-color: coral;
	border: solid 0.1em;
	width: 100%;
	height: 20%;
	position: absolute;
}

.containerPageMyAccount {
	display: flex;

	width: 100%;
	height: 100%;
	/*border-color: red;*/
	/*border: solid 0.1em;*/
}

.containerPageMyAccountRow{
	border-color: red;
	border: solid 0.1em;
	position: absolute;
	margin-top: 100%;
	width: 100%;
	height: 20%;
}

.hola {
	border-color: red;
	border: solid 0.1em;
	width: 100%;
 }


.containerNavigation {
	/*margin-left: 300px;*/
	margin-left: 350px;
	margin-left: 18.25%;
	/*height: 70%;*/
	height: 713px;
	width: 12%;
	/*max-width: 250px;*/
	/*width: 231px;*/
	border-color: coral;
	border: solid 0.1em;
	/*margin-top: 7.5%;*/
	margin-top: 144px;
	position: absolute;
}

.containerInformationForm {
	/*margin-left: 132px;*/
	/*margin-left: 666.3px;*/
	margin-left: 34.7%;
	/*margin-top: 7.5%;*/
	margin-top: 144px;
	/*height: 70%;*/
	height: 713px;
	width: 29.65%;
	/*width: 570px;*/
	border-color: coral;
	border: solid 0.1em;
}

.containerSettingsForm {
	/*margin-left: 132px;*/
	margin-left: 34.7%;
	/*margin-top: 7.5%;*/
	margin-top: 144px;
	/*height: 70%;*/
	height: 713px;
	/*width: 35%;*/
	/*width: 630px;*/
	width: 32.8%;
	border-color: coral;
	border: solid 0.1em;
}

.containerInformationTable {
	/*margin-left: 132px;*/
	margin-left: 34.7%;
	position: absolute;
	margin-top: 144px;
	height: 713px;
	/*width: 890px;*/
	width: 46.32%;
	border-color: coral;
	border: solid 0.1em;
}

.containerPageTableCol1 {
	width: 30%;
	/*width: 1500px;*/
}

.containerPageTableCol2 {
	width: 70%;
}

.renderMyCompanyData {
	display: flex;
	margin-top: 7%;
	width: 100%;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	/*height: 70%;*/
	height: 497px;

}

.renderMyCompanyLeft {
	vertical-align: middle;
	width: 35%;
	/*height: 70%;*/
	height: 497px;
	margin-top: 5%;
	font-size: 14pt;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	position: absolute;
}

.renderMyCompanyRight {
	vertical-align: middle;
	/*width: 100%;*/
	width: 33%;
	/*height: 70%;*/
	height: 497px;
	margin-left: 30%;
	margin-top: 5%;
	font-size: 14pt;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	position: absolute;
}

.renderMyCompanySettingsButtons {
	vertical-align: middle;
	width: 28%;
	margin-top: 3%;
	margin-left: 65%;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
}

.renderMyCompanySettingsSingleButton {
	min-height: 3.8%;
	/*max-height: 11%;*/
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 9.0px;

	margin-bottom: -1%;
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}

.renderMyCompanySettingsSingleButton:hover {
	background-color: rgba(255, 255, 255, 0.45);
}

.renderMyCompanySettingsSingleButton2 {
	min-height: 3.8%;
	max-height: 7.6%;
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 38.8px;

	margin-bottom: -1%;
	/*margin-left: 10%;*/
	/*font-size: 14px;*/
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}
.renderMyCompanySettingsSingleButton2:hover {
	background-color: rgba(255, 255, 255, 0.45);
}

.renderMyCompanySettingsSingleButton3 {
	min-height: 4%;
	/*max-height: 11%;*/
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 73px;

	margin-bottom: -1%;
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}
.renderMyCompanySettingsSingleButton3:hover {
	background-color: rgba(255, 255, 255, 0.45);
}

.renderMyCompanySettingsSingleButton4 {
	min-height: 4%;
	/*max-height: 11%;*/
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 107px;

	margin-bottom: -1%;
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}
.renderMyCompanySettingsSingleButton4:hover {
	background-color: rgba(255, 255, 255, 0.45);
}


.renderMyCompanySettingsSingleButton5 {
	min-height: 4%;
	/*max-height: 11%;*/
	width: 28%;
	/*margin-top: 5%;*/
	margin-top: 142px;

	margin-bottom: -1%;
	font-size: 11pt;
	position: absolute;
	/*border-color: coral;*/
	/*border: solid 0.2em;*/
}
.renderMyCompanySettingsSingleButton5:hover {
	background-color: rgba(255, 255, 255, 0.45);
}

.renderMyCompanyLeftText {
	/*height: 6.5%;*/
	height: 32px;
	width: 100%;
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
}

.renderMyCompanyRightText {
	/*height: 6.48%;*/
	height: 32px;
	width: 200px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	/*margin-left: -50%;*/
	/*font-size: 110%;*/
	/*border: solid 0.1em;*/
	/*border-color: red;*/
}

.renderMyCompanyLeftTextBlankSpace {
	/*height: 12%;*/
	height: 55px;
}

.renderMyCompanyLeftTextBlankSpaceSettings {
	height: 9%;
}

.containerPageTable {
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	font-size: 100%;
	border-collapse: collapse;
  	/*border: 3px solid;*/
	/*border-color: red;*/
	width: 100%;
	height: 100%;
}

.containerPageTable td {
	border: none;
}

.containerPageTable tr {
	border: 3px solid;
	border-color: red;
}

.containerPageTableBody {
	/*display: block;*/
}

.MyCompany {
	margin-top: 13%;
	margin-left: 14%;
}

.MyEmployees {
	margin-top: 10%;
	margin-left: 14%;
}

.MyShops {
	margin-top: 10%;
	margin-left: 14%;
}

.Settings {
	margin-top: 10%;
	margin-left: 14%;
}

.MyCompanyButton {
	/*font-size: 125%;*/
	/*font-size: 14pt;*/
	border: none;
	width: 150px;
	/*border: 1px solid red;*/
	background-color: white;
	cursor: pointer;
	font-size: 14pt;
	text-align: left;
}

.MyCompanyButtonSelected {
	/*font-size: 125%;*/
	/*font-size: 14pt;*/
	border: none;
	width: 150px;
	/*border: 1px solid red;*/
	background-color: white;
	cursor: pointer;
	color: rgba(10, 180, 10, 1);
	font-weight: bold;
	font-size: 14pt;
	text-align: left;
	/*text-decoration: underline;*/
}

.MyEmployeesButton {
	border: none;
	width: 175px;
	text-align: left;
	background-color: white;
	cursor: pointer;
	font-size: 14pt;
}
.MyEmployeesButtonSelected {
	border: none;
	width: 175px;
	text-align: left;
	background-color: white;
	cursor: pointer;
	font-size: 14pt;
	/*text-decoration: underline;*/
	color: rgba(10, 180, 10, 1);
	font-weight: bold;
}

.MyShopsButton {
	font-size: 14pt;
	width: 150px;
	text-align: left;
	border: none;
	background-color: white;
	cursor: pointer;
}
.MyShopsButtonSelected {
	font-size: 14pt;
	width: 150px;
	text-align: left;
	border: none;
	background-color: white;
	cursor: pointer;
	/*text-decoration: underline;*/
	color: rgba(10, 180, 10, 1);
	font-weight: bold;
}
.MyCompanyButtonIcon {
	height: 18px;
	width: 18px;
	padding-right: 5px;
}

.SettingsButton {
	font-size: 14pt;
	width: 150px;
	text-align: left;
	border: none;
	background-color: white;
	cursor: pointer;
}
.SettingsButtonSelected {
	font-size: 14pt;
	width: 150px;
	text-align: left;
	border: none;
	background-color: white;
	cursor: pointer;
	/*text-decoration: underline;*/
	color: rgba(10, 180, 10, 1);
	font-weight: bold;
}

.renderMyCompany {
	/*margin-left:  2%;*/
	/*margin-right: 2%;*/
	/*margin-top: 1.5%;*/
	margin-right:  38px;
	margin-left:  38px;
	margin-top:  29px;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 710px;
	width: 31.3%;
	position: absolute;
}

.renderMyEmployees {
	/*margin-left:  38.1px;*/
	/*margin-left:  3.4%;*/

	/*margin-left:  38.1px;*/
	/*margin-right: 2%;*/
	/*margin-top:   2.5%;*/
	margin-right:  38px;
	margin-left:  38px;
	margin-top:  29px;
	/*width: 32.1%;*/
	/*width: 80%;*/
	width: 80.0%;
	position: absolute;
	/*height: 72%;*/
	/*height: 580px;*/
	/*border-color: red;*/
	/*border: solid 0.3em red;*/
}

.renderMyShops {
	margin-left:  2%;
	margin-right: 2%;
	margin-top: 1.5%;
	width: 32.1%;
	position: absolute;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
}

.renderSettings {
	margin-left:  2%;
	margin-right: 2%;
	margin-top: 1.5%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 710px;
	width: 31.3%;
	position: absolute;
}

.renderMyCompanyName {
	width: 85%;
	/*height: 8%;*/
	height: 40px;
	/*font-size: 225%;*/
	font-size: 28pt;
	/*font-size: 38px;*/
	position: absolute;
	/*border-color: red;*/
	/*border: solid 0.1em;*/
}

.editMyCompanyButtonContainer {
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*width: 20%;*/
	/*height: 20%;*/
	position: absolute;
	margin-left: 72%;
	/*margin-top: 6%;*/
	margin-top: 35.6px;
	background-color: white;
	/*border: none;*/
	cursor: pointer;
}

.editMyCompanyButton {
	width: 100%;
	height: 100%;
	text-decoration: underline;
	background-color: transparent;
	border: none;
	font-size: 11pt;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*position: absolute;*/
	/*margin-left: 35%;*/
	/*margin-top: 7%;*/
	/*background-color: white;*/
	/*!*border: none;*!*/
	/*cursor: pointer;*/
}

.renderMyCompanyTable {
	margin-top: 5%;
	width: 100%;
	border: solid 1px red;
	border-collapse: collapse;
}

.renderMyCompanyTable td {
	/*border: solid 1px red;*/
}

.renderMyCompanyTable tr {
	/*border: solid 1px red;*/
	height: 30px;
}

.renderMyCompanyTableCol1 {
	width: 35%;
}

.renderMyCompanyTableCol2 {
	width: 65%;
}

.renderMyEmployeesTable {
	margin-left: 20px;
	text-align: center;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	font-size: medium;
	border-collapse: collapse;
	border: 3px solid #ddd;
	width: 75%;
	height: 100%;
	overflow-y: auto;
	table-layout:fixed;
}

.renderMyEmployeesTable td, .renderMyEmployeesTable th {
  border: 0.5px solid #ddd;
  /*padding: 8px;*/
}

.renderMyEmployeesTable td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.renderMyEmployeesTable tr:nth-child(even){background-color: #f2f2f2;}

.renderMyEmployeesTable tr:hover {background-color: #ddd;}

.renderMyEmployeesTable th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
}


.whiteSpaceInBackground {
	position: absolute;
	/*height: 70%;*/
	height: 710px;
	/*width: 29.7%;*/
	/*height: 710px;*/
	/*width: 31.3%;*/
	width: 29.65%;
	background-color: rgba(255, 255, 255, 1);
	/*background-color: red;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	z-index: 500;
}


.fillingBackgroundContainer {
	margin-left:  2%;
	margin-right: 2%;
	margin-top:   1.5%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 580px;
	/*height: 700px;*/
	width: 31.3%;
	position: absolute;
	z-index: 1000;
}

.fillingBackgroundContainer2 {
	/*margin-left:  3.4%;*/
	/*margin-right: 2%;*/
	/*margin-top:   2.5%;*/

	margin-right:  38px;
	margin-left:  38px;
	margin-top:  29px;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 580px;
	/*height: 700px;*/
	width: 80.0%;
	position: absolute;
	z-index: 1000;
}

/*renderMyEmployees*/




.renderMyCompanyRightInput {
	width: 120%;
	/*font-size: 100%;*/
	font-size: 13pt;
	height: 20px;
	margin-bottom: 6.2px;
	display: flex;
	flex-direction: row;
	/*border: solid 0.05em;*/
	/*border-radius: 3px;*/
}

.renderMyCompanyRightDate{
	width: 121.5%;
	/*font-size: 100%;*/
	font-size: 16px;
	height: 23px;
	margin-bottom: 6.2px;
	display: flex;
	flex-direction: row;
	/*border: solid 0.05em;*/
	/*border-radius: 3px;*/
}

.renderMyCompanyRightInputDropdown {
	width: 124%;
	font-size: 13pt;
	margin-bottom: 6.25px;
	height: 25px;
	text-align: left;
}
.renderMyCompanyRightInputDropdownPerson {
	width: 124%;
	font-size: 13pt;
	margin-bottom: 6.25px;
	margin-top: -2%;
	height: 25px;
	text-align: left;
}
.renderMyCompanyRightInputDropdownTextEmployee {
	text-align: left;
	font-size: 12pt;
	position: absolute;
	/*margin-top: -4%;*/
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 70%;
	/*border: 1px solid red;*/
}
.renderMyCompanyRightInputDropdownTextEmployee a {
	padding-left: 3px;
	text-decoration: none;
	color: black;
}
.renderMyCompanyRightInputDropdownText {
	text-align: left;
	font-size: 12pt;
	position: absolute;
	/*margin-top: -4%;*/
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 11.5%;
	width: 83%;
}
.renderMyCompanyRightInputDropdownTextPerson {
	text-align: left;
	font-size: 12pt;
	position: absolute;
	/*margin-top: -4%;*/
}

.renderMyCompanyRightInputDropdownText a {
	padding-left: 3px;
	text-decoration: none;
	color: black;
}
.dropdownInSettings {
	width: 100%;
	height: 100%;
	border: solid 0.05em;
	border-radius: 3px;
	font-size: 13pt;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.renderMyCompanyRightInputDropdownDividedPhonePrefix {
	width: 28%;
	font-size: 16px;
	height: 26.3px;
	/*margin-left: -100px;*/
	margin-left: 0%;
	/*margin-top: 2%;*/
	position: absolute;
}
.renderMyCompanyRightInputDropdownCurrency {
	width: 104.5%;
	font-size: 16px;
	height: 25px;
	/*margin-left: -100px;*/
	margin-top: 2%;
}
.dropdownMenuSettings{
	width: 123%;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	margin-top:24px;
	font-size: 18px;
}
.dropdownMenuSettingsCompany{
	width: 123%;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	/*margin-top:24px;*/
	font-size: 13pt;
}
.dropdownMenuSettingsCompany a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsTreatment {
	/*width: 296%;*/
	width: 29.3%;
	/*max-height: 200px;*/
	min-height: 70px;
	text-align: left;
	max-height: 200px;
	border: solid 0.1em;
	z-index: 2000;
	background-color: white;
	position: sticky;
	/*position: absolute;*/
	overflow-y: auto;
	margin-top: -1px;
	font-size: 13.5pt;
}
.dropdownMenuSettingsTreatment a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsCountry {
	/*width: 308%;*/
	/*text-align: left;*/
	/*min-height: 70px;*/
	/*max-height: 200px;*/
	/*border: solid 0.05em;*/
	/*z-index: 2500;*/
	/*background-color: white;*/
	/*position: sticky;*/
	/*overflow-y: auto;*/
	/*margin-top: -1px;*/
	/*font-size: 13.5pt;*/

	width: 296%;
	width: 94%;
	/*max-height: 200px;*/
	min-height: 70px;
	text-align: left;
	max-height: 200px;
	border: solid 0.1em;
	z-index: 2000;
	background-color: white;
	position: sticky;
	/*position: absolute;*/
	overflow-y: auto;
	margin-top: -1px;
	font-size: 13.5pt;
}
.dropdownMenuSettingsCountry a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsRegion {
	width: 307%;
	text-align: left;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: sticky;
	overflow-y: auto;
	margin-top: -1px;
	margin-left: -104%;
	font-size: 13.5pt;
}
.dropdownMenuSettingsRegion a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsProvince {
	width: 308%;
	text-align: left;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: sticky;
	overflow-y: auto;
	margin-top: -1px;
	margin-left: -209%;
	font-size: 13.5pt;
}
.dropdownMenuSettingsProvince a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownMenuSettingsTreatmentAbsolute{
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 2000;
}
.dropdownMenuSettingsPhonePrefix {
	width: 97%;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	margin-top: -1px;
	font-size: 12pt;
}
.dropdownMenuSettingsPhonePrefix a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
	/*font-size: 13.5pt;*/
	line-height: 2.0vh;
}
.dropdownMenuSettingsCurrency {
	width: 103.5%;
	max-height: 200px;
	border: solid 0.05em;
	z-index: 1000;
	background-color: white;
	position: absolute;
	overflow-y: auto;
	margin-top: -1px;
	font-size: 13.5pt
}
.dropdownMenuSettingsCurrency a {
	text-decoration: none;
	color: black;
	padding-left: 3px;
}
.dropdownInSettingsEmployees {
	/*POLLA*/
	width: 101%;
	width: 94px;
	height: 101%;
	height: 46px;
	font-size: 16px;
	position: relative;
	/*position: absolute;*/
	z-index: 0;
	/*margin-top: -20px;*/
}
.dropdownInSettingsShops{
	/*POLLA*/
	width: 101%;
	height: 101%;
	font-size: 16px;
	width: 101%;
	/*width: 90px;*/
	/*height: 101%;*/
	/*height: 46px;*/
	/*font-size: 16px;*/
	position: relative;
	/*position: absolute;*/
	z-index: 0;
}

.dropdownEditSettings {
	width: 70px;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.dropdownEditSettingsSticky {
	/*position: absolute;*/
	/*position: sticky;*/
	width: 100%;
	/*width: 50px;*/
}
.dropdownEditSettingsSticky2Container {
	position: sticky;
	z-index: 1000;
	/*margin-top: -20px;*/
	/*position: absolute;*/
}
.dropdownEditSettingsSticky2 {
	/*POLLA*/
	z-index: 10000;
	/*height: 20px;*/
	width: 100%;
	width: 296px;
	position: absolute;
	/*float: bottom;*/
	/*margin-top: 24px;*/
}
.dropdownEditSettingsSticky3 {
	z-index: 10000;
	/*height: 20px;*/
	width: 100%;
	position: absolute;
}
.Test2 {
	/*POLLA*/
	z-index: 10000;
	/*height: 20px;*/
	width: 100%;
	position: absolute;
	width: 1000px;
	border: 1px solid red;
	/*float: bottom;*/
	/*margin-top: 24px;*/
}

.renderMyCompanyRightInputDivided {
	width: 83%;
	font-size: 100%;
	height: 20px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	margin-top: 2%;
	/*border: solid 0.05em;*/
	/*border-radius: 3px;*/
}

.editCompanyForm {
	width: 108%;
}

.renderMyCompanyRightInputDividedLeft {
	/*width: 36%;*/
	width: 43px;
	height: 100%;
	margin-right: 6%;
	margin-bottom: 5%;
	font-size: 18px;
}

.renderMyCompanyRightInputDividedRight {
	width: 175px;
	height: 100%;
	font-size: 18px;
	/*margin-left: 10%;*/
}

.renderMyCompanySettingRightInputDividedRight {
	width: 148px;
	height: 100%;
	font-size: 13.5pt;
	position: absolute;
	margin-left: 31%;
	/*margin-top: 20%;*/
}
.renderMyCompanySettingRightInputDividedRight2 {
	width: 148px;
	height: 100%;
	font-size: 13.5pt;
	position: absolute;
	margin-left: 31%;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}

.containerEditMyCompany {
	margin-left: 6%;
	margin-right: 5%;
	margin-top: 4.5%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	height: 80%;
	width: 100%;
	position: absolute;
}


.Save_button_edit {
	margin-left: 41%;
	/*margin-top: 15%;*/
	/*margin-top: 60%;*/
	width: 36%;
	position: absolute;
	height: 25px;
	font-size: 13pt;
	border: 1px solid;
	/*background-color: rgba(10, 140, 10, 0.6);*/
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 13pt;
	background-color: rgba(10, 180, 10, 1);
}
.Save_button_edit:hover {
	background-color: rgba(10, 180, 10, 0.65);
}
.Clear_button_edit {
	/*margin-left: 11%;*/
	/*margin-top: 60%;*/
	width: 36%;
	position: absolute;
	height: 25px;
	font-size: 13pt;
	border: 1px solid;
	/*top: -785%;*/
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.Clear_button_edit:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.whiteSpaceInBackgroundTable {
	position: absolute;
	/*height: 70%;*/
	height: 713px;
	width: 46.3%;
	background-color: rgba(255, 255, 255, 1);
	/*background-color: red;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	z-index: 500;
}

.whiteSpaceInBackgroundTable2 {
	position: absolute;
	/*height: 70%;*/
	height: 713px;
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	/*background-color: red;*/
	/*border-color: coral;*/
	/*border: solid 0.1em;*/
	z-index: 500;
}




.containerEmployeesAndShopsTable {
	margin-right: 6%;
	/*margin-top: 12%;*/
	margin-top: 78px;
	margin-bottom: 9%;
	width: 100%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	height: 70%;
}

.editMyEmployeesButtonContainer {
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*width: 20%;*/
	/*height: 20%;*/
	position: absolute;
	/*margin-left: 40.7%;*/
	margin-left: 88.4%;
	/*margin-top: 3%;*/
	margin-top: 60px;
	background-color: white;
	/*border: none;*/
	cursor: pointer;
}


.editMyEmployeesButton {
	/*margin-left: 15%;*/
	margin-top: 2%;
	background-color: white;
	border: none;
	cursor: pointer;
	width: 100%;
	height: 100%;

	text-decoration: underline;
	/*background-color: transparent;*/
	/*border: none;*/
	font-size: 11pt;
}

.myEmployeesTable {
	text-align: center;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	font-size: 12pt;
	border-collapse: collapse;
	/*width: 100%;*/
	/*width: 815px;*/
	width: 114.4%;
	/*width: auto;*/
	table-layout: fixed;
	/*height: 98.3%;*/
	/*height: 513px;*/
	display: block;
	empty-cells: show;
	/*border: 1px solid red;*/
	overflow: auto;
}

.myEmployeesTable tbody, .myEmployeesTable thead
{
	display: block;
}

.myEmployeesTable thead
{
	position: sticky;
}

.myEmployeesTableHead {
	width: 100%;
	position: sticky;
}



.myEmployeesTableBodyWithoutScrollBar {
	width: 100%;
	max-height: 530px;
	max-width: 100%;
}

.myEmployeesTableBodyWithScrollBar {
	width: 100%;
	height: 530px;
	max-width: 100%;
}

.myEmployeesTableHead th:nth-child(1) {width: 45px; max-width: 45px; min-width: 45px;  text-align: center; padding-right: 3px;}
/*.myEmployeesTableHead th:nth-child(1) {width: 6.22%;  text-align: center;}*/
.myEmployeesTableHead th:nth-child(2) {width: 92px; max-width: 92px; min-width: 92px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(2) {width: 12.65%;  text-align: left;}*/
.myEmployeesTableHead th:nth-child(3) {width: 107px; max-width: 107px; min-width: 107px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(3) {width: 14.65%;  text-align: left;}*/
.myEmployeesTableHead th:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(4) {width: 24.95%;  text-align: left;}*/
.myEmployeesTableHead th:nth-child(5) {width: 95px; max-width: 95px; min-width: 95px;  text-align: center;  padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(5) {width: 13.1%;  text-align: left; }*/
.myEmployeesTableHead th:nth-child(6) {width: 230px; max-width: 230px; min-width: 230px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHead th:nth-child(6) {width: 28.43%;  text-align: left;}*/

.myEmployeesTableBodyWithoutScrollBar td:nth-child(1) {width: 45px; max-width: 45px; min-width: 45px;  text-align: right; padding-right: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(2) {width: 92px;  max-width: 92px; min-width: 92px;  text-align: left; padding-left: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(3) {width: 107px;  max-width: 107px; min-width: 107px;  text-align: left; padding-left: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px;  text-align: left;  padding-left: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(5) {width: 95px;  max-width: 95px; min-width: 95px;  text-align: left;  padding-left: 3px;}
.myEmployeesTableBodyWithoutScrollBar td:nth-child(6) {width: 230px;  max-width: 230px; min-width: 230px;  text-align: left;  padding-left: 3px;}

/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(1) {width: 6.22%;   max-width: 6.22%;  text-align: center;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(2) {width: 12.65%;  max-width: 12.65%; text-align: left;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(3) {width: 14.65%;  max-width: 14.65%; text-align: left;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(4) {width: 24.95%; max-width: 24.95%;  text-align: left; }*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(5) {width: 13.1%;  max-width: 13.1%;    text-align: left; }*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(6) {width: 28.43%;  max-width: 28.43%;  text-align: left; }*/


.myEmployeesTableBodyWithScrollBar td:nth-child(1) {width: 45px; max-width: 45px; min-width: 45px;  text-align: right; padding-right: 3px;}
.myEmployeesTableBodyWithScrollBar td:nth-child(2) {width: 92px;  max-width: 92px; min-width: 92px;  text-align: left; padding-left: 3px;}
.myEmployeesTableBodyWithScrollBar td:nth-child(3) {width: 107px; max-width: 107px; min-width: 107px;  text-align: left; padding-left: 3px;}
.myEmployeesTableBodyWithScrollBar td:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px; text-align: left;  padding-left: 3px;}
.myEmployeesTableBodyWithScrollBar td:nth-child(5) {width: 95px;  max-width: 95px; min-width: 95px;  text-align: left; padding-left: 3px; }
/*.myEmployeesTableBodyWithScrollBar td:nth-child(6) {width: 216px;  max-width: 216px; text-align: left; }*/
.myEmployeesTableBodyWithScrollBar td:nth-child(6) {width: 230px; max-width: 230px; min-width: 230px; text-align: left;  padding-left: 3px;}

/*.myEmployeesTableBodyWithScrollBar td:nth-child(1) {width: 6.22%;   max-width: 6.22%; text-align: center;}*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(2) {width: 12.65%;  max-width: 12.65%;  text-align: left;}*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(3) {width: 14.65%;  max-width: 14.65%;  text-align: left;}*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(4) {width: 24.95%; max-width: 24.95%;  text-align: left; }*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(5) {width: 13.1%;  max-width: 13.1%;   text-align: left; }*/
/*.myEmployeesTableBodyWithScrollBar td:nth-child(6) {width: 28.43%;  max-width: 28.43%;    text-align: left; }*/

.myEmployeesTableBodyWithoutScrollBar td {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.myEmployeesTableBodyWithScrollBar td {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-wrap: break-word;
}


.myEmployeesTable td, .myEmployeesTable th {
	border: 0.5px solid #ddd;
	/*padding: 8px;*/
	/*width: 100%;*/
}

.myEmployeesTable td {
	height: 45px;
	/*display: inline-block;*/
	/*white-space: nowrap;*/
	/*text-overflow: ellipsis;*/
}

.myEmployeesTable th {
	height: 30px;
}

.myEmployeesTable tr:nth-child(even){background-color: #f2f2f2;}

.myEmployeesTable tr:hover {background-color: #ddd;}

.myEmployeesTable th {
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	color: white;
}






  /* Tables of Employees and Shops with input fields*/

.myEmployeesTableInput {
	text-align: center;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	width: 114.4%;
	table-layout:fixed;
	height: 490px;
	display: block;
	empty-cells: show;
	overflow-y: auto;
	overflow-x: hidden;
}


.myEmployeesTableInput tbody, .myEmployeesTableInput thead
{
	display: block;
}

.myEmployeesTableInput thead
{
	position: sticky;
}

.myEmployeesTableHeadInput {
	width: 100%;
}

/*.myEmployeesTableHeadInput th:nth-child(1) {width: 48px;  text-align: center;}*/
/*.myEmployeesTableHeadInput th:nth-child(2) {width: 100px;  text-align: left;}*/
/*.myEmployeesTableHeadInput th:nth-child(3) {width: 115px;  text-align: left;}*/
/*.myEmployeesTableHeadInput th:nth-child(4) {width: 200px;   text-align: left;}*/
/*.myEmployeesTableHeadInput th:nth-child(5) {width: 103px;  text-align: left;}*/
/*.myEmployeesTableHeadInput th:nth-child(6) {width: 230px;  text-align: left;}*/


.myEmployeesTableHeadInput th:nth-child(1) {width: 45px; max-width: 45px; min-width: 45px;  text-align: center; padding-right: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(1) {width: 6.22%;  text-align: center;}*/
.myEmployeesTableHeadInput th:nth-child(2) {width: 92px; max-width: 92px; min-width: 92px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(2) {width: 12.65%;  text-align: left;}*/
.myEmployeesTableHeadInput th:nth-child(3) {width: 107px; max-width: 107px; min-width: 107px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(3) {width: 14.65%;  text-align: left;}*/
.myEmployeesTableHeadInput th:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(4) {width: 24.95%;  text-align: left;}*/
.myEmployeesTableHeadInput th:nth-child(5) {width: 95px; max-width: 95px; min-width: 95px;  text-align: center; padding-left: 3px; }
/*.myEmployeesTableHeadInput th:nth-child(5) {width: 13.1%;  text-align: left; }*/
.myEmployeesTableHeadInput th:nth-child(6) {width: 230px; max-width: 230px; min-width: 230px;  text-align: center; padding-left: 3px;}
/*.myEmployeesTableHeadInput th:nth-child(6) {width: 28.43%;  text-align: left;}*/



.myEmployeesTableBodyWithoutScrollBarInput {
	width: 100%;
	max-height: 480px;
	max-width: 100%;
	/*border: 3px solid;*/
	/*border-color: coral;*/
}

.myEmployeesTableBodyWithScrollBarInput {
	width: 100%;
	height: 480px;
	max-width: 100%;
	/*border: 3px solid;*/
	/*border-color: coral;*/
}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(2) {
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
	/*top: 0;*/
	/*background-color: orange;*/
}


.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(1) {width: 48px; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(2) {width: 95px; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(3) {width: 110px; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(4) {width: 200px;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(5) {width: 98px; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(6) {width: 230px;}

.myEmployeesTableBodyWithScrollBarInput td:nth-child(1) {width: 48px; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(2) {width: 95px; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(3) {width: 110px; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(4) {width: 200px;}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(5) {width: 98px; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(6) {width: 230px;}


.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(1) {width: 45px;   max-width: 45px; min-width: 45px;  text-overflow: ellipsis;  text-align: right; padding-right: 3px;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(2) {width: 92px;  max-width: 92px; min-width: 92px; text-overflow: ellipsis;  text-align: left;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(3) {width: 110px;  max-width: 110px; min-width: 110px; text-overflow: ellipsis;  text-align: left;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(4) {width: 197px; max-width: 197px; min-width: 197px; text-overflow: ellipsis;  text-align: left; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(5) {width: 98px;  max-width: 98px; min-width: 98px; text-overflow: ellipsis;  text-align: left; }
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(6) {width: 227px;  max-width: 227px; min-width: 227px; text-overflow: ellipsis;  text-align: left; }

/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(1) {width: 6.22%;   max-width: 6.22%; text-overflow: ellipsis;  text-align: center;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(2) {width: 12.65%;  max-width: 12.65%; text-overflow: ellipsis;  text-align: left;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(3) {width: 14.65%;  max-width: 14.65%; text-overflow: ellipsis;  text-align: left;}*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(4) {width: 24.95%; max-width: 24.95%; text-overflow: ellipsis;  text-align: left; }*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(5) {width: 13.1%;  max-width: 13.1%; text-overflow: ellipsis;  text-align: left; }*/
/*.myEmployeesTableBodyWithoutScrollBar td:nth-child(6) {width: 28.43%;  max-width: 28.43%; text-overflow: ellipsis;  text-align: left; }*/


.myEmployeesTableBodyWithScrollBarInput td:nth-child(1) {width: 45px;   max-width: 45px; min-width: 45px;  text-overflow: ellipsis;  text-align: right; padding-right: 3px;}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(2) {width: 92px;  max-width: 92px; min-width: 92px;  text-overflow: ellipsis;  text-align: left;}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(3) {width: 110px;  max-width: 110px; min-width: 110px; text-overflow: ellipsis;  text-align: left;}
.myEmployeesTableBodyWithScrollBarInput td:nth-child(4) {width: 199px; max-width: 199px; min-width: 199px;  text-overflow: ellipsis;  text-align: left; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(5) {width: 98px;  max-width: 98px; min-width: 98px; text-overflow: ellipsis;  text-align: left; }
.myEmployeesTableBodyWithScrollBarInput td:nth-child(6) {width: 225px;  max-width: 225px; min-width: 225px; text-overflow: ellipsis;  text-align: left; }

/*.inputEmployeeTable1{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable1:placeholder-shown{text-overflow: ellipsis; max-width: 95px; font-size: 12pt;}*/
.inputEmployeeTable1{width: 100%; height: 100%; max-height: 45px; font-size: 12pt; z-index: auto} .inputEmployeeTable1:placeholder-shown{text-overflow: ellipsis; max-width: 95px; font-size: 12pt;}
.inputEmployeeTable2{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable2:placeholder-shown{text-overflow: ellipsis; max-width: 110px; font-size: 12pt;}
.inputEmployeeTable22{width: 100%; height: 100%; max-height: 45px; font-size: 12pt; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}
.inputEmployeeTable3{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable3:placeholder-shown{text-overflow: ellipsis; max-width: 195px;  font-size: 12pt;}
.inputEmployeeTable4{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable4:placeholder-shown{text-overflow: ellipsis; max-width: 98px;  font-size: 12pt;}
.inputEmployeeTable5{width: 100%; height: 100%; max-height: 45px; font-size: 12pt;} .inputEmployeeTable5:placeholder-shown{text-overflow: ellipsis; max-width: 225px;  font-size: 12pt;}
.inputEmployeeTable52{width: 100%; height: 100%; max-height: 45px; font-size: 12pt; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}

.inputEmployeeTable1Button {
	position: sticky;
	z-index: auto;
	height: 100%;
	width: 100%;
}

.myEmployeesTableInput td, .myEmployeesTableInput th {
	border: 0.5px solid #ddd;
}
.myEmployeesTableInput td {
	height: 45px;
}
.myEmployeesTableInput th {
	/*padding: 8px;*/
	height: 30px;
}




.myEmployeesTableInput tr:nth-child(even){background-color: #f2f2f2;}
.myEmployeesTableBodyWithoutScrollBarInput td:nth-child(2) {
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
	/*top: 0;*/
	/*background-color: orange;*/
}

.myEmployeesTableInput tr:hover {background-color: #ddd;}

.myEmployeesTableInput th {
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	color: white;
}











.myShopsTable {
	 /*POLLA*/
	/*text-align: center;*/
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	font-size: 12pt;
	border-collapse: collapse;
	/*border: 3px solid red;*/
	/*border-color: coral;*/
	/*width: 100%;*/
	width: 114.4%;
	table-layout:fixed;
	/*height: 88.3%;*/
	/*max-height: 510px;*/
	display: block;
	empty-cells: show;
	overflow: auto;
}

.myShopsTable thead, .myShopsTable tbody {
	display: block;
}

.myShopsTableHead {
	width: 100%;
}

.myShopsTableHead th:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: center; padding-right: 3px;}
.myShopsTableHead th:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px;  text-align: center; padding-left: 3px;}
.myShopsTableHead th:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px;  text-align: center; padding-left: 3px;}
.myShopsTableHead th:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center; padding-left: 3px;}
.myShopsTableHead th:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center;  padding-left: 3px;}
.myShopsTableHead th:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center; padding-left: 3px;}
.myShopsTableHead th:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;   text-align: center; padding-right: 3px;}

.myShopsTableBodyWithoutScrollBar {

	width: 100%;
	max-height: 530px;
	max-width: 100%;
	/*border: 3px solid;*/
	/*border-color: coral;*/
}

.myShopsTableBodyWithScrollBar {
	width: 100%;
	height: 530px;
	max-width: 100%;
	/*border: 3px solid;*/
	/*border-color: coral;*/
}

.myShopsTableBodyWithoutScrollBar td:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: right; padding-right: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(2) {width: 77px; max-width: 77px; min-width: 77px;  text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px; text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 3px;}
.myShopsTableBodyWithoutScrollBar td:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;  text-align: right; padding-right: 3px;}

.myShopsTableBodyWithScrollBar td:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: right; overflow: hidden; padding-right: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(2) {width: 77px; max-width: 77px; min-width: 77px; text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px;  text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; overflow: hidden;padding-left: 3px;}
.myShopsTableBodyWithScrollBar td:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;  text-align: right; overflow: hidden; padding-right: 3px;}

/*.renderMyShopsTableContent1 {max-width: 48px;   text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent2 {max-width: 130px;  text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent3 {max-width: 230px;  text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent4 {max-width: 90px;   text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent5 {max-width: 90px;   text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent6 {max-width: 90px;   text-overflow: ellipsis; white-space: nowrap;}*/
/*.renderMyShopsTableContent7 {max-width: 104px;  text-overflow: ellipsis; white-space: nowrap;}*/


.myShopsTable td, .myShopsTable th {
	border: 0.5px solid #ddd;
}

.myShopsTable td {
	height: 45px;
}

.myShopsTableHead th {
	height: 30px;
}

.myShopsTableBodyWithoutScrollBar td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	/*word-wrap: break-word;*/
}

.myShopsTableBodyWithScrollBar td {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.myShopsTable tr:nth-child(even){background-color: #f2f2f2;}

.myShopsTable tr:hover {background-color: #ddd;}

.myShopsTable th {
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	color: white;
}


/*.myEmployeesTableInput{}*/


.myShopsTableInput {
	text-align: center;
	/*font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
	border-collapse: collapse;
	width: 114.4%;
	/*table-layout:fixed;*/
	height: 490px;
	display: block;
	empty-cells: show;
	overflow-y: auto;
	overflow-x: hidden;
}

.myShopsTableInput tbody, .myShopsTableInput thead {
	display: block;
}

.myShopsTableHeadInput {
	width: 100%;
}

.myShopsTableHeadInput th:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: center;  padding-right: 3px;}
.myShopsTableHeadInput th:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px;  text-align: center; padding-left: 3px;}
.myShopsTableHeadInput th:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px;  text-align: center; padding-left: 3px;}
.myShopsTableHeadInput th:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center; padding-left: 3px;}
.myShopsTableHeadInput th:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center; padding-left: 3px; }
.myShopsTableHeadInput th:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: center;  padding-left: 3px;}
.myShopsTableHeadInput th:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;   text-align: center;  padding-right: 3px;}


.myShopsTableBodyWithoutScrollBarInput {
	width: 100%;
	max-height: 480px;
	max-width: 100%;
	/*overflow-x: hidden;*/
	white-space: nowrap;
	text-overflow: ellipsis;
	/*overflow: hidden;*/
}

.myShopsTableBodyWithScrollBarInput {
	width: 100%;
	max-height: 480px;
	max-width: 100%;
}
.myShopsTableBodyWithScrollBarInput td:nth-child(4) {
	/*position: -webkit-sticky;*/
	/*position: absolute;*/
	width: 30px;
}
.myShopsTableBodyWithScrollBarInput td:nth-child(5) {
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
}
.myShopsTableBodyWithScrollBarInput td:nth-child(6) {
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
}

.inputShopTable1{width: 100%; height: 100%; max-height: 45px; max-width: 80px;} .inputShopTable1:placeholder-shown{text-overflow: ellipsis; max-width: 80px; font-size: 12pt;}
.inputShopTable11{width: 100%; height: 100%; max-height: 45px; max-width: 80px; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}
.inputShopTable2{width: 100%; height: 100%; max-height: 45px; max-width: 238px;} .inputShopTable2:placeholder-shown{text-overflow: ellipsis; max-width: 238px; min-width: 238px; font-size: 12pt;}
.inputShopTable3{width: 99%; height: 100%; max-height: 45px;} .inputShopTable3:placeholder-shown{text-overflow: ellipsis; max-width: 90px;  font-size: 12pt;}
.inputShopTable4{width: 99%; height: 100%; max-height: 45px; max-width: 90px;} .inputShopTable4:placeholder-shown{text-overflow: ellipsis; max-width: 90px; min-width: 90px;  font-size: 12pt;}
.inputShopTable5{width: 99%; height: 100%; max-height: 45px; max-width: 90px;} .inputShopTable5:placeholder-shown{text-overflow: ellipsis; max-width: 90px; min-width: 90px;  font-size: 12pt;}
.inputShopTable6{width: 100%; height: 100%; max-height: 45px; max-width: 126px; } .inputShopTable6:placeholder-shown{text-overflow: ellipsis; max-width: 126px; min-width: 126px; font-size: 12pt; text-align: right;}
.inputShopTable62 {width: 100%; height: 100%; max-height: 45px; max-width: 126px; background-color: rgba(255, 0, 0, 0.10); border: solid rgba(255, 0, 0, 0.30) 2px;}


/*.myShopsTableHeadInput th:nth-child(1) {width: 48px;  max-width: 48px; min-width: 48px;  text-align: center;}*/
/*.myShopsTableHeadInput th:nth-child(2) {width: 80px;  max-width: 80px; min-width: 80px;  text-align: left;}*/
/*.myShopsTableHeadInput th:nth-child(3) {width: 244px; max-width: 244px; min-width: 244px;  text-align: left;}*/
/*.myShopsTableHeadInput th:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left;}*/
/*.myShopsTableHeadInput th:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; }*/
/*.myShopsTableHeadInput th:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left;}*/
/*.myShopsTableHeadInput th:nth-child(7) {width: 132px; max-width: 132px; min-width: 132px;   text-align: right;}*/

/*.myShopsTableHeadInput th:nth-child(1) {width: 45px;  max-width: 45px; min-width: 45px;  text-align: right;  padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px;  text-align: left; padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(3) {width: 239px; max-width: 239px; min-width: 239px;  text-align: left; padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left; padding-left: 3px; }*/
/*.myShopsTableHeadInput th:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px;  text-align: left;  padding-left: 3px;}*/
/*.myShopsTableHeadInput th:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px;   text-align: right;  padding-left: 3px;}*/

.myShopsTableBodyWithoutScrollBarInput td:nth-child(1) {width: 44px;  max-width: 44px; min-width: 44px; text-align: right; padding-right: 3px; font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px; text-align: left;  font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(3) {width: 244px; max-width: 244px; min-width: 244px; text-align: left;  font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left;  padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithoutScrollBarInput td:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px; text-align: right; font-size: 12pt;}

.myShopsTableBodyWithScrollBarInput td:nth-child(1) {width: 44px;  max-width: 44px; min-width: 44px; text-align: right; padding-right: 3px; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(2) {width: 77px;  max-width: 77px; min-width: 77px;  text-align: left; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(3) {width: 244px; max-width: 244px; min-width: 244px; text-overflow: ellipsis;  text-align: left;  font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(4) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(5) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(6) {width: 90px;  max-width: 90px; min-width: 90px; text-align: left; padding-left: 2px; font-size: 12pt;}
.myShopsTableBodyWithScrollBarInput td:nth-child(7) {width: 129px; max-width: 129px; min-width: 129px; text-align: right; }


.myShopsTableInput td, .myShopsTableInput th {
	border: 0.5px solid #ddd;
}

.myShopsTableInput td {
	height: 45px;
}

.myShopsTableInput th {
	height: 30px;
}

.myShopsTableBodyWithoutScrollBarInput td {
	/*white-space: nowrap;*/
	text-overflow: ellipsis;
	/*overflow: hidden;*/
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
}

.myShopsTableBodyWithScrollBarInput td {
	white-space: nowrap;
	text-overflow: ellipsis;
	/*overflow: hidden;*/
}

.myShopsTableInput tr:nth-child(even){background-color: #f2f2f2;}

.myShopsTableInput tr:hover {background-color: #ddd;}

.myShopsTableInput th {
	text-align: center;
	background-color: rgba(10, 180, 10, 1);
	color: white;
}










.changeSettingLargeContainer {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	height: 255px;
	width:  25%;
	/*height: 190px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}
.changeSettingLargeContainerPassword {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	height: 295px;
	width:  25%;
	/*height: 190px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}

.changeSettingLargeContainerContent {
	position: absolute;
	margin: 3%;
	width: 94%;
	height: 90%;
}

.EditSettingsMessage {
	font-size: 27pt;
	margin-left: 5%;
}

.EditSettingsDeleteMyAccountMessage {
	font-size: 27pt;
	margin-left: 5%;
	margin-top: 3.5%;
	position: absolute;
}

.EditSettingsMessageDelete {
	margin-left: 5.5%;
	margin-top: 13%;
	margin-right: 7%;
	text-align: justify;
	position: absolute;
}
br {
	display: block;
	margin: 30px 0;
}

.changeSettingDeleteMyAccountContainer {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 2.5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	height: 305px;
	width:  27.5%;
	/*height: 190px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}


.changeSettingContainerName {
	vertical-align: middle;
	width: 40%;
	height: 100%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	position: absolute;
	margin-top: 5%;
	margin-left: 5%;
	font-size: 14pt;
}

.changeSettingDeleteMyAccountContainerName {
	vertical-align: middle;
	width: 40%;
	height: 100%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	position: absolute;
	margin-top: 35%;
	margin-left: 5%;
	font-size: 14pt;
}

.editSettingName {
	margin-top: 3%;
	width: 100%;
	height: 25px;
	/*position: absolute;*/
}

.inputFieldEditSettings {
	margin-top: 1.6%;
	height: 20px;
	font-size: 13.5pt;
	width: 100%;
}

.changeSettingContainerInput {
	vertical-align: middle;
	width: 45%;
	height: 20px;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	position: absolute;
	margin-left: 45%;
	margin-top: 5%;
}
.messageFormatPasswordMyProfile{
	position: absolute;
	font-size: 10pt;
	text-align: justify;
	width: 46%;
	margin-left: 45%;
	margin-top: 111px;
}

.changeSettingDeleteMyAccountContainerInput {
	vertical-align: middle;
	width: 45%;
	height: 20px;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	position: absolute;
	margin-left: 45%;
	margin-top: 36%;
}

.changeSettingSmallContainer {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	/*height: 22%;*/
	height: 190px;
	width:  22%;
	/*width:  418px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}

.changeSettingSmallContainerContent {
	position: absolute;
	margin: 3%;
	width: 94%;
	height: 90%;
}

.changeSettingContactPhoneContainer {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	/*height: 22%;*/
	height: 190px;
	width:  26%;
	/*width:  418px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}

.manageMyPSIContainer {
	position: absolute;
	margin-left: 5%;
	margin-top: 5%;
	border-color: coral;
	border: solid 0.1em;
	/*height: 22%;*/
	height: 190px;
	width:  26%;
	/*width:  418px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1000;
}

.buttonsEditMyEmail {
	position: absolute;
	margin-left: 11.6%;
	/*margin-top: 34%;*/
	margin-top: 153px;
	/*margin-top: 12.5%;*/
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}
.buttonsDeleteMyAccount {
	position: absolute;
	margin-left: 11.6%;
	/*margin-top: 34%;*/
	margin-top: 240px;
	/*margin-top: 12.5%;*/
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}
.buttonsEditMyPassword {
	position: absolute;
	margin-left: 30.6%;
	/*margin-top: 34%;*/
	margin-top: 193px;
	/*margin-top: 12.5%;*/
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}

.buttonsEditMyCurrency {
	position: absolute;
	margin-left: 12.1%;
	margin-top: 23%;
	/*margin-top: 5.8%;*/
	/*margin-top: 12.5%;*/
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}

.buttonsEditMyContactPhone {
	position: absolute;
	margin-left: 12.1%;
	/*margin-top: 22%;*/
	/*margin-top: 5.8%;*/
	margin-top: 18.4%;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 80%;
	height: 100%;
}


.EditSettingButtonLeft {
	position: absolute;
	width: 40%;
	height: 25px;
	margin-left: 10%;
	font-size: 13.0pt;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.EditSettingButtonLeft:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.EditSettingButtonRight {
	position: absolute;
	width: 40%;
	height: 25px;
	margin-left: 60%;
	font-size: 13.0pt;
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 13pt;
	background-color: rgba(10, 180, 10, 1);
}
.EditSettingButtonRight:hover {
	background-color: rgba(10, 180, 10, 0.65);
}

.EditSettingPassButtonLeft {
	position: absolute;
	width: 36%;
	height: 25px;
	margin-left: 0%;
	font-size: 13.0pt;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.EditSettingPassButtonLeft:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.EditSettingPassButtonRight {
	position: absolute;
	width: 36%;
	height: 25px;
	margin-left: 41%;
	font-size: 13.0pt;
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	background-color: rgba(10, 180, 10, 1);
}
.EditSettingPassButtonRight:hover {
	background-color: rgba(10, 180, 10, 0.65);
}

.buttonsEditMyShops {
	position: absolute;
	margin-left: 6.0%;
	margin-top: 73%;
	margin-top: 649px;
	/*border-color: coral;*/
	/*border: solid 0.3em;*/
	width: 85.8%;
	z-index: 1500;
}

.buttonsEditMyShopsLeft {
	position: absolute;
	width: 45%;
	margin-left: 5%;
	height: 32px;
	font-size: 18px;
	border: 1px solid rgba(50, 50, 50, 1);
	background-color: rgba(125, 125, 125, 0.25);
	color: rgba(10, 130, 10, 0.85);
}
.buttonsEditMyShopsLeft:hover {
	background-color: rgba(50, 50, 50, 0.25);
	color: white;
}

.buttonsEditMyShopsRight {
	position: absolute;
	width: 50%;
	margin-left: 55%;
	height: 32px;
	font-size: 18px;
	/*background-color: rgba(10, 165, 10, 1.0);*/
	color: white;
	/*border: 1px solid rgba(50, 50, 50, 1);*/
	/*background-color: rgba(125, 125, 125, 0.25);*/
	/*color: rgba(10, 130, 10, 0.85);*/
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 13pt;
	background-color: rgba(10, 180, 10, 1);
}
.buttonsEditMyShopsRight:hover {
	background-color: rgba(10, 180, 10, 0.65);
}


.ObscurerMyProfile {
	position:absolute !important;
	height: 100%;
	width: 100%;
	/*margin-top: -60px;*/
	/*border: solid 0.3em;*/
	border: none;
	background-color: rgba(105, 105, 105, 0.1);
	z-index: 200;
}
.ObscurerDeletion {
	position:absolute !important;
	height: 100%;
	width: 100%;
	/*margin-top: -60px;*/
	/*border: solid 0.3em;*/
	border: none;
	background-color: rgba(105, 105, 105, 0.3);
	z-index: 1100;
}

.messageWrongEmails {
	position: absolute;
	text-align: right;
	margin-left: -8%;
	margin-top: 30.0%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}
.messageWrongPasswords {
	position: absolute;
	text-align: right;
	margin-left: -8%;
	margin-top: 39.0%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}
.messageWrongPasswordsForDeleteWrongEmail {
	position: absolute;
	text-align: right;
	margin-left: -21%;
	margin-left: -8.5%;
	margin-top: 41.5%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}
.messageWrongEmailsForDelete {
	position: absolute;
	text-align: right;
	margin-left: -21%;
	margin-top: 39.0%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}

.inputFieldEditSettings2 {
	margin-top: 1.6%;
	height: 20px;
	font-size: 13.5pt;
	width: 100%;
	background-color: rgba(255, 0, 0, 0.10);
	border: solid rgba(255, 0, 0, 0.30) 2px;
}

.messageWrongContactPhone {
	position: absolute;
	text-align: right;
	margin-left: -8%;
	margin-top: 14.5%;
	/*background-color: green;*/
	width: 100%;
	height: 15px;
	/*text-align: justify;*/
	color: red;
	font-size: 10pt;
}
.ErrorMessageEmployeeName {
	position: absolute;
	margin-left: 21%;
	margin-top: 10.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	z-index: 1500;
	/*border: 1px red solid;*/
}
.ErrorMessagesInMyAccount{
	position: absolute;
	margin-left: 77.55%;
	margin-top: 10.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	z-index: 1500;
	/*border: 1px red solid;*/
}
.ErrorMessageShopName {
	position: absolute;
	margin-left: 10.5%;
	margin-top: 10.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	z-index: 1500;
}
.ErrorMessagesInMyAccountText {
	margin: 0;
}
.ErrorMessagesInMyAccountShops {
	position: absolute;
	margin-left: 77.55%;
	margin-top: 10.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	text-align: justify;
	color: red;
	font-size: 10pt;
	z-index: 1500;
	/*border: 1px red solid;*/
}
.ErrorMessagesInMyAccountShopsText {
	margin: 0;
}


.MessageOnSaveButton {
	position: absolute;
	margin-left: 65%;
	margin-top: -2.0%;
	/*background-color: green;*/
	/*width: 71.5%;*/
	height: 20px;
	width: 300px;
	text-align: right;
	color: red;
	font-size: 10pt;
	/*z-index: 7392486785;*/
	/*border: 1px red solid;*/
}

.legalTextContainersProfile {
	position: absolute;
	margin-top: 52.3%;
	width: 100%;
	height: 50px;
	background-color: #f2f2f2;;
}
.legalTextContainers1{
	position: absolute;
	margin-left: 5.3%;
	text-align: center;
	/*margin-top: -30%;*/
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.legalTextContainers1:hover {
	text-decoration: underline;
}
.legalTextContainers2{
	position: absolute;
	margin-left: 25.35%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers2:hover {
	text-decoration: underline;
}
.legalTextContainers3{
	position: absolute;
	margin-left: 47.5%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers3:hover {
	text-decoration: underline;
}
.legalTextContainers4{
	position: absolute;
	margin-left: 65.5%;
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers4:hover {
	text-decoration: underline;
}
.legalTextContainers5{
	position: absolute;
	margin-left: 87.2%;
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
}
.legalTextContainers5:hover {
	text-decoration: underline;
}

.legalTextContainers22{
	position: absolute;
	margin-left: 32.2%;
	margin-left: 19.5%;
	margin-left: 17.0%;
	/*margin-left: 36.5%;*/
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.legalTextContainers22:hover {
	text-decoration: underline;
}
.legalTextContainers32{
	position: absolute;
	margin-left: 61.2%;
	margin-left: 37.5%;
	margin-left: 32.0%;
	/*margin-left: 55%;*/
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.legalTextContainers32:hover {
	text-decoration: underline;
}
.legalTextContainers42{
	position: absolute;
	margin-left: 84.8%;
	margin-left: 53.0%;
	margin-left: 45.0%;
	/*margin-left: 19.0%;*/
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.legalTextContainers42:hover {
	text-decoration: underline;
}
.ContactSupport {
	position: absolute;
	margin-left: 84.8%;
	margin-left: 53.0%;
	margin-left: 75.0%;
	/*margin-left: 19.0%;*/
	/*margin-top: -45%;*/
	text-align: center;
	border: none;
	background-color: transparent;
	margin-top: 0.8%;
	font-size: 85%;
}
.ContactSupport:hover {
	text-decoration: underline;
}

.TIDIName{
	position: absolute;
	margin-left: 87.8%;
	margin-left: 85.8%;
	/*margin-left: 72.5%;*/
	/*font-size: 10.0pt;*/
	text-align: left;
	width: 11%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
	font-size: 85%;
}
.licensesButton {
	position: absolute;
	margin-left: 72.5%;
	margin-left: 60.0%;
	/*margin-left: 68%;*/
	/*margin-top: -45%;*/
	text-align: left;
	width: 10%;
	border: none;
	background-color: transparent;
	margin-top: 0.45%;
	font-size: 85%;
}
.licensesButton:hover {
	text-decoration: underline;
}

.showLegalTextBigProfile {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 60%;
	height: 55.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1200;
}
.showLegalTextSmallProfile {
	margin-top: 10%;
	margin-left: 20%;
	position: absolute;
	width: 60%;
	height: 40.5%;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.showLegalTextDeciderTitleDivSupport {
	margin-top: 18%;
	margin-left: 34.53%;
	/*margin-left: 25%;*/
	position: absolute;
	width: 30%;
	height: 120px;
	border: solid 0.1em;
	border-radius: 5px;
	background-color: #FFFFFF;
	z-index: 1000;
}
.buttonCloseLegalText {
	left: 97.5%;
	top: -0.1%;
	/*left: 1210px;*/
	position: absolute;
	width: 30px;
	height: 23px;
}
/*.buttonCloseLegalTextButton {*/
/*	width: 100%;*/
/*	height: 100%;*/
/*	font-size: 11.5pt;*/
/*}*/
.showLegalTextDeciderText{
	margin-top: 2%;
	font-size: 10pt;
}
.showCookiesDetailsCloseButtonMyProfile{
	position: absolute;
	margin-left: 97.5%;
	margin-top: -6.7%;
	text-align: center;
	width: 30px;
	height: 23px;
	/*margin-top: 0.8%;*/
}

.buttonsEditMyCompany {
	/*height: 5%;*/
	height: 26px;
	width: 70%;
	position: absolute;
	/*bottom: 30%;*/
	/*top: 72%;*/
	/*top: 504px;*/
	top: 554px;
	top: 484px;
	top: 600px;
	/*top: 204px;*/
	left: 15.7%;
	/*border: 3px solid;*/
	/*border-color: red;*/
}

.disclaimerTFN {
	position: absolute;
	margin-top: 85%;
	width: 420px;
	font-size: 9pt;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationCompanyAndSettings {
	position: absolute;
	margin-top: 95%;
	width: 420px;
	font-size: 9pt;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationCompanyAndSettingsEdit {
	position: absolute;
	margin-top: 78%;
	width: 420px;
	font-size: 9pt;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationEmployees {
	position: absolute;
	margin-top: 4%;
	width: 815px;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationShops{
	position: absolute;
	margin-top: 7%;
	width: 815px;
	font-size: 8.5pt;
	text-align: justify;
}
.disclaimerVeracityInformationCurrency {
	position: absolute;
	margin-top: 14.4%;
	width: 345px;
	font-size: 8.5pt;
	margin-left: 5%;
	text-align: justify;
}
.accountDeletionSuccessful {
	/*margin-top: 0%;*/
	position: absolute;
	margin-left: 8.0%;
	margin-top: 8%;
	border-color: coral;
	border: solid 0.1em;
	height: 115px;
	width:  16.5%;
	/*height: 190px;*/
	background: rgba(255, 255, 255, 1);
	z-index: 1200;
}
.accountDeletionSuccessfulText {
	margin-left: 10.5%;
	margin-top: 5%;
	margin-right: 7%;
	text-align: justify;
	position: absolute;
}
.accountDeletionSuccessfulButton {
	position: absolute;
	width: 40%;
	height: 25px;
	margin-left: 29%;
	margin-top: 65px;
	font-size: 13.0pt;
	border: 1px solid rgba(0, 50, 0, 0.85);
	color: white;
	font-size: 12pt;
	background-color: rgba(10, 180, 10, 1);
}
.accountDeletionSuccessfulButton:hover {
	background-color: rgba(10, 180, 10, 0.65);
}

.dropDownInMyProfileEmployeesTreatment {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 67px;
	top: -0px;
	transform: rotate(180deg);
}
.dropDownInMyProfileEmployeesTreatmentEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 67px;
	margin-top: -45px;
	transform: rotate(180deg);
}

.dropDownInMyProfileContactNumber {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 37px;
	top: -0px;
	transform: rotate(180deg);
}
.dropDownInMyProfileContactNumberEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 35px;
	margin-top: -45px;
	transform: rotate(180deg);
}
.dropDownInMyProfilePersonGenre {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 215px;
	margin-top: -45px;
	transform: rotate(180deg);
}
.dropDownInMyProfilePersonGenreEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 215px;
	margin-top: -45px;
	transform: rotate(180deg);
}
.dropDownInMyProfileCurrency {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 155px;
	top: -0px;
	transform: rotate(180deg);
}
.dropDownInMyProfileCurrencyEmpty {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	/*position: absolute;*/
	margin-left: 155px;
	margin-top: -45px;
	transform: rotate(180deg);
}
.dropDownInMyProfileProfile {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 10px 5px;
	border-color: transparent transparent black transparent;
	position: relative;
	margin-left: 215px;
	top: -0px;
	transform: rotate(180deg);
}
.errorMessageWhenUpdatingProfile {
	margin-left:  42%;
	margin-top:   18.0%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 80px;
	/*height: 700px;*/
	width: 18%;
	position: absolute;
	z-index: 2000;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid black;
}
.errorMessageWhenUpdatingProfileText {
	margin-left: 10%;
	margin-top: 5%;
}

.errorWhenLoading {
	margin-left:  42%;
	margin-top:   18.0%;
	/*border-color: red;*/
	/*border: solid 0.3em;*/
	/*height: 80%;*/
	height: 80px;
	/*height: 700px;*/
	width: 18%;
	position: absolute;
	z-index: 2000;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid black;
}
.errorWhenLoadingText {
	margin-left: 10%;
	margin-top: 5%;
}

.BackgroundMyProfile{
	background-color: white;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1000;
}

.PasswordContainerWithEyeIcon4 {
	margin-bottom: 3.9%;
	height: 23px;
	width: 100%;
	/*border: 1px solid red;*/
}
.eyePasswordMyProfile {
	height: 20px;
	width: 20px;
	position: absolute;
	right: 0%;
	margin-top: -8.0%;
	margin-top: -31px;
	margin-top: -23px;

}
.PasswordContainerWithEyeIconMyProfile2 {
	width: 100%;
	/*height: 110%;*/
	height: 22px;
	font-size: 100%;
	/*border: 1px solid red;*/
}
.deleteMyAccountInputFields {
	width: 100%;
	height: 100%;
	font-size: 100%;
}
.eyePasswordMyProfile2 {
	height: 20px;
	width: 20px;
	position: absolute;
	right: 0;
	margin-top: -9.0%;
	margin-top: -23px;
	/*margin-right: 6%;*/
}
.deleteMyAccountInputFieldsToAccomodateScreen {
	position: absolute;
	margin-top: 550%;
}


.body {
	/*background-color: rgba(46,139,87, 0.1);*/
	background-color: rgba(255, 255, 255, 0.1);
	/*background-color: red;*/
}